<template>
  <v-dialog
    v-model="dialog"
    max-width="1100"
    scrollable
  >
    <v-card>
      <v-card-title>
        Historial de modificaciones
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-0">
        <v-data-table
          class="cebra mt-2"
          :loading="load"
          :items="historial"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron cambios
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      historial: [],
      headers: [
        { text: 'Pre', value: 'pre', sortable: false, filterable: false },
        { text: 'Post', value: 'post', sortable: false, filterable: false },
        { text: 'Usuario', value: 'usuario', sortable: false, filterable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    value: Boolean,
    p_id: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.historial = []
        if (this.p_id) {
          this.load = true
          await this.$store.dispatch('cheques/get_historial', this.p_id)
            .then((res) => {
              this.historial = res.data
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        }
      }
    }
  }
}
</script>