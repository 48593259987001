<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTRO -->
      <v-row>
        <v-col cols="6" sm="3" md="2">
          Periodo
          <PeriodoPicker
            v-model="periodo"
            :fecha_minima="moment(new Date()).subtract(5, 'months').format('YYYY-MM')"
            :fecha_maxima="moment(new Date()).format('YYYY-MM')"
            @change="seleccionar_periodo()"
          />
        </v-col>
        <!-- OBJETIVOS -->
        <v-col cols="6" sm="9" md="10">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="6" md="5" xl="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-if="moment(moment(periodo, 'MM/YYYY').toDate()).year() >= 2025"
                  :elevation="hover && $vuetify.breakpoint.mdAndUp ? 12 : 2"
                  :class="hover && $vuetify.breakpoint.mdAndUp ? 'my-n2' : ''"
                  color="indigo"
                  @click="tipo = null"
                >
                  <v-card-title class="pt-5 white--text" style="font-size: 28px;">
                    <v-icon class="mr-4" color="white" large>fas fa-store-alt</v-icon>
                    Pto Vta
                  </v-card-title>
                  <v-card-text class="white--text" style="font-size: 22px;">
                    <div class="mt-2">Objetivo: {{ formatMoney(local.objetivo) }}</div>
                    <div class="mt-2">Alcanzado: {{ formatMoney(local.alcanzado) }}</div>
                    <div class="mt-2">Porcentaje: {{ local.porcentaje.toFixed(2) }} %</div>
                  </v-card-text>
                </v-card>
                <v-card
                  v-else
                  :elevation="hover && $vuetify.breakpoint.mdAndUp ? 12 : 2"
                  :class="hover && $vuetify.breakpoint.mdAndUp ? 'my-n2' : ''"
                  color="indigo"
                  @click="ver_detalle('ACC')"
                >
                  <v-card-title class="pt-5 white--text" style="font-size: 28px;">
                    <v-icon class="mr-4" color="white" large>fas fa-headphones</v-icon>
                    Accesorios
                  </v-card-title>
                  <v-card-text class="white--text" style="font-size: 22px;">
                    <div class="mt-2">Objetivo: {{ formatMoney(accesorios.objetivo) }}</div>
                    <div class="mt-2">Alcanzado: {{ formatMoney(accesorios.alcanzado) }}</div>
                    <div class="mt-2">Porcentaje: {{ accesorios.porcentaje.toFixed(2) }} %</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="6" md="5" xl="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover && $vuetify.breakpoint.mdAndUp ? 12 : 2"
                  :class="hover && $vuetify.breakpoint.mdAndUp ? 'my-n2' : ''"
                  color="success"
                  @click="ver_detalle('VENTAS')"
                >
                  <v-card-title class="pt-5 white--text" style="font-size: 28px;">
                    <v-icon class="mr-4" color="white" large>fas fa-{{ moment(moment(periodo, 'MM/YYYY').toDate()).year() >= 2025 ? 'user' : 'money-check-alt' }}</v-icon>
                    {{ moment(moment(periodo, 'MM/YYYY').toDate()).year() >= 2025 ? 'Vendedor' : 'Total facturado' }}
                  </v-card-title>
                  <v-card-text class="white--text" style="font-size: 22px;">
                    <div class="mt-2">Objetivo: {{ formatMoney(ventas.objetivo) }}</div>
                    <div class="mt-2">Alcanzado: {{ formatMoney(ventas.alcanzado) }}</div>
                    <div class="mt-2">Porcentaje: {{ ventas.porcentaje.toFixed(2) }} %</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- 05-08-2024 por MMURILLO, detalle de Operaciones -->
      <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="tipo != null">
        <v-col cols="12">
          <v-card
            elevation="2"
            outlined
            id="seccionDetalles"
          >
            <v-card-title>
              <v-icon
                :color="tipo == 'ACC' ? 'indigo' : 'success'"
                left
              >
                fas fa-{{ tipo == 'ACC' ? 'headphones' : 'money-check-alt' }}
              </v-icon>
              {{ tipo == 'ACC' ? 'Accesorios' : 'Total facturado' }} {{ periodo }}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
          <v-data-table
            class="cebra"
            sort-by="num_pedido"
            :headers="headers"
            :items="detalle"
            :loading="load"
            :search="search"
            :footer-props="{
              'items-per-page-options': [5, 10, 15],
              'show-first-last-page': true,
              'show-current-page': true
            }"
            sort-desc
            dense
          >
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.importe_base`]="{ item }">
              {{ formatMoney(item.importe_base) }}
            </template>
            <template v-slot:[`item.importe`]="{ item }">
              {{ formatMoney(item.importe) }}
            </template>
            <!-- fila del total -->
            <template slot="body.append">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="body-2 font-weight-bold">
                  {{ formatMoney(total) }}
                </th>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- GRAFICA -->
      <v-row>
        <v-col cols="12">
          <apexchart type="line" height="350" :options="options" :series="moment(moment(periodo, 'MM/YYYY').toDate()).year() >= 2025 ? series_new : series"></apexchart>
        </v-col>
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <DetalleObjetivosVend
      v-model="dialog"
      :codigo="codigo"
      :periodo="periodo"
      :tipo="tipo"
    />
  </v-row>
</template>

<script>
import PeriodoPicker from '../../components/util/PeriodoPicker'
import { format_money } from '../../util/utils'
import DetalleObjetivosVend from '../../components/generales/DetalleObjetivosVend'
import moment from 'moment'
import SearchDataTable from '../../components/util/SearchDataTable.vue'

export default {
  data () {
    return {
      formatMoney: format_money,
      moment: moment,
      dialog: false,
      codigo: null,
      tipo: null,
      periodo: moment(new Date()).startOf('month').format('MM/YYYY'),
      objetivos: [],
      series: [],
      series_new: [],
      options: {},
      accesorios: {
        objetivo: 0,
        alcanzado: 0,
        porcentaje: 0
      },
      ventas: {
        objetivo: 0,
        alcanzado: 0,
        porcentaje: 0
      },
      local: {
        objetivo: 0,
        alcanzado: 0,
        porcentaje: 0
      },
      detalle: [],
      total: 0,
      search: '',
      load: false,
      headers: [
        { text: 'Nro. Ped.', value: 'num_pedido', align: 'end' },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Local', value: 'local' },
        { text: 'Artículo', value: 'articulo' },
        { text: 'FP.', value: 'forma_de_pago' },
        { text: 'Grupo', value: 'grupo', align: 'center' },
        { text: 'Imp. Base', value: 'importe_base', align: 'right' },
        { text: 'Sum. Obj.', value: 'importe', align: 'right' }
      ]
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('objetivos/get_codigo_vend')
      .then(async (res) => {
        if (res.data) {
          this.codigo = res.data
          // obtengo los objetivos
          await this.$store.dispatch('objetivos/get_objetivos_vend', this.codigo)
            .then((response) => {
              this.objetivos = response.data
              this.seleccionar_periodo()
              // seteo los datos del grafico
              let alcanzado_acc = []
              let objetivo_acc = []
              let alcanzado_ventas = []
              let objetivo_ventas = []
              let alcanzado_local = []
              let objetivo_local = []
              let periodos = []
              for (const periodo of this.objetivos) {
                periodos.push(periodo.periodo)
                alcanzado_acc.push(periodo.alcanzado_acc.toFixed(2))
                objetivo_acc.push(periodo.objetivo_acc.toFixed(2))
                alcanzado_ventas.push(periodo.alcanzado_ventas.toFixed(2))
                objetivo_ventas.push(periodo.objetivo_ventas.toFixed(2))
                alcanzado_local.push(periodo.alcanzado_local.toFixed(2))
                objetivo_local.push(periodo.objetivo_local.toFixed(2))
              }
              this.series = [
                { name: 'Alcanzado Accesorios', type: 'column', data: alcanzado_acc, color: '#9FA8DA' },
                { name: 'Objetivo Accesorios', type: 'line', data: objetivo_acc, color: '#3F51B5' },
                { name: 'Alcanzado Ventas', type: 'column', data: alcanzado_ventas, color: '#A5D6A7' },
                { name: 'Objetivo Ventas', type: 'line', data: objetivo_ventas, color: '#4CAF50' }
              ]
              this.series_new = [
                { name: 'Alcanzado Pto Vta', type: 'column', data: alcanzado_local, color: '#9FA8DA' },
                { name: 'Objetivo Pto Vta', type: 'line', data: objetivo_local, color: '#3F51B5' },
                { name: 'Alcanzado Ventas', type: 'column', data: alcanzado_ventas, color: '#A5D6A7' },
                { name: 'Objetivo Ventas', type: 'line', data: objetivo_ventas, color: '#4CAF50' }
              ]
              this.options = {
                chart: {
                  toolbar: { show: false },
                  foreColor: '#646464'
                },
                tooltip: { enabled: false },
                labels: periodos,
                stroke: {
                  width: [0, 2, 0, 2],
                  curve: 'smooth'
                },
                plotOptions: {
                  bar: { columnWidth: '50%' }
                },
                yaxis: {
                  min: 0,
                  labels: {
                    formatter: function (value) {
                      return format_money(value)
                    }
                  }
                }
              }
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        else {
          this.$swal.fire({
            icon: 'warning',
            title: 'No se encontraron datos de los últimos periodos.'
          })
        }
      })
      .catch((error) => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  },
  components: {
    PeriodoPicker,
    DetalleObjetivosVend,
    SearchDataTable
  },
  methods: {
    seleccionar_periodo () {
      this.tipo = null
      this.detalle = []
      const objetivo = this.objetivos.find(obj => obj.periodo == this.periodo)
      if (objetivo) {
        this.accesorios = {
          objetivo: objetivo.objetivo_acc,
          alcanzado: objetivo.alcanzado_acc,
          porcentaje: objetivo.procentaje_acc
        }
        this.ventas = {
          objetivo: objetivo.objetivo_ventas,
          alcanzado: objetivo.alcanzado_ventas,
          porcentaje: objetivo.porcentaje_ventas
        }
        this.local = {
          objetivo: objetivo.objetivo_local,
          alcanzado: objetivo.alcanzado_local,
          porcentaje: objetivo.procentaje_local
        }
      }
    },
    async ver_detalle (tipo) {
      if (this.codigo) {
        this.tipo = tipo
        // 05-08-2024 por MMURILLO, dejamos de usar el modal por la cantidad de elementos a mostrar
        // this.dialog = true
        this.detalle = []
        this.total = 0
        this.$store.state.loading = true
        await this.$store.dispatch('objetivos/get_detalles', {
          codigo: this.codigo,
          periodo: this.periodo,
          tipo: this.tipo
        })
          .then((res) => {
            this.detalle = res.data.detalle
            this.total = res.data.total.toFixed(2)
            this.$vuetify.goTo('#seccionDetalles')
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
          .finally(() => this.$store.state.loading = false)
      }
    }
  }
}
</script>