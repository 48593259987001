import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initLiqMensuales({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/initLiqMensuales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initLiqMensuales: ' + error.message
          })
        }
      })
    },
    async getLiqMensuales({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let liqPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/getLiqMensuales?forma_cobro_id=${datos.forma_cobro_id}&tarjeta_codigo=${datos.tarjeta_codigo}&liquidacion_id=${datos.liquidacion_id}&nro_resumen=${datos.nro_resumen}&nro_comercio=${datos.nro_comercio}&periodo=${datos.periodo}&empresa_id=${datos.empresa_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let liqs = await liqPeticion.json()
          liqs.msj = liqs.message
          return resolve(liqs)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLiqMensuales: ' + error.message
          })
        }
      })
    },
    async getLiqAsociadas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let liqPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/getLiqAsociadas?liquidacion_id=${datos.liquidacion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let liqs = await liqPeticion.json()
          liqs.msj = liqs.message
          return resolve(liqs)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLiqAsociadas: ' + error.message
          })
        }
      })
    },
    async initLiqMensual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/initLiqMensual?liquidacion_id=${datos.liquidacion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initLiqMensual: ' + error.message
          })
        }
      })
    },
    async saveBaseImponible({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let savePeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/saveBaseImponible`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let save = await savePeticion.json()
          save.msj = save.message
          return resolve(save)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo saveBaseImponible: ' + error.message
          })
        }
      })
    },
    async syncLiqMensual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/syncLiqMensual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let sync = await syncPeticion.json()
          sync.msj = sync.message
          return resolve(sync)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncLiqMensual: ' + error.message
          })
        }
      })
    },
    async auditar({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let auditarPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/auditarLiqMensual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let auditar = await auditarPeticion.json()
          auditar.msj = auditar.message
          return resolve(auditar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo auditar: ' + error.message
          })
        }
      })
    },
    async saveCambiosEnc({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let savePeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/saveCambiosEnc`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let save = await savePeticion.json()
          save.msj = save.message
          return resolve(save)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo saveCambiosEnc: ' + error.message
          })
        }
      })
    },
    async generaLiqMensual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let generaPeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/generarLiqMensual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let genera = await generaPeticion.json()
          genera.msj = genera.message
          return resolve(genera)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generaLiqMensual: ' + error.message
          })
        }
      })
    },
    async existeLiqMensual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let existePeticion = await fetch(`${config.BASE_URL}/adminLiquidaciones/existeLiqMensual?periodo=${datos.periodo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let existe = await existePeticion.json()
          existe.msj = existe.message
          return resolve(existe)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo existeLiqMensual: ' + error.message
          })
        }
      })
    }
  }
}