<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-icon
          :color="tipo == 'ACC' ? 'indigo' : 'success'"
          left
        >
          fas fa-{{ tipo == 'ACC' ? 'headphones' : 'money-check-alt' }}
        </v-icon>
        {{ tipo == 'ACC' ? 'Accesorios' : 'Total facturado' }} {{ periodo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          sort-by="num_pedido"
          :headers="headers"
          :items="detalle"
          :loading="load"
          :search="search"
          :footer-props="{
            'items-per-page-options': [5, 10, 15],
            'show-first-last-page': true,
            'show-current-page': true
          }"
          sort-desc
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            {{ formatMoney(item.importe) }}
          </template>
          <!-- fila del total -->
          <template slot="body.append">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th class="body-2 font-weight-bold">
                {{ formatMoney(total) }}
              </th>
            </tr>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="false" class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable'
import { format_money } from '../../util/utils'
import moment from 'moment'

export default {
  data () {
    return {
      formatMoney: format_money,
      moment: moment,
      load: false,
      total: 0,
      search: '',
      detalle: [],
      headers: [
        { text: 'Pedido número', value: 'num_pedido', align: 'end' },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Artículo', value: 'articulo' },
        { text: 'Local', value: 'local' },
        { text: 'Importe', value: 'importe' }
      ]
    }
  },
  props: {
    value: Boolean,
    codigo: Number,
    periodo: String,
    tipo: String
  },
  components: {
    SearchDataTable
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.search = ''
        this.total = 0
        this.detalle = []
        this.load = true
        await this.$store.dispatch('objetivos/get_detalles', {
          codigo: this.codigo,
          periodo: this.periodo,
          tipo: this.tipo
        })
          .then((res) => {
            this.detalle = res.data.detalle
            this.total = res.data.total.toFixed(2)
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      }
    }
  }
}
</script>