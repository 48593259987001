<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nueva ? 'Nueva financiación' : `Financiación: ${financiacion_copy.descripcion}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nueva && existe && financiacion_copy.inhabilitada != 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="12" sm="4" md="2" class="py-0">
              <v-autocomplete
                v-model="financiacion.empresa_codigo"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="editar ? [rules.required] : []"
                :autofocus="editar"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="get_cuotas(); get_locales(); financiacion.cuota_codigo = null; financiacion.locales = []; tarjetas_masivo = []; cuotas_masivo = []"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" class="py-0">
              <v-text-field
                v-model="financiacion.descripcion"
                label="Descripción"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="5" class="py-0">
              <v-autocomplete
                v-model="financiacion.forma_cobro_codigo"
                label="Forma de cobro"
                item-text="nombre"
                item-value="id"
                :items="formas_cobro"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                :multiple="cadena_vacia(id)"
                :chips="cadena_vacia(id)"
                :deletable-chips="cadena_vacia(id)"
                :clearable="cadena_vacia(id)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="financiacion.forma_pago_codigo"
                label="Forma de pago"
                item-text="nombre"
                item-value="id"
                :items="formas_pago"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="financiacion.cuota_codigo = null; financiacion.tarjeta_codigo = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="3" xl="2" class="py-0">
              <FechaPicker
                v-model="financiacion.desde"
                label="Vigencia desde"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" sm="4" md="3" xl="2" class="py-0">
              <FechaPicker
                v-model="financiacion.hasta"
                label="Vigencia hasta"
                :disabled="!editar"
                :clearable="editar"
              />
            </v-col>
            <v-col cols="6" sm="3" md="3" xl="1" class="py-0">
              <v-text-field
                v-model="financiacion.alicuota"
                label="Alicuota"
                type="number"
                suffix="%"
                :rules="nueva ? [rules.required] : []"
                :readonly="!nueva || !editar"
                :filled="!nueva || !editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <template v-if="masivo">
              <v-col cols="12" sm="4" class="d-flex py-0">
                <v-autocomplete
                  v-model="tarjetas_masivo"
                  label="Tarjetas"
                  item-text="nombre"
                  item-value="codigo"
                  :items="tarjetas_masivo"
                  :filled="financiacion.empresa_codigo == null"
                  small-chips
                  multiple
                  readonly
                  outlined
                  dense
                ></v-autocomplete>
                <v-btn
                  class="ml-2"
                  :color="tarjetas_masivo.length == 0 ? 'success' : 'indigo'"
                  :disabled="financiacion.empresa_codigo == null || !editar"
                  small
                  icon
                  @click="dialog_masiva = true"
                >
                  <v-icon>{{ tarjetas_masivo.length == 0 ? 'fas fa-plus' : 'fas fa-pen' }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="5" class="py-0">
                <v-autocomplete
                  v-model="cuotas_masivo"
                  label="Cuotas"
                  item-text="nombre"
                  item-value="codigo"
                  :items="cuotas_masivo"
                  :filled="financiacion.empresa_codigo == null"
                  small-chips
                  multiple
                  readonly
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom v-if="cuotas_masivo.length > 0">
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="info" v-on="on">
                          <v-icon>fas fa-info</v-icon>
                        </v-btn>
                      </template>
                      <p 
                        v-for="(item, index) in cuotas_masivo" :key="index"
                        class="ma-0"
                      >
                        {{ cadena_vacia(financiacion.descripcion) ? item.nombre : (item.nombre + ' - ' + financiacion.descripcion) }}
                      </p>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" sm="4" md="3" class="py-0">
                <v-autocomplete
                  v-model="financiacion.tarjeta_codigo"
                  label="Tarjeta"
                  item-text="nombre"
                  item-value="codigo"
                  :items="tarjetas"
                  :rules="editar && requiere_tarjeta == 1 ? [rules.required] : []"
                  :readonly="!editar || !requiere_tarjeta"
                  :filled="!editar || !requiere_tarjeta"
                  validate-on-blur
                  outlined
                  dense
                  @change="get_cuotas(); financiacion.cuota_codigo = null"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="py-0">
                <v-autocomplete
                  v-model="financiacion.cuota_codigo"
                  label="Cuota"
                  item-text="nombre"
                  item-value="codigo"
                  :hint="`** Cuota = ${(cuotas != null && cuotas != undefined && cuotas.length > 0 && !cadena_vacia(financiacion.cuota_codigo)) ? cuotas.find(c => c.codigo == financiacion.cuota_codigo).cuotas : ''} | Cuota interna = ${ (cuotas != null && cuotas != undefined && cuotas.length > 0 && !cadena_vacia(financiacion.cuota_codigo)) ? cuotas.find(c => c.codigo == financiacion.cuota_codigo).cuota_interna : '' } | Plan de Cuotas = ${(cuotas != null && cuotas != undefined && cuotas.length > 0 && !cadena_vacia(financiacion.cuota_codigo)) ? cuotas.find(c => c.codigo == financiacion.cuota_codigo).plan : ''}`"
                  :items="cuotas"
                  :persistent-hint="financiacion.cuota_codigo && cuotas.length > 0"
                  :rules="editar && requiere_tarjeta == 1 ? [rules.required] : []"
                  :readonly="!editar || !requiere_tarjeta"
                  :filled="!editar || !requiere_tarjeta"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </template>
            <v-col cols="6" sm="4" md="2" class="py-0">
              <v-autocomplete
                v-model="financiacion.grupo"
                label="Grupo Comisiones"
                item-text="grupo_nombre"
                item-value="grupo_id"
                :items="grupos"
                :readonly="!editar || !cadena_vacia(id)"
                :filled="!editar || !cadena_vacia(id)"
                :rules="(!editar || !cadena_vacia(id)) ? [] : [rules.required]"
                :clearable="nueva"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="nueva && editar"
              :cols="masivo ? 3 : 2" :sm="masivo ? 3 : 3" :md="masivo ? 3 : 2"
              class="d-flex py-0"
              style="margin-top: 2px; margin-bottom: 4px;"
            >
              <v-btn
                :color="masivo ? 'purple' : 'cyan'"
                small
                dark
                @click="format_masivo()"
              >
                <v-icon small left>
                  fas fa-{{ masivo ? 'credit-card' : 'layer-group' }}
                </v-icon>
                Carga {{ masivo ? 'individual' : 'masiva' }}
              </v-btn>
            </v-col>
            <v-col
              v-if="!nueva"
              cols="6" sm="4" md="2"
              class="py-sm-0 pt-0 pb-8">
              <v-switch
                v-model="financiacion.inhabilitada"
                label="Inhabilitada"
                class="my-0"
                :readonly="!editar"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0 d-flex">
              <v-checkbox
                class="align-start"
                v-model="financiacion.fp_empleado"
                label="Aplica para Empleados"
                :true-value="1"
                :false-value="null"
                :readonly="!editar"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <!-- comentado el 15-05-2024 por MMURILLO, lo colocamos en el hint del autocomplete Cuota -->
            <!-- <v-col
              v-if="financiacion.cuota_codigo && cuotas.length > 0"
              cols="12" sm="4"
              class="d-flex d-sm-inline body-2 py-0"
            >
              <div>
                ** Cuota = {{ cuotas.find(c => c.codigo == financiacion.cuota_codigo).cuotas }}
              </div>
              <div style="margin-left: 18px">
                Cuota interna = {{ cuotas.find(c => c.codigo == financiacion.cuota_codigo).cuota_interna }}
              </div>
            </v-col> -->
            <!-- 05-08-2024 por MMURILLO, agregamos el Grupo de Comisiones -->
            <!-- CARD DE CONFIGURACION -->
            <v-col cols="12" class="mt-2 pt-2 pt-sm-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Habilitar para
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-2 mb-4">
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.sucursales"
                        label="Sucursales"
                        item-text="nombre"
                        item-value="id"
                        :items="sucursales"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                        @change="get_locales(); financiacion.locales = []; financiacion.listas = []"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.locales"
                        label="Locales"
                        item-text="nombre"
                        item-value="id"
                        :items="locales"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.canales"
                        label="Canales"
                        item-text="nombre"
                        item-value="id"
                        :items="canales"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.rubros"
                        label="Rubros"
                        item-text="nombre"
                        item-value="codigo"
                        :items="rubros"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                        @change="get_categorias(); financiacion.categorias = []"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.categorias"
                        label="Categorias"
                        item-text="nombre"
                        item-value="codigo"
                        :items="categorias_filtradas"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.marcas"
                        label="Marcas"
                        item-text="nombre"
                        item-value="codigo"
                        :items="marcas"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <!-- Agregado el 06-06-2024 por MMURILLO -->
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.dias"
                        label="Dias"
                        item-text="dia"
                        item-value="dia_id"
                        :items="dias_semana"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-autocomplete
                        v-model="financiacion.listas"
                        label="Listas Variables"
                        item-text="nombre"
                        item-value="id"
                        :items="listas.filter(lis => (financiacion.sucursales.find(suc => suc == lis.sucursal) && lis.inhabilitado != 1) ||
                                               (!nueva && financiacion.listas.find(fcl => fcl == lis.id)))"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-4">
                      <ArticulosActivos
                        v-model="articulos"
                        :disabled="!editar"
                      />
                    </v-col>
                    <v-col cols="12" class="pt-4 pb-4">
                      <v-card>
                        <v-card-title class="pb-0 pt-2">
                          Artículos exceptuados
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialog"
                            max-width="800px"
                            :persistent="load"
                            scrollable
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="mt-n10"
                                color="info"
                                title="Buscar artículo"
                                :disabled="!editar || articulos.length !=0"
                                small
                                fab
                              >
                                <v-icon small>fas fa-search</v-icon>
                              </v-btn>
                            </template>
                            <v-card :disabled="load">
                              <v-card-title>
                                Artículos
                                <v-spacer></v-spacer>
                                <v-btn
                                  icon
                                  @click="dialog = false"
                                >
                                  <v-icon>fas fa-times</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text class="">
                                <v-data-table
                                  class="cebra"
                                  sort-by="nombre"
                                  :search="search"
                                  :loading="load"
                                  :headers="headers"
                                  :items="articulos_activos"
                                  :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
                                  dense
                                >
                                  <template v-slot:top>
                                    <v-row class="pb-2">
                                      <v-col cols="12" sm="6">
                                        <SearchDataTableVue
                                          v-model="search"
                                        />
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                      color="success"
                                      title="Agregar"
                                      class="mr-2"
                                      small
                                      @click="agregar_articulo(item)"
                                    >
                                      fas fa-check-circle
                                    </v-icon>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-card-title>
                        <v-card-text class="pb-0">
                          <v-data-table
                            class="cebra mt-2"
                            sort-by="nombre"
                            :headers="headers"
                            :items="articulosNoAplica"
                            dense
                          >
                            <template v-slot:top>
                              <v-row class="pb-2 mb-2">
                                <v-col cols="12" sm="3" md="2" class="py-1">
                                  Cod. Interno
                                  <v-text-field
                                    v-model="filtro.codigo"
                                    ref="artCod"
                                    :readonly="!editar"
                                    :filled="!editar"
                                    hide-details
                                    outlined
                                    dense
                                    @blur="get_articulo(filtro.codigo)"
                                    @keypress="enterKey"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5" md="4" class="py-1">
                                  Nombre
                                  <v-text-field
                                    v-model="filtro.nombre"
                                    hide-details
                                    outlined
                                    readonly
                                    filled
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                color="error"
                                title="Quitar"
                                class="mr-2"
                                :disabled="!editar"
                                small
                                @click="quitar_articulo(item)"
                              >
                                fas fa-times-circle
                              </v-icon>
                            </template>
                            <template v-slot:no-data>
                              <v-alert
                                class="mx-auto mt-4"
                                max-width="400"
                                type="warning"
                                border="left"
                                dense
                                text
                              >
                                No hay artículos seleccionados
                              </v-alert>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <v-row class="d-flex pr-3 my-3" :class="pantallaChica ? 'justify-center' : 'justify-end'">
      <v-btn
        class="ml-3 mt-2"
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3 mt-2"
        @action="masivo ? guardar_masivo() : guardar()"
      />
    </v-row>
    <!-- componentes -->
    <FnTarjetaMasiva
      v-model="dialog_masiva"
      :p_tarjetas.sync="tarjetas_masivo"
      :p_cuotas.sync="cuotas_masivo"
      :p_empresa_id="financiacion.empresa_codigo"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { order_list_by, cadenaVacia } from '../../util/utils'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import ArticulosActivos from '../../components/util/ArticulosActivos'
import FechaPicker from '../../components/util/FechaPicker'
import FnTarjetaMasiva from '../../components/auditoria/FnTarjetaMasiva'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'

export default {
  data () {
    return {
      id: this.$route.params.id,
      cadena_vacia: cadenaVacia,
      nueva: false,
      editar: false,
      existe: false,
      masivo: false,
      dialog_masiva: false,
      requiere_tarjeta: false,
      cuotas: [],
      listas: [],
      locales: [],
      articulos: [],
      cuotas_masivo: [],
      tarjetas_masivo: [],
      categorias_filtradas: [],
      financiacion: {
        desde: moment(new Date()).format('DD/MM/YYYY'),
        hasta: null,
        empresa_codigo: null,
        tarjeta_codigo: null,
        cuota_codigo: null,
        forma_pago_codigo: null,
        forma_cobro_codigo: null,
        descripcion: null,
        alicuota: null,
        sucursales: [],
        canales: [],
        rubros: [],
        categorias: [],
        locales: [],
        listas: [],
        marcas: [],
        dias: [],
        grupo: null,
        fp_empleado: null,
      },
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      // variables de respaldo
      articulos_copy: [],
      financiacion_copy: {},
      load:false,
      dialog: false,
      articulosNoAplica:[],
      articulosNoAplica_copy:[],
      filtro:{
        codigo: null,
        nombre: null,
      },
      search: '',
      headers: [
        { text: 'Cod. Interno', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoría', value: 'categoria_nombre' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
      grupos: [],
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  async created () {
    await this.get_datos()
    // si el id existe es pq esta visualizando, si no existe está creando
    if (this.id) {
      await this.get_financiacion()
      this.get_categorias()
      this.get_locales()
    } else {
      this.editar = true
      this.nueva = true
    }
  },
  computed: {
    ...mapGetters(['una_empresa']),
    ...mapState(['empresas', 'sucursales', 'canales', 'dias_semana']),
    ...mapState('tarjetas', ['tarjetas']),
    ...mapState('pagos', ['formas_pago']),
    ...mapState('cobros', ['formas_cobro']),
    ...mapState('rubros', ['rubros']),
    ...mapState('marcas', ['marcas']),
    ...mapState('categorias', ['categorias']),
    ...mapState('articulos', ['articulos_activos'])
  },
  components: {
    BtnConfirmar,
    ArticulosActivos,
    FechaPicker,
    FnTarjetaMasiva,
    SearchDataTableVue
  },
  methods: {
    async guardar_masivo () {
      if (this.$refs.form.validate()) {
        if (!this.financiacion.desde) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una vigencia desde',
            color: 'orange'
          })
        }
        // valido que haya seleccionado al menos una cuota de cada tarjeta que selecciono
        for (const tarjeta of this.tarjetas_masivo) {
          const cuotas = this.cuotas_masivo.filter(cm => cm.tarjeta == tarjeta.id)
          if (cuotas.length == 0) {
            return this.$store.dispatch('show_snackbar', {
              text: 'No seleccionó ninguna cuota para ' + tarjeta.nombre,
              color: 'orange'
            })
          }
        }
        // formateo el array de articulos
        this.financiacion.articulos = this.get_array_articulos();
        // genera el array de articulos exceptuados
        this.financiacion.articulosNoAplica = this.get_array_articulosNoAplica();

        this.financiacion.cuotas = this.cuotas_masivo
        // inserta la financiacion masiva
        await this.$store.dispatch('financiaciones/nueva_masiva', this.financiacion)
          .then((res) => {
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
            this.editar = false
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }else{
        this.$store.dispatch('show_snackbar', {text: 'Se requiere completar los campos faltantes', color: 'info'})
      }
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        if (this.financiacion.desde) {
          this.$store.state.loading = true
          if (this.nueva) {

            let equivalentes = []
            let cantidad = 0
            let rechazado = false

            // obtiene las financiaciones equivalentes a la que está por crear
            await this.$store.dispatch('financiaciones/get_equivalentes', {
              empresa: this.financiacion.empresa_codigo,
              tarjeta: this.financiacion.tarjeta_codigo,
              cuota: this.financiacion.cuota_codigo,
              forma_pago: this.financiacion.forma_pago_codigo,
              forma_cobro: this.financiacion.forma_cobro_codigo,
            })
              .then((res) => {
                cantidad = res.size
                equivalentes = res.data
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            
            if (cantidad > 0) {

              // formatea el estilo de las financiaciones quivalentes
              let mensaje = '<div style="text-align: left">'
              equivalentes.forEach(item => {
                if (item.alicuota > 0) item.alicuota = item.alicuota.toFixed(2)
                mensaje += `<p style="font-size: 16px; margin-top: 10px; margin-bottom: 0px"><i class="fas fa-credit-card" style="color: orange; margin-right: 5px"></i> ${item.descripcion} - <strong>Alicuota: ${item.alicuota}%</strong></p>`
              })
              mensaje += '</div>'

              // espera que responda al mensaje de advertencia
              await this.$swal.fire({
                icon: 'warning',
                title: `Se encontraron ${cantidad} financiaciones equivalentes ¿Desea continuar?`,
                html: mensaje,
                width: '675px',
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                showCancelButton: true,
                focusCancel: true
              })
                .then((res) => {
                  // si pone que no o hace click fuera del modal no hace nada
                  if (!res.isConfirmed) {
                    rechazado = true
                  }
                })
            }

            // el return hay que hacerlo fuera del then porque adentro no anda
            if (rechazado) {
              this.$store.state.loading = false
              return
            }

            // formatea el array de articulos
            this.financiacion.articulos = this.get_array_articulos()
            // genera el array de articulos exceptuados
            this.financiacion.articulosNoAplica = this.get_array_articulosNoAplica();

            // inserta la nueva financiacion
            await this.$store.dispatch('financiaciones/nueva', this.financiacion)
              .then((res) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                this.refrescar_datos()
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })

          } else {
            // solo si cambio algun valor actualiza
            if(
                (JSON.stringify(this.financiacion) != JSON.stringify(this.financiacion_copy)) || 
                ((JSON.stringify(this.articulos) != JSON.stringify(this.articulos_copy))) ||
                ((JSON.stringify(this.articulosNoAplica) != JSON.stringify(this.articulosNoAplica_copy)))
              ) {

              // variables para determinar si modifico o no un array
              let suc      = 0
              let loc      = 0
              let can      = 0
              let rub      = 0
              let cat      = 0
              let mar      = 0
              let lvb      = 0
              let art      = 0
              let artExcep = 0
              let dias     = 0

              // verifica si modifico alguno de los array de 'Habilitar para'
              if (this.comparar_valores('sucursales')) suc = 1
              if (this.comparar_valores('locales')) loc = 1
              if (this.comparar_valores('canales')) can = 1
              if (this.comparar_valores('rubros')) rub = 1
              if (this.comparar_valores('categorias')) cat = 1
              if (this.comparar_valores('marcas')) mar = 1
              if (this.comparar_valores('listas')) lvb = 1
              if (this.comparar_valores('dias')) dias = 1
              if (JSON.stringify(this.articulos) != JSON.stringify(this.articulos_copy)) art = 1
              if (JSON.stringify(this.articulosNoAplica) != JSON.stringify(this.articulosNoAplica_copy)) artExcep = 1

              // genera el array de articulos
              this.financiacion.articulos         = this.get_array_articulos()

              // genera el array de articulos exceptuados
              this.financiacion.articulosNoAplica = this.get_array_articulosNoAplica();

              // crea la data para enviar
              let data      = await JSON.parse(JSON.stringify(this.financiacion))
              data.suc      = suc
              data.loc      = loc
              data.can      = can
              data.rub      = rub
              data.cat      = cat
              data.mar      = mar
              data.lvb      = lvb
              data.art      = art
              data.artExcep = artExcep
              data.dia      = dias
              await this.$store.dispatch('financiaciones/editar', data)
                .then((res) => {
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'success',
                  })
                  this.refrescar_datos()
                })
                .catch(error => {
                  this.$store.dispatch('show_snackbar', {
                    text: error.message,
                    color: 'error',
                  })
                })
              
            } else {
              this.$store.dispatch('show_snackbar', {
                text: 'No se realizaron cambios en la financiacion: los campos nuevos son iguales a los anteriores',
                color: 'warning',
                timeout: 4000
              })
            }
          }

          this.$store.state.loading = false

        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una vigencia desde',
            color: 'warning'
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {text: 'Se requiere completar los campos faltantes', color: 'info'})
      }
    },
    async get_financiacion () {
      this.$store.state.loading = true
      await this.$store.dispatch('financiaciones/get_financiacion', this.id)
        .then(async (res) => {
          this.financiacion = res.data
          this.financiacion.sucursales = []
          this.financiacion.canales = []
          this.financiacion.rubros = []
          this.financiacion.categorias = []
          this.financiacion.locales = []
          this.financiacion.listas = []
          this.financiacion.marcas = []
          this.financiacion.dias = []
          // hace un push de la cuota en el v-autocomplete (para no traer todas las cuotas de esa tarjeta y emrpesa, las trae recien cuando edita)
          this.set_cuota_original()

          // obtiene los datos de las tablas intermedias
          await this.get_habilitada_para()

          // hace una copia de la financiacion
          this.financiacion_copy = JSON.parse(JSON.stringify(this.financiacion))
          this.existe = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async get_habilitada_para () {
      this.$store.state.loading = true
      // obtiene las sucursales
      if (this.financiacion.tiene_suc == 1) {
        await this.$store.dispatch('financiaciones/get_sucursales', this.id)
          .then((res) => {
            res.data.forEach(sucursal => {
              this.financiacion.sucursales.push(sucursal.id)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene los locales
      if (this.financiacion.tiene_loc == 1) {
        await this.$store.dispatch('financiaciones/get_locales', this.id)
          .then((res) => {
            res.data.forEach(local => {
              this.financiacion.locales.push(local.id)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene los canales
      if (this.financiacion.tiene_can == 1) {
        await this.$store.dispatch('financiaciones/get_canales', this.id)
          .then((res) => {
            res.data.forEach(canal => {
              this.financiacion.canales.push(canal.id)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene los rubros
      if (this.financiacion.tiene_rub == 1) {
        await this.$store.dispatch('financiaciones/get_rubros', this.id)
          .then((res) => {
            res.data.forEach(rubro => {
              this.financiacion.rubros.push(rubro.codigo)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene las categorias
      if (this.financiacion.tiene_cat == 1) {
        await this.$store.dispatch('financiaciones/get_categorias', this.id)
          .then((res) => {
            res.data.forEach(categoria => {
              this.financiacion.categorias.push(categoria.codigo)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene las marcas
      if (this.financiacion.tiene_mar == 1) {
        await this.$store.dispatch('financiaciones/get_marcas', this.id)
          .then((res) => {
            res.data.forEach(marca => {
              this.financiacion.marcas.push(marca.codigo)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtiene los artículos
      if (this.financiacion.tiene_art == 1) {
        await this.$store.dispatch('financiaciones/get_articulos', this.id)
          .then((res) => {
            this.articulos = res.data

            //obtenerArticulosExceptuados
            this.separarArticulos();

            // hace una copia de los articulos originales
            this.articulos_copy = JSON.parse(JSON.stringify(this.articulos))

            //crea una copia de los no aplica para trabajar con ella 
            this.articulosNoAplica_copy = JSON.parse(JSON.stringify(this.articulosNoAplica));
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
       
      // obtiene los días
      if (this.financiacion.tiene_dias == 1) {
        await this.$store.dispatch('financiaciones/get_dias', this.id)
          .then((res) => {
            res.dias.forEach(dia => {
              this.financiacion.dias.push(dia.dia_id)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      // obtengo las listas
      if (this.financiacion.tiene_lvb == 1) {
        let resultado = await this.$store.dispatch('financiaciones/get_listas_vbles', this.id)
        if (resultado.exito == 1) {
          resultado.data.forEach(lista => {
            this.financiacion.listas.push(lista.id)
          })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: resultado.message,
            color: 'error'
          })
        }
      }
      this.$store.state.loading = false
    },
    //separa los Array usados para los articulos y para los articulos_exceptuados (1 artículo no puede estan en ambas tablas)
    separarArticulos(){
      if(this.articulos.length != 0){
        this.articulos.forEach(e =>{
          if(e.no_aplica == 1){
            this.articulosNoAplica.push(e);
          }
        })
        this.articulosNoAplica.forEach(e => {
          const index = this.articulos.indexOf(e)
          this.articulos.splice(index, 1)
        })

      }
    },
    async get_cuotas () {
      this.cuotas = []
      const empresa = this.financiacion.empresa_codigo
      const tarjeta = this.financiacion.tarjeta_codigo
      if (empresa && tarjeta) {
        let tarjetas = []
        tarjetas.push(tarjeta)
        this.$store.state.loading = true
        await this.$store.dispatch('cuotas/get_cuotas', {
          empresa: empresa,
          tarjetas: tarjetas,
          inhabilitada: 0
        })
          .then((res) => {
            this.cuotas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.cuotas, 'nombre')
        this.$store.state.loading = false
      }
    },
    async get_datos () {
      this.$store.state.loading = true
      await this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
      await this.$store.dispatch('pagos/get_formas_pago_habilitadas')
      await this.$store.dispatch('cobros/get_formas_cobro_habilitadas')
      await this.$store.dispatch('get_canales')
      await this.$store.dispatch('rubros/get_rubros_habilitados')
      await this.$store.dispatch('marcas/get_marcas_habilitadas')
      await this.$store.dispatch('categorias/get_categorias_habilitadas')
      await this.$store.dispatch('get_dias_semana')
      let gruposPeticion = await this.$store.dispatch('financiaciones/get_grupos_comisiones')
      if (gruposPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: gruposPeticion.msj,
          color: 'error'
        })
        return
      }
      this.grupos = gruposPeticion.grupos
      let result_listas = await this.$store.dispatch('listas/get_listas_vbles')
      this.$store.state.loading = false
      if (result_listas.exito != 1) {
        this.$store.dispatch('show_snackbar', {
          text: result_listas.message,
          color: 'error'
        })
        return
      }
      this.listas = result_listas.data
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.financiacion.rubros.forEach(rubro => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rubro)
        categorias.forEach(categoria => {
          this.categorias_filtradas.push(categoria)
        })
      })
    },
    get_locales () {
      this.locales = []
      const empresa = this.financiacion.empresa_codigo
      const sucursales = this.financiacion.sucursales
      if (empresa && sucursales) {
        for (let index = 0; index < sucursales.length; index++) {
          const sucursal = sucursales[index]
          const locales = this.$store.state.locales.filter(loc => loc.sucursal == sucursal && loc.empresa == empresa)
          locales.forEach(local => {
            this.locales.push(local)
          })
        }
      }
    },
    get_array_articulos () {
      let articulos = []
      this.articulos.forEach(articulo => {
        articulos.push(articulo.codigo)
      })
      return articulos
    },
    get_array_articulosNoAplica() {
      let articulos = []
      this.articulosNoAplica.forEach(articulo => {
        articulos.push(articulo.codigo)
      })
      return articulos
    },
    refrescar_datos () {
      // actualiza los datos de la financiacion en la vista y bloquea la edicion
      this.financiacion_copy = JSON.parse(JSON.stringify(this.financiacion))
      this.articulos_copy = JSON.parse(JSON.stringify(this.articulos))
      this.articulosNoAplica_copy = JSON.parse(JSON.stringify(this.articulosNoAplica))
      this.editar = false
    },
    set_cuota_original () {
      // como cuotas depende de empresa y tarjetas tiene que agregar los valores originales en el item
      this.cuotas.push({
        codigo: this.financiacion.cuota_codigo,
        nombre: this.financiacion.cuota_nombre,
        cuotas: this.financiacion.cuotas,
        cuota_interna: this.financiacion.cuota_interna,
        plan: this.financiacion.plan
      })
    },
    comparar_valores (valor) {
      return JSON.stringify(this.financiacion[valor]) != JSON.stringify(this.financiacion_copy[valor])
    },
    format_masivo () {
      this.masivo = !this.masivo
      this.financiacion.tarjeta_codigo = null
      this.financiacion.cuota_codigo = null
      this.tarjetas_masivo = []
      this.cuotas_masivo = []
      if (this.masivo && this.financiacion.empresa_codigo) {
        this.dialog_masiva = true
      }
    },
    cerrar () {
      window.close()
    },
    quitar_articulo (articulo) {
      const index = this.articulosNoAplica.indexOf(articulo)
      this.articulosNoAplica.splice(index, 1)
    },
    agregar_articulo (articulo, mostrar_nombre) {
      if(this.articulos.length == 0){
        // mostrat nombre se utiliza cuando agrega un art buscando por codigo y no encuentra dicho art. Cuando abre el modal para buscar en la lista, no es necesario utilizarlo
        if (mostrar_nombre) {
          this.filtro.nombre = articulo.nombre
        }
        const codigo = articulo.codigo
        let existe = this.articulos.find(a => a.codigo == codigo);
        // verifica que el articulo no se haya agregado previamente
        if (existe) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se agregó el artículo en el módulo anterior ' + codigo,
            color: 'warning',
            timeout: 2000
          })
        } else {
          existe =  this.articulosNoAplica.find(a => a.codigo == codigo);
          if(existe){
            return this.$store.dispatch('show_snackbar', { text: 'El artículo ya se encuentra agregado ' + codigo, color: 'warning', timeout: 2000 })
          }else{
            this.articulosNoAplica.push(articulo)
            this.$store.dispatch('show_snackbar', {
              text: 'Artículo ' + codigo + ' agregado correctamente',
              color: 'success',
              timeout: 2000
            })
          }
        }
        this.filtro.nombre = null
        this.filtro.codigo = null
      }else{
        this.$store.dispatch('show_snackbar', { text: 'No es posible agregar articulos exceptuados ya que existen artículos agregados en la sección anterior.', color: 'info' })
      }
    },
    async get_articulo (codigo) {
      if (codigo) {
        if(this.articulos.length == 0){
          this.$store.state.loading = true
          await this.$store.dispatch('articulos/get_articulo_activo', codigo)
            .then((res) => {
              this.agregar_articulo(res.data, true)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {text: error.message,color: 'error',timeout: 2000})
            })
          this.$store.state.loading = false 
          this.$refs.artCod.focus();
          this.filtro.nombre = null
          this.filtro.codigo = null
        }else{
          this.$store.dispatch('show_snackbar', {text: 'No es posible agregar articulos exceptuados ya que existen artículos agregados en la sección anterior.',color: 'info'})
        }
      }
    },
    enterKey (e) {
      if (e.key == 'Enter') document.activeElement.blur()
    },
  },
  watch:{
    async dialog (val) {
      if (val && this.articulos_activos.length == 0) {
        this.load = true
        await this.$store.dispatch('articulos/get_articulos_activos')
        this.load = false
      } else {
        this.search = ''
      }
    },
    articulos(val){
      if(val.length > 0){
        this.articulosNoAplica = [];
      }
    },
    'financiacion.forma_pago_codigo' (val) {
      if (val) {
        this.requiere_tarjeta = this.formas_pago.find(fp => fp.id == val).tarjeta
      }
    },
    async editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
        await this.get_cuotas()
      } else {
        if (this.masivo) return
        // vuelve a dejar los datos del financiacion como estaban cuando hizo la consulta
        this.financiacion = await JSON.parse(JSON.stringify(this.financiacion_copy))
        this.articulos = await JSON.parse(JSON.stringify(this.articulos_copy))
        this.articulosNoAplica = await JSON.parse(JSON.stringify(this.articulosNoAplica_copy))
        this.set_cuota_original()
        this.get_categorias()
        this.get_locales()
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>