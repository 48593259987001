<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    :items="empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                    @change="filtro.local = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    @change="filtro.local = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="local_accesorios"
                    tabindex="1"
                    :items="locales.filter(loc => loc.empresa == filtro.empresa && loc.sucursal == filtro.sucursal)"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Categorías
                  <v-autocomplete
                    v-model="filtro.categoria_codigo"
                    item-text="nombre"
                    item-value="codigo"
                    :items="categorias"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1">
                  Fecha desde
                  <FechaPicker
                    v-model="filtro.desde"
                    hideDetails
                  />
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1">
                  Código artículo
                  <v-text-field
                    v-model="filtro.articulo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4" md="10" class="py-1 px-0">
                  <BtnFiltro
                    :loading="load"
                    clase="mt-0"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        item-key="codigo"
        sort-by="articulo"
        :items="articulos"
        :headers="headers"
        :loading="load"
        show-select
        dense
        @toggle-select-all="selectAllToggle"
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- format fecha -->
        <template v-slot:[`item.vigencia`]="{ item }">
          {{ moment(item.vigencia).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.stock`]="{ item }">
          <v-text-field
            :id="stockRef"
            :ref="stockRef"
            v-model="item.stock"
            type="number"
            hide-details
            dense
            outlined
            class="d-inline-flex"
            @blur="validar_stock(item)"
            @keypress.native.enter="$refs[stockRef].blur()"
          ></v-text-field>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- BOTONES -->
      <v-row class="d-flex justify-end mx-0 my-3">
        <SelectedCounter
          :selected.sync="selected"
          :items.sync="articulos"
          :custom="true"
          @criterio="select_all()"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          class="mt-2"
          :disabled="selected.length == 0"
          @click="generar_pdf(selected)"
        >
          <v-icon left>fas fa-check-square</v-icon>
          Generar seleccionados
        </v-btn>
        <v-btn
          color="pink accent-1"
          class="ml-3 mt-2"
          :disabled="articulos.length == 0"
          :dark="articulos.length != 0"
          @click="generar_pdf(articulos)"
        >
          <v-icon left>fas fa-ticket-alt</v-icon>
          Generar todos
        </v-btn>
      </v-row>
    </v-col>
    <!-- componentes -->
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { format_money } from '../../util/utils'
import { etiquetasPrecio } from '../../util/plantillas/pdfs'
import moment from 'moment'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import SelectedCounter from '../../components/util/SelectedCounter'
import PDFViewer from '../../util/plantillas/PDFViewer'

export default {
  data () {
    return {
      formatMoney: format_money,
      moment: moment,
      panel: 0,
      load: false,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
      articulos: [],
      selected: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Artículo', value: 'articulo' },
        { text: 'Precio', value: 'precio', align: 'end', formatter: format_money },
        { text: 'Vigencia', value: 'vigencia' },
        { text: 'Stock', value: 'stock' }
      ],
      filtro: {
        empresa:          null,
        sucursal:         null,
        local:            null,
        desde:            null,
        articulo:         null,
        categoria_codigo: null
      },
      stockRef: 'stockRef'
    }
  },
  created () {
    // si tiene solo 1 empresa la selecciono
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciono
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    //llamo a obtener las categorias habilitadas por si no se hizo antes
    this.$store.dispatch('categorias/get_categorias_habilitadas');
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales', 'locales']),
    ...mapState('categorias',['categorias']),
  },
  components: {
    BtnFiltro,
    FechaPicker,
    SelectedCounter,
    PDFViewer
  },
  methods: {
    async generar_pdf (array) {
      this.$store.state.loading = true
      this.pdf = await etiquetasPrecio(array)
      this.pdf_nombre = `Etiquetas precio ${moment(new Date).format('DD-MM-YYYY')}`
      this.pdf_dialog = true
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.empresa || !this.filtro.sucursal || !this.filtro.local || (!this.filtro.desde && !this.filtro.articulo)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un local y una fecha o artículo',
          color: 'orange'
        })
      }
      this.articulos = []
      this.selected = []
      this.load = true
      this.filtro.categoria_codigo = this.filtro.categoria_codigo ? this.filtro.categoria_codigo : 0;
      await this.$store.dispatch('stock/get_etiquetas_precio', this.filtro)
        .then((res) => {
          this.articulos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    select_all () {
      this.selected = []
      this.articulos.forEach(art => {
        this.selected.push(art)
      })
    },
    selectAllToggle () {
      if (this.selected.length < this.articulos.length) this.select_all()
      else this.selected = []
    },
    limpiar () {
      this.filtro = {
        empresa:          null,
        sucursal:         null,
        local:            null,
        desde:            null,
        articulo:         null,
        categoria_codigo: null
      }
    },
    async validar_stock(item){
      if (!this.filtro.empresa || !this.filtro.sucursal || !this.filtro.local || (!this.filtro.desde && !this.filtro.articulo)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un local y una fecha o artículo',
          color: 'orange'
        })
      }
      let posArt = this.articulos.indexOf(item)
      if (posArt == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del artículo',
          color: 'warning'
        })
        return
      }
      // cambió la cantidad en articulos, pero en los seleccionados no
      let enSelect = this.selected.filter(element => element.codigo == item.codigo)
      let posSelect = -1
      if (enSelect.length > 0){
        posSelect = this.selected.indexOf(enSelect[0])
        this.selected[posSelect].stock = item.stock
      }
      // paso a buscar las cantidades
      this.$store.state.loading = true
      let stockPeticion = await this.$store.dispatch('stock/get_etiquetas_precio', this.filtro)
      this.$store.state.loading = false
      if (stockPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: stockPeticion.message,
          color: 'error'
        })
        return
      }
      // busco el articulo en los resultados
      let existe = stockPeticion.data.filter(element => element.codigo == item.codigo)
      if (stockPeticion.data.length == 0 || existe.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin stock para imprimir esta etiqueta.',
          color: 'info'
        })
        // actualizo en seleccionados
        if (posSelect != -1) this.selected[posSelect].stock = 0
        // actualizo en los articulos
        this.articulos[posArt].stock = 0
        return
      }
      // existe en en los resultados, tengo stock para que lo cambie?
      if (parseInt(item.stock) > existe[0].stock){
        // no tengo suficiente, lo cambio en articulos y en select si correspondiera
        if (posSelect != -1) this.selected[posSelect].stock = existe[0].stock
        this.articulos[posArt].stock = existe[0].stock
      }
    }
  }
}
</script>