<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col class="d-flex align-center text-h6 font-weight-regular pt-0 pb-4">
      <div class="align-center" :class="!$vuetify.breakpoint.xs ? 'd-flex' : ''">
        <div class="mr-2">
          {{ nueva ? 'Nueva venta' : `Venta N° ${id_venta}` }}
        </div>
        <v-scroll-x-transition>
          <span v-if="!nueva && log.usuario != ''" class="body-2">
            <span v-if="!$vuetify.breakpoint.xs" class="mr-1">–</span>
            Grabada por {{ log.usuario }} el {{ log.fecha }}
          </span>
        </v-scroll-x-transition>
      </div>
      <v-spacer></v-spacer>
      <v-slide-x-reverse-transition>
        <v-switch
          v-if="nueva && ptovta.mayorista_salon == 1"
          v-model="venta_mayorista"
          label="Venta Mayorista"
          class="mt-0"
          :disabled="articulos.length == 0 || (articulos.filter(art => parseInt(art.cantidad) < vta_min_mayorista).length > 0 && !venta_mayorista)"
          hide-details
          dense
          @change="aplicarPrecioMayorista()"
        ></v-switch>
      </v-slide-x-reverse-transition>
      <v-scale-transition hide-on-leave>
        <v-icon
          v-if="venta_mayorista && articulos.filter(art => parseInt(art.cantidad) < vta_min_mayorista).length > 0"
          color="orange"
          :title="`Existen artículos cuya cantidad es menor a ${vta_min_mayorista}. Verificar`"
          style="cursor: pointer;"
          :class="`ml-4 pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
          small
        >
          fas fa-exclamation-triangle
        </v-icon>
      </v-scale-transition>
      <v-slide-x-reverse-transition>
        <v-switch
          v-if="!nueva && permite_editar == 1"
          v-model="editar"
          label="Editar"
          class="mt-0"
          hide-details
          dense
        ></v-switch>
      </v-slide-x-reverse-transition>
    </v-col>
    <!-- ---------------- -->
    <!-- LOCAL Y VENDEDOR -->
    <!-- ---------------- -->
    <v-col cols="12">
      <v-row>
        <v-col cols="6" sm="3" md="2" class="py-0">
          <v-autocomplete
            v-model="sucursal"
            ref="atcSucursal"
            label="Sucursal"
            item-text="nombre"
            item-value="id"
            tabindex="1"
            :items="sucursales"
            :readonly="!nueva || bloquear_ptovta"
            :filled="!nueva || bloquear_ptovta"
            outlined
            dense
            @change="getPtovta()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-autocomplete
            v-model="ptovta"
            ref="atcLocal"
            label="Local"
            item-text="nombre"
            tabindex="1"
            :items="locales_user.filter(loc => loc.sucursal == sucursal && loc.canal == 1)"
            :readonly="!nueva || bloquear_ptovta"
            :filled="!nueva || bloquear_ptovta"
            return-object
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="9" md="6">
          <v-row>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor"
                ref="vtfVendedor"
                label="Vendedor"
                type="number"
                tabindex="1"
                :readonly="!nueva || bloquear_vend"
                :filled="!nueva || bloquear_vend"
                outlined
                dense
                @blur="buscarVendedor()"
                @keypress="enterKey"
              ></v-text-field>
            </v-col>
            <v-col cols="8" class="py-0">
              <v-text-field
                v-model.trim="vendedor_nombre"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- ----------------------- -->
    <!-- COMPONENTE DE ARTICULOS -->
    <!-- ----------------------- -->
    <template>
      <!-- CABECERA ART -->
      <v-col cols="12" class="pt-0" :class="!nueva ? 'pb-0' : ''">
        <v-divider></v-divider>
        <div class="d-flex align-center py-3" style="font-size: 18px;">
          <v-icon left>fas fa-shopping-cart</v-icon>
          Artículos
          <v-btn
            v-if="nueva"
            color="info"
            class="ml-2"
            title="Buscar artículos"
            small
            icon
            @click="modal_articulos = true"
          >
            <v-icon small>fas fa-search</v-icon>
          </v-btn>
          <v-switch
            v-if="nueva"
            v-model="simular"
            label="Simular"
            class="mt-0 ml-3"
            hide-details
            dense
          ></v-switch>
          <v-spacer></v-spacer>
          <v-col v-if="cupon != null && !nueva" cols="6" sm="4" md="3" xl="2" class="pa-0 pl-3">
            <v-text-field
              v-model.trim="cupon"
              label="Cupón de descuento"
              hide-details
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
          <v-col v-if="lista_vble_nombre != null" cols="6" sm="4" md="3" xl="2" class="d-flex pa-0 pl-3">
            <v-text-field
              v-model="lista_vble_nombre"
              label="Precio excepción"
              hide-details
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
        </div>
        <v-row v-if="nueva">
          <v-col cols="12" sm="6" md="4" xl="3" class="d-flex align-center">
            <v-text-field
              v-model.trim="articulo"
              ref="vtfArticulo"
              label="Artículo"
              type="tel"
              class="mr-3"
              tabindex="1"
              hide-details
              outlined
              dense
              @blur="buscarArticulo(1)"
              @keypress="enterKey"
            ></v-text-field>
            <v-btn
              color="info"
              tabindex="1"
              @click="buscarArticulo(1)"
            >
              Agregar
            </v-btn>
          </v-col>
          <v-col v-if="listas_vbles.length > 0" cols="8" sm="3" md="2" class="d-flex align-center px-sm-0 ml-sm-3">
            <v-autocomplete
              v-model="lista_variable"
              label="Precio excepción"
              tabindex="1"
              item-value="id"
              item-text="nombre"
              :items="listas_vbles"
              hide-details
              clearable
              outlined
              dense
              @change="aplicarPrecioLista(1, 1)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="3" md="2" class="d-flex justify-center px-0 mr-n3">
            <v-switch
              v-model="combo"
              label="Combo"
              class="mt-0 mr-1"
              tabindex="1"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="6" sm="4" md="2">
            <v-text-field
              v-model.trim="cupon"
              label="Cupón de descuento"
              tabindex="1"
              hide-details
              clearable
              outlined
              dense
              @change="validarCupon()"
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="6" md="2">
            <v-btn
              tabindex="1"
              color="primary"
              :disabled="articulos.filter(art => art.gift_card == 1).length > 0 || giftcard"
              @click="openGF({})"
            >
              <v-icon left>fas fa-gift</v-icon>
              Gift Card
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- LISTA DE ARTICULOS -->
      <v-col cols="12" :class="!nueva ? 'pt-0' : ''">
        <v-data-table
          class="elevation-2 cebra"
          item-key="codigo"
          :items="articulos"
          :headers="headersArt"
          :items-per-page="-1"
          :item-class="itemRowBackground"
          :expanded.sync="expanded"
          hide-default-footer
          hide-default-header
          mobile-breakpoint
          dense
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th
                  v-for="(h, index) in headers"
                  :key="index"
                  :class="`text-${h.align ? h.align : 'start' }`"
                  :style="h.width ? `width: ${h.width}px; min-width: ${h.width}px;` : ''"
                >
                  <span v-if="h.value != 'efectivo'">
                    {{ h.text }}
                  </span>
                </th>
              </tr>
            </thead>
          </template>
          <!-- campos editables -->
          <template v-slot:[`item.efectivo`]="{ item }">
            <v-tooltip
              v-if="promociones.find(pro => pro.articulo == item.codigo)"
              :color="promociones.find(pro => pro.articulo == item.codigo && pro.detalle_efectivo.length > 0) ? 'success' : ''"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="promociones.find(pro => pro.articulo == item.codigo && pro.detalle_efectivo.length > 0) ? 'success' : ''"
                  small
                >
                  {{ promociones.find(pro => pro.articulo == item.codigo && pro.detalle_efectivo.length > 0) ? 'fas' : 'far' }} fa-money-bill-alt
                </v-icon>
              </template>
              <strong v-if="promociones.find(pro => pro.articulo == item.codigo && pro.detalle_efectivo.length > 0)">
                {{ promociones.find(pro => pro.articulo == item.codigo).detalle_efectivo[0].nombre }}
                <!-- para la edicion calculo el valor, para la vista lo muestro -->
                <span v-if="editar">
                  — {{ formatMoney(item.precio * (1 - (promociones.find(pro => pro.articulo == item.codigo).detalle_efectivo[0].porcentaje / 100))) }}
                </span>
                <span v-else>
                  — {{ formatMoney(item.precio_real - (item.redondeo / item.cantidad)) }}
                </span>
              </strong>
              <strong v-else>
                No aplica descuento efectivo
              </strong>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small>far fa-money-bill-alt</v-icon>
              </template>
              <strong>No aplica descuento efectivo</strong>
            </v-tooltip>
          </template>
          <template v-slot:[`item.cantidad`]="{ item }">
            <v-text-field
              v-if="item.gift_card != 1"
              v-model.trim="item.cantidad"
              type="number"
              class="py-1"
              :readonly="!nueva"
              :filled="!nueva"
              hide-details
              outlined
              dense
              @change="validarCantidades(item)"
            ></v-text-field>
            <span v-else>
              {{ item.cantidad }}
            </span>
          </template>
          <template v-slot:[`item.precio`]="{ item }">
            <text-field-money
              v-if="modifica_precio == 1 && item.gift_card != 1"
              v-model="item.precio"
              class="py-1"
              v-bind:properties="{
                prefix: '$',
                readonly: !nueva,
                filled: !nueva,
                'hide-details': true
              }"
              @change="recalcularImporteArticulo(item, 1)"
            />
            <span v-else>
              {{ formatMoney(item.precio) }}
            </span>
          </template>
          <template v-slot:[`item.combo`]="{ item }">
            <v-autocomplete
              v-if="nueva"
              v-model="item.combo"
              :items="[1,2,3,4,5]"
              hide-details
              outlined
              dense
            ></v-autocomplete>
            <span v-else>
              {{ item.combo }}
            </span>
          </template>
          <!-- campos con formato -->
          <template v-slot:[`item.precio_lista`]="{ value }">
            {{ formatMoneyRound(value) }}
          </template>
          <template v-slot:[`item.importe`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.importe_gift`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.descuento`]="{ item }">
            {{ item.descuento.toFixed(parseFloat(item.descuento) % 1 == 0 || parseFloat(item.descuento) == 0 ? 0 : 2) }} %
            <v-tooltip
              v-if="item.descuento > 0 && promociones.find(pro => pro.articulo == item.codigo)"
              color="primary"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="info"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <div
                v-for="(promo, index) in promociones.find(pro => pro.articulo == item.codigo).detalle"
                :key="index"
                style="width: 400px;"
              >
                <v-row style="width: 100%;" no-gutters>
                  <v-col cols="10" class="font-weight-bold">
                    {{ promo.nombre.substring(0, 38) }}
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <strong>
                      {{ promo.porcentaje.toFixed(parseFloat(promo.porcentaje) % 1 == 0 || parseFloat(promo.porcentaje) == 0 ? 0 : 2) }} %
                    </strong>
                  </v-col>
                </v-row>
              </div> 
            </v-tooltip>
          </template>
          <!-- acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="nueva"
              color="error"
              title="Quitar"
              style="margin-right: 6px;"
              small
              @click="quitarArticulo(item)"
            >
              fas fa-times-circle
            </v-icon>
            <v-icon
              v-if="item.solicita_serie == 1"
              color="cyan"
              title="Series"
              style="margin-right: 6px;"
              small
              :disabled="simular"
              @click="verSeries(item)"
            >
              fas fa-th-list
            </v-icon>
            <v-icon
              v-if="modifica_precio == 1 && nueva"
              color="orange"
              title="Agregar info"
              style="margin-right: 6px;"
              small
              @click="articulo_select = item; modal_info_adicional = true"
            >
              fas fa-edit
            </v-icon>
            <v-icon
              v-if="!efectivo && editar && item.financiaciones_especiales.length > 1 && (nueva && !(efectivo || debito))"
              color="success"
              title="Agregar financiación esp."
              style="margin-right: 6px;"
              :disabled="item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                        array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.codigo == fp.articulo_codigo).length > 0"
              small
              @click="desglosarFinEsp(item)"
            >
              fas fa-plus
            </v-icon>
            <v-icon
              v-if="item.gift_card == 1"
              color="green"
              title="Ver Gift Card"
              style="margin-right: 6px;"
              small
              @click="openGF(item)"
            >
              fas fa-gift
            </v-icon>
          </template>
          <!-- no datos -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="600"
              type="info"
              border="left"
              dense
              text
            >
              Agregue artículos por IMEI (serie), código de Barra o código Interno
            </v-alert>
          </template>
          <!-- FINANCIACIONES ESPECIALES (del articulo) -->
          <template v-slot:[`item.financing`]="{ item }">
            <v-autocomplete
              v-if="!efectivo && editar && item.financiaciones_especiales.length > 0 && expanded.filter(art => art.codigo == item.codigo).length == 0 && (nueva && !(efectivo || debito))"
              v-model="item.financiacion"
              :items="item.financiaciones_especiales"
              item-text="forma_pago_nombre"
              return-object
              hide-details
              outlined
              dense
              @change="finEspUnitaria(item)"
            ></v-autocomplete>
          </template>
          <!-- expandible (financiaciones especiales) -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="d-flex justify-end py-2 mr-4">
                <v-card style="width: 85%;" flat>
                  <v-data-table
                    class="tableVta"
                    :items="item.financiaciones"
                    :headers="headersFinEsp"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:[`item.financiacion`]="{ item }">
                      <div class="d-flex">
                        <v-autocomplete
                          v-if="editar && ((item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                            item.financiaciones_especiales.filter(fe => fe.forma_pago_id == item.financiacion.forma_pago_id).length > 0) ||
                                            item.monto != null)"
                          v-model="item.financiacion"
                          :items="item.financiaciones_especiales"
                          item-text="forma_pago_nombre"
                          :readonly="!editar || (item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                  array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.art_cod == fp.articulo_codigo &&
                                                        item.id_internos && item.id_internos.filter(id => id == fp.id_interno).length > 0).length > 0)"
                          :filled="!editar || (item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                  array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.art_cod == fp.articulo_codigo &&
                                                        item.id_internos && item.id_internos.filter(id => id == fp.id_interno).length > 0).length > 0)"
                          return-object
                          hide-details
                          outlined
                          dense
                          @change="calcularCuotasFinEsp(item)"
                        ></v-autocomplete>
                        <div v-else>
                          <span v-if="item.financiacion != null && Object.keys(item.financiacion).length > 0">
                            {{ item.financiacion.forma_pago_nombre }}
                          </span>
                        </div>
                        <v-chip v-if="editar" class="body-1 ml-3">
                          {{ item.financiacion.grupo ? item.financiacion.grupo : '–' }}
                        </v-chip>
                      </div>
                    </template>
                    <template v-slot:[`item.monto`]="{ item }">
                      <text-field-money
                        v-model="item.monto"
                        class="py-1"
                        v-bind:properties="{
                          prefix: '$',
                          readonly: !editar || (item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                  array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.art_cod == fp.articulo_codigo &&
                                                        item.id_internos && item.id_internos.filter(id => id == fp.id_interno).length > 0).length > 0),
                          filled: !editar || (item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                  array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.art_cod == fp.articulo_codigo &&
                                                        item.id_internos && item.id_internos.filter(id => id == fp.id_interno).length > 0).length > 0),
                          'hide-details': true
                        }"
                        @change="calcularCuotasFinEsp(item)"
                      />
                    </template>
                    <!-- campos calculados -->
                    <template v-slot:[`item.cuota`]="{ value }">
                      {{ formatMoney(value) }}
                    </template>
                    <template v-slot:[`item.tarjeta_nombre`]="{ item }">
                      {{ Object.keys(item.financiacion).length != 0 ? item.financiacion.tarjeta_nombre : '' }}
                    </template>
                    <template v-slot:[`item.cuota_nombre`]="{ item }">
                      {{ Object.keys(item.financiacion).length != 0 ? item.financiacion.cuota_nombre : '' }}
                    </template>
                    <template v-slot:[`item.total`]="{ value }">
                      {{ formatMoney(value) }}
                    </template>
                    <!-- acciones -->
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip
                        v-if="Object.keys(item.financiacion).length != 0"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="info"
                            class="mr-2"
                            small
                          >
                            fas fa-info-circle
                          </v-icon>
                        </template>
                        <span>
                          Alícuota: {{ item.financiacion.porcentaje.toFixed(2) }}%
                        </span>
                      </v-tooltip>
                      <v-icon
                        v-if="editar"
                        color="error"
                        title="Quitar"
                        :disabled="item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                  array_fp.filter(fp => fp.forma_pago_id == item.financiacion.forma_pago_id && item.art_cod == fp.articulo_codigo &&
                                                        item.id_internos && item.id_internos.filter(id => id == fp.id_interno).length > 0).length > 0"
                        small
                        @click="quitarFinEsp(item)"
                      >
                        fas fa-times-circle
                      </v-icon>
                    </template>
                    <!-- total -->
                    <template slot="body.append">
                      <tr class="font-weight-bold">
                        <th colspan="3" style="font-size: 14px" >Total financiado</th>
                        <th style="font-size: 14px" class="text-right">
                          {{ formatMoney(item.financiaciones.reduce((sum, fin) => sum + roundNumberTF(fin.monto), 0)) }}
                        </th>
                        <th>
                          <span
                            v-if="item.importe - item.financiaciones.reduce((sum, fin) => sum + roundNumberTF(fin.monto), 0) != 0"
                            :class="`${$vuetify.theme.dark ? 'orange' : 'blue-grey'}--text`"
                          >
                            ({{ formatMoney(item.importe - item.financiaciones.reduce((sum, fin) => sum + roundNumberTF(fin.monto), 0)) }})
                          </span>
                        </th>
                        <th style="font-size: 14px" class="text-right">
                          {{ formatMoney(item.financiaciones.reduce((sum, fin) => sum + roundNumberTF(fin.total), 0)) }}
                        </th>
                        <th></th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </template>
    <!-- ------------------------ -->
    <!-- TOTALES Y TERMINAL LAPOS -->
    <!-- ------------------------ -->
    <template>
      <v-col cols="12">
        <v-divider class="mt-n3 mb-3"></v-divider>
        <v-row>
          <v-col cols="8" sm="4" md="3" class="mb-4">
            <v-expand-transition>
              <v-autocomplete
                v-if="ptovta.fp_integrado == 1 && !efectivo"
                v-model="terminal"
                label="Terminal"
                style="background: var(--v-rowwar-base);"
                item-text="nombre"
                item-value="numero"
                tabindex="1"
                :items="terminales"
                :clearable="nueva"
                :readonly="!nueva"
                :filled="!nueva"
                hide-details
                outlined
                dense
              ></v-autocomplete>
            </v-expand-transition>
          </v-col>
          <!-- SUBTOTALES -->
          <v-col cols="12" md="9" xl="8">
            <v-row class="d-flex justify-end">
              <v-col cols="12" sm="6" xl="5">
                <v-card class="pa-3" style="font-size: 18px; cursor: default;">
                  <v-row class="pb-2">
                    <v-col>Subtotal</v-col>
                    <v-col class="d-flex justify-end font-weight-bold">
                      {{ formatMoney(monto_articulos) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="monto_fp_alic - monto_fp > 0" class="mt-n6 pb-2">
                    <v-col>Intereses</v-col>
                    <v-col class="d-flex justify-end font-weight-bold">
                      {{ formatMoney(monto_fp_alic - monto_fp) }}
                    </v-col>
                  </v-row>
                  <template v-if="monto_descuento > 0">
                    <v-row
                     v-for="(descuento, index) in articulos.filter(art => art.descuento_efectivo > 0).reduce(function (r, a) {
                                                                                  r[a.descuento_efectivo] = r[a.descuento_efectivo] || []
                                                                                  r[a.descuento_efectivo].push(a)
                                                                                  return r
                                                                                }, Object.create(null))"
                      :key="index"
                      class="mt-n6 pb-2"
                    >
                      <v-col>Desc. efectivo {{ descuento[0].descuento_efectivo }}%</v-col>
                      <v-col class="d-flex justify-end font-weight-bold success--text">
                        ({{ formatMoney(monto_descuento * -1) }})
                      </v-col>
                    </v-row>
                  </template>
                  <v-divider class="pt-2"></v-divider>
                  <v-row class="font-weight-bold">
                    <v-col>Total a Pagar</v-col>
                    <v-col class="d-flex justify-end" style="font-size: 20px;">
                      <!-- el monto a pagar es el subtotal - los descuentos + el redondeo + los intereses -->
                        {{ formatMoney(monto_articulos - monto_descuento + monto_redondeo + (monto_fp_alic - monto_fp)) }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <!-- detalle subtotal -->
              <v-col cols="12" sm="6" xl="5">
                <v-card class="pa-3" style="font-size: 16px; cursor: default;">
                  <v-row>
                    <v-col class="d-flex align-center">
                      <v-icon color="success" style="padding-right: 10px;" small>
                        fas fa-money-bill-alt
                      </v-icon>
                      Efectivo
                    </v-col>
                    <v-col class="d-flex justify-end font-weight-medium" style="font-size: 18px;">
                      {{ formatMoney(monto_efectivo) }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-n4">
                    <v-col class="d-flex align-center">
                      <v-icon color="purple" style="padding-right: 10px;" small>
                        fas fa-credit-card
                      </v-icon>
                      Tarjeta/Otros
                    </v-col>
                    <v-col class="d-flex justify-end font-weight-medium" style="font-size: 18px;">
                      {{ formatMoney(monto_fp_alic) }}
                    </v-col>
                  </v-row>
                  <v-divider class="mt-3 mb-5"></v-divider>
                  <v-row class="d-flex justify-center font-weight-bold" style="font-size: 20px;">
                    <v-col class="d-flex align-center primary--text pt-0">
                      <v-icon left>fas fa-hand-holding-usd</v-icon>
                      Total
                    </v-col>
                    <v-col class="d-flex justify-end primary--text pt-0">
                      <strong>
                        <!-- el total a pagar es el efectivo + tarjeta (con intereses) -->
                        {{ formatMoney(monto_efectivo + monto_fp_alic) }}
                      </strong>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <!-- ---------------------------- -->
    <!-- COMPONENTE DE FORMAS DE PAGO -->
    <!-- ---------------------------- -->
    <template>
      <!-- FILTRO FP -->
      <v-col cols="12">
        <v-divider class="py-3"></v-divider>
        <v-card color="primary" class="mb-4">
          <v-row class="d-flex align-center" style="font-size: 18px;">
            <v-col cols="12" sm="4" md="3" xl="2" class="d-flex ml-3 white--text">
              <v-icon dark left>fas fa-dollar-sign</v-icon>
              Formas de Pago
              <v-btn
                v-if="editar"
                color="white"
                class="ml-2"
                title="Buscar Formas de Pago"
                small
                icon
                @click="modal_search_fp = true"
              >
                <v-icon small>fas fa-search</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4" sm="2" class="d-flex justify-sm-end pt-0 pb-3 pb-sm-0 ml-3 ml-sm-0">
              <v-checkbox
                v-model="efectivo"
                class="mt-0 mr-sm-6 mr-0"
                label="Efectivo"
                color="white"
                tabindex="1"
                :disabled="!editar || formas_pago_group.filter(fpg => fpg.estado_solicitud == 2).length > 0 ||
                            formas_pago_group.filter(fpg => fpg.lapos_integrado == 1 && fpg.id_solicitud != null && fpg.estado_solicitud != 2).length > 0"
                hide-details
                dense
                dark
                @click="debito ? debito = false : ''"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="4" lg="3" xl="2" class="d-flex justify-start pt-0 pb-3 pb-sm-0 ml-md-0 ml-n3">
              <v-checkbox
                v-model="debito"
                class="mt-0 mr-3"
                label="Débito/Transferencia"
                color="white"
                tabindex="1"
                :disabled="!editar"
                hide-details
                dense
                dark
                @change="!efectivo ? setDebito() : setDebitoEf()"
              ></v-checkbox>
            </v-col>
            <v-col v-if="giftcard || nueva" cols="4" sm="2" class="d-flex justify-start pt-0 pb-3 pb-sm-0 ml-sm-n3 ml-md-n9 ml-3 pr-0 mr-0 mr-sm-n12">
              <v-checkbox
                v-model="giftcard"
                class="mt-0 mr-3"
                label="Gift Card"
                color="white"
                tabindex="1"
                :disabled="articulos.filter(art => art.gift_card == 1).length > 0 || !nueva"
                hide-details
                dense
                dark
              ></v-checkbox>
            </v-col>
            <v-expand-transition>
              <div
                v-if="giftcard"
                :style="$vuetify.breakpoint.xs ? ('width: 50%;') : (
                        $vuetify.breakpoint.sm ? ('width: 33%;') : (
                        $vuetify.breakpoint.md ? ('width: 25%;') : (
                        $vuetify.breakpoint.lg ? ('width: 21%;') : 'width: 16%;'
                )))"
              >
                <v-col cols="12" class="d-flex justify-start mt-n4 mt-sm-n1 mb-n1 pl-sm-6 pl-0 pr-sm-6 pr-0">
                  <v-text-field
                    v-model.trim="giftcard_codigo"
                    label="Gift Card"
                    color="swalText"
                    style="background: var(--v-swalBkg-base);"
                    :readonly="!nueva"
                    :filled="!nueva"
                    hide-details
                    outlined
                    dense
                    @change="validarGF()"
                  ></v-text-field>
                </v-col>
              </div>
            </v-expand-transition>
          </v-row>
        </v-card>
        <v-expand-transition>
          <div v-if="!efectivo && editar">
            <v-form @submit.prevent="agreagrFormaPago()" class="pt-8">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <text-field-money
                    v-model="monto"
                    label="Monto"
                    class="py-0"
                    v-bind:properties="{
                      prefix: '$',
                      tabindex: 1
                    }"
                    @change="simularFormaPago()"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <v-autocomplete
                    v-model="banco"
                    label="Banco"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="bancos.filter(bp => bp.inhabilitado != 1)"
                    :readonly="monto == null || parseFloat(monto) <= 0"
                    :filled="monto == null || parseFloat(monto) <= 0"
                    clearable
                    outlined
                    dense
                    @change="setBanco()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="forma_pago"
                    label="Forma pago"
                    item-value="forma_pago_id"
                    item-text="forma_pago_nombre"
                    tabindex="1"
                    :items="financiaciones_disponibles.filter(fd => (fd.bancos.length == 0 || fd.bancos.filter(ba => ba == banco).length > 0) &&
                                                                    (aplica_desc == 1 && beneficio_empl == 2 ? fd.fp_empleado == 1 : fd.fp_empleado != 1))"
                    :readonly="monto == null || parseFloat(monto) <= 0"
                    :filled="monto == null || parseFloat(monto) <= 0"
                    return-object
                    clearable
                    outlined
                    dense
                    @change="simularFormaPago()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <text-field-money
                    v-model="monto_cuotas"
                    label="Monto Cuotas"
                    class="py-0 pr-2"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true
                    }"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0 pl-0 d-flex align-start">
                  <text-field-money
                    v-model="fp_importe"
                    label="Importe"
                    class="py-0"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true
                    }"
                  />
                  <v-tooltip
                    v-if="Object.keys(forma_pago).length != 0"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="pt-1 ml-2"
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <div
                      v-for="(item, index) in simulacion"
                      :key="index"
                      style="width: 640px;"
                    >
                      <v-row style="width: 100%;" no-gutters>
                        <v-col cols="6" class="font-weight-bold">
                          {{ item.nombre.substring(0, 38) }}
                          <v-chip v-if="editar" class="body-2 ml-1 mr-n3" small>
                            {{ item.grupo ? item.grupo : '–' }}
                          </v-chip>
                        </v-col>
                        <v-row no-gutters>
                          <v-col cols="4" class="d-flex justify-end mr-4">
                            {{ formatMoney(item.monto) }}
                          </v-col>
                          <v-col cols="4">
                            Alic: {{ item.alicuota == 0 || (item.alicuota % item.alicuota == 0) ? item.alicuota : item.alicuota.toFixed(2) }} %
                          </v-col>
                          <v-col cols="4" class="d-flex justify-end ml-n4">
                            <strong>{{ formatMoney(item.importe) }}</strong>
                          </v-col>
                        </v-row>
                      </v-row>
                    </div> 
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="6" md="12" class="d-flex justify-end pt-0">
                  <v-btn
                    color="orange"
                    class="mr-2"
                    title="Limpiar"
                    small
                    icon
                    @click="limpiarFP()"
                  >
                    <v-icon small>fas fa-broom</v-icon>
                  </v-btn>
                  <v-btn
                    color="info"
                    type="submit"
                    tabindex="1"
                    style="margin-top: -2px;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-expand-transition>
      </v-col>
      <v-expand-transition>
        <div v-if="monto_efectivo < 0">
          <v-alert
            type="error"
            border="left"
          >
            Existen diferencias entre las formas de pago ingresadas y el total de la venta. Verificar
          </v-alert>
        </div>
      </v-expand-transition>
      <!-- LISTA DE FORMAS DE PAGO -->
      <v-expand-transition>
        <div v-if="!efectivo" style="width: 100%;">
          <v-col cols="12" class="pt-0">
            <v-data-table
              class="elevation-2 cebra"
              :items="formas_pago_group"
              :headers="headersFP"
              :items-per-page="-1"
              hide-default-footer
              mobile-breakpoint
              dense
            >
              <!-- checks boxs -->
              <template v-slot:[`item.cobro_online`]="{ item }">
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="item.cobro_online"
                    class="mb-1"
                    :true-value="1"
                    :false-value="0"
                    :disabled="item.id != null || item.estado_solicitud == 2 || item.solicita_banco == 1 || ptovta.cobro_electronico == 0 || item.tar_cobro_online == 0 ||
                              (item.id == null && item.cobro_transaccion != null)"
                    hide-details
                    dense
                    @click="setSelectedCheck(item, 1)"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </div>
              </template>
              <template v-slot:[`item.lapos_integrado`]="{ item }">
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="item.lapos_integrado"
                    class="mb-1"
                    :true-value="1"
                    :false-value="0"
                    :disabled="item.id != null || item.estado_solicitud == 2 || item.solicita_banco == 1 || ptovta.fp_integrado == 0 || item.tar_lapos_int == 0 ||
                              (item.id == null && item.cobro_transaccion != null) || (ptovta.fp_integrado == 1 && ptovta.fp_desintegrado == 0)"
                    hide-details
                    dense
                    @click="setSelectedCheck(item, 2)"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </div>
              </template>
              <template v-slot:[`item.qr`]="{ item }">
                <v-checkbox
                  v-model="item.qr"
                  class="mb-1"
                  :true-value="1"
                  :false-value="0"
                  :disabled="item.id != null || item.cobro_online == 1 || item.lapos_integrado == 0 || ptovta.fp_integrado == 0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <!-- campos con formato -->
              <template v-slot:[`item.monto`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <template v-slot:[`item.banco`]="{ value }">
                {{ bancos.find(bp => bp.id == value) ? bancos.find(bp => bp.id == value).nombre : '' }}
              </template>
              <template v-slot:[`item.monto_cuotas`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <template v-slot:[`item.monto_extraccion`]="{ item }">
                <div v-if="item.debito == 1" class="d-flex">
                  <text-field-money
                    v-model="item.monto_extraccion"
                    v-bind:properties="{
                      prefix: '$',
                      hideDetails: true,
                      readonly: item.id != null || !(editar && ((item.cobro_online == 0 && item.lapos_integrado == 0) ||
                                                                (item.cobro_online == 1 && item.estado_solicitud != 2) ||
                                                                (item.lapos_integrado == 1 && item.estado_solicitud != 2 &&
                                                                  (item.actualizar_correcto == 1 || !item.hasOwnProperty('actualizar_correcto'))))),
                      filled: item.id != null || !(editar && ((item.cobro_online == 0 && item.lapos_integrado == 0) ||
                                                              (item.cobro_online == 1 && item.estado_solicitud != 2) ||
                                                              (item.lapos_integrado == 1 && item.estado_solicitud != 2 &&
                                                                (item.actualizar_correcto == 1 || !item.hasOwnProperty('actualizar_correcto')))))
                    }"
                    @blur="validarExtraccion(item)"
                  />
                  <v-tooltip v-if="nueva || permite_editar == 1" color="success" right>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="success"
                        class="ml-2"
                        small
                      >
                        fas fa-money-bill-alt
                      </v-icon>
                    </template>
                    Efectivo disponible: <strong>{{ formatMoneyRound(efectivo_disponible) }}</strong>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.total`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <!-- acciones -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip color="primary" left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="info"
                      class="mr-4"
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div
                    v-for="(detalle, index) in formas_pago.filter(fp => fp.id_interno == item.id_interno)"
                    :key="index"
                    style="width: 640px;"
                  >
                    <v-row style="width: 100%;" no-gutters>
                      <v-col cols="6" class="font-weight-bold">
                        {{ detalle.forma_pago_nombre.substring(0, 38) }}
                        <v-chip v-if="editar" class="body-2 ml-1" small>
                          {{ detalle.grupo ? detalle.grupo : '–' }}
                        </v-chip>
                      </v-col>
                      <v-row no-gutters>
                        <v-col cols="4" class="d-flex justify-end mr-4">
                          {{ formatMoney(detalle.monto) }}
                        </v-col>
                        <v-col cols="4">
                          Alic: {{ detalle.porcentaje == 0 || (detalle.porcentaje % detalle.porcentaje == 0) ? detalle.porcentaje : detalle.porcentaje.toFixed(2) }} %
                        </v-col>
                        <v-col cols="4" class="d-flex justify-end ml-n4">
                          <strong>
                            {{ formatMoney(redondear_detalles ? roundNumberTF(detalle.monto * (1 + (detalle.porcentaje / 100))) : detalle.monto * (1 + (detalle.porcentaje / 100))) }}
                          </strong>
                        </v-col>
                      </v-row>
                    </v-row>
                  </div>
                </v-tooltip>
                <v-icon
                  color="info"
                  title="Detalle Forma Pago"
                  class="mr-2"
                  small
                  @click="fp_selected = item; modal_fp = true"
                >
                  fas fa-list
                </v-icon>
                <v-icon
                  v-if="editar && ((item.cobro_online == 0 && item.lapos_integrado == 0) ||
                                    (item.cobro_online == 1 && item.estado_solicitud != 2) ||
                                    (item.lapos_integrado == 1 && item.estado_solicitud != 2 &&
                                      (item.actualizar_correcto == 1 || !item.hasOwnProperty('actualizar_correcto'))))"
                  color="error"
                  title="Quitar"
                  class="mr-2"
                  small
                  @click="quitarFP(item)"
                >
                  fas fa-times-circle
                </v-icon>
                <v-icon
                  v-if="!item.completa"
                  color="orange"
                  title="Faltan completar datos"
                  style="cursor: pointer;"
                  :class="`pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                  small
                  @click="fp_selected = item; modal_fp = true"
                >
                  fas fa-exclamation-triangle
                </v-icon>
                <v-icon
                  v-if="item.estado_solicitud == 2 && item.cobro_online == 1"
                  color="indigo"
                  title="Imprimir Cupón"
                  class="mr-2"
                  small
                  @click="imprimirCupon(item)"
                >
                  fas fa-print
                </v-icon>
                <v-icon
                  v-show="nueva || permite_editar == 1"
                  v-if="((item.cobro_online == 1 || item.lapos_integrado == 1) && item.error_id != 0) ||
                        (item.lapos_integrado == 1 && item.estado_solicitud != 2 && item.estado_solicitud != 4 && item.id_solicitud != null)"
                  color="deep-orange"
                  title="Error"
                  :class="`pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                  small
                  @click="fp_selected = item; modal_fp = true"
                >
                  fas fa-exclamation-circle
                </v-icon>
              </template>
              <!-- no datos -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="450"
                  type="info"
                  border="left"
                  dense
                  text
                >
                  No se agregaron Formas de Pago
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </div>
      </v-expand-transition>
    </template>
    <!-- ------------------------------- -->
    <!-- DATOS DEL CLIENTE -->
    <!-- ------------------------------- -->
    <modulo-cliente
      :p_venta_mayorista="venta_mayorista"
      :p_promociones="promociones"
      :p_cliente.sync="cliente"
      :p_cliente_escaneado.sync="cliente_escaneado"
      :p_local="ptovta"
      :p_init="init_clientes"
      :newvta="nueva"
      :empleado="empleado"
      :aplica_desc="aplica_desc"
      :beneficio_empleado="beneficio_empl"
      @descuento="getDescuentos"
      @setNuevo="setNuevoClinte"
      @setBeneficio="setBeneficioEmpl"
    />
    <!-- ------- -->
    <!-- BOTONES -->
    <!-- ------- -->
    <v-col cols="12" class="d-flex justify-end pt-4">
      <v-btn
        :color="nueva ? 'error' : 'secondary'"
        @click="yElUltimoEnQuedarQueApagueLaLuz()"
      >
        {{ nueva ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <v-btn
        v-if="!nueva && (permite_anular == 1 || cobros_pendientes > 0 || formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0)"
        color="error"
        class="ml-3"
        @click="verificarAnular()"
      >
        <v-icon small left>fas fa-ban</v-icon>
        Anular
      </v-btn>
      <v-btn
        v-if="editar"
        color="success"
        class="ml-3"
        tabindex="1"
        :disabled="articulos.length == 0 || simular"
        @click="nueva ? grabarVenta() : editarVenta()"
      >
        <v-icon small left>fas fa-{{ nueva ? 'save' : 'edit' }}</v-icon>
        {{ nueva ? 'Grabar' : 'Modificar' }}
      </v-btn>
    </v-col>
    <!-- --------------------- -->
    <!-- COMPONENTES (MODALES) -->
    <!-- --------------------- -->
    <buscar-articulo
      v-model="modal_articulos"
      :p_lista="precios"
      @selected="seleccionarArticulo"
    />
    <buscar-vendedor
      v-model="modal_vendedores"
      :p_lista="vendedores"
      @selected="seleccionarVendedor"
    />
    <seleccionar-serie
      v-model="modal_series"
      :p_articulo="articulo_select"
      :nueva="nueva"
      @confirmar="setSeries"
      @cancelar="cancelarSeries"
    />
    <modal-forma-pago
      v-model="modal_fp"
      :p_fp.sync="fp_selected"
      :p_ptovta="ptovta"
      :p_init="init_fp"
      :p_formas_pago_group="formas_pago_group"
      :editar="editar"
    />
    <modal-gift-card
      v-model="modal_gf"
      :p_gf="art_gf"
      @nueva="newGF"
      @actualizar="updateGF"
    />
    <ModalEsperaLaPos
      v-model="modal_lapos"
      :venta_id="id_venta"
      :anulando="anulando"
      @editar="editarLapos()"
      @anular="anularVenta(0)"
      @cobrok="finalizarVta()"
      @libera="liberarCobros(0)"
    />
    <BuscarFormaPago
      v-model="modal_search_fp"
      :p_monto="monto"
      :p_bancos="bancos"
      :p_financiaciones="financiaciones_disponibles"
      :p_financiaciones_especiales="financiaciones_especiales"
      :p_formas_pago="formas_pago"
      @seleccionar="seleccionarFP"
    />
    <!-- modal para seleccionar 1 art si encuentra varios -->
    <v-dialog
      v-model="modal_select_art"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Seleccionar artículo
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="modal_select_art = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <v-data-table
            class="cebra"
            sort-by="nombre"
            :items="select_arts"
            :headers="[
              { text: 'Código', value: 'codigo', align: 'end', sortable: false, filterable: false, },
              { text: 'Nombre', value: 'nombre', sortable: false, filterable: false, },
              { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
            ]"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="success"
                title="Seleccionar"
                small
                @click="seleccionarArticulo(item)"
              >
                fas fa-check
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modal informacion adicional del articulo -->
    <v-dialog
      v-model="modal_info_adicional"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title style="word-break: normal;">
          {{ articulo_select.nombre }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="modal_info_adicional = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-form ref="formInfoAdicional">
            <v-textarea
              v-model.trim="info_adicional"
              label="Información adicional"
              counter="200"
              rows="3"
              :rules="[rules.max]"
              validate-on-blur
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="modal_info_adicional = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="info_adicional ? info_adicional.length > 200 : false"
            @click="setInfoAdicional()"
          >
            <v-icon small left>fas fa-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- super modal de espera -->
    <v-dialog
      v-model="modal_espera"
      width="500"
      persistent
      scrollable
    >
      <v-card class="text-center">
        <v-card-title :class="$vuetify.breakpoint.xs ? 'pa-0' : ''">
          <v-alert
            class="text-h5"
            style="word-break: normal; width: 100%"
            :type="estado_titulo"
            prominent
            outlined
            text
          >
            {{ titulo_espera }}
          </v-alert>
        </v-card-title>
        <v-card-text class=" body-1">
          <!-- listado de mensajes -->
          <div
            v-for="(msj, index) of mensajes"
            :key="index"
            class="text-left mb-1"
          >
            <v-icon :color="msj.status" class="mb-1" left small>
              fas fa-{{ msj.status == 'success' ? 'check'  : 'times' }}-circle
            </v-icon>
            {{ msj.mensaje }}
            <v-tooltip
              v-if="msj.status == 'error'"
              right
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="orange" class="mb-1" small>
                  fas fa-exclamation-triangle
                </v-icon>
              </template>
              {{ msj.error }}
            </v-tooltip>
          </div>
          <!-- loading -->
          <div
            v-if="mensaje_espera != ''"
            class="d-flex justify-center align-center mt-4"
            style="font-size: 20px !important;"
          >
            <vue-loaders-ball-clip-rotate-multiple class="mr-4" :color="$vuetify.theme.dark ? 'white' : '#1E88E5'" />
            {{ mensaje_espera }} ...
          </div>
        </v-card-text>
        <v-card-actions v-if="show_exit_button" class="d-flex pb-4">
          <v-row class="d-flex justify-center pa-3">
            <v-btn
              v-if="estado_titulo == 'success'"
              v-show="archivos.length > 0"
              color="info"
              :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mr-3'"
              :disabled="mensaje_espera != ''"
              @click="modal_impresion = true"
            >
              <v-icon small left>fas fa-print</v-icon>
              Reimprimir comprobantes
            </v-btn>
            <v-btn
              v-else
              color="info"
              :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mr-3'"
              :disabled="!(permite_anular == 1 || cobros_pendientes > 0 || venta_copy.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0)"
              @click="verificarAnular()"
            >
              <v-icon small left>fas fa-sync</v-icon>
              Reintentar Anulación
            </v-btn>
            <v-btn
              color="warning"
              @click="yElUltimoEnQuedarQueApagueLaLuz()"
            >
              <v-icon small left>fas fa-sign-in-alt</v-icon>
              Salir
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal de seleccion de impresiones -->
    <v-dialog
      v-model="modal_impresion"
      width="400"
      persistent
      scrollable
    >
      <v-card class="text-center">
        <v-card-title style="word-break: normal;">
          ¿Desea imprimir los siguientes comprobantes?
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
            style="cursor: pointer;"
            :items="archivos"
            :headers="[
              { text: '', value: 'actions', sortable: false, filterable: false, width: '1' },
              { text: '', value: 'name', sortable: false, filterable: false }
            ]"
            :items-per-page="-1"
            hide-default-footer
            hide-default-header
            dense
            @click:row="rowClick"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-checkbox
                v-model="item.imprimir"
                class="py-0 my-0 mr-n8 ml-4"
                :true-value="1"
                :false-value="0"
                :disabled="item.opcional == 0"
                hide-details
                readonly
                dense
              ></v-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex py-4">
          <v-row class="d-flex justify-center pa-3">
            <v-btn
              color="success"
              class="mr-2"
              @click="imprimir()"
            >
              Sí
            </v-btn>
            <v-btn
              color="error"
              class="ml-2"
              @click="modal_impresion = false; show_exit_button = true"
            >
              No
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import download from 'downloadjs'
import * as JSPM from 'jsprintmanager'
import { mapState, mapGetters } from 'vuex'
import { format_money, format_money_round, order_list_by, roundNumberTF, ArrayBufferToBase64 } from '../../util/utils'
import { pdfCobroOnline, remitoTermica, facturaTermica } from '../../util/plantillas/pdfs'
import TextFieldMoney from '../../components/util/TextFieldMoney.vue'
import BuscarArticulo from '../../components/ventas/venta/BuscarArticulo.vue'
import BuscarVendedor from '../../components/ventas/venta/BuscarVendedor.vue'
import ModuloCliente from '../../components/ventas/venta/ModuloCliente.vue'
import SeleccionarSerie from '../../components/ventas/venta/SeleccionarSerie.vue'
import ModalFormaPago from '../../components/ventas/venta/ModalFormaPago.vue'
import ModalGiftCard from '../../components/ventas/venta/ModalGiftCard.vue'
import ModalEsperaLaPos from '../../components/ventas/venta/ModalEsperaLaPos.vue'
import BuscarFormaPago from '../../components/ventas/venta/BuscarFormaPago.vue'

export default {
  data () {
    return {
      // en caso q se rompa todo, poner en falso la siguiente variable de redondeo
      redondear_detalles: true,
      formatMoney: format_money,
      formatMoneyRound: format_money_round,
      roundNumberTF: roundNumberTF,
      id_venta: this.$route.params.id,
      nueva: false,
      editar: false,
      simular: false,
      todo_efectivo: false,
      venta_mayorista: false,
      modal_lapos: false,
      modal_espera: false,
      modal_impresion: false,
      modal_search_fp: false,
      modal_articulos: false,
      modal_select_art: false,
      modal_vendedores: false,
      modal_info_adicional: false,
      modal_series: false,
      modal_fp: false,
      modal_gf: false,
      bloquear_vend: false,
      bloquear_ptovta: false,
      show_exit_button: false,
      titulo_espera: '',
      estado_titulo: '',
      mensaje_espera: '',
      permite_editar: 0,
      permite_anular: 0,
      anula_dias_mes: 0,
      importe_max_b: 0,
      venta_min: 0,
      redondeo: 0,
      empleado: 0,
      anulando: 0,
      keyEfectivo: 0,
      aplica_desc: 0,
      beneficio_empl: 0,
      modifica_precio: 0,
      cobros_pendientes: 0,
      vta_min_mayorista: 0,
      redondeo_ext_efect: 0,
      limite_ext_efectivo: 0,
      efectivo_disponible: 0,
      efectivo_caja: 0,
      combo: false,
      debito: false,
      efectivo: false,
      giftcard: false,
      sucursal: null,
      lista_variable: null,
      lista_vble_nombre: null,
      ptovta: {},
      terminal: null,
      articulo: null,
      vend_cod: null,
      vendedor: null,
      vendedor_acc: null,
      vendedor_nombre: null,
      giftcard_codigo: '',
      lista_local: null,
      lista: null,
      canal: null,
      cupon: null,
      id_fin: 0,
      monto_cuotas: 0,
      fp_importe: 0,
      forma_pago: {},
      banco: null,
      monto: null,
      monto_articulos: 0,
      monto_descuento: 0,
      monto_efectivo: 0,
      monto_redondeo: 0,
      monto_fp_alic: 0,
      monto_fp: 0,
      subtotal_fp: 0,
      info_adicional: '',
      stock_giftcard: [],
      art_gf: {},
      cliente: {},
      cliente_nuevo: false,
      cliente_escaneado: {},
      articulo_select: {},
      fp_selected: {},
      precios: [],
      listas_vbles: [],
      promociones: [],
      select_arts: [],
      simulacion: [],
      terminales: [],
      vendedores: [],
      articulos: [],
      expanded: [],
      bancos: [],
      cuentas: [],
      localidades: [],
      formas_pago: [],
      formas_pago_group: [],
      financiaciones_especiales: [],
      financiaciones_disponibles: [],
      lapos_eliminados: [],
      array_fp: [],
      archivos: [],
      mensajes: [],
      headersArt: [
        { text: 'Cant.', value: 'cantidad', sortable: false, filterable: false, width: '65', align: 'right' },
        { text: 'Cod. Interno', value: 'codigo', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Artículo', value: 'nombre', sortable: false, filterable: false },
        { text: 'Precio lista', value: 'precio_lista', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Desc.', value: 'descuento', sortable: false, filterable: false, width: '85', align: 'right' },
        { text: 'Precio', value: 'precio', sortable: false, filterable: false, width: '135', align: 'right' },
        { text: 'Importe', value: 'importe', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, width: '1' },
        { text: 'Financiación especial', value: 'financing', sortable: false, filterable: false }
      ],
      headersFinEsp: [
        { text: 'Financiación', value: 'financiacion', sortable: false, filterable: false },
        { text: 'Tarjeta', value: 'tarjeta_nombre', sortable: false, filterable: false, width: '110' },
        { text: 'Cuota', value: 'cuota_nombre', sortable: false, filterable: false, width: '90' },
        { text: 'Importe', value: 'monto', sortable: false, filterable: false, align: 'right', width: '135' },
        { text: 'Valor cuota', value: 'cuota', sortable: false, filterable: false, align: 'right', width: '1' },
        { text: 'Total', value: 'total', sortable: false, filterable: false, align: 'right', width: '1' },
        { text: '', value: 'actions', sortable: false, filterable: false, align: 'end', width: '56' }
      ],
      headersFP: [
        { text: 'Online', value: 'cobro_online', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'Integrado', value: 'lapos_integrado', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'QR', value: 'qr', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'Monto', value: 'monto', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Banco', value: 'banco', sortable: false, filterable: false, width: '1' },
        { text: 'Forma pago', value: 'descripcion', sortable: false, filterable: false },
        { text: 'Monto extracción', value: 'monto_extraccion', sortable: false, filterable: false, width: '160', align: 'right' },
        { text: 'Monto cuotas', value: 'monto_cuotas', sortable: false, filterable: false, width: '100', align: 'right' },
        { text: 'Total', value: 'total', sortable: false, filterable: false, width: '1', align: 'right', cellClass: 'font-weight-bold' },
        { text: '', value: 'actions', sortable: false, filterable: false, width: '180' }
      ],
      locales_user: [],
      init_clientes: {
        provincias: [],
        tipos_doc: [],
        cond_iva: []
      },
      venta_copy: {},
      init_fp: {
        bancos_pais: [],
        tipos_mov: [],
        cuentas: [],
        bancos: []
      },
      datosFPLimpio : {
        codigo_autorizacion: '',
        cuotas: '',
        cuotas_importe: '',
        tarjeta_numero: '',
        tarjeta_codigo: null,
        terceros: false,
        cliente: false,
        titular: '',
        titular_dni: '',
        imei_cotizado: '',
        descuento: '',
        cupon: '',
        lote: '',
        numero_prestamo: '',
        legajo_fisico: null,
        fecha_cobro: null,
        banco_codigo: null,
        banco_cuenta: null,
        banco_fecha: null,
        banco_tipo_movimiento: null,
        banco_comprobante: '',
        tarjeta_vencimiento: '',
        codigo_seguridad: ''
      },
      log: {
        usuario: '',
        fecha: ''
      },
      rules: {
        max: value => (value ? value.length : 0) <= 200 || 'Máximo 200 caracteres'
      }
    }
  },
  async created () {
    this.nueva = !this.id_venta
    await new Promise(resolve => setTimeout(resolve, 1))
    this.$store.state.loading = true
    await this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
    let result = await this.$store.dispatch('ventas/init_form')
    this.$store.state.loading = false

    if (result.exito == 1) {
      const datos = result.data
      this.locales_user = datos.locales.data
      this.bancos = datos.bancos_pais.data
      this.vend_cod = datos.vend_cod
      this.init_clientes = {
        provincias: datos.provincias.data,
        tipos_doc: datos.tipos_doc.data,
        cond_iva: datos.cond_iva.data
      }
      this.init_fp = {
        bancos_pais: datos.bancos_pais.data,
        tipos_mov: datos.tipos_mov.data,
        cuentas: datos.cuentas.data,
        bancos: []
      }
      this.anula_dias_mes = this.setFuncParmDefault(datos.parametros.anula_dias_mes)
      this.importe_max_b = this.setFuncParmDefault(datos.parametros.importe_max_b)
      this.venta_min = this.setFuncParmDefault(datos.parametros.venta_min)
      this.redondeo = this.setFuncParmDefault(datos.parametros.redondeo)
      this.redondeo_ext_efect = this.setFuncParmDefault(datos.parametros.redondeo_ext_efect)
      this.limite_ext_efectivo = this.setFuncParmDefault(datos.parametros.limite_ext_efectivo)
      this.vta_min_mayorista = this.setFuncParmDefault(datos.parametros.vta_min_mayorista)
      this.modifica_precio = this.setFuncParmDefault(datos.funciones.modifica_precio)
    } else {
      this.$store.dispatch('show_snackbar', {
        text: result.message,
        color: 'error'
      })
    }

    if (this.nueva) {
      this.editar = true
      if (this.una_sucursal) {
        this.sucursal = this.sucursales[0].id
        // si tiene favorito lo autoselecciono
        let favorito = this.locales_user.filter(lu => lu.favorito == 1)
        if (favorito.length == 1) {
          this.ptovta = favorito[0]
          this.bloquear_ptovta = true
          this.$refs.vtfVendedor.focus()
        } else {
          this.$refs.atcLocal.focus()
        }
      } else {
        this.$refs.atcSucursal.focus()
      }
    } else {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/get_vta', this.id_venta)
      this.$store.state.loading = false

      if (result.exito != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }

      this.venta_copy = JSON.parse(JSON.stringify(result.data))
      this.loadVta(result.data)
    }
  },
  mounted () {
    this.JSPMInit()
  },
  computed: {
    ...mapGetters(['una_sucursal']),
    ...mapState(['sucursales'])
  },
  components: {
    TextFieldMoney,
    BuscarArticulo,
    BuscarVendedor,
    ModuloCliente,
    SeleccionarSerie,
    ModalFormaPago,
    ModalGiftCard,
    ModalEsperaLaPos,
    BuscarFormaPago
  },
  watch: {
    forma_pago: {
      handler: function (val) {
        if (val == null) this.forma_pago = {}
      },
      deep: true
    },
    ptovta: {
      handler: async function () {
        if (this.nueva) this.limpiar()
        if (this.ptovta == null) {
          this.ptovta = {}
          return
        }
        if (Object.keys(this.ptovta).length != 0) {

          if (this.nueva) {
            // si es venta mayorista le seteo el canal 2, sino el canal del punto de venta
            this.canal = this.venta_mayorista ? 2 : this.ptovta.canal
          } else {
            // si la venta existe seteo el canal q me vino
            this.canal = JSON.parse(JSON.stringify(this.venta_copy.cabecera.canal))
          }

          this.$store.state.loading = true
          let result = await this.$store.dispatch('ventas/ptovta_data', {
            mayorista_salon: this.venta_mayorista ? 1 : 0,
            empresa: this.ptovta.empresa,
            sucursal: this.ptovta.sucursal,
            local: this.ptovta.id,
            local_acc: this.ptovta.local_accesorios,
            canal: this.canal,
            lista: this.lista_variable,
            ver: !this.nueva && this.venta_copy.cabecera.permite_modificar == 0 ? 1 : 0
          })
          this.$store.state.loading = false

          if (result.exito == 1) {
            const datos = result.data
            this.lista_local = datos.lista
            this.precios = datos.precios.data
            this.terminales = datos.terminales.data
            this.vendedores = datos.vendedores.data
            this.listas_vbles = datos.listas_variables.data
            this.financiaciones_disponibles = datos.financiaciones.data
            this.init_fp.bancos = datos.bancos.data
            // si tiene codigo de vendedor se lo autocompleto
            if (this.vend_cod) {
              this.vendedor = this.vend_cod
              this.buscarVendedor()
            }
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }
          order_list_by(this.financiaciones_disponibles, 'forma_pago_nombre')

          // si ya usaron una terminal, veo si puedo setearla
          if (localStorage.getItem('terminal_def')) {
            let num_terminal = JSON.parse(localStorage.getItem('terminal_def'))
            // determino si se encuentra en mi listado de terminales
            let terminal = this.terminales.find(ter => ter.numero == num_terminal)
            this.terminal = terminal ? terminal.numero : null
          }
        }
      },
      deep: true
    },
    articulos: {
      handler: function () {
        this.todo_efectivo = this.articulos.filter(art => art.efectivo == 1).length > 0
        this.keyEfectivo++
        this.calcularTotales()
      },
      deep: true
    },
    formas_pago_group: {
      handler: function () {
        this.calcularTotales()
      },
      deep: true
    },
    efectivo (val) {
      if (val) {
        this.limpiarFP()
        this.terminal = null
        this.formas_pago = []
        this.formas_pago_group = []
        this.efectivo_disponible = 0
        this.efectivo_caja = 0
        this.lista_variable = null
        this.aplicarPrecioLista(1)
        this.agregarEfectivo()
      } else {
        this.limpiarEfectivo()
        // si tenian una terminal seleccionada debo volver a seleccionarla
        if (Object.keys(this.venta_copy).length > 0 && this.venta_copy.cabecera.terminal_id) {
          this.terminal = parseInt(this.venta_copy.cabecera.terminal_id)
        }
        this.getDescuentos(1)
      }
    },
    giftcard (val) {
      if (val) {
        this.headersArt.splice(6, 0, { text: 'Imp. Gift', value: 'importe_gift', sortable: false, filterable: false, width: '1' })
      } else {
        this.limpiarGF()
        this.headersArt.splice(6, 1)
      }
      this.getDescuentos()
    },
    combo (val) {
      let cantidad = this.giftcard ? 8 : 7
      if (this.headersArt.find(ha => ha.value == 'efectivo')) {
        cantidad++
      }
      if (val) {
        this.headersArt.splice(cantidad, 0, { text: 'Combo', value: 'combo', sortable: false, filterable: false, width: '75', align: 'center' })
        for (let art of this.articulos) {
          art.combo = art.combo ? art.combo : 1
        }
      } else {
        this.headersArt.splice(cantidad, 1)
        for (let art of this.articulos) {
          art.combo = null
        }
      }
    },
    modal_info_adicional (val) {
      if (val) {
        this.info_adicional = JSON.parse(JSON.stringify(this.articulo_select.info_adicional))
      } else {
        this.$refs.formInfoAdicional.resetValidation()
        this.articulo_select = {}
        this.info_adicional = ''
      }
    },
    editar (val) {
      if (!val) {
        this.limpiarEfectivo(1)
        this.loadVta(JSON.parse(JSON.stringify(this.venta_copy)))
      }
    },
    simular (val) {
      val || this.validarDesimulacion()
    },
    modal_series (val) {
      val || this.validarDesimulacion()
    }
  },
  methods: {
    /****************
      FACTURACION E IMPRESIONES
     ****************/
    async getNotaCredito () {
      // solo hago la rnc si no esta facturada
      if (this.venta_copy.cabecera.permite_modificar == 1) return

      this.mensaje_espera = 'Obteniendo el Comprobante Asociado'
      let result_comp = await this.$store.dispatch('ventas/get_comp_asociado', this.venta_copy.comprobante_caja.id)

      if (result_comp.exito != 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_comp.message
        })
        return
      }

      this.mensaje_espera = 'Solicitando Nota de Crédito'
      let result_nc = await this.$store.dispatch('afip/getNotaCredito', { comprobante_caja_id: result_comp.data })

      if (result_nc.resultado == 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })

        this.mensaje_espera = 'Obteniendo datos de la Nota de Crédito'
        await this.generarFactura(result_comp.data)
        
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_nc.msj
        })
      }

    },
    async getRemito () {
      this.mensaje_espera = 'Obteniendo el Remito'
      let result_re = await this.$store.dispatch('ventas/get_remito', this.id_venta)
      if (result_re.exito == 1) {
        let pdf = await remitoTermica(result_re.data)
        this.archivos.push({
          file: pdf,
          name: `Remito N° 0000-${result_re.data.id_remito.toString().padStart(8, '0')}`,
          imprimir: 1,
          opcional: 1
        })
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_re.message
        })
      }
    },
    async facturar (comprobante) {
      this.mensaje_espera = 'Facturando la Venta'
      let result_fa = await this.$store.dispatch('afip/getFactura', { comprobante_caja_id: comprobante })

      if (result_fa.resultado == 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })

        this.mensaje_espera = 'Obteniendo datos de la Factura'
        await this.generarFactura(comprobante)

        if (this.cliente.email) {
          this.mensaje_espera = 'Enviando Email'
          let res_email = await this.$store.dispatch('caja/enviar_factura_cliente', {
            venta_id: this.id_venta,
            email: this.cliente.email
          })
          if (res_email.resultado == 1) {
            this.mensajes.push({
              mensaje: this.mensaje_espera,
              status: 'success'
            })
          } else {
            this.mensajes.push({
              mensaje: this.mensaje_espera,
              status: 'error',
              error: res_email.message
            })
          }
        }
        
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_fa.msj
        })
      }
    },
    async imprimirCupon (detalle) {
      let result_pdf = await this.$store.dispatch('cobros/get_pdf_cobro_data', { id: detalle.id_solicitud })
      if (result_pdf.resultado == 1) {
        let pdf_res = await pdfCobroOnline(result_pdf.cobro)
        if (pdf_res.resultado == 1) {
          if (this.ptovta.impresion_automatica == 1) {
            this.doPrintPDF(pdf_res.pdf)
          } else {
            await download(pdf_res.pdf, `Comprobante del Cobro N° ${detalle.id_solicitud}.pdf`, 'application/pdf')
          }
        }
      }
    },
    async imprimir () {
      this.mensaje_espera = 'Descargando comprobantes'
      for (const archivo of this.archivos) {
        if (archivo.imprimir == 1 || archivo.opcional == 0) {
          if (this.ptovta.impresion_automatica == 1) {
            this.doPrintPDF(archivo.file)
          } else {
            await download(archivo.file, `${archivo.name}.pdf`, 'application/pdf')
          }
          // si mando a imprimir pongo todo opcional, esto es para q la primera vez q imprima sea obligatorio los cobros y si reimprime ya no
          archivo.opcional = 1
        }
      }
      this.mensaje_espera = ''
      this.modal_impresion = false
      this.show_exit_button = true
    },
    async generarFactura (comprobante_id) {
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comprobante_id })

      if (respuesta.exito == 1) {
        const datos = respuesta.datos
        let pdf_factura = datos.encabezado.impresora_termica == 1 ? await facturaTermica(datos) : await facturaA4(datos)
        this.archivos.push({
          file: pdf_factura,
          name: `${datos.encabezado.referencia}-${datos.encabezado.emynum}`,
          imprimir: 0,
          opcional: 1
        })
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: respuesta.message
        })
      }
    },
    /****************
      VENTA Y LAPOS INTEGRADO
     ****************/
    async verificarAnular () {
      // si detecto incosistencias entre los array originales y los q tiene actualmente es pq se estan haciendo los locos quitando detalles para anular :^v
      // no tiene q tener ninguno nuevo agregar y la longitud tiene q ser la misma
      if (this.venta_copy.formas_pago_group.length != this.formas_pago_group.length ||
          this.formas_pago_group.filter(fpg => fpg.id == null).length > 0) {
        this.$swal.fire({
          icon: 'error',
          title: 'Se detectaron incosistencias en las Formas de Pago',
          text: 'Refresque la página si quiere reintentar este procedimiento'
        })
        return
      }

      let json_modal = {
        icon: 'question',
        title: `¿Está seguro de anular la Venta N° ${this.id_venta}?`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowEnterKey: false
      }

      let lapos = this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1)
      let cobros = this.formas_pago_group.filter(fpg => fpg.cobro_online == 1)
      let tiene_cobros = lapos.length > 0 || cobros.length > 0

      // si tiene lapos o cobros pregunto si quere liberarlos
      if (tiene_cobros) {
        json_modal.input = 'radio'
        json_modal.text = 'Anular con Cobros (Online y/o Integrado):',
        json_modal.inputOptions = {
          0: 'NO',
          1: 'SI'
        }
        json_modal.inputValidator = (value) => {
          if (!value) {
            return 'Debe seleccionar una opción'
          }
        }
      }

      let modal = await this.$swal.fire(json_modal)
      if (!modal.isConfirmed) return

      // si tiene cobros verifico si quiere anularlos
      if (tiene_cobros) {
        if (modal.value == 1) {
          // si tiene lapos paso a abrir el modal
          if (lapos.length > 0) {
            this.anulando = 1
            this.modal_lapos = true
          } else {
            // si no tiene lapos, en la anulacion ya me ocupo de los cobros >:D
            this.anularVenta(0)
          }
        } else {
          this.liberarCobros(1)
        }
      } else {
        // si no tiene paso directamente a anular
        this.anularVenta(0)
      }
    },
    async liberarCobros (con_lapos) {
      this.initModalEspera('Anulando la Venta N° ' + this.id_venta, 'info')
      this.mensaje_espera = 'Liberando cobros'

      // si esta anulando desde el editar antes tengo q liberar todos los lapos :v
      if (con_lapos == 1) {
        let pendientes = this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1 && fpg.estado_solicitud == 1)
        if (pendientes.length > 0) {
          for (let lapos of pendientes) {
            let result = await this.$store.dispatch('laposIntegrado/cancelar_pago', lapos.id_solicitud)
            
            // si la cancelacion del pendiente tiro error puede ser q el vendedor se hizo el loco y lo haya cobrado o q nunca se actualizo el estado
            if (result.exito != 1) {
              // por lo tanto solo si tira error mando a sincronizar ese detalle y quiebro
              let result_sync = await this.$store.dispatch('laposIntegrado/actualizar', lapos.id_solicitud)

              if (result_sync.exito == 1) {
                // si sincronizo bien obtengo el nuevo estdao y lo actualizo en el array para q no quede en un loop
                let result_act = await this.$store.dispatch('ventas/get_estado_cobros', [ lapos.id_solicitud ])

                if (result_act.exito == 1) {
                  let estados = result_act.data.filter(sol => sol.id == lapos.id_solicitud)
                  if (estados.length == 1) {
                    lapos.estado_solicitud = estados[0].estado
                  }
                }
              }

              this.setErrorAnularModal(result.message)
              return
            }
          }
        }
      }

      // paso a liberar los cobros
      let result = await this.$store.dispatch('ventas/liberar_cobros', this.id_venta)

      if (result.exito == 1) {
        // se libero todo joya, paso a anular
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })
        this.anularVenta(1)
      } else {
        // si no se pudieron liberar F
        this.setErrorAnularModal(result.message)
      }
    },
    async anularVenta (liberar_cobros) {
      // si viene de liberar cobros no inicio el modal pq ya se inicio en el liberar
      if (liberar_cobros == 0) {
        this.initModalEspera('Anulando la Venta N° ' + this.id_venta, 'info')

        // si no viene del liberar y ademas tiene cobros online tengo q anularlos
        let cobros = this.formas_pago_group.filter(fpg => fpg.cobro_online == 1)
        if (cobros.length > 0) {
          this.mensaje_espera = 'Anulando Cobros Online'

          let result = await this.$store.dispatch('ventas/get_estado_cobros', cobros.map(co => co.id_solicitud))

          // verifico q lo q haya devuelto exista
          let existentes = 0
          for (const detalle of result.data) {
            let cobro = this.formas_pago_group.filter(fpg => fpg.id_solicitud == detalle.id)
            if (cobro.length == 1) {
              existentes++
            }
          }

          if (result.exito != 1 || existentes != cobros.length) {
            this.setErrorAnularModal(result.exito == 1 ? 'Se detectaron inconsistencias en los Cobros Online' : result.message)
            return
          }

          // todo lo q este en estado pendiente tengo q revisar si realmente se mando antes de marcarlo como anulado
          for (const detalle of result.data.filter(dt => dt.estado == 1)) {
            let res_cancelar = await this.$store.dispatch('cobros/cancelar_cobro_electronico', {
              solicitud_id: detalle.id,
              empresa_id: this.ptovta.empresa
            })
            if (res_cancelar.exito != 1) {
              this.setErrorAnularModal(res_cancelar.message)
              return
            }
          }

          // mando a anular todo lo q esta en estado aprobado
          for (const detalle of result.data.filter(dt => dt.estado == 2)) {
            let cobro = this.formas_pago_group.find(fpg => fpg.id_solicitud == detalle.id)

            let res_anular = await this.$store.dispatch('cobros/anular_cobro_electronico', {
              transaccion_id: cobro.cobro_transaccion,
              empresa_id: this.ptovta.empresa
            })
            
            if (res_anular.resultado == 1) {
              // si se anulo paso a agregar el comprobante al array de archivos
              let result_pdf = await this.$store.dispatch('cobros/get_pdf_cobro_data', { id: detalle.id })
              if (result_pdf.resultado == 1) {
                let pdf_res = await pdfCobroOnline(result_pdf.cobro)
                if (pdf_res.resultado == 1) {
                  this.archivos.push({
                    file: pdf_res.pdf,
                    name: `Comprobante del Cobro N° ${detalle.id}`,
                    imprimir: 1,
                    opcional: 0
                  })
                }
              }
            } else {
              this.setErrorAnularModal(res_anular.msj)
              return
            }
          }
          this.mensajes.push({
            mensaje: this.mensaje_espera,
            status: 'success'
          })
        }
      }

      // si tiene lapos y retira efectivo debo sincronizar los cierres de lote

      this.mensaje_espera = 'Anulando la Venta'
      let result = await this.$store.dispatch('ventas/anular_vta', this.id_venta)

      if (result.exito != 1) {
        this.setErrorAnularModal(result.message)
        return
      }

      // venta anulada correctamente []v
      this.titulo_espera = 'Venta N°' + this.id_venta + ' anulada correctamente'
      this.estado_titulo = 'success'
      this.mensajes.push({
        mensaje: this.mensaje_espera,
        status: 'success'
      })

      // paso a ver si genero la NC
      await this.getNotaCredito()
      
      // imprimo los comprobantes y finalizo
      this.mensaje_espera = ''
      if (this.archivos.length > 0) {
        this.modal_impresion = true
      } else {
        this.show_exit_button = true
      }

    },
    async editarVenta () {
      const titulo = 'Error al modificar la Venta'

      let formas_pago = []
      let result_validar = await this.validarVenta(titulo)
      if (result_validar.exito == 1) {
        formas_pago = result_validar.formas_pago
      } else {
        return
      }

      // controlo el array lapos_eliminados para ver si eliminaron alguno de los detalles de lapos integrado
      // los estados de los lapos se actualizaron cuando lo quitaron, por lo cual tenemos los estados actualizados con los que nos mando la api de LaPos
      if (this.lapos_eliminados.length > 0) {
        let error = 0
        for (let lapos of this.lapos_eliminados) {
          // la unica forma que figuren en lapos_eliminados es que haya estado pendiente o con error
          this.$store.state.loading_lps = true
          let result_can = await this.$store.dispatch('laposIntegrado/cancelar_pago', lapos.id_solicitud)
          this.$store.state.loading_lps = false
          if (result_can.exito != 1) {
            error++
          }
        }
        if (error > 0) {
          this.$swal.fire({
            icon: 'error',
            title: 'No se pudieron enviar algunas Cancelaciones a LaPos',
            text: 'No se puede continuar con la modificación'
          })
          return
        }
      }

      // pasó todas las validaciones >:D
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          nombre: art.nombre,
          precio: art.precio_real,
          precio_lista: art.precio_lista,
          importe: art.importe_real,
          efectivo: art.efectivo,
          cantidad: art.cantidad,
          descuento_efectivo: art.descuento_efectivo,
          descuento: art.descuento,
          gift_card: art.gift_card
        }
      })

      let json = {
        id_venta: this.id_venta,
        sucursal: this.sucursal,
        local: this.ptovta.local_accesorios,
        canal: this.canal,
        cupon: this.cupon,
        fecha: moment(this.venta_copy.cabecera.fecha).format('DD/MM/YYYY'),
        total_descuento: roundNumberTF(this.monto_descuento),
        total_redondeo: roundNumberTF(this.monto_redondeo),
        total_efectivo: roundNumberTF(this.monto_efectivo),
        total_tarjeta: roundNumberTF(this.monto_fp_alic),
        total: roundNumberTF(this.monto_efectivo + this.monto_fp_alic),
        subtotal: roundNumberTF(this.monto_articulos),
        ajuste_total: roundNumberTF(this.monto_fp_alic - this.monto_fp),
        articulos: articulos,
        formas_pago: formas_pago,
        formas_pago_group: this.formas_pago_group,
        cliente: {
          apellido: this.cliente.apellido,
          nombre: this.cliente.nombre,
          codigo: this.cliente.codigo,
          fecha_nac: this.cliente.fecha_nac,
          cumpleanos: this.cliente.cumpleanos,
          tipo_doc: this.cliente.tipo_doc,
          num_doc: this.cliente.documento,
          cuit: this.cliente.documento
        }
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/editar_vta', json)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: titulo,
          text: result.message
        })
        return
      }

      this.initModalEspera(result.message, 'success')

      // controlo los cobro online q tengan que reprocesarse o cobrarse por primera vez
      let cobros_online = this.formas_pago_group.filter(fpg => fpg.cobro_online == 1 && fpg.decidir_reprocesar == 1)
      if (cobros_online.length > 0) {
        let exito = await this.sendCobrosOnline(cobros_online)
        if (!exito) return
      }

      this.verifyLapos(result)
    },
    async grabarVenta () {
      const titulo = 'Error al grabar la Venta'
      
      let formas_pago = []
      let result_validar = await this.validarVenta(titulo)
      if (result_validar.exito == 1) {
        formas_pago = result_validar.formas_pago
      } else {
        return
      }

      // pasó todas las validaciones >:D
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          nombre: art.nombre,
          precio: art.precio_real,
          precio_lista: art.precio_lista,
          importe: art.importe_real,
          titular: art.titular,
          dni: art.dni,
          solicita_serie: art.solicita_serie,
          solicita_datos: art.solicita_datos,
          efectivo: art.efectivo,
          cantidad: art.cantidad,
          descuento_efectivo: art.descuento_efectivo,
          descuento: art.descuento,
          gift_card: art.gift_card,
          importe_gift: art.importe_gift,
          combo: art.combo,
          info_adicional: art.info_adicional,
          series: art.series
        }
      })

      let json = {
        venta_mayorista: this.venta_mayorista ? 1 : 0,
        beneficio: this.beneficio_empl,
        sucursal: this.ptovta.sucursal,
        local: this.ptovta.id,
        local_acc: this.ptovta.local_accesorios,
        vendedor: this.vendedor,
        vendedor_acc: this.vendedor_acc,
        total_descuento: roundNumberTF(this.monto_descuento),
        total_redondeo: roundNumberTF(this.monto_redondeo),
        total_efectivo: roundNumberTF(this.monto_efectivo),
        total_tarjeta: roundNumberTF(this.monto_fp_alic),
        total: roundNumberTF(this.monto_efectivo + this.monto_fp_alic),
        subtotal: roundNumberTF(this.monto_articulos),
        ajuste_total: roundNumberTF(this.monto_fp_alic - this.monto_fp),
        cupon: this.cupon,
        giftcard_codigo: this.giftcard_codigo,
        terminal: this.terminal,
        canal: this.canal,
        lista: this.lista,
        lista_esp: this.lista_variable,
        articulos: articulos,
        formas_pago: formas_pago,
        formas_pago_group: this.formas_pago_group,
        cliente_scan: this.cliente_escaneado,
        cliente: this.cliente
      }

      // guardo en localStorage el nro de terminal por default
      if (this.terminal) localStorage.setItem('terminal_def', JSON.stringify(this.terminal))

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/grabar_vta', json)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: titulo,
          text: result.message
        })
        return
      }

      this.initModalEspera(result.message, 'success')

      this.id_venta = result.datos.pedido_num

      // si compro una gf envio el mail
      if (this.articulos.filter(art => art.gift_card == 1).length > 0) {
        this.mensaje_espera = 'Enviando email de Gift Card'
        let result_gf = await this.$store.dispatch('ventas/enviar_email_gf', { venta_id: this.id_venta })

        if (result_gf.resultado == 1) {
          this.mensajes.push({
            mensaje: this.mensaje_espera,
            status: 'success'
          })
        } else {
          this.mensajes.push({
            mensaje: this.mensaje_espera,
            status: 'error',
            error: result_gf.message
          })
        }
      }

      // controlo si tiene cobros online
      let cobros_online = this.formas_pago_group.filter(fpg => fpg.cobro_online == 1)
      if (cobros_online.length > 0) {
        let exito = await this.sendCobrosOnline(cobros_online)
        if (!exito) return
      }

      this.verifyLapos(result)
    },
    async validarVenta (titulo) {
      return await new Promise(async (resolve, reject) => {
        const data_error = {
          exito: 0,
          formas_pago: []
        }

        // muestro el modal de confirmación
        let modal = await this.$swal.fire({
          title: 'Confirmar Venta',
          html: `
          <span style="font-size: 18px">Desea confirmar la venta por un total de:</span>
          <table style="width: 100%; margin-top: 15px; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
            <tr style="height: 30px">
              <th style="font-size: 18px; text-align: left">Total Efectivo</th>
              <th style="font-size: 20px; text-align: right">${format_money(this.monto_efectivo)}</th>
            </tr>
            <tr style="height: 30px">
              <th style="font-size: 18px; text-align: left">Total Tarjeta</th>
              <th style="font-size: 20px; text-align: right">${format_money(this.monto_fp_alic)}</th>
            </tr>
          </table>
          <div style="margin${this.$vuetify.breakpoint.xs ? '-top: 15px' : ': 15px 15px 0px 15px'}; border: 1px solid"></div>
          <table style="width: 100%; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
            <tr style="height: 50px">
              <th style="font-size: 20px; text-align: left">Total</th>
              <th style="font-size: 22px; text-align: right">${format_money(this.monto_efectivo + this.monto_fp_alic)}</th>
            </tr>
          </table>
          `,
          width: '450px',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          allowEnterKey: false
        })

        if (!modal.isConfirmed) return resolve(data_error)
        
        // empiezo con las validaciones ¬¬
        const cliente_valido = this.nueva ? !this.cliente.num_doc && !this.cliente.cuit : !this.cliente.documento

        // valido que el vendedor no sea null
        if (!this.vendedor) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Debe seleccionar un vendedor para poder grabar la venta'
          })
          return resolve(data_error)
        }

        // valido que no le tenga q pagar yo al cliente xd
        if (roundNumberTF(this.monto_efectivo) < 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Existen diferencias en las Formas de Pago. Verificar'
          })
          return resolve(data_error)
        }

        // verifico q si no puso fp tilde efectivo
        // puede ser q page con gf, en ese caso el efectivo puede no estar tildado solo si no hay efectivo xd
        if ((this.formas_pago_group.length == 0 && !this.efectivo) || (this.formas_pago_group.length == 0 && !this.efectivo && this.giftcard && roundNumberTF(this.monto_efectivo) > 0)) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'No se agregaron Formas de Pago, seleccione Efectivo'
          })
          return resolve(data_error)
        }

        // si es un cliente nuevo y cuit y num_doc son nulos es pq no completo nada >:v
        if (this.cliente_nuevo && !this.cliente.cuit && !this.cliente.num_doc) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Complete los campos del cliente'
          })
          return resolve(data_error)
        }

        // si ingreso un cliente verifico q envie la condicion de iva y el tipo de doc
        if (!!this.cliente.cuit || !!this.cliente.num_doc) {
          if (!this.cliente.cond_iva || !this.cliente.tipo_doc) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'Debe ingresar Condición de IVA y Tipo Documento del cliente'
            })
            return resolve(data_error)
          }
          // tmb tiene q ingresar los nombres, q no se haga el loco
          if (!this.cliente.apellido || !this.cliente.nombre) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'Debe ingresar Apellido y Nombre del cliente'
            })
            return resolve(data_error)
          }
          // si el tipo de doc es CUIT verifico q sean 11 caracteres
          if (this.cliente.tipo_doc == 1 && (!this.cliente.cuit || this.cliente.cuit.toString().length != 11)) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'El CUIT del cliente debe tener 11 dígitos'
            })
            return resolve(data_error)
          }
        }

        // el monto de los articulos va a ser el subtotal - descuentos + redondeo + intereses
        const monto_arts = roundNumberTF(this.monto_articulos - this.monto_descuento + this.monto_redondeo + (this.monto_fp_alic - this.monto_fp))

        // si la venta supera el monto minimo o si al menos 1 art solicita datos, valido los datos del cliente
        if (monto_arts >= this.venta_min || this.articulos.filter(art => art.solicita_datos == 1).length > 0) {
          if (cliente_valido) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `La venta tiene un monto de ${format_money(monto_arts)} es obligatorio completar los datos del cliente`
            })
            return resolve(data_error)
          }
          if (!this.cliente.email || !this.cliente.telefono) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `La venta tiene un monto de ${format_money(monto_arts)} es obligatorio Email y Teléfono`
            })
            return resolve(data_error)
          }
        }

        // verificio si agrego una forma de pago
        if (this.formas_pago_group.length > 0) {
          if (cliente_valido) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'La venta contiene formas de pago en tarjeta. Debe ingresar Nº Documento, Condición de IVA, Tipo Documento, Apellido y Nombre'
            })
            return resolve(data_error)
          }
          if (!this.cliente.nombre || !this.cliente.apellido) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'La venta contiene formas de pago en tarjeta. Debe ingresar Apellido y Nombre'
            })
            return resolve(data_error)
          }
        }

        // valido las fp
        if (this.formas_pago_group.filter(fpg => !fpg.completa).length > 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Existen formas de pago con los datos incompletos. Verificar'
          })
          return resolve(data_error)
        }

        // controlo que coloque si o si el cliente cuando al menos 1 detalle de forma de pago sea lapos integrado y esté marcado como CLIENTE
        if (cliente_valido && this.formas_pago_group.filter(fpg => fpg.datos.cliente == 1 && fpg.lapos_integrado == 1).length > 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Existen formas de pago marcadas como Cliente y no se ingresó Datos del Cliente en la seccion "Datos Cliente"'
          })
          return resolve(data_error)
        }

        // controlo que ingresen la terminal siempre y cuando el local este marcado como lapos integrado y como que ingresa una terminal para el cobro
        if (this.ptovta.fp_integrado == 1 && this.ptovta.ingresa_terminal == 1 &&
            this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0 && !this.terminal) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Debe seleccionar la terminal integrada en la que se van a realizar los cobros'
          })
          return resolve(data_error)
        }
        
        // controlamos que las formas de pago no se esten cobrando como lapos integrado cuando la configuracion de la tarjeta no lo admita
        if (this.ptovta.fp_integrado == 1) {
          let inconsistentes = this.formas_pago_group.filter(fpg => fpg.tar_lapos_int == 0 && fpg.lapos_integrado == 1)
          if (inconsistentes.length > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `Las financiaciones ${inconsistentes.map(inc => inc.descripcion).join(', ')} no se pueden cobrar con Lapos Integrado`
            })
            return resolve(data_error)
          }
        }

        // controlamos que las formas de pago no se esten cobrando como cobro online cuando la configuracion de la tarjeta no lo admita
        if (this.ptovta.cobro_electronico == 1) {
          let inconsistentes = this.formas_pago_group.filter(fpg => fpg.tar_cobro_online == 0 && fpg.cobro_online == 1)
          if (inconsistentes.length > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `Las financiaciones ${inconsistentes.map(inc => inc.descripcion).join(', ')} no se pueden cobrar con Cobro Online`
            })
            return resolve(data_error)
          }
        }

        // si están vendiendo una Gift Card el cliente es obligatorio
        if (cliente_valido && this.articulos.filter(art => art.gift_card == 1).length > 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Se está vendiendo una Gift Card, debe ingresar los datos del Cliente'
          })
          return resolve(data_error)
        }

        // validaciones de venta mayorista
        if (this.nueva && this.venta_mayorista) {
          if (cliente_valido) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'La venta se marcó como Mayorista, es obligatorio completar los datos del cliente'
            })
            return resolve(data_error)
          }
          // el cliente no puede ser un cliente mayorista
          if (this.cliente.mayorista == 1) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'El cliente es un Cliente Mayorista, debe comunicarse con su Ejecutivo'
            })
            return resolve(data_error)
          }
          // cada detalle de articulo tiene q tener una cantidad minima
          if (this.articulos.filter(art => parseInt(art.cantidad) < this.vta_min_mayorista).length > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `Existen artículos cuya cantidad es menor a ${this.vta_min_mayorista}. Verificar`
            })
            return resolve(data_error)
          }
        }

        this.armarArrayFP()

        // como mi array_fp y formas_pago deberian ser iguales en longitud y monto (en teoria si salio todo bien)
        // directamente le agrego el codigo de articulo al de formas_pago
        let formas_pago = []
        for (const fp of this.formas_pago) {

          let coincidencia = this.array_fp.filter(afp => afp.id_interno == fp.id_interno && afp.forma_pago_id == fp.forma_pago_id)
          if (coincidencia.length > 0) {
            // si encontro 1 o mas debo revisar que la sumatoria de todos los montos con esa fp sea la misma
            const sumatoria = coincidencia.reduce((sum, fin) => sum + roundNumberTF(fin.monto), 0)

            if (roundNumberTF(fp.monto) == roundNumberTF(sumatoria)) {
              for (const detalle of coincidencia) {
                let forma_pago = JSON.parse(JSON.stringify(fp))
                forma_pago.articulo_codigo = detalle.articulo_codigo
                forma_pago.monto = detalle.monto
                formas_pago.push(forma_pago)
              }
            } else {
              this.$swal.fire({
                icon: 'warning',
                title: titulo,
                text: 'Se detectaron inconsistencias en los montos de las financiaciones especiales por artículo'
              })
              return resolve(data_error)
            }

          } else {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: 'Se detectaron inconsistencias al agrupar las financiaciones especiales por artículo'
            })
            return resolve(data_error)
          }
        }

        // si hace extracciones vuelvo a traer el efectivo de caja para ver si no hubo cambios
        if (this.formas_pago_group.filter(fpg => fpg.monto_extraccion > 0).length > 0) {
          await this.getEfectivoCaja()
          let efectivo = 0
          for (const fp of this.formas_pago_group.filter(fpg => fpg.debito == 1)) {
            efectivo += parseFloat(fp.monto_extraccion)
          }
          if (roundNumberTF(efectivo) > roundNumberTF(this.efectivo_caja)) {
            this.$swal.fire({
              icon: 'warning',
              title: titulo,
              text: `El total a extraer (${format_money(efectivo)}) es mayor al efectivo en Caja (${format_money_round(this.efectivo_caja)}). Pruebe con otros importes`
            })
            return resolve(data_error)
          }
        }

        // si usa lapos valido q no este caido payway :P
        if (this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0) {
          this.$store.state.loading = true
          let payway = await this.$store.dispatch('laposIntegrado/get_payway_status', this.ptovta.empresa)
          this.$store.state.loading = false

          if (payway.exito != 1) {
            this.$swal.fire({
              icon: 'error',
              title: 'La interfaz de terminales integradas se encuentra con problemas',
              text: 'Pruebe más tarde o utilice una terminal desintegrada'
            })
            return resolve(data_error)
          }
        }

        // si llego hasta salio tode bien Bv
        return resolve({
          exito: 1,
          formas_pago: formas_pago
        })
      })
    },
    async sendCobrosOnline (cobros_online) {
      return await new Promise(async (resolve, reject) => {
        let errores = []
        let no_cobrados = 0
        this.mensaje_espera = 'Procesando Cobros Online'

        for (const fp of cobros_online) {
          const tarjeta_numero = fp.datos.tarjeta_numero

          let id_sol = fp.id_solicitud

          // si no tiene id de solicitud lo obtengo
          if (id_sol == null) {
            let result_co = await this.$store.dispatch('ventas/getCobroOnline', {
              venta: this.id_venta,
              numero: tarjeta_numero.substring(tarjeta_numero.length - 4, tarjeta_numero.length),
              monto: roundNumberTF(fp.total)
            })
            if (result_co.exito == 1) {
              id_sol = result_co.id_sol
            } else {
              errores.push(result_co.message)
              continue
            }
          }

          let totalAPagar = '0'
          if (fp.total % 1 == 0) {
            totalAPagar = parseInt(fp.total).toString() + '00'
          } else {
            totalAPagar = roundNumberTF(fp.total) * 100
            totalAPagar = parseInt(totalAPagar).toString()
          }

          const [mes, año] = fp.datos.tarjeta_vencimiento ? fp.datos.tarjeta_vencimiento.split('/') : ['', '']

          // defino mi body para ejecutar el cobro
          let body = {
            tarjeta_numero: tarjeta_numero,
            mes_expira: mes,
            anio_expira: año,
            codigo_seguridad: fp.datos.codigo_seguridad,
            titular: fp.datos.titular,
            tipo_doc: 'dni',
            documento: fp.datos.titular_dni,
            empresa_id: this.ptovta.empresa,
            tipo_pedido: '1',
            importe: totalAPagar,
            cuotas: fp.cuota_interna,
            tarjeta_codigo: fp.tarjeta_codigo,
            pto_vta_codigo: this.ptovta.id,
            sucursal_codigo: this.sucursal,
            pedido_numero: this.id_venta,
            id: id_sol
          }

          let result_pp = await this.$store.dispatch('cobros/process_payment', body)

          if (result_pp.resultado == 1) {
            // se cobro? si no se cobro entonces acomulo los no cobrados
            if (result_pp.datosCobro != null && result_pp.datosCobro.hasOwnProperty('body') && result_pp.datosCobro.body.hasOwnProperty('status') &&
               (result_pp.datosCobro.body.status.toUpperCase() == 'APPROVED' || result_pp.datosCobro.body.status.toUpperCase() == 'ACCREDITED')) {
              
              // guardo el pfd del cobro en el array de archivos para luego imprimir todito
              let result_pdf = await this.$store.dispatch('cobros/get_pdf_cobro_data', { id: id_sol })
              if (result_pdf.resultado == 1) {
                let pdf_res = await pdfCobroOnline(result_pdf.cobro)
                if (pdf_res.resultado == 1) {
                  this.archivos.push({
                    file: pdf_res.pdf,
                    name: `Comprobante del Cobro N° ${id_sol}`,
                    imprimir: 1,
                    opcional: 0
                  })
                }
              }

            } else {
              no_cobrados++
            }
          } else {
            errores.push(result_pp.msj)
          }
        }

        // reviso los errores
        if (errores.length == 0 && no_cobrados == 0) {
          this.mensajes.push({
            mensaje: this.mensaje_espera,
            status: 'success'
          })
          resolve(true)
        } else {
          // si algo fallo, le recargo la pagina u.u
          let texto = 'fueron rechazados y/o procesados con error'
          if (errores.length > 0 && no_cobrados == 0) {
            texto += 'se procesaron con error'
          }
          else if (errores.length == 0 && no_cobrados > 0) {
            texto += 'fueron rechazados'
          }
          await this.$swal.fire({
            icon: 'error', 
            title: 'Algunos Cobros ' + texto,
            text: errores.length > 0 ? errores.join('; ') : '',
            allowOutsideClick: false
          })
          this.refreshPage()
          resolve(false)
        }
      })
    },
    async verifyLapos (result_vta) {
      // reviso si tiene lapos
      if (this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0) {
        this.mensaje_espera = 'Preparado envio a LaPos'
        await new Promise(resolve => setTimeout(resolve, 1000))
        // si tiene lapos refresco la pagina
        this.refreshPage()
      } else {
        // genero el remito
        await this.getRemito()
        // paso a facturar []v (si es q permite xd)
        if (result_vta.datos.permite_fe == 1) {
          await this.facturar(result_vta.datos.comprobante_id)
        }
        this.mensaje_espera = ''
        if (this.archivos.length > 0) {
          this.modal_impresion = true
        } else {
          this.show_exit_button = true
        }
      }
    },
    async finalizarVta () {
      this.initModalEspera('La venta se grabo con exito! Pedido N° ' + this.id_venta, 'success')

      // genero el remito
      await this.getRemito()

      // hago la peticion para ver si factura
      let result = await this.$store.dispatch('ventas/solicita_fe', this.id_venta)

      if (result.exito == 1) {
        if (result.data.permite_fe == 1) {
          await this.facturar(result.data.comprobante_id)
        }
      } else {
        this.mensajes.push({
          mensaje: 'No es posible facturar',
          status: 'error',
          error: result.message
        })
      }

      this.mensaje_espera = ''
      if (this.archivos.length > 0) {
        this.modal_impresion = true
      } else {
        this.show_exit_button = true
      }
    },
    async loadVta (venta) {
      if (Object.keys(venta).length == 0) return

      this.limpiar()

      const data = venta.cabecera

      this.log = {
        usuario: data.usuario_grabacion,
        fecha: moment(data.fecha).format(`DD/MM/YYYY${moment(data.fecha).format('HH:mm:ss') != '00:00:00' ? ' [a las] HH:mm:ss' : ''}`)
      }

      this.canal = data.canal
      this.aplica_desc = data.beneficio > 0 ? 1 : 0
      this.beneficio_empl = data.beneficio
      this.lista_variable = data.lista_esp_id
      this.venta_mayorista = data.mayorista_salon == 1
      this.sucursal = data.sucursal_codigo
      this.ptovta = {}
      this.getPtovta()

      let local = this.locales_user.find(loc => loc.id == data.local_codigo)
      if (local) {
        this.ptovta = local
      } else {
        return this.$swal.fire({
          icon: 'error',
          title: 'No se encontró el local de la venta'
        })
      }

      this.monto_descuento = venta.monto_descuento
      this.monto_redondeo = data.monto_redondeo ? data.monto_redondeo : 0
      this.cupon = data.cupon
      this.debito = venta.promociones.filter(pr => pr.fp == 1).length > 0 && venta.formas_pago_group.length > 0
      this.efectivo = data.planilla == 0
      this.giftcard = data.gift_card != null
      this.terminal = data.terminal_id ? parseInt(data.terminal_id) : null
      this.vendedor = data.vend_codigo
      this.vendedor_nombre = data.vend_nombre
      this.giftcard_codigo = data.gift_card
      this.permite_anular = data.permite_anular
      this.cobros_pendientes = data.cobros_pendientes
      this.lista_vble_nombre = data.lista_esp_nombre

      this.cliente = {
        codigo: data.cliente_id,
        documento: data.documento,
        nombre: data.nombres,
        apellido: data.apellidos,
        tipo_doc: data.tipo_doc,
        cond_iva: data.condiva_codigo,
        telefono: data.telefono,
        email: data.email,
        email_referido: data.email_referido,
        provincia: data.provincia_codigo,
        localidad: data.localidad_codigo,
        localidad_nombre: data.localidad_nombre,
        direccion: data.domicilio,
        fecha_nac: data.fecha_nac,
        cumpleanos: data.descuento_cumpleanos,
        fraude: data.fraude
      }

      for (const articulo of venta.articulos) {
        this.articulos.push(articulo)
        if (articulo.financiaciones.length > 0) {
          this.expanded.push(articulo)
        }
      }

      this.agregarEfectivo()
      this.array_fp = []
      this.promociones = venta.promociones
      this.formas_pago = venta.formas_pago
      this.formas_pago_group = venta.formas_pago_group
      this.id_fin = this.formas_pago_group.length
      this.armarArrayFinanciacionEspecial()
      this.armarArrayFP(1)

      // marco si es un combo
      this.combo = this.articulos.filter(art => art.combo != null).length > 0

      // si esta facturada ya esta, no se puede hacer mas nada
      this.permite_editar = data.permite_modificar

      if (this.permite_editar == 0) return

      // controlo si tengo q traer el efectivo de caja
      if (this.formas_pago_group.filter(fpg => fpg.debito == 1).length > 0) {
        await this.getEfectivoCaja()
      }

      // verifico si tiene cobros con estados no finales y actualizo los mismos
      let cobros = this.formas_pago_group.filter(fpg => fpg.cobro_online == 1 && fpg.estado_solicitud != 2 && fpg.estado_solicitud != 4 &&
                                                        fpg.num_trans != null && fpg.num_trans != undefined && fpg.num_trans != '' && parseInt(fpg.num_trans) > 0)
      if (cobros.length > 0) {
        let verificar = []
        for (const cobro of cobros) {
          this.$store.state.loading_texto = 'Sincronizando Cobros Online...'
          this.$store.state.loading_ultra = true
          let resultado = await this.$store.dispatch('cobros/payment_status', {
            id_decidir: cobro.num_trans,
            empresa_id: this.ptovta.empresa,
            reprocesar: cobro.decidir_reprocesar,
            id_solicitud: cobro.id_solicitud
          })
          this.$store.state.loading_ultra = false

          if (resultado.resultado == 1) {
            let existe = verificar.filter(item => item.idDecidir == cobro.num_trans)
            if (existe.length == 0) {
              verificar.push(resultado)
            } 
          } else {
            this.$swal.fire({
              icon: 'error',
              title: 'Hubo errores al actualizar los estados de los Cobros',
              text: 'Refresque la página si quiere reintentar este procedimiento'
            })
            this.permite_editar = 0
            return
          }
        }

        // verifico si tuve errores
        let errores = verificar.filter(item => item.datosStaus.exito == 0 || !item.datosStaus.hasOwnProperty('body'))
        if (errores.length > 0) {
          // error al consultar el estado de algun cobro
          this.$swal.fire({
            icon: 'error', 
            text: 'Ocurrio un problema inesperado al obtener el estado de los Cobros',
            text: 'Refresque la página si quiere reintentar este procedimiento'
          })
          this.permite_editar = 0
          return
        }

        // controlar el estado de oracle con el de decidir
        let actualizar = verificar.filter(item => (item.datosStaus.body.status.toUpperCase() == 'APPROVED' ||
                                                   item.datosStaus.body.status.toUpperCase() == 'ACCREDITED') && item.reprocesar == 1)
        if (actualizar.length > 0) {
          // actualizar el estado del cobro en oracle
          let cobros = actualizar.map(cobro => {
            return {
              num_trans: cobro.idDecidir,
              id_sol: cobro.idSolicitud
            }
          })
          this.$store.state.loading_ultra = true
          let result = await this.$store.dispatch('ventas/actualizar_cobros', cobros)
          this.$store.state.loading_ultra = false

          if (result.exito == 1) {
            if (result.exitosos + result.errores.length == actualizar.length && result.errores.length == 0) {
              // si todo salio bien refresco la pagina
              this.refreshPage()
            } else {
              this.$swal.fire({
                icon: 'warning', 
                text: 'Algunos estados de los Cobros no se actualizaron correctamente',
                text: result.errores.join('; ')
              })
              this.permite_editar = 0
            }
          } else {
            this.$swal.fire({
              icon: 'error', 
              text: 'Ocurrio un problema inesperado al actualizar el estado de los Cobros',
              text: 'Refresque la página si quiere reintentar este procedimiento'
            })
            this.permite_editar = 0
          }
        } else {
          // si despues de sincronizar con prisma no tengo nada q reprocesar abro para q edite
          // (esto significa q tiene cobros que quedaron con error)
          this.editar = true
        }

        return
      }

      // verifico si tiene lapos
      if (this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0) {
        this.sincronizarLapos()
      }
    },
    async sincronizarLapos () {
      // si la api de prisma esta caida ni me gasto en seguir preguntando
      this.$store.state.loading_lps = true
      let payway = await this.$store.dispatch('laposIntegrado/get_payway_status', this.ptovta.empresa)
      this.$store.state.loading_lps = false

      if (payway.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: 'La interfaz de terminales integradas se encuentra con problemas',
          text: 'Pruebe más tarde'
        })
        this.permite_editar = 0
        return
      }

      let error = 0
      this.$store.state.loading_lps = true
      for (const fp of this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1)) {
        let result = await this.$store.dispatch('laposIntegrado/actualizar', fp.id_solicitud)
        if (result.exito == 1) {
          fp.actualizar_correcto = 1
        } else {
          error ++
        }
      }
      this.$store.state.loading_lps = false

      // controlo si tuve errores
      if (error > 0) {
        this.$swal.fire({
          icon: 'error',
          title: 'Hubo errores al actualizar los estados de LaPos',
          text: 'Refresque la página si quiere reintentar este procedimiento'
        })
        this.permite_editar = 0
        return
      }

      let mensaje = 'Hubo errores al actualizar los estados de LaPos en los registros de la Venta actual'
      // hago una sola llamada mandando solo los id de solicitud cobro
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('ventas/getEstadosLaPos', {
        solicitudes: this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).map(sol => sol.id_solicitud)
      })
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        if (resultado.size > 0) {
          for (const fp of resultado.data) {
            let solicitud = this.formas_pago_group.filter(fpg => fpg.id_solicitud == fp.solicitud_id)
            if (solicitud.length == 1) {
              solicitud[0].estado_solicitud = fp.estado_id
            } else {
              error ++
            }
          }
        } else {
          mensaje = 'No se encontraron los estados de los detalles'
          error ++
        }
      } else {
        mensaje = resultado.message
        error ++
      }

      // controlo si tuve errores
      if (error > 0) {
        for (let fp of this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1)) {
          fp.actualizar_correcto = 0
        }
        this.$swal.fire({
          icon: 'error',
          title: mensaje,
          text: 'Refresque la página si quiere reintentar este procedimiento'
        })
        this.permite_editar = 0
        return
      }

      // si todos los estados de los lapos son 2 o 4 entonces todo joya, sino abro el modal
      if (this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1 && fpg.estado_solicitud != 2 && fpg.estado_solicitud != 4).length > 0) {
        this.anulando = 0
        this.modal_lapos = true
      }
    },
    editarLapos () {
      this.modal_lapos = false
      this.editar = true
    },
    /****************
      FINANCIACIONES
     ****************/
    async setFinanciaciones () {
      this.financiaciones_disponibles = []

      this.$store.state.loading = true
      let result = await this.$store.dispatch('financiaciones/get_financiaciones_vta', {
        empresa: this.ptovta.empresa,
        sucursal: this.sucursal,
        local: this.ptovta.id,
        canal: this.canal,
        lista: this.lista_variable
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.financiaciones_disponibles = result.data
      } else {
        return this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
      order_list_by(this.financiaciones_disponibles, 'forma_pago_nombre')

      await this.setFinanciacionesArticulos()
    },
    async setFinanciacionesArticulos (con_precios) {
      this.$store.state.loading = true
      for (let articulo of this.articulos) {
        
        if (con_precios == 1) {
          let articulo_lista = this.precios.find(art => art.codigo == articulo.codigo)
          const precio = parseFloat(articulo_lista.precio)

          // recalculo el importe
          articulo.precio = precio
          articulo.precio_lista = precio
          articulo.precio_lista_original = precio
          articulo.precio_original = precio
          articulo.importe = parseInt(articulo.cantidad) * precio
        }

        articulo.financiaciones_especiales = []
        articulo.financiaciones = []
        articulo.financiacion = {}

        // obtengo las financiaciones del articulo
        let result = await this.$store.dispatch('financiaciones/articulo', {
          documento: this.cliente.num_doc ? this.cliente.num_doc : this.cliente.cuit,
          codigo: articulo.codigo,
          empresa: this.ptovta.empresa,
          sucursal: this.sucursal,
          local: this.ptovta.id,
          canal: this.canal,
          lista: this.lista_variable
        })

        if (result.exito == 1) {
          articulo.financiaciones_especiales = result.data
          order_list_by(articulo.financiaciones_especiales, 'forma_pago_nombre')
        }
      }
      this.$store.state.loading = false

      // borro todito de formas de pago pq cambio el canal por ende cambiaron las financiaciones
      if (this.formas_pago_group.length > 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Se quitaron algunas Formas de Pago agregadas debido a que se modificó el canal de la Venta. Verificar',
          color: 'deep-orange',
          time: 4500
        })
      }
      this.financiaciones_especiales = []
      this.formas_pago_group = []
      this.formas_pago = []
      this.array_fp = []
      this.expanded = []
      this.verificarDebito()
    },
    setSelectedCheck (item, opcion) {
      // restablezco los valores
      item.datos = JSON.parse(JSON.stringify(this.datosFPLimpio))
      item.completa = false

      if (opcion == 1) {
        // es cobro electronico
        // queda tildado cobro electronico?
        if (item.cobro_online == 1) {
          item.estado_solicitud = 1
          item.lapos_integrado = 0
          item.qr = 0
        } else {
          // destildaron, pero quedó tildado el de lapos integrado?
          if (item.lapos_integrado == 0) {
            item.estado_solicitud = 0
            // me fijo si tengo que devolverle el tilde a lapos integrado
            if (this.ptovta.fp_integrado == 1) {
              item.lapos_integrado = 1
              this.setSelectedCheck(item, 2)
              return
            }
          }
        }
      } else {
        // es Lapos integrado
        // queda tildado lapos integrado?
        if (item.lapos_integrado == 1) {
          item.cobro_online = 0
          item.estado_solicitud = 1
        } else {
          // destildo qr
          item.qr = 0
          // destildaron, pero quedó tildado el de cobro online?
          if (item.cobro_online == 0) {
            // destildaron los dos check, reseteo el ingreso de tarjeta normal
            item.estado_solicitud = 0
          }
        }
      }
    },
    async agregarFinanciacion (financiacion, monto) {
      // busco la financiacion comunacha para obtener los datos extra
      let forma_pago = this.financiaciones_disponibles.filter(fin =>
        fin.fp_cod_id == financiacion.fp_cod_id &&
        fin.fc_cod_id == financiacion.fc_cod_id &&
        fin.cuota_interna == financiacion.cuota_interna &&
        fin.tarjeta_codigo == financiacion.tarjeta_codigo
      )
      if (forma_pago.length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al agregar la forma de pago',
          color: 'error'
        })
      }
      const banco_pais = this.banco
      
      // si es debito verifico si tengo q traer el efectivo de caja
      if (forma_pago[0].debito == 1 && roundNumberTF(this.efectivo_caja) == 0) {
        await this.getEfectivoCaja()
      }
      
      this.formas_pago.push({
        id_interno: this.id_fin,
        id_autoriz: financiacion.autorizacion_id,
        forma_pago_nombre: financiacion.forma_pago_nombre,
        tarjeta_nombre: financiacion.tarjeta_nombre,
        cuota_nombre: financiacion.cuota_nombre,
        forma_pago_id: financiacion.forma_pago_id,
        fp_cod_id: financiacion.fp_cod_id,
        fc_cod_id: financiacion.fc_cod_id,
        tarjeta_codigo: financiacion.tarjeta_codigo,
        cuota_interna: financiacion.cuota_interna,
        cuota: financiacion.cuota,
        grupo: financiacion.grupo,
        porcentaje: financiacion.porcentaje,
        monto: monto
      })

      // agrupo las fp por id interno
      let group_by = this.formas_pago.reduce(function (r, a) {
        r[a.id_interno] = r[a.id_interno] || []
        r[a.id_interno].push(a)
        return r
      }, Object.create(null))

      // el grupo que me interesa es el que tenga el mismo id_interno que mi id_fin actual
      let group = group_by[this.id_fin]
      let importe = 0
      let importe_final = 0

      // calculo el importe total en base a las alicuotas
      for (const index in group) {
        const item = group[index]
        importe += parseFloat(item.monto)
        let importecito = parseFloat(item.monto) * (1 + (parseFloat(item.porcentaje) / 100))
        if (this.redondear_detalles) {
          importecito = roundNumberTF(importecito)
        }
        importe_final += importecito
      }

      // verifico si en las fp agrupadas ya existe una con mi id interno
      let existe = this.formas_pago_group.find(fpg => fpg.id_interno == this.id_fin)

      // si existe solo le actualizo los importes
      if (existe) {
        existe.monto = importe
        existe.total = importe_final
        existe.monto_cuotas = importe_final / parseInt(group[0].cuota)
      } else {
        this.formas_pago_group.push({
          id: null,
          id_interno: this.id_fin,
          id_solicitud: null,
          descripcion: forma_pago[0].solicita_banco == 1 || group[0].tarjeta_nombre == group[0].cuota_nombre ? group[0].forma_pago_nombre : group[0].tarjeta_nombre + ' - ' + group[0].cuota_nombre,
          forma_pago_cod_id: group[0].fp_cod_id,
          forma_cobro_cod_id: group[0].fc_cod_id,
          tarjeta_codigo: group[0].tarjeta_codigo,
          banco: banco_pais,
          forma_pago_viejo_id: forma_pago[0].forma_pago_viejo_id,
          solicita_tarjeta: forma_pago[0].tarjeta,
          cod_autoriz: forma_pago[0].cod_autoriz,
          cuotas: forma_pago[0].cuotas,
          imei_cotizado: forma_pago[0].imei_cotizado,
          monto: importe,
          total: importe_final,
          monto_cuotas: importe_final / parseInt(group[0].cuota),
          cant_cuotas: parseInt(group[0].cuota),
          cupon_lote: forma_pago[0].cupon_lote,
          financiera: forma_pago[0].financiera,
          solicita_banco: forma_pago[0].solicita_banco,
          cantidad_digitos: forma_pago[0].cantidad_digitos,
          cuota_interna: group[0].cuota_interna,
          tar_lapos_int: forma_pago[0].tar_lapos_int,
          tar_cobro_online: forma_pago[0].tar_cobro_online,
          fp_empleado: forma_pago[0].fp_empleado,
          debito: forma_pago[0].debito,
          monto_extraccion: 0,
          estado_solicitud: 0,
          cobro_online: 0,
          error_id: 0,
          qr: 0,
          decidir: this.ptovta.decidir,
          lapos_integrado: this.ptovta.fp_integrado == 1 && forma_pago[0].tar_lapos_int == 1 ? 1 : 0,
          cobro_transaccion: null,
          decidir_reprocesar: 1,
          solicitud_error: null,
          num_trans: null,
          completa: false,
          datos: this.datosFPLimpio
        })
      }
      this.getDescuentos()
      this.armarArrayFinanciacionEspecial()
    },
    async agregarFinEsp (financiacion_especial, simulacion) {
      if (simulacion != 1) this.id_fin++

      let monto_ingresado = parseFloat(this.monto)
      let importe_final = 0

      for (const fin_esp of financiacion_especial) {
        // si ya no tengo nada mas que agregar entonces quiebro
        if (monto_ingresado == 0) break

        // reviso si el monto que ingresó es mayor o menor que el disponible (importe total de la fin esp - suma de todas esas fin esp en las fp)
        let sumatoria = this.formas_pago.filter(fp => fp.forma_pago_id == fin_esp.forma_pago_id).reduce((sum, fp) => sum + roundNumberTF(fp.monto), 0)
        let monto_disponible = parseFloat(fin_esp.importe_total) - parseFloat(sumatoria)

        // si el monto disponible es negativo => es 0
        if (monto_disponible < 0) monto_disponible = 0

        // si el monto ingresado supera el saldo disponible de la financiacion especial => agrego lo disponible
        if (roundNumberTF(monto_disponible) < roundNumberTF(monto_ingresado)) {
          if (monto_disponible > 0) {
            if (simulacion == 1) {
              let importe_especial = monto_disponible * (1 + (parseFloat(fin_esp.porcentaje) / 100))
              if (this.redondear_detalles) {
                importe_especial = roundNumberTF(importe_especial)
              }
              importe_final += importe_especial
              this.simulacion.push({
                monto: monto_disponible,
                grupo: fin_esp.grupo,
                nombre: fin_esp.forma_pago_nombre,
                importe: importe_especial,
                alicuota: fin_esp.porcentaje
              })
            } else {
              this.agregarFinanciacion(fin_esp, monto_disponible)
            }
            monto_ingresado -= monto_disponible 
          }
        }
        // si el monto esta dentro del margen del saldo de la especial => agrego todo
        else {
          if (simulacion == 1) {
            let importe_especial = monto_ingresado * (1 + (parseFloat(fin_esp.porcentaje) / 100))
            if (this.redondear_detalles) {
              importe_especial = roundNumberTF(importe_especial)
            }
            importe_final += importe_especial
            this.simulacion.push({
              monto: monto_ingresado,
              grupo: fin_esp.grupo,
              nombre: fin_esp.forma_pago_nombre,
              importe: importe_especial,
              alicuota: fin_esp.porcentaje
            })
          } else {
            this.agregarFinanciacion(fin_esp, monto_ingresado)
          }
          monto_ingresado = 0
        }
      }

      // si ya termine de ciclar y todavia me queda parte del monto ingresado => lo agrego a la comunacha
      if (monto_ingresado > 0) {
        if (simulacion == 1) {
          let importe_comunacho = monto_ingresado * (1 + (parseFloat(this.forma_pago.porcentaje) / 100))
          if (this.redondear_detalles) {
            importe_comunacho = roundNumberTF(importe_comunacho)
          }
          importe_final += importe_comunacho
          this.simulacion.push({
            monto: monto_ingresado,
            grupo: this.forma_pago.grupo,
            nombre: this.forma_pago.forma_pago_nombre,
            importe: importe_comunacho,
            alicuota: this.forma_pago.porcentaje
          })
        } else {
          this.agregarFinanciacion(this.forma_pago, monto_ingresado)
        }
      }

      // asigno los importes
      this.fp_importe = importe_final
      this.monto_cuotas = parseFloat(this.fp_importe) / parseInt(this.forma_pago.cuota)
    },
    async calcularFinanciacion (simulacion) {
      this.simulacion = []

      // obtengo los datos de la financiacion seleccionada
      const pago_id = this.forma_pago.fp_cod_id
      const cobro_id = this.forma_pago.fc_cod_id
      const cuota_interna = this.forma_pago.cuota_interna
      const tarjeta_id = this.forma_pago.tarjeta_codigo

      // busco en las financiaciones especiales
      let financiaciones_especiales = this.financiaciones_especiales.filter(fin =>
        fin.fp_cod_id == pago_id &&
        fin.fc_cod_id == cobro_id &&
        fin.cuota_interna == cuota_interna &&
        fin.tarjeta_codigo == tarjeta_id
      )

      if (financiaciones_especiales.length > 0) {
        // si encontro una o mas, ordeno el array por alicuota desc
        order_list_by(financiaciones_especiales, 'porcentaje', 'desc')
        this.agregarFinEsp(financiaciones_especiales, simulacion)
        if (simulacion != 1) this.limpiarFP(1)
      } else {
        // si no encontró ninguna, agrego la q selecciono
        if (simulacion == 1) {
          this.fp_importe = parseFloat(this.monto) * (1 + (parseFloat(this.forma_pago.porcentaje) / 100))
          if (this.redondear_detalles) {
            this.fp_importe = roundNumberTF(this.fp_importe)
          }
          this.monto_cuotas = parseFloat(this.fp_importe) / parseInt(this.forma_pago.cuota)
          this.simulacion.push({
            nombre: this.forma_pago.forma_pago_nombre,
            grupo: this.forma_pago.grupo,
            importe: this.fp_importe,
            alicuota: this.forma_pago.porcentaje
          })
        } else {
          this.id_fin++
          this.agregarFinanciacion(this.forma_pago, this.monto)
          this.limpiarFP(1)
        }
      }
    },
    async simularFormaPago () {
      if (this.monto == null || parseFloat(this.monto) <= 0) {
        this.forma_pago = {}
        this.fp_importe = 0
        this.monto_cuotas = 0
        this.banco = null
        return
      }
      if (this.forma_pago == null || Object.keys(this.forma_pago) == 0) {
        this.fp_importe = 0
        this.monto_cuotas = 0
        return
      }
      this.calcularFinanciacion(1)
    },
    async agreagrFormaPago () {
      // validaciones
      if (this.monto == null || parseFloat(this.monto) <= 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto debe ser mayor a 0',
          color: 'orange'
        })
      }
      if (roundNumberTF(this.monto_articulos) < roundNumberTF(this.monto) ||
          roundNumberTF(this.monto_articulos) < roundNumberTF(this.subtotal_fp + parseFloat(this.monto))) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto ingresado supera el subtotal de la venta',
          color: 'orange'
        })
      }
      if (this.banco == null) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione el banco',
          color: 'orange'
        })
      }
      if (Object.keys(this.forma_pago).length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione la forma de pago',
          color: 'orange'
        })
      }
      this.calcularFinanciacion()
      this.armarArrayFP()
    },
    seleccionarFP (monto, financiacion) {
      this.monto = monto
      this.banco = financiacion.bancos.length == 1 ? financiacion.bancos[0] : (this.banco ? this.banco : null)
      this.forma_pago = financiacion
      this.modal_search_fp = false
      this.calcularFinanciacion(1)
    },
    preseleccionarFP (item) {
      if (/*(this.monto == null || parseFloat(this.monto) <= 0) &&*/ item != null && Object.keys(item).length != 0) {
        let forma_pago = this.financiaciones_disponibles.filter(fin =>
          fin.fp_cod_id == item.fp_cod_id &&
          fin.fc_cod_id == item.fc_cod_id &&
          fin.cuota_interna == item.cuota_interna &&
          fin.tarjeta_codigo == item.tarjeta_codigo
        )
        if (forma_pago.length == 1) {
          this.forma_pago = forma_pago[0]
        }
        this.monto = this.monto_articulos - this.subtotal_fp
      }
      this.calcularFinanciacion(1)
    },
    /**
     *  Nota: esta funcion es mucho muy importante, ya que el array_fp es lo que va a terminar impactando en planilla detalle.
     *        si la sumatoria de los montos de este array agrupados por financiacion no coinciden con el monto total de
     *        la forma de pago group va a dar error y no va a poder grabar
     * 
     *  Resumen: cuando es una venta nueva ordena los array de articulos y formas de pogo por importes/montos descendientes
     *           para cruzar los importes mas altos de articulos con las formas de pago mas altas. Pero cuando esta editando
     *           ya no ordeno por improte pq debo respetar el orden de las formas de pago q tienen planilla id
     */
    armarArrayFP (cargando_vta) {
      // si es nueva limpio todo, si esta editando me quedo con los q tienen planilla id
      this.array_fp = this.nueva ? [] : this.array_fp.filter(afp => afp.planilla_id)

      // el array de sumatorias sirve para cuando estan editando, para almacenar los montos de las financiaciones x articulo que tienen planilla id
      let sumatorias = []

      // a todas las financiaciones de los articulos les reseteo su monto disponible y sus id internos
      for (let articulo of this.articulos) {
        for (let fin of articulo.financiaciones) {
          if (fin.financiacion != null && Object.keys(fin.financiacion).length > 0) {
            sumatorias.push({
              articulo: articulo.codigo,
              financiacion: fin.financiacion.forma_pago_id,
              sumatoria: 0
            })
            // si no existe en formas de pago con planilla_id tengo q resetear su array de id internos
            let cantidad = this.formas_pago_group.filter(fp => fin.id_internos && fp.id != null &&
                                                               fin.id_internos.filter(id => id == fp.id_interno).length > 0).length
            fin.id_internos = cantidad == 0 ? [] : fin.id_internos
            fin.monto_disponible = parseFloat(fin.monto)
          }
        }
      }

      let formas_pago_group = [...this.formas_pago_group]
      let ordenar = this.formas_pago_group.filter(fpg => fpg.id != null).length == 0
      if (ordenar)  {
        order_list_by(formas_pago_group, 'monto', 'desc')
      }

      for (const grupo of formas_pago_group) {
        // busco las fp del grupo por id interno
        let forma_pago = this.formas_pago.filter(fp => fp.id_interno == grupo.id_interno && (!fp.planilla_id || cargando_vta == 1))
        let total_disponible = parseFloat(grupo.monto)

        for (const fp of forma_pago) {

          // antes de empezar a hacer la magia tengo q ver si la financiacion existe entre todos los articulos, si no existe la agrego como comun
          let existe = false
          for (let articulo of this.articulos) {
            if (articulo.financiaciones.filter(item => item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                                       item.financiacion.forma_pago_id == fp.forma_pago_id).length > 0) {
              existe = true
              break
            }
          }

          // si no exste la agrego y quiebro
          if (!existe) {
            this.array_fp.push({
              articulo_codigo: null,
              planilla_id: fp.planilla_id ? fp.planilla_id : null,
              id_interno: fp.id_interno,
              forma_pago_id: fp.forma_pago_id,
              monto: total_disponible
            })
            break
          }

          let articulos = [...this.articulos]
          if (ordenar)  {
            order_list_by(articulos, 'importe', 'desc')
          }

          for (let articulo of articulos) {

            // ciclo siempre que me quede monto
            if (total_disponible <= 0) break

            // busco todas las financiaciones especiales x articulo q coincidan con la q estoy ciclando para pegarle el id_interno
            let coinciden = articulo.financiaciones.filter(item => item.financiacion != null && Object.keys(item.financiacion).length > 0 &&
                                                                   item.financiacion.forma_pago_id == fp.forma_pago_id)
            
            if (ordenar)  {
              order_list_by(coinciden, 'monto', 'desc')
            }

            /**
             *  Nota: aca tengo que revisar si la financiacion que estoy agregando existe en el mismo articulo con id de planilla.
             *        si es asi tengo que sumarle el monto utilizado, tambien llamado sumatoria (?
             *        (esto pasa pq las que tienen planilla jamas deberian procesarse nuevamente entonces queda su monto colgado)
             */
            let sumatoria = 0
            for (let fin of articulo.financiaciones) {
              if (fin.financiacion != null && Object.keys(fin.financiacion).length > 0 &&
                  fin.financiacion.forma_pago_id == fp.forma_pago_id) {
                let utilizado = this.array_fp.filter(afp => afp.articulo_codigo == articulo.codigo && afp.planilla_id != null &&
                                                            afp.forma_pago_id == fin.financiacion.forma_pago_id)
                sumatoria = parseFloat(utilizado.reduce((sum, afp) => sum + roundNumberTF(afp.monto), 0))
              }
            }
            
            let sumatoria_art = sumatorias.find(sm => sm.articulo == articulo.codigo && sm.financiacion == fp.forma_pago_id)
            if (sumatoria > 0 && sumatoria_art && sumatoria_art.sumatoria != -1) {
              sumatoria_art.sumatoria = sumatoria
            }

            // por cada financiacion del articulo tengo q preguntar si el saldo disponible de la financiacion me alcanza para cubrir el monto
            for (let fin of coinciden) {
              let monto_utilizado = 0

              /**
               *  Nota: si la sumatoria coincide con el fin.monto significa q no toco esa y quedo como esta
               *        si la sumatoria coincide tiene q ser 0 despues pq ya uso todo
               */
              if (roundNumberTF(sumatoria_art.sumatoria) == roundNumberTF(fin.monto)) {
                sumatoria_art.sumatoria = 0
                continue
              }

              if (sumatoria_art.sumatoria > 0 && cargando_vta != 1) {
                fin.monto_disponible -= parseFloat(sumatoria_art.sumatoria)
                sumatoria_art.sumatoria = -1
              }

              // ciclo siempre que me quede total en la fp y monto en el articulo
              if (total_disponible <= 0 || fin.monto_disponible <= 0) continue

              // si mi total es igual ambos pasan a ser 0
              if (roundNumberTF(total_disponible) == roundNumberTF(fin.monto_disponible)) {
                monto_utilizado = parseFloat(fin.monto_disponible)
                total_disponible = 0
                fin.monto_disponible = 0
              }
              // si el total es mayor, consume todo el monto
              else if (roundNumberTF(total_disponible) > roundNumberTF(fin.monto_disponible)) {
                monto_utilizado = parseFloat(fin.monto_disponible)
                total_disponible -= fin.monto_disponible
                fin.monto_disponible = 0
              }
              // si el monto es el mayor, consume todo el total
              else {
                monto_utilizado = parseFloat(total_disponible)
                fin.monto_disponible -= total_disponible
                total_disponible = 0
              }

              // si llego hasta aca significa que utilizo ese monto en la financiacion por eso la vinculo
              if (!fin.id_internos) {
                fin.id_internos = []
              }
              if (cargando_vta != 1 && fin.id_internos.filter(id => id == fp.id_interno).length == 0) {
                fin.id_internos.push(fp.id_interno)
              }

              this.array_fp.push({
                articulo_codigo: articulo.codigo,
                planilla_id: fp.planilla_id ? fp.planilla_id : null,
                id_interno: fp.id_interno,
                forma_pago_id: fp.forma_pago_id,
                monto: monto_utilizado
              })
            }
          }
        }
      }
    },
    armarArrayFinanciacionEspecial (eliminar_fp) {
      if (eliminar_fp == 1) {
        if (this.formas_pago_group.length > 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Se quitaron algunas Formas de Pago agregadas debido a que se modificaron importes o cantidades en Artículos. Verificar',
            color: 'deep-orange',
            time: 4500
          })
        }

        // me quedo solo con las desintegradas completas (q peude ser q ya haya cobrado)
        this.formas_pago_group = this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 0 && fpg.cobro_online == 0 && fpg.completa)

        // si me quedo alguna, tengo q ver q no sea especial (si es especial igual se la deleto pq pudo haber bajado los saldos especiales)
        // voy a recorrer una copia para borrar el original y que no se descuajeringen los indices
        let fpg_copy = JSON.parse(JSON.stringify(this.formas_pago_group))
        let fp_copy = JSON.parse(JSON.stringify(this.formas_pago))

        this.formas_pago = []

        for (const fp_desint of fpg_copy) {
          // para ver si es especial tengo q ver si existe en el array_fp con articulo codigo no nulo y mismo id_interno
          let existe = this.array_fp.filter(afp => afp.id_interno == fp_desint.id_interno && afp.articulo_codigo != null)
          if (existe.length > 0) {
            let forma_pago = this.formas_pago_group.find(fp => fp.id_interno == fp_desint.id_interno)
            const index = this.formas_pago_group.indexOf(forma_pago)
            this.formas_pago_group.splice(index, 1)
          } else {
            // si es igual a 0 significa q no es especial, por eso vuelvo a insertar su detalle en formas_pago
            let fp = fp_copy.find(fpc => fpc.id_interno == fp_desint.id_interno)
            this.formas_pago.push(fp)
          }
        }

        // borro directamente el array_fp y los espandidos pq en teoria no quedo nada especial
        this.array_fp = []
        this.expanded = []

        // limpio las financiaciones de los articulos
        for (let articulo of this.articulos) {
          articulo.financiacion = {}
          articulo.financiaciones = []
        }

        this.verificarDebito()
      }

      this.financiaciones_especiales = []
      let financiaciones_especiales_articulos = []

      for (const articulo of this.articulos) {
        for (const art_fin of articulo.financiaciones) {
          const financiacion = art_fin.financiacion
          financiaciones_especiales_articulos.push({
            autorizacion_id: financiacion.autorizacion_id,
            forma_pago_nombre: financiacion.forma_pago_nombre,
            tarjeta_nombre: financiacion.tarjeta_nombre,
            cuota_nombre: financiacion.cuota_nombre,
            forma_pago_id: financiacion.forma_pago_id,
            fp_cod_id: financiacion.fp_cod_id,
            fc_cod_id: financiacion.fc_cod_id,
            tarjeta_codigo: financiacion.tarjeta_codigo,
            cuota_id: financiacion.cuota_id,
            cuota: financiacion.cuota,
            grupo: financiacion.grupo,
            cuota_interna: financiacion.cuota_interna,
            porcentaje: financiacion.porcentaje,
            importe_total: art_fin.monto
          })
        }
      }

      let financiaciones_especiales = []
      // agrupo las financiaciones especiales
      let group_by_fe = financiaciones_especiales_articulos.reduce(function (r, a) {
        r[a.forma_pago_id] = r[a.forma_pago_id] || []
        r[a.forma_pago_id].push(a)
        return r
      }, Object.create(null))

      // ciclo for por cada grupo de financiaciones
      for (const key in group_by_fe) {
        let group = group_by_fe[key]
        let monto_total = 0

        // calculo el monto total disponible para las mismas financiaciones
        for (const index in group) {
          const item = group[index]
          monto_total += parseFloat(item.importe_total)
        }

        financiaciones_especiales.push({
          autorizacion_id: group[0].autorizacion_id,
          forma_pago_nombre: group[0].forma_pago_nombre,
          tarjeta_nombre: group[0].tarjeta_nombre,
          cuota_nombre: group[0].cuota_nombre,
          forma_pago_id: key,
          fp_cod_id: group[0].fp_cod_id,
          fc_cod_id: group[0].fc_cod_id,
          tarjeta_codigo: group[0].tarjeta_codigo,
          cuota_id: group[0].cuota_id,
          cuota: group[0].cuota,
          grupo: group[0].grupo,
          cuota_interna: group[0].cuota_interna,
          porcentaje: group[0].porcentaje,
          importe_total: monto_total
        })
      }
      this.financiaciones_especiales = financiaciones_especiales
    },
    calcularCuotasFinEsp (item) {
      if (Object.keys(item.financiacion).length != 0) {
        // obtengo el articulo
        let articulo = this.articulos.find(art => art.codigo == item.art_cod)
        let total = 0
        // sumo las financiaciones especiales del articulo
        articulo.financiaciones.forEach(fin => {
          total += parseFloat(fin.monto)
        })
        // validaciones
        if (item.monto == null || roundNumberTF(item.monto) <= 0) item.monto = 0
        if (roundNumberTF(total) > roundNumberTF(articulo.importe)) {
          item.monto = 0
          item.cuota = 0
          item.total = 0
          return this.$store.dispatch('show_snackbar', {
            text: 'El monto ingresado supera el importe del artículo',
            color: 'orange'
          })
        }
        // calculo
        const alicuota = 1 + (parseFloat(item.financiacion.porcentaje) / 100)
        item.total = alicuota * parseFloat(item.monto)
        if (this.redondear_detalles) {
          item.total = roundNumberTF(item.total)
        }
        item.cuota = item.total / parseInt(item.financiacion.cuota)
      }
      this.armarPreseleccionado(item.financiacion)
    },
    armarPreseleccionado (financiacion) {
      this.armarArrayFinanciacionEspecial()
      this.preseleccionarFP(financiacion)
    },
    finEspUnitaria (articulo) {
      articulo.financiaciones = []
      if (articulo.financiacion != null && Object.keys(articulo.financiacion).length > 0) {
        let unaFinanciacion = {
          art_cod: articulo.codigo,
          financiacion: articulo.financiacion,
          financiaciones_especiales: articulo.financiaciones_especiales,
          eliminado: 0,
          monto: articulo.importe,
          cuota: 0,
          total: 0
        }
        articulo.financiaciones.push(unaFinanciacion)
        this.calcularCuotasFinEsp(unaFinanciacion)
        articulo.financiacion = {}
        this.expanded.push(articulo)
      } else {
        this.armarArrayFinanciacionEspecial()
        this.calcularFinanciacion(1)
      }
    },
    desglosarFinEsp (item) {
      let unaFinanciacion = {
        art_cod: item.codigo,
        financiacion: item.financiacion ? item.financiacion : {},
        financiaciones_especiales: item.financiaciones_especiales,
        eliminado: 0,
        monto: 0,
        cuota: 0,
        total: 0
      }
      // si no agrego desgloso especiales aun, heredo la fin. unitaria
      if (!this.expanded.find(xp => xp.codigo == item.codigo)) {
        this.expanded.push(item)
        item.financiacion = {}
        item.financiaciones = []
        item.financiaciones.push(unaFinanciacion)
      } else {
        // valido si todas las financiaciones tienen monto y financiacion
        let incompletas = item.financiaciones.filter(fin => parseFloat(fin.monto) == 0 || Object.keys(fin.financiacion).length == 0)
        if (incompletas.length == 0) {
          // valido que todavia pueda ingresar financiaciones
          if (item.financiaciones.length >= item.financiaciones_especiales.length) {
            return this.$store.dispatch('show_snackbar', {
              text: 'Ya agregó todas las financiaciones disponibles para este producto',
              color: 'orange'
            })
          }
          // si no es la primera que agrega le calculo el saldo restante
          if (item.financiaciones.length > 0) {
            let saldo_utilizado = parseFloat(item.financiaciones.reduce((sum, fin) => sum + roundNumberTF(fin.monto), 0))
            unaFinanciacion.monto = item.importe - saldo_utilizado
          }
          item.financiaciones.push(unaFinanciacion)
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Complete las financiaciones para agregar nuevas',
            color: 'orange'
          })
        }
      }
      this.armarArrayFinanciacionEspecial()
    },
    quitarFinEsp (item) {
      item.eliminado = 1
      // obtengo el articulo
      let articulo = this.articulos.find(art => art.codigo == item.art_cod)
      // elimino todas las fin esp. del articulo que tengan el eliminado
      articulo.financiaciones = articulo.financiaciones.filter(fin => fin.eliminado != 1)
      // si era la ultima fin esp. quito el articulo de expandidos y seteo nuevamente la fin esp. unitaria
      if (articulo.financiaciones.length == 0) {
        articulo.financiacion = {}
        let expand = this.expanded.find(exp => exp.codigo == articulo.codigo)
        if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
      }
      this.armarArrayFinanciacionEspecial()
      this.limpiarFP(1)
    },
    async quitarFP (item) {
      // si es lapos y esta editando sincronizo el estado para q no se haga el loco
      if (!this.nueva && item.lapos_integrado == 1 && item.id_solicitud != null) {
        item.actualizar_correcto = 0

        this.$store.state.loading_lps = true
        let result_sync = await this.$store.dispatch('laposIntegrado/actualizar', item.id_solicitud)
        this.$store.state.loading_lps = false

        if (result_sync.exito != 1) {
          this.$swal.fire({
            icon: 'error',
            title: 'Ocurrio un problema al sincronizar el detalle',
            text: result_sync.message
          })
          return
        }

        // si sincronizó bien obtengo el nuevo estdao y lo actualizo en el array
        this.$store.state.loading = true
        let result_act = await this.$store.dispatch('ventas/get_estado_cobros', [ item.id_solicitud ])
        this.$store.state.loading = false

        if (result_act.exito != 1) {
          this.$swal.fire({
            icon: 'error',
            title: 'Ocurrio un problema al actualizar el estado del detalle',
            text: result_act.message
          })
          return
        }

        let estados = result_act.data.filter(sol => sol.id == item.id_solicitud)
        if (estados.length != 1) {
          this.$swal.fire({
            icon: 'error',
            title: 'Ocurrio un problema al actualizar el estado del detalle',
            text: result_act.message
          })
          return
        }

        item.actualizar_correcto = 1
        item.estado_solicitud = estados[0].estado

        if (item.estado_solicitud == 2) {
          this.$store.dispatch('show_snackbar', {
            text: 'El estado de la solicitud no permite quitar el detalle',
            color: 'info'
          })
          return
        }
      }

      // si es lapos y tiene id de solicitud lo meto al array pa manda la cancelacion despues
      if (!this.nueva && item.lapos_integrado == 1 && item.id_solicitud != null) {
        this.lapos_eliminados.push(item)
      }

      // elimino el id interno de las financiaciones de los articulos asociados
      for (let articulo of this.articulos) {
        // obtengo el detalle del array_fp
        let existe = this.array_fp.filter(afp => afp.articulo_codigo == articulo.codigo && afp.planilla_id == item.id)

        let coinciden = articulo.financiaciones.filter(fin => fin.financiacion != null && Object.keys(fin.financiacion).length > 0 &&
                                                              fin.id_internos && fin.id_internos.filter(id => id == item.id_interno).length > 0)
        for (let fin of coinciden) {
          /**
           *  Nota: si el id iterno de la fpg coincide con el del afp tengo q liberar el monto en la financiacion del articulo.
           *        esto se hace para q pueda utilizar ese monto en otra financiacion. Por ejemplo:
           *        puso el total en visa con 2 tarjetas, pero 1 le reboto, entonces quita esa financiacion
           *        y quiere agregar el mismo monto en amex. si no libero el monto, nunca va a poder agregar la otra
           */
          if (existe.length == 1 && existe[0].planilla_id != null && fin.id_internos.filter(id => id == existe[0].id_interno).length == 1) {
            // aca tengo q tener cuidado y liberar el monto del detalle y no del total de la fp pq puede q este dividida en comun y especial
            let detalle_especial = this.formas_pago.filter(fp => fp.id_interno == item.id_interno && fp.forma_pago_id == fin.financiacion.forma_pago_id)
            if (detalle_especial.length == 1) {
              fin.monto -= parseFloat(detalle_especial[0].monto)
              fin.total = parseFloat(fin.monto) * (1 + (parseFloat(fin.financiacion.porcentaje) / 100))
              if (this.redondear_detalles) {
                fin.total = roundNumberTF(fin.total)
              }
              fin.cuota = parseFloat(fin.total) / parseInt(fin.financiacion.cuota)
            }
          }
          fin.id_internos = fin.id_internos.filter(id => id != item.id_interno)
        }

        // tambien desvinculo del array_fp
        if (existe.length == 1) {
          existe[0].planilla_id = null
        }
      }

      // por ultimo desvinculo las comunes del array fp (q no tienen articulo)
      let desvincular = this.array_fp.filter(afp => afp.articulo_codigo == null && afp.planilla_id == item.id)
      for (let item of desvincular) {
        item.planilla_id = null
      }

      const index = this.formas_pago_group.indexOf(item)
      this.formas_pago = this.formas_pago.filter(fp => fp.id_interno != item.id_interno)
      this.formas_pago_group.splice(index, 1)

      this.verificarDebito()
      this.getDescuentos()
      this.armarArrayFP()
    },
    async limpiarFP (verificar_siguiente) {
      this.monto = null
      this.forma_pago = {}
      this.fp_importe = 0
      this.monto_cuotas = 0
      this.banco = null

      if (verificar_siguiente == 1) {
        // preselecciono la siguiente especial (en caso de existir y q le falte completar el total)
        if (roundNumberTF(this.monto_articulos) > roundNumberTF(this.subtotal_fp)) {
          let sobran = this.financiaciones_especiales.map(fe => fe.forma_pago_id).filter(x => !this.formas_pago.map(fp => fp.forma_pago_id).includes(x))
          if (sobran.length > 0) {
            let especial = this.financiaciones_especiales.find(fe => fe.forma_pago_id == sobran[0])
            await new Promise(resolve => setTimeout(resolve, 1))
            this.preseleccionarFP(especial)
          }
        }
      }
    },
    /***********
      ARTICULOS
     ***********/
    async aplicarPrecioLista (limpiar_fp, traer_fp) {
      if (!this.editar) return
      let porcentaje = 0
      let lista = this.listas_vbles.filter(lis => lis.id == this.lista_variable)
      if (lista.length == 1) {
        porcentaje = lista[0].porcentaje
      }
      for (let articulo of this.articulos) {
        let precio_original = JSON.parse(JSON.stringify(articulo.precio_original))
        let nuevo_precio = parseFloat(articulo.precio_lista_original) * (1 + (parseFloat(porcentaje) / 100))
        if (this.redondear_detalles) {
          nuevo_precio = roundNumberTF(nuevo_precio)
        }

        // recalculo el importe
        articulo.precio_lista = nuevo_precio
        articulo.precio = nuevo_precio
        articulo.precio_original = nuevo_precio
        articulo.importe = parseInt(articulo.cantidad) * parseFloat(articulo.precio)

        // si el precio disminuyo y tengo el articulo expandido con una fin vinculada en las fp tengo q borrar todito
        let existe = articulo.financiaciones.filter(fin => fin.id_internos && fin.id_internos.length > 0)
        if (existe.length > 0 && roundNumberTF(nuevo_precio) < roundNumberTF(precio_original)) {
          limpiar_fp = 1
        }
      }
      // vuelvo a traer las financiaciones
      if (traer_fp == 1) {
        await this.setFinanciaciones()
      }
      await this.getDescuentos(limpiar_fp)

      /**
       * si limpio las fp tengo q traer de nuevo pq sino me trae mal ahre
       * (loq pasa aca es trae enviando el monto sin ningun descuento entonces si tenia un descuento cambia el precio
       *  y las promo con monto minimo se rompen, antes no pasaba pq no se usaban ese tipo de promo, por eso traigo de nuevo)
       */
      if (limpiar_fp == 1) {
        this.getDescuentos()
      }
    },
    async buscarArticulo (buscar) {
      if (this.articulo == null || this.articulo == '' || this.$store.state.loading) return

      let art_cod = this.articulo
      let serie = null
      
      if (buscar == 1) {
        if (Object.keys(this.ptovta).length == 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Seleccione un local para agregar artículos',
            color: 'info'
          })
          return
        }
        this.$store.state.loading = true
        let resultado = await this.$store.dispatch('articulos/get_articulo', {
          codigo: this.articulo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (resultado.exito == 1) {
          if (resultado.data.length == 1) {
            // si lo encontro tengo q ver si el codigo que ingrego coincide con un codigo de articulo
            // si coincide y el codigo es distinto al codigo de articulo muestro modal para q elija
            let existe = this.precios.find(art => art.codigo == art_cod)
            if (existe && existe.codigo != resultado.data[0].codigo) {
              this.select_arts = [ resultado.data[0], existe ]
              this.modal_select_art = true
              this.articulo = null
              return
            } else {
              art_cod = resultado.data[0].codigo
              serie = resultado.data[0].serie
            }
          } else if (resultado.data.length > 1) {
            this.select_arts = resultado.data
            this.modal_select_art = true
            this.articulo = null
            return
          }
        } else {
          this.$store.dispatch('show_snackbar', {
            text: resultado.message,
            color: 'error'
          })
          return
        }
        this.$refs.vtfArticulo.focus()
      }

      let articulo = this.precios.find(art => art.codigo == art_cod)
      if (articulo) {
        let existe = this.articulos.find(art => art.codigo == articulo.codigo)
        if (existe) {
          // si solicita serie y buscó por serie, verifico que la serie a ingresar no este ya incluida
          if (existe.solicita_serie == 1 && serie != null) {
            // si no está, la agrego
            if (existe.series.filter(se => se.serie == serie).length == 0) {
              existe.series.push({ serie: serie })
            } else {
              this.articulo = null
              this.$store.dispatch('show_snackbar', {
                text: 'La serie ingresada ya se ecuentra en el listado',
                color: 'info'
              })
              return
            }
          }
          // si no buscó por serie => muestro el modal para q seleccione la serie
          else if (existe.solicita_serie == 1) {
            this.solicitarSerie(existe)
            this.articulo = null
            return
          }
          existe.cantidad ++
          this.recalcularImporteArticulo(existe)
          this.articulo = null
          return
        }
        let unArticulo = {
          codigo: articulo.codigo,
          nombre: articulo.nombre,
          precio: articulo.precio,
          precio_lista: articulo.precio,
          precio_lista_original: articulo.precio,
          precio_original: articulo.precio,
          importe: articulo.precio,
          solicita_serie: articulo.series,
          solicita_datos: articulo.cli_datos_add,
          cantidad: 1,
          efectivo: 0,
          descuento: 0,
          gift_card: 0,
          importe_gift: 0,
          combo: null,
          info_adicional: '',
          financiaciones: [],
          series_disponibles: [],
          series: []
        }

        // busco financiaciones
        this.$store.state.loading = true
        let result = await this.$store.dispatch('financiaciones/articulo', {
          documento: this.cliente.num_doc ? this.cliente.num_doc : this.cliente.cuit,
          codigo: articulo.codigo,
          empresa: this.ptovta.empresa,
          sucursal: this.sucursal,
          local: this.ptovta.id,
          canal: this.canal,
          lista: this.lista_variable
        })
        this.$store.state.loading = false

        if (result.exito != 1) {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          return
        }

        unArticulo.financiaciones_especiales = result.data
        order_list_by(unArticulo.financiaciones_especiales, 'forma_pago_nombre')

        // si es un art que solicita serie, abro el modal
        if (unArticulo.solicita_serie == 1) {
          this.solicitarSerie(unArticulo, serie)
          this.articulo = null
          return
        }
        this.articulos.unshift(unArticulo)
        this.aplicarPrecioLista()
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontro el artículo ' + this.articulo,
          color: 'error'
        })
      }
      this.articulo = null
    },
    validarDesimulacion () {
      // si deseleccionan simular tengo q verificar q todo lo q solicite serie cumpla con su cantidad
      // esta funcion tambien es recursiva cuando se cierra el modal de series >:D
      let incompletos = this.articulos.filter(art => art.solicita_serie == 1 && art.cantidad != art.series.length)
      if (incompletos.length > 0 && !this.simular) {
        this.solicitarSerie(incompletos[0])
      }
    },
    async solicitarSerie (articulo, serie) {
      // si esta simulando agrego el articulo sin asco
      if (this.simular) {
        let existe = this.articulos.find(art => art.codigo == articulo.codigo)
        if (existe) {
          existe.cantidad ++
        } else {
          this.articulos.unshift(articulo)
        }
        this.aplicarPrecioLista()
        return
      }
      this.$store.state.loading = true
      let result = await this.$store.dispatch('articulos/get_series', {
        articulo: articulo.codigo,
        local: this.ptovta.local_accesorios
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        articulo.series_disponibles = result.data
        // si buscó por serie y esa serie existe => directamente la agrego
        if (serie && articulo.series_disponibles.filter(se => se.serie == serie).length != 0) {
          articulo.series.push({ serie: serie })
          this.articulos.unshift(articulo)
          this.aplicarPrecioLista()
          return
        }
        this.validarSerieExistente(articulo)
        this.articulo_select = articulo
        this.modal_series = true
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async validarCantidades (art) {
      if (art.solicita_serie == 1 && !this.simular) {
        const cantidad = parseInt(art.cantidad)
        if (art.cantidad == art.series.length) return

        if (cantidad <= 0) {
          art.cantidad = art.series.length
        } else if (cantidad > art.series_disponibles.length) {
          art.cantidad = art.series.length
          this.$store.dispatch('show_snackbar', {
            text: 'La cantidad ingresada supera el stock disponible del artículo',
            color: 'error'
          })
        } else {
          this.$store.state.loading = true
          let result = await this.$store.dispatch('articulos/get_series', {
            articulo: art.codigo,
            local: this.ptovta.local_accesorios
          })
          this.$store.state.loading = false

          if (result.exito == 1) {
            art.series_disponibles = result.data
            art.cantidad = cantidad
            this.validarSerieExistente(art)
            this.articulo_select = art
            this.modal_series = true
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }
        }
      } else {
        this.recalcularImporteArticulo(art)
      }
    },
    async verSeries (art) {
      if (this.nueva) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('articulos/get_series', {
          articulo: art.codigo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (result.exito == 1) {
          art.series_disponibles = result.data
          this.validarSerieExistente(art)
          this.articulo_select = art
          this.modal_series = true
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      } else {
        this.articulo_select = art
        this.modal_series = true
      }
    },
    validarSerieExistente (articulo) {
      // si las series disponibles cambiaron y la serie q habia seleccionado ya no existe => tengo q eliminar
      let existentes = []
      for (let serie of articulo.series) {
        let existe = articulo.series_disponibles.find(ser => ser.serie == serie.serie)
        if (existe) {
          existentes.push(existe)
        }
      }
      // si la longitud es distinta significa q hay series q no existen
      if (articulo.series.length != existentes.length) {
        articulo.series = existentes
      }
    },
    cancelarSeries () {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.cantidad = this.articulo_select.series.length
      }
      this.articulo_select = {}
      this.modal_series = false
    },
    setSeries (series, cantidad) {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.series = series
        existe.cantidad = cantidad
        this.recalcularImporteArticulo(existe)
      } else {
        this.articulo_select.series = series
        this.articulo_select.cantidad = cantidad
        this.articulos.unshift(this.articulo_select)
        this.recalcularImporteArticulo(this.articulo_select, null, 1)
        this.aplicarPrecioLista()
      }
      this.articulo_select = {}
      this.modal_series = false
    },
    seleccionarArticulo (art) {
      this.modal_select_art = false
      this.articulo = art.codigo
      this.buscarArticulo()
    },
    quitarArticulo (item) {
      const con_fp_vinculada = item.financiaciones.filter(fin => fin.id_internos && fin.id_internos.length > 0)
      const index = this.articulos.indexOf(item)
      this.articulos.splice(index, 1)
      // si esta expandido tmb lo elimino de ahi
      let expand = this.expanded.find(exp => exp.codigo == item.codigo)
      if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
      // si el articulo no tiene especiales y su monto no esta contemplado en ninguna fp => no limpio fp
      this.getDescuentos(con_fp_vinculada.length > 0 ? 1 : 0)
    },
    recalcularImporteArticulo (articulo, editado, no_limpiar) {
      // recalculo el importe
      if (articulo.cantidad == '' || parseInt(articulo.cantidad) <= 0) articulo.cantidad = 1
      if (articulo.precio == null || roundNumberTF(articulo.precio) <= 0) articulo.precio = articulo.precio_lista
      articulo.importe = parseInt(articulo.cantidad) * parseFloat(articulo.precio)

      if (editado == 1) {
        articulo.precio_original = articulo.precio
      }
      // borro todas las financiaciones para ese articulo
      if (no_limpiar != 1) {
        let expand = this.expanded.find(exp => exp.codigo == articulo.codigo)
        if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
        
        articulo.financiaciones = []
        articulo.financiacion = {}

        this.getDescuentos(1)
      }
    },
    async calcularTotales () {
      this.subtotal_fp = parseFloat(this.formas_pago.reduce((sum, fp) => sum + roundNumberTF(fp.monto), 0))
      this.monto_fp = parseFloat(this.formas_pago_group.reduce((sum, fpg) => sum + roundNumberTF(fpg.monto), 0))
      this.monto_fp_alic = parseFloat(this.formas_pago_group.reduce((sum, fpa) => sum + roundNumberTF(fpa.total), 0))
      this.monto_articulos = parseFloat(this.articulos.reduce((sum, art) => sum + roundNumberTF(art.importe), 0))
      await new Promise(resolve => setTimeout(resolve, 1))
      let total_pagar = this.monto_articulos - this.monto_descuento
      if (this.editar) {
        // si es efectivo redondeo el total
        let total_redondo = this.efectivo ? Math.round(total_pagar / this.redondeo) * this.redondeo : total_pagar
        this.monto_redondeo = total_redondo - total_pagar
        this.monto_efectivo = roundNumberTF(total_redondo - this.monto_fp)
      } else {
        this.monto_efectivo = roundNumberTF((total_pagar + this.monto_redondeo) - this.monto_fp)
      }
    },
    setInfoAdicional () {
      this.articulo_select.info_adicional = JSON.parse(JSON.stringify(this.info_adicional))
      this.modal_info_adicional = false
    },
    /*********
      GIFT CARD
     *********/
    async validarGF () {
      if (!this.nueva) return
      if (!this.giftcard_codigo) {
        this.aplicarGF(0)
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/validar_gf', this.giftcard_codigo)
      this.$store.state.loading = false

      if (result.exito == 1) {
        switch (result.size) {
          case 1:
            const datos = result.data[0]
            // si la gf tiene dni, si o si tiene q ingresar el dni en clientes
            if (datos.dni) {
              if (!this.cliente.num_doc) {
                this.limpiarGF()
                this.$swal.fire({
                  icon: 'info',
                  title: 'Ingrese los datos del cliente e intente nuevamente'
                })
              } else if (this.cliente.num_doc == datos.dni) {
                this.aplicarGF(datos.importe)
              } else {
                this.limpiarGF()
                this.$swal.fire({
                  icon: 'info',
                  title: 'Los datos del Cliente no coinciden con los del destinatario de la Gift Card'
                })
              }
            } else {
              this.aplicarGF(datos.importe)
            }
            break
          case 0:
            this.limpiarGF()
            this.$swal.fire({
              icon: 'info',
              title: 'No se encontraron datos de la Gift Card indicada'
            })
            break
          default:
            this.limpiarGF()
            this.$swal.fire({
              icon: 'info',
              title: 'Se encontraron muchos datos de la Gift Card indicada'
            })
            break
        }
      } else {
        this.limpiarGF()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async newGF (item) {
      // verifico si ya existe una gf en el detalle
      let gift = this.articulos.filter(art => art.gift_card == 1)

      if (gift.length == 0) {
        // verifico si hace falta traer la info del articulo
        if (this.stock_giftcard.length == 0) {
          this.$store.state.loading = true
          let result = await this.$store.dispatch('ventas/get_stock_gf')
          this.$store.state.loading = false

          if (result.exito == 1) {
            switch (result.size) {
              case 0:
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró stock de Gift Card',
                  color: 'error'
                })
                return
              case 1:
                this.stock_giftcard = result.data
                break
              default:
                this.$store.dispatch('show_snackbar', {
                  text: 'Se encontró mucho stock de Gift Card',
                  color: 'error'
                })
                return
            }
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
            return
          }
        }

        const giftcard = this.stock_giftcard[0]

        this.articulos.unshift({
          codigo: giftcard.codigo,
          nombre: giftcard.nombre,
          precio: item.precio,
          precio_lista: item.precio,
          precio_lista_original: item.precio,
          precio_original: item.precio,
          importe: item.precio,
          titular: item.nombre,
          dni: item.dni,
          solicita_serie: giftcard.series,
          solicita_datos: giftcard.cli_datos_add,
          cantidad: 1,
          efectivo: 0,
          descuento: 0,
          gift_card: 1,
          importe_gift: 0,
          combo: null,
          info_adicional: '',
          financiaciones: [],
          series_disponibles: [],
          financiaciones_especiales: [],
          series: []
        })
        this.modal_gf = false
        this.aplicarPrecioLista()
      } else {
        this.modal_gf = false
        this.$store.dispatch('show_snackbar', {
          text: 'Ya ingresó una Gift Card al detalle de artículos',
          color: 'error'
        })
      }
    },
    updateGF (item) {
      // busco la gf
      let giftcard = this.articulos.find(art => art.gift_card == 1)
      if (giftcard) {
        const precio_viejo = JSON.parse(JSON.stringify(giftcard.importe))
        giftcard.precio = item.precio
        giftcard.precio_lista = item.precio
        giftcard.precio_lista_original = item.precio
        giftcard.precio_original = item.precio
        giftcard.importe = item.precio
        giftcard.titular = item.nombre
        giftcard.dni = item.dni

        // solo si se actualizo el precio vuelvo a calcular los descuentos
        if (roundNumberTF(precio_viejo) != roundNumberTF(item.precio)) {
          this.getDescuentos(1)
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontró la Gift Card en el detalle de artículos',
          color: 'error'
        })
      }
    },
    aplicarGF (monto) {
      let saldo_restante = parseFloat(monto)
      for (let articulo of this.articulos) {
        const precio = parseFloat(articulo.precio) * parseInt(articulo.cantidad)
        if (roundNumberTF(saldo_restante) == 0) {
          articulo.importe_gift = 0
          articulo.importe = precio
        } else if (roundNumberTF(precio) < roundNumberTF(saldo_restante)) {
          articulo.importe_gift = precio
          articulo.importe = 0
          saldo_restante -= precio
        } else {
          articulo.importe_gift = saldo_restante
          articulo.importe = precio - saldo_restante
          saldo_restante = 0
        }
      }
    },
    openGF (gf) {
      if (Object.keys(this.ptovta).length == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un local para agregar una Gift Card',
          color: 'info'
        })
        return
      }
      this.art_gf = gf
      this.modal_gf = true
    },
    limpiarGF () {
      this.giftcard_codigo = ''
      this.aplicarGF(0)
    },
    /*********
      GETTERS
     *********/
    async getDescuentos (limpiar_fp, documento) {
      if (this.ptovta == null || Object.keys(this.ptovta).length == 0 || !this.editar) return

      const monto_copia = parseFloat(this.articulos.reduce((sum, art) => sum + roundNumberTF(art.importe), 0))
      const efectivo = this.efectivo || this.debito ? 1 : 0
      const num_doc = !this.cliente.num_doc && !this.cliente.cuit ? documento : this.cliente.num_doc
      const cliente = {
        cuit: this.cliente.cuit,
        codigo: this.cliente.codigo,
        num_doc: this.nueva ? num_doc : this.cliente.documento,
        tipo_doc: this.cliente.tipo_doc,
        fecha_nac: this.cliente.fecha_nac,
        cumpleanos: this.cliente.cumpleanos
      }
      // si es efectivo marco todo con check
      if (efectivo == 1) {
        for (let art of this.articulos) {
          art.efectivo = 1
        }
      }
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          cantidad: art.cantidad,
          efectivo: art.efectivo,
          precio_lista: art.precio_lista
        }
      })
      const formas_pago = this.formas_pago.map(fp => {
        return {
          fp_id: fp.fp_cod_id,
          cuota: fp.cuota
        }
      })

      // copio si es empelado para traer las financiaciones nuevamente solo si este campo cambio
      const copy_empleado = await JSON.parse(JSON.stringify(this.empleado))
      const copy_aplica = await JSON.parse(JSON.stringify(this.aplica_desc))

      this.monto_descuento = 0
      this.empleado = 0
      this.aplica_desc = this.nueva ? 0 : this.aplica_desc
      this.promociones = []
      this.canal = this.venta_mayorista ? 2 : this.ptovta.canal
      this.lista = JSON.parse(JSON.stringify(this.lista_local))

      this.$store.state.loading_dct = true
      let result = await this.$store.dispatch('ventas/calcular_descuento', {
        cliente: this.giftcard ? {} : cliente,
        articulos: articulos,
        formas_pago: this.giftcard ? [] : formas_pago,
        local: this.ptovta.local_accesorios,
        canal: this.canal,
        cupon: this.cupon,
        efectivo: this.giftcard ? 0 : efectivo,
        subtotal: parseFloat(this.articulos.reduce((sum, art) => sum + (art.precio * art.cantidad), 0)),
        fecha: this.nueva ? null : moment(this.venta_copy.cabecera.fecha).format('DD/MM/YYYY'),
        venta: this.nueva ? null : this.id_venta
      })
      this.$store.state.loading_dct = false

      if (result.exito == 1) {
        this.empleado = result.data.empleado
        this.promociones = result.data.descuentos
        if (this.nueva) {
          this.aplica_desc = result.data.aplica ? result.data.aplica : 0
          // si es empleado seteo canal, lista y obtengo las financiaciones de mayorista
          if (this.empleado == 1) {
            //this.lista = result.data.lista
            this.canal = 2
          }
          // solo si cambio el campo empleado vuelvo a traer las financiaciones
          if (copy_empleado != this.empleado) {
            await this.setFinanciaciones()
          }
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }

      if (this.nueva) {
        // si cambio a empleado o si ahora aplica obtengo precio mayorista
        if ((copy_empleado == 0 && this.empleado == 1) || (copy_aplica == 0 && this.aplica_desc == 1)) {
          this.beneficio_empl = 1
          this.aplicarPrecioMayorista()
          return
        }
        // si cambio de empleado a comun o si ya no aplica traigo precio minorista
        if ((copy_empleado == 1 && this.empleado == 0) || (copy_aplica == 1 && this.aplica_desc == 0)) {
          this.beneficio_empl = 0
          this.aplicarPrecioMayorista()
          return
        } 
      }

      // antes de aplicar los descuentos limpio el array de articulos
      for (let articulo of this.articulos) {
        articulo.precio = articulo.precio_original
        articulo.importe = articulo.precio_original * articulo.cantidad
        articulo.precio_real = articulo.precio_original
        articulo.importe_real = articulo.precio_original * articulo.cantidad
        articulo.descuento_efectivo = 0
        articulo.descuento = 0
        // reviso si tengo q destildar el efectivo
        let promo_art = this.promociones.find(pr => pr.articulo == articulo.codigo)
        if (!promo_art || (promo_art && promo_art.detalle_efectivo.length == 0)) {
          articulo.efectivo = 0
        }
      }

      // aplico los descunetos
      for (const promo of this.promociones) {
        // busco el articulo en el array
        let articulo = this.articulos.find(art => art.codigo == promo.articulo)
        if (articulo) {
          const precio_mayorista = promo.precio_mayorista

          // verifico si tiene una lista especial seleccionada
          let procentaje_esp = 1
          let lista = this.listas_vbles.filter(lis => lis.id == this.lista_variable)
          if (lista.length == 1 && this.nueva) {
            procentaje_esp += parseFloat(lista[0].porcentaje) / 100
          }

          /**
           * ojo al piojo, aca agregue un roundNumberTF a todo precio q se multiplique por un % de descuento
           * si se llega a romper algo, sacar todos los roundNumberTF q esten en un % de descuento
           * (son 10 roundNumberTF en total, 4 en procentaje_esp, 4 en descuento y 2 en desc_efectivo)
           */

          // verifico si es empleado si corresponde precio mayorista
          if (this.empleado == 1 && precio_mayorista > 0) {
            articulo.precio = roundNumberTF(precio_mayorista * procentaje_esp)
            articulo.precio_lista = roundNumberTF(precio_mayorista * procentaje_esp)
            articulo.importe = roundNumberTF(precio_mayorista * procentaje_esp) * promo.cantidad
          } else {
            articulo.precio_lista = roundNumberTF(articulo.precio_lista_original * procentaje_esp)
          }
          // aplico los descuentos
          let porcentaje = 0
          for (const desc of promo.detalle) {
            porcentaje += parseFloat(desc.porcentaje)
          }
          let descuento = porcentaje / 100
          descuento = parseFloat(descuento)

          articulo.descuento = porcentaje
          articulo.precio -= roundNumberTF(articulo.precio * descuento)
          articulo.importe -= roundNumberTF(articulo.importe * descuento)
          articulo.precio_real -= roundNumberTF(articulo.precio_real * descuento)
          articulo.importe_real -= roundNumberTF(articulo.importe_real * descuento)

          // una vez q calcule todos los descuentos de las promos tengo q plicar los descuentos de fp al total del articulo con descuento *emoji q le explota la cabeza*
          if (promo.detalle_efectivo.length > 0) {
            const porc_efectivo = parseFloat(promo.detalle_efectivo[0].porcentaje)
            const desc_efectivo = parseFloat(porc_efectivo / 100)

            articulo.precio_real = articulo.precio - roundNumberTF(articulo.precio * desc_efectivo)
            articulo.importe_real = articulo.importe - roundNumberTF(articulo.importe * desc_efectivo)
            articulo.descuento_efectivo = porc_efectivo

            this.monto_descuento += parseFloat(articulo.importe) - parseFloat(articulo.importe_real)
          }

        }
      }

      // si el documento no es indefinido significa q entró por el modulo de clientes
      if (documento != undefined) {
        let limpiar_todo = false
        let rearmar = false

        // busco si el cliente tiene alguna autorizacion para los articulos cargados
        if (this.articulos.length > 0) {

          // debo eliminar todas las financiaciones autorizadas por dni q existan
          for (let articulo of this.articulos.filter(art => art.financiaciones_especiales.length > 0)) {
            articulo.financiaciones_especiales = articulo.financiaciones_especiales.filter(fe => fe.autoriza_documento != 1)

            // si ya tengo q limpiar todo es al vicio q siga controlando
            if (limpiar_todo) continue

            // si encuentro 1 agregada como especial tmb tengo q limpiar el array de fp :'v
            for (let fin of articulo.financiaciones) {
              fin.financiaciones_especiales = fin.financiaciones_especiales.filter(fe => fe.autoriza_documento != 1)

              if (fin.financiacion != null && Object.keys(fin.financiacion).length > 0 && fin.financiacion.autoriza_documento == 1) {
                // si la esta usando en fp hay q aniquilar todo rastro, sino con rearmar el array basta uwu
                if (fin.id_internos && fin.id_internos.length > 0) {
                  limpiar_todo = true
                  break
                } else {
                  rearmar = true
                }
              }
            }

            articulo.financiaciones = articulo.financiaciones.filter(fin => fin.financiacion != null && Object.keys(fin.financiacion).length > 0 &&
                                                                            fin.financiacion.autoriza_documento != 1)
            
            // si mi articulo queda sin financiaciones (pobrecito) hay que desexpandirlo
            if (articulo.financiaciones.length == 0) {
              let expand = this.expanded.find(exp => exp.codigo == articulo.codigo)
              if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
            }
          }
          
          this.$store.state.loading_dct = true
          let result_aut = await this.$store.dispatch('financiaciones/get_autorizaciones', {
            articulos: this.articulos.map(art => art.codigo),
            documento: documento
          })
          this.$store.state.loading_dct = false

          if (result_aut.exito == 1 && result_aut.size > 0) {
            // recorro el array de articulos agregando las autorizaciones que no existan
            for (let articulo of this.articulos) {
              let financiaciones_art = result_aut.data.filter(dt => dt.articulo_codigo == articulo.codigo || dt.articulo_codigo == null)

              for (const fin_aut of financiaciones_art) {
                let fin = fin_aut
                delete fin.articulo_codigo

                let existe = articulo.financiaciones_especiales.filter(fe => fe.forma_pago_id == fin_aut.forma_pago_id)
                if (existe.length == 0) {
                  articulo.financiaciones_especiales.push(fin)
                }

                // tambien tengo q agregarla en cada detalle desagregado
                for (const det of articulo.financiaciones) {
                  let existe_det = det.financiaciones_especiales.filter(fe => fe.forma_pago_id == fin_aut.forma_pago_id)
                  if (existe_det.length == 0) {
                    det.financiaciones_especiales.push(fin)
                  }
                }
              }
            }
          }
        }

        // en este caso solo rearmo el array de financiaciones especiales si el subtotal cambia
        if (roundNumberTF(this.monto_articulos) != roundNumberTF(monto_copia)) {
          this.armarArrayFinanciacionEspecial(limpiar_fp)
        } else {
          // reviso si encontre autorizaciones por dni
          if (rearmar || limpiar_todo) {
            this.armarArrayFinanciacionEspecial(limpiar_todo ? 1 : 0)
          }
        }
      } else {
        this.armarArrayFinanciacionEspecial(limpiar_fp)
      }

      this.validarGF()
      this.limpiarFP(1)
      this.calcularTotales()
    },
    getPtovta () {
      let locales = this.locales_user.filter(loc => loc.sucursal == this.sucursal && loc.canal == 1)
      if (locales.length == 1) {
        this.ptovta = locales[0]
      } else {
        this.ptovta = {}
      }
    },
    /********
      IMPRESION TERMICA
     ********/
    JSPMInit () {
      let _this = this
      JSPM.JSPrintManager.auto_reconnect = true
      JSPM.JSPrintManager.start()
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p
          _this.$nextTick()
        })
      }
    },
    getPrinters () {
      return new Promise((ok, err) => {
        let printers = []
        if(JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (myPrinters) {
            printers = myPrinters
            //console.log(printers)
            ok(printers)
          }).catch((e) => err(e))
        } else {
          console.warn("JSPM WS not open")
          ok(printers)
        }
      })
    },
    JSPMStatus () {
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        return true
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
        this.$swal.fire({
          icon: 'warning',
          title: 'JSPrintManager no se está ejecutando en esta máquina',
          text: 'Por favor instale los drivers proporcionados'
        })
        return false
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
        this.$swal.fire({
          icon: 'error',
          title: 'JSPM a bloqueado este sitio!'
        })
        return false
      }
    },
    doPrintPDF (file) {
      if (this.JSPMStatus()) {
        let cpj = new JSPM.ClientPrintJob()
        cpj.clientPrinter = new JSPM.DefaultPrinter()
        let my_file = new JSPM.PrintFilePDF(ArrayBufferToBase64(file), JSPM.FileSourceType.Base64, 'MyFile.pdf', 1)
        cpj.files.push(my_file)
        cpj.sendToClient()
      }
    },
    /********
      VARIOS
     ********/
    async setBeneficioEmpl (beneficio) {
      if (!this.nueva) return
      this.beneficio_empl = beneficio
      if (Object.keys(this.ptovta).length > 0) {
        await this.aplicarPrecioMayorista()
      }
    },
    marcarTodoEfectivo () {
      let desmarcar = this.articulos.filter(art => art.efectivo == 1).length == this.articulos.length
      for (let art of this.articulos) {
        art.efectivo = desmarcar ? 0 : 1
      }
      this.getDescuentos()
    },
    async aplicarPrecioMayorista () {
      // si es venta mayorista o es empleado le seteo el canal 2, sino el canal del punto de venta
      let canal = this.venta_mayorista || this.empleado == 1 ? 2 : this.ptovta.canal
      let precios = []
      let financiaciones_disponibles = []
      let lista_local = null

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/mayorista_salon_data', {
        mayorista_salon: this.venta_mayorista || (this.aplica_desc == 1 && this.beneficio_empl == 1) ? 1 : 0,
        empresa: this.ptovta.empresa,
        sucursal: this.ptovta.sucursal,
        local: this.ptovta.id,
        local_acc: this.ptovta.local_accesorios,
        canal: canal,
        lista: this.lista_variable
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        const datos = result.data
        lista_local = datos.lista_precios
        precios = datos.precios.data
        financiaciones_disponibles = datos.financiaciones.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
      order_list_by(financiaciones_disponibles, 'forma_pago_nombre')

      // tengo q revisar q todos los articulos q tenga agregados existan en la nueva lista
      let inexistentes = []
      for (const articulo of this.articulos) {
        let existe = precios.filter(pre => pre.codigo == articulo.codigo)
        if (existe.length == 0) {
          inexistentes.push(`• ${articulo.codigo} - ${articulo.nombre}`)
        }
      }

      // si al menos 1 articulo no eixste explota todo
      if (inexistentes.length > 0) {
        this.$swal.fire({
          icon: 'warning',
          title: `Los siguientes artículos no se encuentran disponibles para venta ${this.venta_mayorista ? 'may' : 'min'}orista`,
          html: inexistentes.join('</br>')
        })
        this.venta_mayorista = !this.venta_mayorista
        return
      }

      // seteo los nuevos valores
      this.canal = canal
      this.lista_local = lista_local
      this.precios = precios
      this.financiaciones_disponibles = financiaciones_disponibles

      // como se q todos los articulos existen paso a actualizar sus precios
      await this.setFinanciacionesArticulos(1)

      this.aplicarPrecioLista()
    },
    agregarEfectivo () {
      let existe = this.headersArt.find(ha => ha.value == 'efectivo')
      const editando = !this.nueva && (this.articulos.find(art => art.efectivo == 1) || this.monto_descuento > 0 || this.efectivo || this.debito)
      if (!existe && (this.nueva || editando)) {
        let cantidad = this.headersArt.find(ha => ha.value == 'importe_gift') ? 8 : 7
        this.headersArt.splice(cantidad, 0, { text: 'Ef.', value: 'efectivo', sortable: false, filterable: false, width: '1' })
      }
      if (editando && !this.efectivo) {
        this.debito = true
      }
    },
    limpiarEfectivo (forzar) {
      if (!this.editar && forzar != 1) return
      if (!this.debito) {
        let cantidad = this.headersArt.find(ha => ha.value == 'importe_gift') ? 8 : 7
        this.headersArt.splice(cantidad, 1)
        for (let art of this.articulos) {
          art.efectivo = 0
        }
      }
    },
    async setDebitoEf () {
      this.agregarEfectivo()
      await new Promise(resolve => setTimeout(resolve, 1))
      if (this.efectivo) {
        this.efectivo = false
      } else {
        this.limpiarEfectivo()
      }
      if (this.nueva && this.debito && this.expanded.length > 0) {
        this.armarArrayFinanciacionEspecial(1)
      }
    },
    async setDebito () {
      this.lista_variable = null
      await this.aplicarPrecioLista()
      this.setDebitoEf()
    },
    setBanco () {
      // si tiene seleccionada una fp debo ver si existe con el cambio q hizo en banco
      if (Object.keys(this.forma_pago).length > 0) {
        let fp_disponibles = this.financiaciones_disponibles.filter(fd => fd.bancos.length == 0 || fd.bancos.filter(ba => ba == this.banco).length > 0)
        // si existe quiebro, sino continuo pa q borre todo >:D
        if (fp_disponibles.filter(fpd => fpd.forma_pago_id == this.forma_pago.forma_pago_id).length > 0) return
      }
      this.forma_pago = {}
      this.fp_importe = 0
      this.monto_cuotas = 0
    },
    validarExtraccion (item) {
      if (!(((item.cobro_online == 0 && item.lapos_integrado == 0) || (item.cobro_online == 1 && item.estado_solicitud != 2) ||
              (item.lapos_integrado == 1 && item.estado_solicitud != 2 && (item.actualizar_correcto == 1 || !item.hasOwnProperty('actualizar_correcto')))) &&
            this.editar && item.debito == 1)) {
        return
      }

      // el monto a extraer del detalle es mayor al limite por detalle?
      if (roundNumberTF(item.monto_extraccion) > roundNumberTF(this.limite_ext_efectivo)) {
        this.$swal.fire({
          icon: 'warning',
          title: 'Límite de Extracción',
          text: `No puede realizar extracciones de efectivo de más de ${format_money_round(this.limite_ext_efectivo)} por detalle. Pruebe con otro importe`
        })
        item.monto_extraccion = 0
        this.calcularEfectivoDisponible()
        return
      }

      let efectivo = 0
      let efectivo_disponible = 0
      // sumo el efectivo q tengo entre los otros detalles
      for (const fp of this.formas_pago_group.filter(fpg => fpg.debito == 1 && fpg.id_interno != item.id_interno)) {
        efectivo += parseFloat(fp.monto_extraccion)
      }
      efectivo_disponible = this.efectivo_caja - efectivo

      // el monto a extraer del detalle es mayor al efectivo disponible en caja?
      if (roundNumberTF(item.monto_extraccion) > roundNumberTF(efectivo_disponible)) {
        this.$swal.fire({
          icon: 'warning',
          title: 'Límite de Extracción',
          text: `El monto a extraer (${format_money(item.monto_extraccion)}) es mayor al efectivo disponible en Caja (${format_money_round(efectivo_disponible)}). Pruebe con otro importe`
        })
        item.monto_extraccion = 0
        this.calcularEfectivoDisponible()
        return
      }

      // está correctamente redondeado?
      if (roundNumberTF(item.monto_extraccion) % this.redondeo_ext_efect != 0) {
        this.$swal.fire({
          icon: 'warning',
          title: 'Monto de Extracción',
          text: `Los montos de las extracciones deben ser múltiplos de ${format_money_round(this.redondeo_ext_efect)}. Pruebe con otro importe`
        })
        item.monto_extraccion = 0
        this.calcularEfectivoDisponible()
        return
      }

      this.calcularEfectivoDisponible()
    },
    calcularEfectivoDisponible () {
      let efectivo = 0
      for (const fp of this.formas_pago_group.filter(fpg => fpg.debito == 1)) {
        efectivo += parseFloat(fp.monto_extraccion)
      }
      this.efectivo_disponible = this.efectivo_caja - efectivo
    },
    async getEfectivoCaja () {
      this.efectivo_disponible = 0
      this.efectivo_caja = 0

      this.$store.state.loading_texto = 'Obteniendo Efectivo Disponible en Caja...'
      this.$store.state.loading_ultra = true
      let result = await this.$store.dispatch('ventas/efectivo_caja', {
        local: this.ptovta.id,
        venta: this.nueva ? null : this.id_venta
      })
      this.$store.state.loading_ultra = false

      if (result.exito == 1) {
        this.efectivo_caja = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
      this.calcularEfectivoDisponible()
    },
    verificarDebito () {
      // si no tengo debitos limpio el efectivo de caja para traerlo nuevamente por si simulan sin refrescar
      if (this.formas_pago_group.filter(fpg => fpg.debito == 1).length > 0) {
        this.calcularEfectivoDisponible()
      } else {
        this.efectivo_disponible = 0
        this.efectivo_caja = 0
      }
    },
    refreshPage () {
      const routeData = this.$router.resolve({ path: '/venta-salon/' + this.id_venta })
      window.open(routeData.href, '_self')
    },
    initModalEspera (mensaje, estado) {
      this.modal_espera = true
      this.titulo_espera = mensaje
      this.estado_titulo = estado
      this.mensaje_espera = ''
      this.archivos = []
      this.mensajes = []
    },
    setErrorAnularModal (error) {
      this.titulo_espera = 'No se pudo anular la Venta N°' + this.id_venta
      this.estado_titulo = 'error'
      this.show_exit_button = true
      this.mensajes.push({
        mensaje: this.mensaje_espera,
        status: 'error',
        error: error
      })
      this.mensaje_espera = ''
    },
    setNuevoClinte (valor) {
      this.cliente_nuevo = valor
    },
    setFuncParmDefault (value) {
      return value ? value : 0
    },
    async validarCupon () {
      if (!this.cupon) {
        this.getDescuentos(1)
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/validar_cupon', this.cupon)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.cupon = null
        this.getDescuentos(1)
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        return
      }

      if (result.valido != 1) {
        this.cupon = null
        this.$swal.fire({
          icon: 'info',
          title: 'Cupón invalido',
          text: 'El cupón ingresado ya fue utilizado, no es un cupón valido, o no esta vigente'
        })
      }
      this.getDescuentos(1)

    },
    async buscarVendedor () {
      if (!this.nueva || this.bloquear_vend) return
      if (this.vendedor == null || this.vendedor == '') {
        this.vendedor_acc = null
        this.vendedor_nombre = null
        return
      }
      let vendedor = this.vendedores.find(ven => ven.codigo == this.vendedor)
      if (vendedor) {
        this.vendedor_acc = vendedor.codigo_acc
        this.vendedor_nombre = vendedor.nombre
        this.$refs.vtfArticulo.focus()
        // si me vino vendedor lo bloqueo
        if (this.vend_cod == this.vendedor) {
          this.bloquear_vend = true
        }
      } else {
        this.vendedor = null
        this.vendedor_acc = null
        this.vendedor_nombre = null
        this.modal_vendedores = true
      }
    },
    seleccionarVendedor (ven) {
      this.vendedor = ven.codigo
      this.vendedor_acc = ven.codigo_acc
      this.vendedor_nombre = ven.nombre
    },
    limpiar () {
      this.simular = false
      this.venta_mayorista = false
      this.bloquear_vend = false
      this.debito = false
      this.efectivo = false
      this.giftcard = false
      this.combo = false
      this.cupon = null
      this.terminal = null
      this.vendedor = null
      this.vendedor_acc = null
      this.vendedor_nombre = null
      this.canal = null
      this.lista = null
      this.lista_local = null
      this.lista_variable = null
      this.empleado = 0
      this.aplica_desc = 0
      this.efectivo_caja = 0
      this.efectivo_disponible = 0
      this.listas_vbles = []
      this.promociones = []
      this.precios = []
      this.terminales = []
      this.vendedores = []
      this.articulos = []
      this.expanded = []
      this.financiaciones_especiales = []
      this.financiaciones_disponibles = []
      this.formas_pago = []
      this.formas_pago_group = []
      this.init_fp.bancos = []
      this.cliente = {}
      this.limpiarFP()
      this.limpiarGF()
    },
    itemRowBackground (item) {
      return item.financiaciones_especiales.length > 0 ? 'background-color: rowsel' : ''
    },
    enterKey (event) {
      if (event.key == 'Enter') document.activeElement.blur()
    },
    rowClick (item, row) {
      if (item.opcional == 1) {
        item.imprimir = item.imprimir == 1 ? 0 : 1
      }
    },
    yElUltimoEnQuedarQueApagueLaLuz () {
      window.close()
    }
  }
}
</script>

<style>
.pulse-light {
  animation: pulse-light-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-light-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.pulse-dark {
  animation: pulse-dark-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-dark-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}
</style>