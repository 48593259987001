<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      color="secondary"
      style="z-index: 3"
      height="100%"
      width=240
      :absolute="$vuetify.breakpoint.mdAndUp"
      :expand-on-hover="expand"
      :mobile-breakpoint="960"
      :mini-variant="expand"
      dark
      app
    >
      <v-list class="py-0">
        <v-list-item class="px-2 text-left ">
          <v-list-item-avatar>
            <v-img
              :src="$store.state.img_perfil === '' ? require('./assets/bb.webp') : `data:image/jpeg;base64,${$store.state.img_perfil}`"
            ></v-img>
          </v-list-item-avatar>
          <div>
            <v-list-item-title style="cursor: default;">
              {{ $store.state.username }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1" style="cursor: default;">
              <v-icon
                color="success"
                class="mr-1"
                x-small
              >
                fas fa-circle
              </v-icon>
              Online
            </v-list-item-subtitle>
          </div>
          <!--<v-tooltip bottom>
            <template  v-slot:activator="{ on }">
              <v-btn class="pa-0"
                icon
                v-on="on"
                small
                @click="expand2 = !expand2"
              >
                <v-icon small color="white">fas fa-search</v-icon>
              </v-btn>
            </template>
            <span>
              Buscar
            </span>
          </v-tooltip> -->
        </v-list-item>
        <v-list-item class="px-2">
          <v-text-field
            v-model="search"
            placeholder="Buscar en el menú"
            outlined
            dense
            hide-details
            @input="filterItems()"
            :clearable="!expand"
          >
          <template v-slot:prepend-inner v-if="!expand2">
            <v-icon
              class="mt-1"
              title="Buscar en el menú"
              small
            >
              fas fa-search
            </v-icon>
          </template>
          <template v-slot:append v-else>
            <v-icon
              class="mt-1"
              title="Buscar en el menú"
              small
            >
              fas fa-search
            </v-icon>
          </template>
          </v-text-field>
        </v-list-item>
        <!-- <v-expand-transition>
          <div 
            v-show="expand2"
            height="100"
            width="100"
            class="secondary px-2 mb-2">
            <v-text-field
              v-model="search"
              label="Buscar"
              outlined
              dense
              hide-details
              @input="filterItems()"
              clearable
            ></v-text-field>
          </div>
        </v-expand-transition> -->
      </v-list>

      <v-divider></v-divider>

      <v-list
        class="text-left pa-2"
        dense
        nav
      >
        <template v-for="(item, index) in padresFiltrados/*$store.state.menu*/">
          <v-list-group
            v-if="item.children"
            :key="index"
            color="accent"
            no-action
            @click.stop="$emit('change_size', item.children.length)"
          >
            <v-icon slot="appendIcon" small>fas fa-chevron-down</v-icon>
            <template v-slot:activator>
              <v-icon
                slot="prependIcon"
                class="ml-1 py-3"
                style="width: 15px"
                small
              >
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title class="ml-5 font-weight-bold">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="(child, i) in item.children">
              <v-list-item
                :key="i"
                class="pl-8"
                color="white"
                :to="child.href"
                link
                @click.stop="$emit('subir_vista')"
              >
                <v-list-item-icon class="mr-0">
                  <v-icon x-small>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-regular">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :key="index"
            :to="item.href"
            link
            @click.stop="$emit('subir_vista')"
          >
            <v-list-item-icon>
              <v-icon
                class="ml-1"
                style="width: 15px"
                small
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

    </v-navigation-drawer>

    <AppBar
      :p_expand.sync="expand"
      :p_drawer.sync="drawer"
    />
  </div>
</template>

<script>
import AppBar from './AppBar'

export default {
  data () {
    return {
      expand: false,
      drawer: true,
      expand2: true,
      search: '',
      padresFiltrados: [],
      hijostFiltrados: [],
    }
  },
  created () {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false
    }
   // Inicializa padresFiltrados con todos los items del menu de la base al cargar
   this.padresFiltrados = this.$store.state.menu;
  },
  components: {
    AppBar,
  },
  methods: {
    filterItems() {
      let pos = -1;
      this.padresFiltrados = [];
      
      if(!this.search){
        this.search = '';
        this.padresFiltrados = this.$store.state.menu
      }else{

        this.$store.state.menu.forEach(padre => {
          this.hijostFiltrados = [];
          
          if(padre.hasOwnProperty('children') && padre.children && padre.children.length > 0){
            padre.children.forEach(hijo => {
              if(hijo.text.toLowerCase().includes(this.search.toLowerCase())){
                this.hijostFiltrados.push(hijo);
              }
            });
            if(this.hijostFiltrados.length > 0){
              this.padresFiltrados.push({
                href:     padre.href,
                icon:     padre.icon,
                text:     padre.text,
                children: this.hijostFiltrados
              });
            }
          }
          if(padre.text.toLowerCase().includes(this.search.toLowerCase())){
              pos = this.padresFiltrados.map(elem => elem.text).indexOf(padre.text)
              if(pos == -1){
                this.padresFiltrados.push(padre);
              }
            }
        });
      }
    },
  },
  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      if (val) {
        this.expand = false
        this.drawer = false
      } else {
        this.expand = false
        this.drawer = true
      }
    },
    /*expand2: function(val){
      if(!val){
        this.search = '';
        this.padresFiltrados = this.$store.state.menu
      }
    },*/
    expand: function(val){
      /*if(!val){
        //this.search = '';
        //this.padresFiltrados = this.$store.state.menu
        this.expand2 = true;
      }else this.expand2 = true;*/
      this.expand2 = !val;

    },
  }
}
</script>