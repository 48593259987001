<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nivel
                  <v-autocomplete
                    v-model="filtro.nivel"
                    tabindex="1"
                    :items="niveles.filter(n => n.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipo de caja
                  <v-autocomplete
                    v-model="filtro.tipo"
                    tabindex="1"
                    :items="tipos"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    class="mb-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        v-on:item-expanded="get_hijos"
        class="cebra elevation-2 mt-2"
        item-key="numero"
        :headers="headers"
        :items="cajas"
        :loading="load"
        :search="search"
        :expanded.sync="expanded"
        single-expand
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <v-simple-table
              class="ma-4"
              style=""
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nombre</th>
                    <th class="text-left">Sucursal</th>
                    <th class="text-left">Nivel</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Local</th>
                    <th class="text-center">Transp.</th>
                    <th class="text-center">Rep. gastos</th>
                    <th class="text-center">Diaria</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in hijos"
                    :key="item.numero"
                  >
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.sucursal }}</td>
                    <td>{{ item.nivel }}</td>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.local }}</td>
                    <td class="text-center">
                      <v-icon
                        v-if="item.transporta == 1"
                        color="success"
                        title="Transporta"
                        small
                      >
                        far fa-check-circle
                      </v-icon>
                    </td>
                    <td class="text-center">
                      <v-icon
                        v-if="item.reporte == 1"
                        color="success"
                        title="Reporte gastos"
                        small
                      >
                        far fa-check-circle
                      </v-icon>
                    </td>
                    <td class="text-center">
                      <v-icon
                        v-if="item.diaria == 1"
                        color="success"
                        title="Caja diaria"
                        small
                      >
                        far fa-check-circle
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:[`item.transporta`]="{ item }">
          <v-icon
            v-if="item.transporta == 1"
            color="success"
            title="Transporta"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.reporte`]="{ item }">
          <v-icon
            v-if="item.reporte == 1"
            color="success"
            title="Reporte gastos"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.diaria`]="{ item }">
          <v-icon
            v-if="item.diaria == 1"
            color="success"
            title="Caja diaria"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitada"
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
          <v-icon
            v-if="!item.inhabilitada"
            color="purple"
            title="Ver hijos"
            class="mr-2"
            small
            @click="expand(item)"
          >
            fas fa-sitemap
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitada ? 'success' : 'error'"
            :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitada
              ? `¿Desea habilitar la caja <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar la caja <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <EditarCajas
      v-model="dialog"
      :p_caja="caja"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import EditarCajas from '../../components/auditoria/EditarCajas'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      dialog: false,
      hijos: [],
      expanded: [],
      cajas: [],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Sucursal', value: 'sucursal_nombre' },
        { text: 'Nivel', value: 'nivel_nombre' },
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Local', value: 'local_nombre' },
        { text: 'Caja padre', value: 'padre_nombre' },
        { text: 'Transp.', value: 'transporta', align: 'center', sortable: false, filterable: false },
        { text: 'Rep. gastos', value: 'reporte', align: 'center', sortable: false, filterable: false },
        { text: 'Diaria', value: 'diaria', align: 'center', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      caja: {},
      filtro: {
        empresa: null,
        sucursal: null,
        nivel: null,
        tipo: null,
        nombre: '',
        inhabilitada: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    this.$store.dispatch('admCajas/get_tipos')
    this.$store.dispatch('admCajas/get_niveles')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['empresas', 'sucursales']),
    ...mapState('admCajas', ['tipos', 'niveles'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    EditarCajas
  },
  methods: {
    async buscar () {
      if (this.filtro.empresa) {
        this.load = true
        this.search = ''
        this.cajas = []
        this.expanded = []
        await this.$store.dispatch('admCajas/get_cajas', this.filtro)
          .then((res) => {
            this.cajas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
      }
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('admCajas/habilitar', item.numero)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la caja de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('admCajas/inhabilitar', item.numero)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la caja de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_hijos (payload) {
      if (payload.value) {
        const item = payload.item
        this.hijos = []
        this.$store.state.loading = true
        await this.$store.dispatch('admCajas/get_hijos', item.numero)
          .then((res) => {
            this.hijos = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async hijos_controller (item) {
      await this.get_hijos({
        item: item,
        value: true
      })
      // si no tiene hijos muestra un modal y no expande la row
      if (this.hijos.length > 0) {
        this.expanded.push(item)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: item.nombre + ' no tiene cajas hijas',
          color: 'warning'
        })
      }
    },
    async expand (item) {
      if (this.expanded.length == 0) {
        // si no hay ninguna row abierta, abre la que seleccionó
        this.hijos_controller(item)
      }
      /** si hay una row abierta, verifica si donde hizo click
       *  es la misma row que esta abierta, si es así la cierra.
       *  Caso contrario, elimina la row vieja y abre la nueva
       */
      else if (item == this.expanded[0]) {
        this.expanded = []
      }
      else {
        this.expanded = []
        this.hijos_controller(item)
      }
    },
    editar (item) {
      let caja = this.cajas.find(ca => ca.numero == item.numero)
      // verifica si cambio la empresa
      const empresa = item.empresa != caja.empresa
      // actualiza la caja en la lista
      Object.assign(caja, item)
      // si la inhabilito o cambio de empresa la quita de la lista
      if (caja.inhabilitada == 1 || empresa) {
        this.quitar(caja)
      }
    },
    quitar (item) {
      const index = this.cajas.indexOf(item)
      this.cajas.splice(index, 1)
    },
    open_dialog (item) {
      this.caja = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        sucursal: null,
        nivel: null,
        tipo: null,
        nombre: '',
        inhabilitada: 0
      }
    }
  }
}
</script>