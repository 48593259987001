<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            v-if="p_crear"
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.dni"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  CUIT
                  <v-text-field-integer
                    v-model.trim="filtro.cuit"
                    v-bind:properties="{
                      type: 'tel',
                      tabindex: '1',
                      'hide-details': true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '##-########-#',
                      outputMask: '###########',
                      empty: ''
                    }"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Condición IVA
                  <v-autocomplete
                    v-model="filtro.cond_iva"
                    item-text="condicion_iva_nombre"
                    item-value="condicion_iva_codigo"
                    tabindex="1"
                    :items="cond_iva"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Tipo documento
                  <v-autocomplete
                    v-model="filtro.tipo_doc"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_doc"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.fraude"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="name"
        :headers="headers"
        :items="clientes"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="item.fraude == 0 && p_fraude"
            color="error"
            class="mr-2"
            title="Marcar como fraudulento"
            small
            @click="cliente = item; item.observaciones ? observaciones = JSON.parse(JSON.stringify(item.observaciones)) : observaciones = ''; dialog_fraude = true"
          >
            fas fa-ban
          </v-icon>
          <BtnConfirmar
            v-if="item.fraude == 1 && p_fraude"
            clase="mr-2"
            color="success"
            icono="fas fa-check"
            title="Desmarcar fraudulento"
            :texto="`¿Desea <strong>desmarcar el fraude</strong> del cliente <strong>${item.name}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="desmarcar_fraude(item)"
          />
          <v-icon
            v-if="item.fraude == 1 && item.observaciones"
            color="orange"
            class="mr-2"
            title="Observaciones"
            small
            @click="$swal.fire({
              icon: 'info',
              title: item.observaciones
            })"
          >
            fas fa-info-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Cliente
      v-model="dialog"
      :p_cliente="cliente"
      @nuevo="agregar"
      @editar="editar"
    />
    <v-dialog
      v-model="dialog_fraude"
      width="500"
    >
      <v-card>
        <v-card-title>
          Marcar como fraudulento
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_fraude = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-textarea
            v-model.trim="observaciones"
            label="Observaciones"
            rows="3"
            hide-details
            auto-grow
            outlined
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-3"
            color="error"
            @click="dialog_fraude = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            :disabled="observaciones == ''"
            :texto="`¿Desea <strong>marcar como fraudulento</strong> al cliente <strong>${cliente ? cliente.name : ''}</strong>?`"
            @action="marcar_fraude(cliente)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import Cliente from '../../components/generales/clientes/Cliente'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      dialog_fraude: false,
      observaciones: '',
      search: '',
      tipos_doc: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Nº Documento', value: 'num_doc' },
        { text: 'CUIT', value: 'cuit' },
        { text: 'Tipo documento', value: 'tipo_doc_nombre' },
        { text: 'Condición IVA', value: 'cond_iva_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      clientes: [],
      cliente: {},
      filtro: {
        dni: '',
        cuit: '',
        nombre: '',
        cond_iva: null,
        tipo_doc: null,
        fraude: 0
      }
    }
  },
  async created () {
    // obtiene los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('get_tipos_doc')
      .then((data) => {
        this.tipos_doc = data.tipos_doc
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('clientes', ['p_crear', 'p_fraude']),
    ...mapState(['cond_iva'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    Cliente
  },
  watch: {
    dialog_fraude (val) {
      val || (this.observaciones = '')
    }
  },
  methods: {
    async marcar_fraude (item) {
      if (!this.observaciones) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una observación',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      await this.$store.dispatch('clientes/marcar_fraude', {
        codigo: item.codigo,
        observaciones: this.observaciones
      })
        .then((res) => {
          this.quitar(item)
          this.dialog_fraude = false
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async desmarcar_fraude (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('clientes/desmarcar_fraude', item.codigo)
        .then((res) => {
          this.quitar(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.dni && !this.filtro.cuit && !this.filtro.nombre) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un DNI, CUIT o nombre',
          color: 'orange'
        })
      }
      this.load = true
      this.search = ''
      this.clientes = []
      await this.$store.dispatch('clientes/get_clientes', this.filtro)
        .then((res) => {
          this.clientes = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    agregar (cliente) {
      this.clientes.unshift(cliente)
    },
    editar (cliente) {
      let cli = this.clientes.find(c => c.codigo == cliente.codigo)
      Object.assign(cli, cliente)
    },
    quitar (item) {
      const index = this.clientes.indexOf(item)
      this.clientes.splice(index, 1)
    },
    open_dialog (item) {
      this.cliente = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        dni: '',
        cuit: '',
        nombre: '',
        cond_iva: null,
        tipo_doc: null,
        fraude: 0
      }
    }
  }
}
</script>