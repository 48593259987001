var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"850"},model:{value:(_vm.activo),callback:function ($$v) {_vm.activo=$$v},expression:"activo"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Carga masiva de promociones")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.activo = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"5","md":"4"}},[_vm._v(" Canal "),_c('v-autocomplete',{attrs:{"item-text":"canal_nombre","item-value":"canal_id","items":_vm.canales,"hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.canal_id),callback:function ($$v) {_vm.canal_id=$$v},expression:"canal_id"}})],1),_c('v-col',{staticClass:"py-1 d-flex align-end",attrs:{"cols":"8","sm":"5","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"title":"Adjuntar archivo","color":"primary"},on:{"click":function($event){return _vm.$refs.uploader.click()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-upload")]),_vm._v(" Subir Archivo ")],1)],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":_vm.tipos.join(',')},on:{"change":function($event){return _vm.onFileChanged($event)}}})]),_c('v-col',{staticClass:"py-1 d-flex align-end",attrs:{"cols":"2","sm":"2","md":"4"}},[_c('download-excel',{attrs:{"name":"Modelo de Subir Promos.xls","data":[
              {a:'Promo Prueba a04',b: '30968', c: '130000', d: '95000', e: '10/04/2023', f: '12/04/2023', g: '500', h: ''},
              {a:'Promo Prueba a03',b: '30393', c: '46999',  d: '42000', e: '13/04/2023', f: '15/04/2023', g: '',    h: '4969'},
              {a:'Promo Prueba a55',b: '470',   c: '90000',  d: '75000', e: '13/04/2024', f: '15/04/2025', g: '',    h: ''},
            ],"fields":{
              'Promoción': 'a',
              'Artículo': 'b',
              'Precio Venta': 'c',
              'Precio Desc.': 'd',
              'Fecha Inicio' : 'e',
              'Fecha Fin' : 'f',
              'Cupo' : 'g',
              'Promo Id Cupo': 'h'
            }}},[_c('v-btn',{staticClass:"ml-2",attrs:{"title":"Descargar modelo","color":"green darken-3","dark":""}},[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"small":""}},[_vm._v(" fas fa-file-download ")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'Modelo' : '')}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"cebra elevation-2 mt-2",attrs:{"headers":_vm.header,"items":_vm.promos,"loading":_vm.load,"dense":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"d-flex justify-end pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('SearchDataTable',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:`item.descuento`,fn:function({item}){return [_vm._v(" "+_vm._s(_vm.roundNumber((1- (item.precio_descuento/item.precio_venta))*100,2))+"% ")]}},_vm._l((_vm.header.filter((h) => h.hasOwnProperty('formatter'))),function(h){return {key:`item.${h.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(h.formatter(value))+" ")]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mx-auto mt-4",attrs:{"max-width":"400","type":"warning","border":"left","dense":"","text":""}},[_vm._v(" No hay datos de promociones ")])]},proxy:true}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){_vm.activo = false}}},[_vm._v("Cancelar")]),_c('BtnConfirmar',{attrs:{"nombre":"Grabar","texto":"¿Desea grabar éstas promociones?"},on:{"action":_vm.grabarPromosMasivas}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }