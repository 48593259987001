<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar campañas
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <!-- filtro -->
        <v-row class="mx-2 mt-0 mb-3">
          <v-col :cols="big ? 12 : 6" :sm="big ? 12 : 4" class="py-1">
            Fecha
            <FechaPickerRango
              v-model="fecha"
              :limpiar.sync="limpiar_fecha"
              @changeSize="changeSize"
            />
          </v-col>
          <v-col cols="6" sm="4" class="py-1">
            Tipo
            <v-autocomplete
              v-model="tipo"
              item-text="nombre"
              item-value="id"
              :items="tipos_camp"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="4" class="py-1">
            Estado
            <v-autocomplete
              v-model="estado"
              item-text="nombre"
              item-value="id"
              :items="estados_camp"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col :cols="big ? 12 : 6" :sm="big ? 4 : 12" class="d-flex justify-end align-end pt-3 pb-1">
            <v-btn
              color="info"
              :loading="load"
              @click="buscar()"
            >
              <v-icon left>fas fa-search</v-icon>
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <!-- datos -->
        <v-data-table
          v-model="selected"
          sort-by="nombre"
          checkbox-color="primary"
          :headers="headers"
          :items="campanias"
          :loading="load"
          show-select
          dense
        >
          <template v-slot:[`item.fecha_grabacion`]="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length == 0"
          @click="seleccionar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import FechaPickerRango from '../util/FechaPickerRango'

export default {
  data () {
    return {
      moment: moment,
      big: false,
      load: false,
      limpiar_fecha: false,
      fecha: [ null, null ],
      tipo: null,
      estado: null,
      campanias: [],
      selected: [],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Estado', value: 'estado_nombre' }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapState('encuestas', ['tipos_camp', 'estados_camp']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    FechaPickerRango
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async buscar () {
      if (!this.fecha[0]) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una fecha',
          color: 'orange'
        })
      }

      this.campanias = []
      this.selected = []

      this.load = true
      let result = await this.$store.dispatch('encuestas/get_campanias', {
        fecha: moment(this.fecha[0]).format('DD/MM/YYYY'),
        tipo: this.tipo,
        estado: this.estado
      })
      this.load = false

      if (result.exito == 1) {
        this.campanias = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    seleccionar () {
      this.$emit('seleccion', this.selected)
      this.dialog = false
    },
    clear () {
      this.tipo = null
      this.estado = null
      this.fecha = [ null, null ]
      this.limpiar_fecha = true
      this.campanias = []
      this.selected = []
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>