<template>
  <div>
  <v-dialog
      v-model="activo"
      width="750"
      :persistent="activo"
      scrollable
    >
      <v-card>
        <v-card-title class="">
          <div style="font-size: 20px" class="d-flex ">{{ nuevo ? 'Nuevo markup' : 'Editar Markup' }}</div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form @submit.prevent="guardarCambios()">
            <v-row class="pt-2">
              <v-col cols="12" sm="4" md="4" class="py-1">
                Categoria
                <v-text-field
                  v-model="categoria.nombre"
                  hide-details
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Listas
                <v-autocomplete
                  v-model="lista_codigo"
                  item-text="lista_nombre"
                  item-value="lista_codigo"
                  :items="listas"
                  hide-details
                  outlined
                  dense
                  clearable
                  :error="errorMsjLista"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Markup
                <v-text-field
                  v-model="markup"
                  hide-details
                  type="number"
                  outlined
                  dense
                  suffix="%"
                  :error="errorMsjMarkup"
                  @change="controlarMarkup()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="activo = false;" color="secondary" class="mr-2" >
            Cancelar
          </v-btn>
          <BtnConfirmar
            :nombre="nuevo? 'Grabar' : 'Guardar cambios'"
            :texto="nuevo? '¿Estás seguro de grabar este nuevo markup?' : '¿Estás seguro de guardar los cambios?'"
            @action="grabarGuardar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import { roundNumber } from '../../../util/utils';
  import BtnConfirmar from '../../util/BtnConfirmar.vue';

  export default {
    name: 'ModalNvoEditarMarkup',
    props:{
      value: Boolean,
      categoria: { type: Object, require: true, },
      p_lista: Object,
      nuevo: Boolean,
      datos: Object
    },
    computed:{
      activo:{
        get(){
          return this.value;
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      p_markup:{
        get(){
          return this.datos.markup;
        },
      },
      p_lista_codigo:{
        get(){
          return this.datos.lista_codigo;
        },
      },
    },
    data(){
      return{
        roundNumber: roundNumber,
        lista_codigo: 0,
        listas: [],
        markup: null,
        load: false,
        errorMsjLista: false,
        errorMsjMarkup: false
      }
    },
    created(){
    },
    methods:{
      async init(){
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/listasMarkup', {categoria_codigo: this.categoria.codigo});
        this.$store.state.loading = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
        }

        this.listas = res.listas;
        if(!this.nuevo){
          this.markup = this.p_markup;
          this.lista_codigo = this.p_lista_codigo;
          this.listas.push(this.p_lista);
        }
      },
      controlarMarkup(){
        if(this.markup < 0){
          this.markup = 0;
          return this.$store.dispatch('show_snackbar', { text: 'El markup no puede ser negativo', color: 'info' })
        }
      },  
      async buscar(){
        
      },
      validarCampos(){
        let error = {}
        if( !this.lista_codigo || this.lista_codigo == 0){
          error.text    = 'Se debe seleccionar una lista.'
          error.color   = 'warning'
          this.errorMsjLista = true;
          return error
        }
        if(!this.markup){
          error.text  = 'Se debe ingresar un valor de markup';
          error.color = 'warning';
          this.errorMsjMarkup = true;
          return error
        }
        return error;
      },
      async grabarGuardar(){
        let error = this.validarCampos();
        if(Object.keys(error).length != 0){
          return this.$store.dispatch('show_snackbar', error)
        }
        
        if(!this.nuevo && this.lista_codigo == this.p_lista_codigo && this.markup == this.p_markup){
          this.activo = false;
          return this.$store.dispatch('show_snackbar', { text: 'No realizaste modificaciones.' , color: 'info' })
        }
        this.$store.state.loading = true;
        const res = await this.$store.dispatch(`categorias/nuevoEditarMarkup`, { 
          categoria_codigo: this.categoria.codigo, 
          lista_codigo:     this.lista_codigo,
          markup:           this.markup,
          nuevo:            this.nuevo,
          id_markup:        this.datos.id_markup
        });
        this.$store.state.loading = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message , color: 'error' })
        }
        let accion = this.nuevo ? 'grabado' : 'actualizado';
        this.$store.dispatch('show_snackbar', { text: `Markup ${accion} correctamente.`, color: 'success' })
        this.activo = false;
        this.$emit('actualizar',{categoria_codigo: this.categoria.codigo, lista_codigo: this.lista_codigo})
      },
    },
    components:{
      BtnConfirmar
    },
    watch:{
      activo: function(val){
        if(val){
          this.init();
        }else{
          this.markup         = null;
          this.lista_codigo   = null;
          this.listas         = [];
          this.errorMsjLista  = false;
          this.errorMsjMarkup = false;
        }
      },
      lista_codigo(val){
        if(val){
          this.errorMsjLista = false;
        }
      },
      markup(val){
        if(val){
          this.errorMsjMarkup = false
        }
      },
      /*'feriadoCopia.id_tipo': {
        handler: function(val){
          if(val){
            this.feriadoCopia.nombre_tipo_feriado = (this.tipos.filter(e => e.tipo_id == val))[0].tipo_nombre
          }else this.feriadoCopia.nombre_tipo_feriado = ''
        },
        deep: true,
      },
      'feriadoCopia.sucursal_codigo': {
        handler: function(val){
          if(val){
            this.feriadoCopia.sucursal_nombre = (this.sucursales.filter(e => e.sucursal_codigo == val))[0].sucursal_nombre
          }else this.feriadoCopia.sucursal_nombre = ''
        },
        deep: true,
      }*/
    }
  }
</script>
  
<style>

</style>