<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            v-if="p_editar"
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.ejecutivo = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="py-1">
                  Ejecutivo
                  <v-autocomplete
                    v-model="filtro.ejecutivo"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="ejecutivos.filter(ej => (ej.sucursal == filtro.sucursal || filtro.sucursal == null) && ej.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" md="3" class="py-1">
                  Condición IVA
                  <v-autocomplete
                    v-model="filtro.cond_iva"
                    item-text="condicion_iva_nombre"
                    item-value="condicion_iva_codigo"
                    tabindex="1"
                    :items="cond_iva"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="d-flex  align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1 d-flex align-center">
                  <download-excel
                    :name="`resumen_clientes_mayorista.xls`"
                    :fields="resumen_header"
                    :fetch="getResumenClienteMayorista"
                    :disabled="!filtro.sucursal && (!filtro.sucursal || !filtro.ejecutivo)"
                  >
                    <v-btn
                      class=""
                      title="Descargar Excel"
                      outlined
                      :disabled="!filtro.sucursal && (!filtro.sucursal || !filtro.ejecutivo)"
                    >
                      SALDOS
                      <v-icon
                        color="green darken-3"
                        :small="$vuetify.breakpoint.xs"
                        right
                      >
                        fas fa-file-download
                      </v-icon>
                    </v-btn>
                  </download-excel>
                </v-col>
                <v-col cols="6" sm="5" md="2" class="py-1 px-0 d-flex justify-end">
                  <BtnFiltro
                    clase="pt-0"
                    :loading="load"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :class="p_descargar? '' : 'prevent-select'"
        sort-by="nombre"
        :headers="headers"
        :items="gestores"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <!-- Búsqueda -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center" v-if="p_descargar">
              <DownloadDataTable
                name="Clientes Mayoristas"
                :data="gestores"
                :headers="headers_excel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- Acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="item.inhabilitado != 1"
            color="green"
            title="Saldos a favor"
            class="mr-2"
            small
            @click="mostrar_saldos(item)"
          >
            fas fa-search-dollar
          </v-icon>
          <v-icon
            v-if="item.inhabilitado != 1 && p_lim_cred"
            color="purple"
            title="Modificar límite de crédito"
            class="mr-2"
            small
            @click="gestor = item; dialog_lim_cred = true"
          >
            fas fa-wallet
          </v-icon>
          <v-icon
            v-if="p_editar"
            class="mr-2"
            :color="item.usuario ? 'indigo' : 'success'"
            :title="item.usuario ? 'Usuario' : 'Crear usuario'"
            :disabled="!item.usuario && item.inhabilitado == 1"
            small
            @click="item.usuario ? openDialogUser(item, 'dialog_user') : openDialogUser(item, 'dialog_crear_user')"
          >
            fas fa-user{{ item.usuario ? '' : '-plus' }}
          </v-icon>
          <BtnConfirmar
            v-if="p_eliminar"
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea <strong>habilitar</strong> al cliente <strong>${item.nombre}</strong>?`
              : `¿Desea <strong>inhabilitar</strong> al cliente <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <!-- Si no hay datos -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Gestor
      v-model="dialog"
      :p_gestor="gestor"
      @editar="editar"
    />
    <EditarLimiteCredito
      v-model="dialog_lim_cred"
      :p_gestor="gestor"
      @actualizar="cambiar_limite"
    />
    <SaldosAFavor 
      :datos="modalSaldosFavor"
      @setearModalSaldos="setModalSaldos"
    />
    <ModalUsuario
      v-model="dialog_user"
      :usuario="gestor ? gestor.usuario : null"
    />
    <CrearUsuario
      v-model="dialog_crear_user"
      :vendedor="gestor ? gestor.codigo : null"
      :sucursal="gestor ? gestor.sucursal_codigo : null"
      :empresa="6"
      :nombre="gestor ? gestor.nombre : null"
      :categoria="gestor ? gestor.categoria : null"
      :vendedores_list.sync="gestores"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import Gestor from '../../components/generales/clientes/Gestor'
import EditarLimiteCredito from '../../components/generales/clientes/EditarLimiteCredito'
import SaldosAFavor from '../../components/clientes/SaldosAFavor'
import DownloadDataTable from '../../components/util/DownloadDataTable'
import CrearUsuario from '../../components/vendedores/CrearUsuario.vue'
import ModalUsuario from '../../components/util/ModalUsuario.vue'
import { roundNumber } from '../../util/utils'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      dialog_user: false,
      dialog_lim_cred: false,
      dialog_crear_user: false,
      search: '',
      headers: [
        { text: 'Codigo', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Nº Documento', value: 'num_doc' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Ejecutivo', value: 'ejecutivo_nombre' },
        { text: 'Localidad', value: 'localidad_nombre' },
        { text: 'Domicilio', value: 'domicilio' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      headers_excel: {
        'CÓDIGO':           'codigo',
        'NOMBRE':           'nombre',
        'NÚMERO DOCUMENTO': 'num_doc',
        'TELÉFONO':         'telefono',
        'EJECUTIVO':        'ejecutivo_nombre',
        'SUCURSAL':         'sucursal_nombre',
        'LOCALIDAD':        'localidad_nombre',
        'DOMICILIO':        'domicilio'
      },
      resumen_header: {
        "CODIGO":           "cliente_codigo",
        "NOMBRE":           "cliente_nombre",
        "DOCUMENTO":        "dni",
        "TELEFONO":         "telefono",
        "ESTADO" :          "estado",
        "EJECUTIVO_NOMBRE": "ejecutivo_nombre",
        "LOCALIDAD_NOMBRE": "localidad_nombre",
        "DOMICILIO":        "domicilio",
        "SALDO":            "saldo",
        "LIMITE":           "limite",
        "A_FAVOR":          "a_favor",
      },
      clientes_excel:[],
      gestores: [],
      gestor: {},
      modalSaldosFavor: {
        activo: false,
        cliente_codigo: '',
        cliente_nombre: ''
      },
      filtro: {
        sucursal: null,
        ejecutivo: null,
        cond_iva: null,
        codigo: null,
        nombre: null,
        documento: null,
        inhabilitado: 0
      }
    }
  },
  async created () {
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    // obtiene los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('get_cond_iva')
    await this.$store.dispatch('get_ejecutivos')
    await this.$store.dispatch('gestores/get_permisos')
    //await this.getPermiso();
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['sucursales', 'cond_iva', 'ejecutivos']),
    ...mapState('gestores', ['p_lim_cred', 'p_eliminar', 'p_editar', 'p_descargar'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    Gestor,
    EditarLimiteCredito,
    SaldosAFavor,
    DownloadDataTable,
    CrearUsuario,
    ModalUsuario
  },
  methods: {
    async getPermiso(){
      const res = await this.$store.dispatch('gestores/getPermiso');
    },
    mostrar_saldos (item) {
      this.modalSaldosFavor.cliente_codigo = item.codigo
      this.modalSaldosFavor.cliente_nombre = item.nombre
      this.modalSaldosFavor.activo = true
    },
    setModalSaldos (value) {
      this.modalSaldosFavor.activo = value
    },
    cambiar_limite (gestor_codigo, limite_nuevo) {
      let gestor = this.gestores.find(g => g.codigo == gestor_codigo)
      gestor.limite_credito = limite_nuevo
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('gestores/inhabilitar', item.codigo)
        .then((res) => {
          this.quitar(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('gestores/habilitar', item.codigo)
        .then((res) => {
          this.quitar(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.sucursal && !(this.filtro.codigo || this.filtro.ejecutivo || this.filtro.documento)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una sucursal',
          color: 'warning'
        })
      }
      this.load = true
      this.search = ''
      this.gestores = []
      await this.$store.dispatch('gestores/get_gestores', this.filtro)
        .then((res) => {
          this.gestores = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar (gestor) {
      let ges = this.gestores.find(g => g.codigo == gestor.codigo)
      Object.assign(ges, gestor)
    },
    quitar (item) {
      const index = this.gestores.indexOf(item)
      this.gestores.splice(index, 1)
    },
    open_dialog (item) {
      this.gestor = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    openDialogUser (item, dialogName) {
      this.gestor = item
      this[dialogName] = true
    },
    limpiar () {
      this.filtro = {
        sucursal: null,
        ejecutivo: null,
        cond_iva: null,
        codigo: null,
        nombre: null,
        documento: null,
        inhabilitado: 0
      }
    },
    async getResumenClienteMayorista(){
      if(this.filtro.sucursal || (this.filtro.sucursal && this.filtro.ejecutivo)){
        this.$store.state.loading = true
        const res = await this.$store.dispatch('gestores/getResumenClienteMayorista', {
          sucursal_codigo:  this.filtro.sucursal,
          ejecutivo_codigo: this.filtro.ejecutivo ? this.filtro.ejecutivo : 0 
        })
        this.$store.state.loading = false
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
        }
        res.clientes.forEach(e => {
          e.saldo = roundNumber(e.saldo,2)
        });
        this.clientes_excel = res.clientes;
        if(this.clientes_excel.length == 0){
          this.$store.dispatch('show_snackbar', { text: 'No se obtuvo ningún cliente y porque lo tanto el excel se generó vacío.', color: 'orange' })
        }
      }      
      return this.clientes_excel;
    },
  }
}
</script>

<style scoped>
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>