<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="load || load_input"
    scrollable
  >
    <v-card
      :disabled="load || load_input"
      :loading="load_input"
    >
      <v-card-title>
        Editar caja
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="caja.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="caja.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_padres(); caja.local_codigo = null; caja.padre = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="caja.sucursal_codigo"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="[rules.required_zero]"
                validate-on-blur
                outlined
                dense
                @change="caja.local_codigo = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="caja.local_codigo"
                label="Local"
                item-text="nombre"
                item-value="id"
                :items="all_locales.filter(l => l.sucursal == caja.sucursal_codigo && l.empresa == caja.empresa)"
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="caja.nivel_codigo"
                label="Nivel"
                :items="niveles"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="caja.tipo_codigo"
                label="Tipo"
                :items="tipos"
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Caja padre
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-4 mb-6">
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-autocomplete
                        v-model="caja.nivel_padre"
                        label="Nivel"
                        :items="niveles"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                        @change="get_padres(); caja.padre = null"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-autocomplete
                        v-model="caja.padre"
                        label="Caja"
                        item-text="nombre"
                        item-value="numero"
                        :items="padres"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Configuración
                </v-card-title>
                <v-card-text class="py-0 mb-sm-6 mb-8">
                  <v-row>
                    <v-col cols="12" md="4" class="py-0">
                      <v-checkbox
                        v-model="caja.transporta"
                        label="Transporta"
                        :true-value="1"
                        :false-value="null"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-checkbox
                        v-model="caja.reporte"
                        label="Reporte gastos"
                        :true-value="1"
                        :false-value="null"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-checkbox
                        v-model="caja.diaria"
                        label="Caja diaria"
                        :true-value="1"
                        :false-value="null"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-switch
                v-model="caja.inhabilitada"
                label="Inhabilitada"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          :disabled="load || load_input"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          :disabled="load_input"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar una caja jerarquia
 * 
 *  Notas: este componente emite la accioon @editar al padre caundo edita una caja (en caso de estar definida)
 *         todos los props son obligatorios
 */
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      load_input: false,
      padres: [],
      caja: {},
      rules: {
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  props: {
    value: Boolean,
    p_caja: Object
  },
  computed: {
    ...mapState(['empresas', 'sucursales', 'all_locales']),
    ...mapState('admCajas', ['tipos', 'niveles']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        // obtiene los datos necesarios para el formulario
        await this.get_data()
        // crea una copia del prop p_caja para editarlo
        this.caja = await JSON.parse(JSON.stringify(this.p_caja))
        // obtiene los posibles padres
        await this.get_padres()
      }
      this.$refs.form.resetValidation()
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // solo edita si realmente se modifico algun valor
        if (JSON.stringify(this.caja) != JSON.stringify(this.p_caja)) {
          this.load = true
          await this.$store.dispatch('admCajas/editar', this.caja)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              // obtiene los nombres de los array
              this.caja.sucursal_nombre = get_nombre_obj_arr(this.sucursales, 'id', this.caja.sucursal_codigo)
              this.caja.local_nombre = get_nombre_obj_arr(this.all_locales, 'id', this.caja.local_codigo)
              this.caja.nivel_nombre = get_nombre_obj_arr(this.niveles, 'value', this.caja.nivel_codigo, 'text')
              this.caja.tipo_nombre = get_nombre_obj_arr(this.tipos, 'value', this.caja.tipo_codigo, 'text')
              this.caja.padre_nombre = get_nombre_obj_arr(this.padres, 'numero', this.caja.padre)
              // emite la accion 'editar' definifa en el padre
              this.$emit('editar', this.caja)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        } else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: `No se realizaron cambios en la caja ${this.caja.nombre}: los nuevos valores son iguales a los anteriores`,
            color: 'warning',
            timeout: 4000
          })
        }
      }
    },
    async get_padres () {
      const empresa = this.caja.empresa
      const nivel = this.caja.nivel_padre
      this.padres = []
      if (empresa && nivel) {
        this.$store.state.loading = true
        await this.$store.dispatch('admCajas/get_cajas', {
          inhabilitada: 0,
          empresa: empresa,
          nivel: nivel
        })
          .then((res) => {
            this.padres = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: 'Fallo al obtener la caja padre de ' + this.p_caja.nombre + ': ' + error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
        // busca el padre en el array, si no lo encuentra es pq esta inhabilitado y lo agrega a la lista
        const padre = this.caja.padre
        const padre_obj = this.padres.find(p => p.numero == padre)
        if (!padre_obj) {
          this.padres.push({
            numero: padre,
            nombre: this.caja.padre_nombre
          })
        }
      }
    },
    async get_data () {
      this.load_input = true
      await this.$store.dispatch('admCajas/get_tipos')
      await this.$store.dispatch('admCajas/get_niveles')
      await this.$store.dispatch('get_all_locales')
      this.load_input = false
    }
  }
}
</script>