<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn
                  fab
                  dark
                  color="success"
                  title="Generar Liq. Mensual"
                  small
                  @click="dialogAddLiqMensual.activo = true"
                >
                  <v-icon dark small>
                    fas fa-plus
                  </v-icon>
                </v-btn>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.empresa_id"
                      item-text="nombre_corto"
                      item-value="id"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Liquidacion Id
                    <v-text-field
                      v-model="filtro.liquidacion_id"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Nro. Resumen
                    <v-text-field
                      v-model="filtro.nro_resumen"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Nro. Comercio
                    <v-text-field
                      v-model="filtro.nro_comercio"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Periodo
                    <PeriodoPicker
                      v-model="filtro.periodo"
                      :label="false"
                      :fecha_minima="moment(new Date()).subtract(6, 'months').format('YYYY-MM')"
                    />
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Forma de Cobro
                    <v-autocomplete
                      v-model="filtro.forma_cobro_id"
                      item-text="forma_cobro_nombre"
                      item-value="forma_cobro_id"
                      :items="formasCobro"
                      hide-details
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Tarjeta
                    <v-autocomplete
                      v-model="filtro.tarjeta_codigo"
                      item-text="tarjeta_nombre"
                      item-value="tarjeta_codigo"
                      :items="tarjetas"
                      hide-details
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" align="right" class="pa-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                      clase="mt-1"
                    />
                  </v-col>
                </v-row>
                <!-- <v-row :no-gutters="$vuetify.breakpoint.xs">
                  
                </v-row> -->
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado Liquidaciones -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="liquidaciones"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin Liquidaciones para mostrar.
                </v-alert>
              </template>
              <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- Fecha -->
              <template v-slot:[`item.periodo`]="{ item }">
                {{ format_date(item.periodo) }}
              </template>
              <!-- Auditado -->
              <template v-slot:[`item.auditado`]="{ item }">
                <v-checkbox
                  v-model="item.auditado"
                  :true-value="1"
                  :false-value="0"
                  readonly
                  hide-details
                  dense
                  class="ml-2"
                  :color="item.auditado == 1 ? 'info' : ''"
                ></v-checkbox>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="success" @click="verLiqMensual(item)" v-on="on">
                      <v-icon small>fas fa-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="info" @click="verLiqAsociadas(item)" v-on="on">
                      <v-icon small>far fa-list-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Liq. Asociadas</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Modal Liquidaciones Asociadas -->
    <ModalLiqAsociadas 
      :datos="dialogLiq"
      @setearModalLiqAsoc="setModalLiqAsoc"
    />
    <!-- Modal Generar Liq Mensuales -->
    <ModalGenerarLiqMensual 
      :datos="dialogAddLiqMensual"
      @setearModalGenLiqMens="setModalGenLiqMens"
    />
  </div>
</template>

<script>
import PeriodoPicker from '../../components/util/PeriodoPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalLiqAsociadas from '../../components/administracion/ModalLiqAsociadas.vue'
import moment from 'moment'
import { cadenaVacia, roundNumber, format_money, format_date } from '../../util/utils'
import ModalGenerarLiqMensual from '../../components/administracion/ModalGenerarLiqMensual.vue'
export default {
  data() {
    return {
      moment: moment,
      format_date: format_date,
      panel: 0,
      load: false,
      filtro: {
        empresa_id: null,
        forma_cobro_id: null,
        tarjeta_codigo: null,
        liquidacion_id: null,
        nro_resumen: null,
        nro_comercio: null,
        periodo: ''
      },
      formasCobro: [],
      tarjetas: [],
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'Periodo', align: 'center', value: 'periodo' },
        { text: 'Comerc.', align: 'center', value: 'nro_comercio' },
        { text: 'Nro. Res.', align: 'center', value: 'nro_resumen' },
        { text: 'F. Cobro', align: 'center', value: 'forma_cobro_nombre' },
        { text: 'Tarjeta', align: 'center', value: 'tarjeta_nombre' },
        { text: 'T. Bruto', align: 'right', value: 'total_bruto', formatter: format_money },
        { text: 'T. Neto', align: 'right', value: 'total_neto', formatter: format_money },
        { text: 'Desc.', align: 'right', value: 'descuentos', formatter: format_money },
        { text: 'Audit.', align: 'center', value: 'auditado' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      liquidaciones: [],
      search: '',
      dialogLiq: {
        activo: false,
        liquidacion_id: 0
      },
      dialogAddLiqMensual: {
        activo: false
      },
      empresas: []
    }
  },
  components: {
    PeriodoPicker,
    BtnFiltro,
    SearchDataTable,
    ModalLiqAsociadas,
    ModalGenerarLiqMensual
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('liqMensuales/initLiqMensuales')
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      this.formasCobro = initPeticion.formas_cobro
      this.tarjetas = initPeticion.tarjetas
    },
    limpiar() {
      this.filtro = {
        forma_cobro_id: null,
        tarjeta_codigo: null,
        liquidacion_id: null,
        nro_resumen: null,
        nro_comercio: null,
        periodo: ''
      }
      this.search = ''
    },
    async buscar(){
      if (cadenaVacia(this.filtro.periodo) && cadenaVacia(this.filtro.liquidacion_id)){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese el Periodo o Id de Liquidación a filtrar',
          color: 'warning',
        })
        return
      }
      this.load = true
      let liqsPeticion = await this.$store.dispatch('liqMensuales/getLiqMensuales', this.filtro)
      this.load = false
      if (liqsPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: liqsPeticion.msj,
          color: 'error',
        })
        return
      }
      this.liquidaciones = liqsPeticion.liquidaciones
    },
    verLiqMensual(item){
      const routeData = this.$router.resolve({path: `/liquidacion-mensual/${item.id}`})
      window.open(routeData.href, '_blank')
    },
    verLiqAsociadas(item){
      this.dialogLiq.liquidacion_id = item.id
      this.dialogLiq.activo = true
    },
    setModalLiqAsoc(value){
      this.dialogLiq.activo = value
      if (this.dialogLiq.activo == false) this.dialogLiq.liquidacion_id = 0
    },
    setModalGenLiqMens(value){
      this.dialogAddLiqMensual.activo = value
    }
  },
  watch: {
    liquidaciones: function(){
      if (this.liquidaciones.length > 0){
        for (let id in this.liquidaciones){
          this.liquidaciones[id].bb_vs_tarjeta = roundNumber(this.liquidaciones[id].bb_vs_tarjeta, 2)
          this.liquidaciones[id].iva = roundNumber(this.liquidaciones[id].iva, 2)
          this.liquidaciones[id].neto_grabado = roundNumber(this.liquidaciones[id].neto_grabado, 2)
          this.liquidaciones[id].total_bb = roundNumber(this.liquidaciones[id].total_bb, 2)
          this.liquidaciones[id].total_bruto = roundNumber(this.liquidaciones[id].total_bruto, 2)
          this.liquidaciones[id].total_neto = roundNumber(this.liquidaciones[id].total_neto, 2)
        }
      }
    }
  },
}
</script>

<style>

</style>