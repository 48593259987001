import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import config from '../../config'
import { VueEasyJwt } from "vue-easy-jwt"

const jwt = new VueEasyJwt()
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
    component: () => import(/* webpackChunkName: "notificaciones" */ '../views/Notificaciones.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/descargarArchivo',
    name: 'Descargar Archivo',
    component: () => import(/* webpackChunkName: "descargar-archivo" */ '../views/DescargarArchivo.vue'),
    meta: { requireAuth: true }
  },
  // ----------------------------------
  //           DATOS GENERALES
  // ----------------------------------
  {
    path: '/talonarios',
    name: 'Talonarios',
    component: () => import(/* webpackChunkName: "talonarios" */ '../views/generales/Talonarios.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/generales/Usuarios.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/index/vendedores',
    name: 'Vendedores',
    component: () => import(/* webpackChunkName: "vendedores" */ '../views/generales/Vendedores.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/vendedor/:id?',
    name: 'Vendedor',
    component: () => import(/* webpackChunkName: "vendedores" */ '../views/generales/Vendedor.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: () => import(/* webpackChunkName: "marcas" */ '../views/generales/Marcas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/modelos',
    name: 'Modelos',
    component: () => import(/* webpackChunkName: "modelos" */ '../views/generales/Modelos.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/rubros',
    name: 'Rubros',
    component: () => import(/* webpackChunkName: "rubros" */ '../views/generales/Rubros.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/categorias',
    name: 'Categorias',
    component: () => import(/* webpackChunkName: "categorias" */ '../views/generales/Categorias.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  /*{
    path: '/tamanios',
    name: 'Tamaños',
    component: () => import(/* webpackChunkName: "tamanios" * '../views/generales/Tamanios.vue'),
    meta: { requireAuth: true, /*rutaProtegida: true* }
  },*/
  {
    path: '/lineas',
    name: 'Lineas',
    component: () => import(/* webpackChunkName: "lineas" */ '../views/generales/Lineas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/listas',
    name: 'Listas Precio',
    component: () => import(/* webpackChunkName: "listas" */ '../views/generales/Listas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/index/proveedores',
    name: 'Proveedores',
    component: () => import(/* webpackChunkName: "proveedores" */ '../views/generales/Proveedores.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/proveedor/:id?',
    name: 'Proveedor',
    component: () => import(/* webpackChunkName: "proveedores" */ '../views/generales/Proveedor.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/actualizar-precios',
    name: 'Actualizar Precios',
    component: () => import(/* webpackChunkName: "actualizar-precios" */ '../views/generales/ActualizarPrecios.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/bodegas',
    name: 'Bodegas',
    component: () => import(/* webpackChunkName: "bodegas" */ '../views/generales/Bodegas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/bancos',
    name: 'Bancos',
    component: () => import(/* webpackChunkName: "bancos" */ '../views/generales/Bancos.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/importar-archivos',
    name: 'Importar Archivos',
    component: () => import(/* webpackChunkName: "archivos" */ '../views/generales/ImportarArchivos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/feriados',
    name: 'Feriados',
    component: () => import(/* webpackChunkName: "feriados" */ '../views/generales/IndexFeriados.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import(/* webpackChunkName: "feriados" */ '../views/generales/Banners.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //         ENCUESTA CALIDAD
  // ----------------------------------
  {
    path: '/encuesta-calidad',
    name: 'Encuesta Calidad',
    component: () => import(/* webpackChunkName: "feriados" */ '../views/encuestas/EncuestaCalidad.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           AUDITORIA
  // ----------------------------------
  {
    path: '/tarjetas',
    name: 'Tarjetas',
    component: () => import(/* webpackChunkName: "tarjetas" */ '../views/auditoria/Tarjetas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/cuotas',
    name: 'Cuotas',
    component: () => import(/* webpackChunkName: "cuotas" */ '../views/auditoria/Cuotas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/index/financiaciones',
    name: 'Financiaciones',
    component: () => import(/* webpackChunkName: "financiaciones" */ '../views/auditoria/Financiaciones.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/financiacion/:id?',
    name: 'Financiación',
    component: () => import(/* webpackChunkName: "financiaciones" */ '../views/auditoria/Financiacion.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/creditos-debitos',
    name: 'Creditos Debitos',
    component: () => import(/* webpackChunkName: "creditos-debitos" */ '../views/auditoria/CreditosDebitos.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin-cajas',
    name: 'Cajas',
    component: () => import(/* webpackChunkName: "cajas" */ '../views/auditoria/Cajas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/inventario',
    name: 'Inventario',
    component: () => import(/* webpackChunkName: "inventario" */ '../views/auditoria/Inventario.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/conf/index/control-stock',
    name: 'Conf. Controles Stock',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/auditoria/ConfControlStock.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/conf/control-stock/:id?',
    name: 'Conf. Control Stock',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/auditoria/ConfControlStockDetalle.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/control-cajas/:filtro?',
    name: 'Control Cajas',
    component: () => import(/* webpackChunkName: "control-cajas" */ '../views/auditoria/ControlCajas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/tipificaciones',
    name: 'Tipificaciones',
    component: () => import(/* webpackChunkName: "tipificaciones" */ '../views/auditoria/Tipificaciones.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/movimientos-bancarios',
    name: 'Movimientos Bancarios',
    component: () => import(/* webpackChunkName: "movimientos-bancarios" */ '../views/auditoria/MovBancarios.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/tipos-comprobantes',
    name: 'Tipos Comprobantes',
    component: () => import(/* webpackChunkName: "movimientos-bancarios" */ '../views/auditoria/TiposComprobantes.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/cheques',
    name: 'Cheques',
    component: () => import(/* webpackChunkName: "cheques" */ '../views/auditoria/Cheques.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/index/conciliacion-manual',
    name: 'Conciliación Manual',
    component: () => import(/* webpackChunkName: "Conciliación Manual" */ '../views/auditoria/IndexConciliacionManual.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  }, {
    path: '/conciliacion-manual/:id',
    name: 'Datos liquidación',
    component: () => import(/* webpackChunkName: "Datos de la liquidación" */ '../views/auditoria/UnaConciliacion.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/index/aud-facturas-compra',
    name: 'Facturas compra',
    component: () => import(/* webpackChunkName: "Facturas-de-compra" */ '../views/auditoria/IndexFacturasCompraAud.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/aud-factura-compra/:id',
    name: 'Factura Compra',
    component: () => import(/* webpackChunkName: "Ver-Editar-Factura-de-compra" */ '../views/auditoria/UnaFacturaCompraAud.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/aud-nueva-factura-compra',
    name: 'Nueva Factura Compra',
    component: () => import(/* webpackChunkName: "Nueva-Factura-de-compra" */ '../views/auditoria/UnaFacturaCompraAud.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/bancos-pais',
    name: 'ABM Bancos FP',
    component: () => import(/* webpackChunkName: "ABM Bancos FP" */ '../views/auditoria/IndexBancosPais.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           LIQ MENSUALES
  // ----------------------------------
  {
    path: '/liquidaciones-mensuales',
    name: 'Liquidaciones Mensuales',
    component: () => import(/* webpackChunkName: "ABM Bancos FP" */ '../views/administracion/IndexLiqMensuales.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/liquidacion-mensual/:id',
    name: 'Liquidacion Mensual',
    component: () => import(/* webpackChunkName: "ABM Bancos FP" */ '../views/administracion/unaLiqMensual.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           STOCK
  // ----------------------------------
  {
    path: '/index/control-stock',
    name: 'Controles Stock',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/stock/IndexControlStock.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/control-stock/:id/:ver?',
    name: 'Control Stock',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/stock/ControlStock.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/etiquetas-precio',
    name: 'Etiquetas Precio',
    component: () => import(/* webpackChunkName: "etiquetas-precio" */ '../views/stock/EtiquetasPrecio.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/codigo-barra',
    name: 'Articulos Codigo Barras',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/ArtCodBarra.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/pedidos-stock',
    name: 'Pedidos Stock',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/PedidosStock.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/pedido-stock/:id?',
    name: 'Pedido Stock',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/PedidoStock.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/stock-disponible',
    name: 'Stock Disponible',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/StockDisponible.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/index/articulos',
    name: 'ABM Articulos',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/ABMdeArticulos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/articulo/:id',
    name: 'Articulo',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/UnArticulo.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/nuevo-articulo',
    name: 'Nuevo Articulo',
    component: () => import(/* webpackChunkName: "stock" */ '../views/stock/UnArticulo.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  

  // ----------------------------------
  //           PROMOCIONES
  // ----------------------------------
  {
    path: '/promociones',
    name: 'Promociones',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/promociones/index.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nueva-promo',
    name: 'Nueva Promoción',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/promociones/unaPromocion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/ver-promo/:id',
    name: 'Promoción',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/promociones/unaPromocion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           RECEPCIONES
  // ----------------------------------
  {
    path: '/recepciones',
    name: 'Recepciones',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/recepciones/index.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nueva-recepcion',
    name: 'Nueva Recepcion',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/recepciones/unaRecepcion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/ver-recepcion/:id',
    name: 'Recepcion',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/recepciones/unaRecepcion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //        INDICES GERENCIALES
  // ----------------------------------
  {
    path: '/indices-locales',
    name: 'Indices Gerenciales',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/indices/IndicesGerenciales.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //       CONCILIACIÓN BANCARIA
  // ----------------------------------
  {
    path: '/conciliacion-bancaria/:filtro?',
    name: 'Conciliacion Bancaria',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/auditoria/ConciliacionBancaria.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         REMITOS INTERNOS
  // ----------------------------------
  {
    path: '/index/remitos-internos',
    name: 'Remitos Internos',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/remitos/IndexRemitos.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/remito-intero/:numero',
    name: 'Remito Interno',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/remitos/VerRemito.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/nuevo-remito',
    name: 'Nuevo Remito Interno',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/remitos/NuevoRemito.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/index/remitos-aceptacion',
    name: 'Remitos Aceptacion',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/remitos-aceptacion/Index.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/aceptar-remito/:numero',
    name: 'Aceptar Remito Interno',
    component: () => import(/* webpackChunkName: "control-stock" */ '../views/remitos-aceptacion/AceptarRemito.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  // ----------------------------------
  //           OBJETIVOS
  // ----------------------------------
  {
    path: '/index/giftcards',
    name: 'Gift Cards',
    component: () => import(/* webpackChunkName: "giftcards" */ '../views/objetivos/GiftCards.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/objetivos/ptosvta',
    name: 'Objetivos Ptos Vta',
    component: () => import(/* webpackChunkName: "objetivos_ptosvta" */ '../views/objetivos/ObjPtosVta.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/objetivos/vendedores',
    name: 'Objetivos Vendedores',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/objetivos/ObjVendedores.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/penalizaciones',
    name: 'Penalizaciones',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/objetivos/PenalizacionVend.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //        ENCUESTA DEL CLIMA
  // ----------------------------------
  {
    path: '/encuesta-clima',
    name: 'Encuesta del Clima',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/encuestas/EncuestaClima.vue'),
    meta: {
      controlRuta: true,
      rutaId: 226
    }
  },
  // ----------------------------------
  //           DEPOSITOS
  // ----------------------------------
  {
    path: '/abm-depositos',
    name: 'ABM Bodegas',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/depositos/Depositos.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           ORDENES COMPRA
  // ----------------------------------
  {
    path: '/ordenes-compra',
    name: 'Ordenes Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/compras/OrdenesCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/orden-compra/:id',
    name: 'Orden Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/compras/UnaOrdenCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nueva-orden-compra',
    name: 'Nueva Orden Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/compras/UnaOrdenCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           ADMINISTRACION
  // ----------------------------------
  {
    path: '/adminProvConceptos',
    name: 'Conceptos Proveedor',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/AdminProveedConceptos.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/index/facturas-compra',
    name: 'Facturas de Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/IndexFacturasCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/factura-compra/:id',
    name: 'Factura de Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/UnaFacturaCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/new-factura-compra',
    name: 'Nueva Factura de Compra',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/UnaFacturaCompra.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/admin-bejerman',
    name: 'Panel Control Bejerman',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/AdminBejerman.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/archivos-shopping',
    name: 'Generar Archivos Shopping',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/ArchivoShopping.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/anular-facturas-manuales',
    name: 'Anulación Facturas Manuales',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/AnularFacturasMan.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/archivos-bejerman',
    name: 'Generar Archivos Bejerman',
    component: () => import(/* webpackChunkName: "bejerman" */ '../views/administracion/ArchivosBejerman.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           LIBRO IVA
  // ----------------------------------
  {
    path: '/libro-iva',
    name: 'Libro Iva',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/administracion/LibroIva.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           COBRO ONLINE
  // ----------------------------------
  {
    path: '/cobro-online',
    name: 'Cobro Online',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/cobro-online/Index.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nuevo-cobro',
    name: 'Nuevo Cobro Online',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/cobro-online/UnCobro.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/reprocesar-cobro/:tipo_pedido/:id',
    name: 'Reprocesar Cobro',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/cobro-online/UnCobro.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         REMITOS DEVOLUCION
  // ----------------------------------
  {
    path: '/remitos-devolucion',
    name: 'Remitos Devolución',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/auditoria/remitos-devolucion/Index.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nueva-devolucion',
    name: 'Nuevo Remito Devolución',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/auditoria/remitos-devolucion/UnRemitoDevolucion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/editar-devolucion/:devolucion_codigo',
    name: 'Editar Remito Devolución',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/auditoria/remitos-devolucion/UnRemitoDevolucion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/aceptar-devolucion/:devolucion_codigo',
    name: 'Aceptar Remito Devolución',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/auditoria/remitos-devolucion/UnRemitoDevolucion.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         ACREDITACIONES
  // ----------------------------------
  {
    path: '/acreditaciones',
    name: 'Acreditaciones Gestores',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/clientes/Acreditaciones.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/acredita-admin',
    name: 'ABM Acreditaciones',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/clientes/ABMAcreditaciones.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         OBJETIVOS ALCANZADOS
  // ----------------------------------
  {
    path: '/objetivos-alcanzados',
    name: 'Objetivos Alcanzados',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/objetivos/ObjAlcanzados.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         CAMBIO DIRECTO
  // ----------------------------------
  {
    path: '/cambio-directo',
    name: 'Cambio Directo',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/ventas/CambioDirecto.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         LOCALES
  // ----------------------------------
  {
    path: '/abm-locales',
    name: 'Locales',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/auditoria/locales/ABMLocales.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/abm-afip-locales',
    name: 'Locales AFIP',
    component: () => import(/* webpackChunkName: "abm-afip-locales" */ '../views/auditoria/locales/ABMLocalesAfip.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //         PARAMETROS
  // ----------------------------------
  {
    path: '/parametros',
    name: 'Parametros',
    component: () => import(/* webpackChunkName: "objetivos_vendedores" */ '../views/parametros/parametros.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           CLIENTES
  // ----------------------------------
  {
    path: '/gestores',
    name: 'Gestores',
    component: () => import(/* webpackChunkName: "gestores" */ '../views/clientes/Gestores.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/Clientes.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/ctacte',
    name: 'Cuenta Corriente',
    component: () => import(/* webpackChunkName: "gestores" */ '../views/clientes/CtaCteGestor.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           CONSULTAS
  // ----------------------------------
  {
    path: '/consulta-serie',
    name: 'Consulta Series',
    component: () => import(/* webpackChunkName: "series" */ '../views/generales/ConsultaSeries.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/generales/Dashboard.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/objetivos/index',
    name: 'Objetivos',
    component: () => import(/* webpackChunkName: "objetivos" */ '../views/generales/ObjetivosVend.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/consultaFinanciaciones',
    name: 'Condiciones Comerciales',
    component: () => import(/* webpackChunkName: "financiaciones" */ '../views/generales/CondComerciales.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/controlFranquicias',
    name: 'Control Franquicias',
    component: () => import(/* webpackChunkName: "control-franquicias" */ '../views/franquicia/IndexContribucionFranquicia.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/condiciones-comerciales',
    name: 'Cond. Comerciales Mayorista',
    component: () => import(/* webpackChunkName: "Condiciones Comerciales" */ '../views/generales/CondComercialesMayorista.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           RRHH
  // ----------------------------------
  {
    path: '/index/empleados',
    name: 'Empleados',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Empleados.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/empleado/:id?',
    name: 'Empleado',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Empleado.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/evaluaciones',
    name: 'Evaluación Desempeño',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Evaluaciones.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/evaluacion/:id',
    name: 'Evaluación',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Evaluacion.vue'),
    meta: { requireAuth: true }
  },
  // ----------------------------------
  //           INCIDENTES
  // ----------------------------------
  {
    path: '/incidentes/:id?',
    name: 'Carga Incidentes',
    component: () => import(/* webpackChunkName: "incidentes" */ '../views/incidentes/Incidentes.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/incidente-mesa/:id?',
    name: 'Resolución Incidentes',
    component: () => import(/* webpackChunkName: "incidentes" */ '../views/incidentes/IncidentesMesa.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           VENTAS
  // ----------------------------------
  {
    path: '/index-ventas',
    name: 'Ventas Salon',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/Index.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/nueva-venta',
    name: 'Nueva Venta',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/Ventas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/venta-salon/:id',
    name: 'Venta',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/Ventas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/cierre-lote',
    name: 'Cierres Lote',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/CierreLote.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/lapos-integrado',
    name: 'LaPos Integrado',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/PanelLapos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/excepciones',
    name: 'Excepciones FP',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/ventas/ExcepcionesFP.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/contactos-garantias',
    name: 'Contactos de Garantías',
    component: () => import(/* webpackChunkName: "garantias" */ '../views/ventas/ContactosGarantias.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //          GESTION STOCK
  // ----------------------------------
  {
    path: '/gestion-stock',
    name: 'Gestion Stock',
    component: () => import(/* webpackChunkName: "ventas" */ '../views/stock/GestionStock.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           CAJA
  // ----------------------------------
  {
    path: '/movimientos-caja',
    name: 'Operaciones Caja',
    component: () => import(/* webpackChunkName: "caja" */ '../views/caja/Caja.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           CAMPAÑAS DE VENTA
  // ----------------------------------
  {
    path: '/encuestas',
    name: 'Encuestas',
    component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/CampaniaVentas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/camp-encuesta/:id?',
    name: 'Campaña Encuestas',
    component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/NuevaCampania.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           MAYORISTA
  // ----------------------------------
  {
    path: '/index-vta-mayorista',
    name: 'Ventas Mayorista',
    component: () => import(/* webpackChunkName: "ventas-mayorista" */ '../views/mayorista/IndexVta.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/nueva-vta-mayorista',
    name: 'Nueva Venta Mayorista',
    component: () => import(/* webpackChunkName: "ventas-mayorista" */ '../views/mayorista/VentaMay.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/vta-mayorista/:id',
    name: 'Venta Mayorista',
    component: () => import(/* webpackChunkName: "ventas-mayorista" */ '../views/mayorista/VentaMay.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/index/cobranzas',
    name: 'Cobranzas',
    component: () => import(/* webpackChunkName: "cobranzas" */ '../views/mayorista/IndexMayoristaCobranza.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/cobranza/:id',
    name: 'Cobranza',
    component: () => import(/* webpackChunkName: "cobranza" */ '../views/mayorista/UnaCobranza.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/nueva-cobranza',
    name: 'Nueva cobranza',
    component: () => import(/* webpackChunkName: "nueva-cobranza" */ '../views/mayorista/UnaCobranza.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/paquetes',
    name: 'Paquetes',
    component: () => import(/* webpackChunkName: "Paquetes" */ '../views/mayorista/IndexPaquetes.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/repartidor',
    name: 'Repartidor',
    component: () => import(/* webpackChunkName: "Repartidor" */ '../views/mayorista/IndexRepartidor.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           OTRAS
  // ----------------------------------
  {
    path: '/pruebas',
    name: 'Pruebas',
    component: () => import(/* webpackChunkName: "pruebas" */ '../views/PruebasMartin.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/dycar/recibos-efectivo',
    name: 'Recibos en efectivo',
    component: () => import(/* webpackChunkName: "recivos-efectivo" */ '../views/dycar/RecibosEfectivo.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/dycar/recibo-efectivo/:id?',
    name: 'Recibo en efectivo',
    component: () => import(/* webpackChunkName: "recivo-efectivo" */ '../views/dycar/ReciboEfectivo.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/grupopy/liquidaciones-ivr',
    name: 'Liquidaciones IVR',
    component: () => import(/* webpackChunkName: "liquidacion-ivr" */ '../views/grupopy/LiquidacionesIVR.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/soporte',
    name: 'Soporte',
    component: () => import(/* webpackChunkName: "soporte" */ '../views/Soporte.vue'),
    meta: { requireAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = to.name == 'Home' || to.name == 'Login' || !to.name ? 'Jarvis 2.0' : to.name

  // si tiene id se lo concateno xd
  if (to.params.id) {
    document.title += ` N° ${to.params.id}`
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    if (is_auth()) {
      store.dispatch('get_notificaciones_noleidas')
      if (to.matched.some(record => record.meta.rutaProtegida)) {
        try {
          store.state.loading = true

          const res = await fetch(`${config.BASE_URL}/user/rutaValida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Base ' + JSON.parse(localStorage.getItem('token'))
            },
            body: JSON.stringify({
              path: to.path,
              redirect: true,
            })
          })
  
          const data = await res.json()
  
          if (data.exito === 1) {
            next()
          } else {
            next({ path: '/' })
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }

          store.state.loading = false
        } catch (error) {
          next({ path: '/' })
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      } else {
        next()
      }
    } else {
      next({ path: '/login' })
      if (JSON.parse(localStorage.getItem('token'))) {
        store.dispatch('logout', false)
        store.dispatch('show_snackbar', {
          text: 'La sesión actual expiró.',
          color: 'error'
        })
      }
    }
  } else if (to.matched.some(record => record.meta.controlRuta)) {
    try{
      store.state.loading = true
      const res = await fetch(`${config.BASE_URL}/user/rutaHabilitada?ruta_id=${to.matched[0].meta.rutaId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Base ' + JSON.parse(localStorage.getItem('token'))
        }
      })
      const data = await res.json()
      store.state.loading = false
      if (data.resultado == 0){
        next({ path: '/login' })
        store.dispatch('show_snackbar', {
          text: 'No se puede ingresar temporalmente a este sitio.',
          color: 'error'
        })
        return
      }
      if (data.inhabilitada == 0){
        next()
      }else{
        next({ path: '/login' })
        store.dispatch('show_snackbar', {
          text: 'No se puede ingresar temporalmente a este sitio.',
          color: 'error'
        })
      }
    }catch(error){
      next({ path: '/login' })
      store.dispatch('show_snackbar', {
        text: 'No se puede ingresar temporalmente a este sitio.',
        color: 'error'
      })
    }
  } else {
    next()
  }
  window.scrollTo(0, 0)
})

function is_auth () {
  const token = JSON.parse(localStorage.getItem('token'))
  return !jwt.isExpired(token)
}

export default router
