<template>
  <div>
    <!-- class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0" -->
    <v-row  class="py-2">
      <!-- Titulo -->
      <v-col cols="12" class="d-flex justify-end align-center py-1">
        <div v-if="nuevaPromo == 1">Nueva Promoción</div>
        <div v-else>Ver Promoción Nro <strong>{{ $route.params.id }}</strong></div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
            v-if="nuevaPromo == 0"
            :disabled="tienePermiso == 0 && selectedEstado.estado_id != 2"
          ></v-switch>
        </v-expand-x-transition>
      </v-col>
      <!-- Descripción, Vigente y Estado -->
      <v-col cols="12" sm="10" :md="nuevaPromo == 1 ? 10 : 10" class="py-2">
        <v-text-field
          v-model="descripcion"
          outlined
          dense
          type="text"
          hide-details
          label="Descripción"
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="1" md="2" class="py-2 d-flex" v-if="nuevaPromo == 1">
        <v-checkbox
          v-model="vigente"
          :true-value="1"
          :false-value="0"
          hide-details
          dense
          label="Vigente"
          class="mt-0"
          :disabled="tienePermiso == 0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" sm="2" md="2" class="py-2" v-if="nuevaPromo == 0">
        <v-autocomplete
          v-model="selectedEstado"
          item-text="estado_nombre"
          :items="estados"
          hide-details
          outlined
          dense
          return-object
          label="Estado"
          :disabled="editar == false || tienePermiso == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <!-- Descuento, Fechas y Cupones -->
      <v-col cols="6" sm="6" md="4" class="py-2">
        <v-text-field
          v-model="descuento"
          outlined
          dense
          type="number"
          hide-details
          label="Descuento"
          suffix="%"
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" md="2" class="py-2">
        <FechaPicker
            v-model="fechaInicio"
            hideDetails
            label="Fecha Inicio"
            :disabled="editar == false && nuevaPromo == 0"
          />
      </v-col>
      <v-col cols="6" sm="3" md="2" class="py-2">
        <FechaPicker
            v-model="fechaFin"
            hideDetails
            label="Fecha Fin"
            :disabled="editar == false && nuevaPromo == 0"
          />
      </v-col>
      <v-col cols="6" sm="2" md="2" class="py-2 d-flex" :class="pantallaChica ?  '' : 'justify-start pr-0'">
        <v-checkbox
          v-model="conCupones"
          :true-value="1"
          :false-value="0"
          hide-details
          dense
          class="mt-0"
          label="Cupones"
          :disabled="nuevaPromo == 0 && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" sm="2" md="2" class="py-2">
        <v-text-field
          v-model="cantCupones"
          outlined
          dense
          type="number"
          hide-details
          label="Cant. Cupones"
          :readonly="conCupones == 0 || nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="6" class="py-2">
        <v-text-field
          v-model="promoIdCupo"
          outlined
          dense
          type="number"
          hide-details
          label="Cupo en Base a Promo"
          :readonly="!editar && nuevaPromo == 0 && !cupos "
          :filled="(!editar && nuevaPromo == 0) || tieneCupo"
          :disabled="tieneCupo"
          @change="buscarPromoId()">
          <template v-slot:append-outer v-if="(!tieneCupo && editar) || nuevaPromo == 1">
            <v-icon
              class="mt-1"
              color="success"
              title="Buscar Promoción"
              small
              @click="dialogBuscarPromo = true;"
              :disable="tieneCupo">
              fas fa-search
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="9" sm="3" md="3" class="py-2">
        <div class="d-flex justify-around">
          <v-text-field
            v-model="cupos"
            outlined
            dense
            type="number"
            hide-details
            label="Cupos"
            :readonly="!editar && nuevaPromo == 0"
            :filled="(!editar && nuevaPromo == 0) || tienePromoIdCupo"
            :disabled="tienePromoIdCupo"
          ></v-text-field>
          <v-tooltip bottom v-if="nuevaPromo == 0" class="align-center">
            <template v-slot:activator="{ on }">
              <v-icon :disabled="tienePromoIdCupo" small color="info" v-on="on" class="pl-2"> fas fa-info-circle </v-icon>
            </template>
            <span>Cantidad vendida: <strong>{{ cant_vendida_cupo }}</strong></span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="3" sm="2" md="3" class="py-2">
        <v-checkbox
          v-model="cupoEstricto"
          label="Cupo estricto"
          dense
          hide-details
          :true-value="1"
          :false-value="null"
          :readonly="!editar && nuevaPromo == 0"
          :filled="!editar && nuevaPromo == 0"
          :disabled="tienePromoIdCupo"
        ></v-checkbox>
      </v-col>
      <!-- Cantidad min y max, Importe min y max -->
      <v-col cols="12" sm="3" md="2" class="py-2">
        <v-text-field
          v-model="cantMin"
          outlined
          dense
          type="number"
          hide-details
          label="Cantidad Min."
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="2" class="py-2">
        <v-text-field
          v-model="cantMax"
          outlined
          dense
          type="number"
          hide-details
          label="Cantidad Max."
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="2" class="py-2">
        <v-text-field
          v-model="importeMin"
          outlined
          dense
          type="number"
          hide-details
          label="Importe Min."
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="2" class="py-2">
        <v-text-field
          v-model="importeMax"
          outlined
          dense
          type="number"
          hide-details
          label="Importe Max."
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="py-2">
        <v-autocomplete
          v-model="monedasSelect"
          item-text="moneda_nombre"
          :items="monedas"
          hide-details
          outlined
          dense
          return-object
          label="Moneda"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
          :clearable="editar || nuevaPromo == 1"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="my-2">
      <!-- Empresas y Canales -->
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedEmpresa"
          item-text="nombre"
          :items="empresas"
          hide-details
          outlined
          dense
          return-object
          label="Empresas"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedCanal"
          item-text="canal_nombre"
          :items="canales"
          hide-details
          outlined
          dense
          return-object
          label="Canales"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <!-- Sucursales y Locales -->
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedSucursal"
          item-text="nombre"
          :items="sucursales"
          hide-details
          outlined
          dense
          return-object
          label="Sucursales"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedLocal"
          item-text="local_nombre"
          :items="locales"
          hide-details
          outlined
          dense
          return-object
          label="Locales"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <!-- Dias y Categorias -->
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedDia"
          item-text="dia_nombre"
          :items="dias"
          hide-details
          outlined
          dense
          return-object
          label="Dias"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="py-2">
        <v-autocomplete
          v-model="selectedCategoria"
          item-text="categoria_nombre"
          :items="categorias"
          hide-details
          outlined
          dense
          return-object
          label="Categorias"
          multiple
          deletable-chips
          small-chips
          :readonly="editar == false && nuevaPromo == 0"
          :filled="editar == false && nuevaPromo == 0"
          :clearable="editar || nuevaPromo == 1"
          @change="controlTodasCat('selectedCategoria')"
        ></v-autocomplete>
      </v-col>
      <!-- Articulos -->
      <v-col cols="12" class="py-2" >
        <v-expansion-panels v-model="panelA" class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-3 px-4 text-h5" style="cursor: default">
              <template v-slot:actions>
                <v-btn icon @click.stop="panelA = panelA === 1? 0 : 1">
                  <v-icon> fas fa-chevron-down </v-icon>
                </v-btn>
              </template>
              <div :class="pantallaChica? 'text-h6' : ''">
                Artículos
                <v-btn title="Buscar artículo" icon @click="getTodosArticulos(1, 'load')" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <v-row v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)" class="px-0" >
                <v-col cols="12" sm="2" md="2" class="py-2">
                  <v-text-field
                    v-model="filtroArticulos.codigo"
                    outlined
                    dense
                    hide-details
                    type="number"
                    label="Código"
                    :id="artRef"
                    :ref="artRef"
                    @blur="addArticulo2({}, 0, 1)"
                    @keypress.native.enter="$refs[artRef].blur()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5" class="py-2" >
                  <v-text-field
                    v-model="filtroArticulos.nombre"
                    outlined
                    dense
                    type="text"
                    label="Nombre"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3" class="py-2">
                  <SubirArchivos
                    :datos="objArchivo"
                    @accion="recibirCodigos"
                  />
                </v-col>
                <v-col cols="5" sm="2" md="2" class="py-1 d-flex justify-end">
                  <v-btn 
                    color="primary" 
                    title="Agregar Artículo"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-0">
                  <!-- Listado de Artículos -->
                  <v-data-table
                    class="cebra elevation-2"
                    :headers="permisoBono == 0 ? headers : headers2"
                    :items="articulos"
                    :loading="load"
                    dense
                    :search="search"
                  >
                    <!-- Buscador en el datatable -->
                    <template v-slot:top>
                      <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="12" sm="6" md="3">
                          <SearchDataTable
                            v-model="search"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        No se agregaron artículos.
                      </v-alert>
                    </template>
                    <!-- Items -->
                    <template v-slot:[`item.bono`]="{ item }" v-if="(nuevaPromo == 1 && permisoBono == 1) || (nuevaPromo == 0 && permisoBono == 1)">
                      <v-row class="d-flex justify-center" no-gutters>
                        <v-text-field 
                          :id="reconRef"
                          :ref="reconRef"
                          v-model="item.bono" 
                          type="number"
                          outlined 
                          dense 
                          hide-details 
                          style="max-width: 80px"
                          :readonly="(nuevaPromo == 0 && editar == false) || (permisoBono == 0 && nuevaPromo == 0)"
                          suffix="%"
                          @blur="validarPorcentaje(item)"
                          @keypress.native.enter="$refs[reconRef].blur()"
                        ></v-text-field>
                      </v-row>
                    </template>
                    <!-- Acciones -->
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="error" @click="quitarArticulo(item,1)" v-on="on">
                            <v-icon small>far fa-times-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Artículos Exceptuados -->
      <v-col cols="12">
        <v-expansion-panels v-model="panelE" class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-3 px-4 text-h5" style="cursor: default">
              <div :class="pantallaChica? 'text-h6' : ''">
                Artículos Exceptuados
                <v-btn title="Buscar artículo" icon @click="getTodosArticulos(2, 'loadd')" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panelE = panelE === 1? 0 : 1">
                  <v-icon> fas fa-chevron-down </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <v-row v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                <v-col cols="12" sm="2" md="2" class="py-2">
                  <v-text-field
                    v-model="filtroExceptuados.codigo"
                    outlined
                    dense
                    hide-details
                    type="number"
                    label="Código"
                    :id="artRefExcep"
                    :ref="artRefExcep"
                    @blur="addArticulo2({}, 0, 2)"
                    @keypress.native.enter="$refs[artRefExcep].blur()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5" class="py-2">
                  <v-text-field
                    v-model="filtroExceptuados.nombre"
                    outlined
                    dense
                    hide-details
                    type="text"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3" class="py-2">
                  <SubirArchivos
                    :datos="objArchivoExceptuados"
                    @accion="recibirCodigosExceptuados"
                  />
                </v-col>
                <v-col cols="5" sm="2" md="2" class="py-1 d-flex justify-end">
                  <v-btn 
                    color="primary" 
                    title="Agregar Artículo"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-0">
                  <!-- Listado de Artículos Exceptuados -->
                  <v-data-table
                    class="cebra elevation-2"
                    :headers="headerss"
                    :items="articulosExcep"
                    :loading="loadd"
                    dense
                    :search="searchh"
                  >
                    <!-- Buscador en el datatable -->
                    <template v-slot:top>
                      <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="12" sm="6" md="3">
                          <SearchDataTable
                            v-model="searchh"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        No se agregaron artículos.
                      </v-alert>
                    </template>
                    <!-- Acciones -->
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="error" @click="quitarArticulo(item,2)" v-on="on">
                            <v-icon class="" small>far fa-times-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Combos -->
      <v-col cols="12" class="py-2" >
        <v-expansion-panels v-model="panelC" class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-3 px-4 text-h5" style="cursor: default">
              <div :class="pantallaChica? 'text-h6' : ''">
                Combos
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panelC = panelC === 1? 0 : 1">
                  <v-icon> fas fa-chevron-down </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-radio-group
                    v-model="tipoCombo"
                    row
                    @change="controlarTipoCombo()"
                    :disabled="nuevaPromo == 0 && !editar"
                    class="pa-0"
                    hide-details
                  >
                    <v-radio
                      label="Por Categoría"
                      value="categorias"
                    ></v-radio>
                    <v-radio
                      label="Por Artículo"
                      value="articulos"
                    ></v-radio>
                  </v-radio-group>
                  <v-btn small icon @click="limpiarCombos()" title="Limpiar" color="orange" :disabled="nuevaPromo == 0 && !editar"><v-icon>fas fa-broom</v-icon> </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="tipoCombo && tipoCombo === 'articulos'" class="px-0" >
                <v-col cols="12" class="px-0" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar)">
                  <v-row>
                    <v-col cols="12" sm="2" md="2" class="py-2">
                      <v-text-field
                        v-model="filtroCombo.codigo"
                        outlined
                        dense
                        hide-details
                        type="number"
                        label="Código"
                        :id="artRef2"
                        :ref="artRef2"
                        @blur="addArticulo2({},0,3)"
                        @keypress.native.enter="$refs[artRef2].blur()"
                      >
                        <template v-slot:append-outer>
                          <v-btn small icon title="Buscar artículo">
                            <v-icon @click="getTodosArticulos(3,'loadC')" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar)" color="info" small>fas fa-search</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-2" >
                      <v-text-field
                        v-model="filtroCombo.nombre"
                        outlined
                        dense
                        type="text"
                        label="Nombre"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7" sm="3" md="3" class="py-2">
                      <SubirArchivos
                        :datos="objArchivoCombos"
                        @accion="recibirCodigosCombos"
                      />
                    </v-col>
                    <v-col cols="5" sm="2" md="2" class="py-1 d-flex justify-end">
                      <v-btn 
                        color="primary" 
                        title="Agregar Artículo"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="px-0">
                  <!-- Listado de Artículos -->
                  <v-data-table
                    class="cebra elevation-2 mt-3"
                    :headers="headers"
                    :items="combos"
                    :loading="loadC"
                    dense
                    :search="searchC"
                  >
                    <!-- Buscador en el datatable -->
                    <template v-slot:top>
                      <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="12" sm="6" md="3">
                          <SearchDataTable
                            v-model="searchC"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        No se agregaron artículos.
                      </v-alert>
                    </template>
                    <!-- Acciones -->
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="error" @click="quitarArticulo(item,3)" v-on="on">
                            <v-icon small>far fa-times-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-if="tipoCombo && tipoCombo === 'categorias'">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="combos"
                    item-text="categoria_nombre"
                    :items="categorias"
                    hide-details
                    outlined
                    dense
                    return-object
                    label="Categorias"
                    multiple
                    deletable-chips
                    small-chips
                    :disabled="nuevaPromo == 0 && !editar"
                    clearable
                    @change="controlTodasCat('combos')"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Formas de Pago -->
      <v-col cols="12" class="py-2" >
        <v-expansion-panels v-model="panelFP" class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-3 px-4 text-h5" style="cursor: default">
              <div :class="pantallaChica? 'text-h6' : ''">
                Formas de Pago
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panelFP = panelFP === 1? 0 : 1">
                  <v-icon> fas fa-chevron-down </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="formasPagoSelect"
                    item-text="forma_pago_nombre"
                    :items="formasPago"
                    hide-details
                    outlined
                    dense
                    return-object
                    label="Formas Pago"
                    multiple
                    deletable-chips
                    small-chips
                    :disabled="nuevaPromo == 0 && !editar"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Botones -->
      <v-col cols="12" class="d-flex justify-end mx-2 py-1">
        <v-btn color="warning" @click="volverIndex()" class="mr-2">Volver</v-btn>
        <BtnConfirmar
          @action="sendPromocion()"
          color="success"
          :texto="`¿Esta seguro de registrar la nueva Promoción?`"
          nombre="Guardar"
          v-if="nuevaPromo == 1"
        />
        <BtnConfirmar
          @action="updatePromocion()"
          color="success"
          :texto="`¿Esta seguro de aplicar los cambios en la promoción ${promoId}?`"
          nombre="Guardar Cambios"
          v-if="nuevaPromo == 0 && editar"
        />
      </v-col>
    </v-row>
    <!-- Modal Seleccionar Articulo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Seleccionar Articulo Exceptuado -->
    <ModalSelectArticulo 
      :datos="dialogSelectArtExcep"
      @setearModalSelectArt="setModalSelectArtExcep"
      @setearArticulo="setArticuloExcep"
    />
    <!-- Modal Seleccionar Articulo Combo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArtCombo"
      @setearModalSelectArt="setModalSelectArtCombo"
      @setearArticulo="setArticuloCombo"
    />
    <!-- Modal Buscar Promociones asociada al cupo -->
    <ModalBuscarPromo
      v-model="dialogBuscarPromo"
      :promociones="promos"
      @seleccionarPromo="seleccionarPromo"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import moment from 'moment'
import { roundNumber, cadenaVacia, order_list_by } from '../../util/utils'
import router from '../../router'
import download from 'downloadjs'
import { mapState } from 'vuex'
import SubirArchivos from '../../components/util/SubirArchivos.vue'
import TextFieldMoney from '../../components/util/TextFieldMoney.vue'
import ModalBuscarPromo from '../../components/promociones/ModalBuscarPromo.vue'

export default {
  data() {
    return {
      nuevaPromo: 0,
      promoId: 0,
      editar: false,
      descripcion: '',
      descripcionCopia: '',
      vigente: 0,
      descuento: '',
      descuentoCopia: '',
      cantMin: '',
      cantMinCopia: '',
      cantMax: '',
      cantMaxCopia: '',
      importeMin: '',
      importeMinCopia: '',
      importeMax: '',
      importeMaxCopia: '',
      fechaInicio: moment(new Date()).format('DD/MM/YYYY'),
      fechaInicioCopia: '',
      fechaFin: moment(new Date()).format('DD/MM/YYYY'),
      fechaFinCopia: '',
      conCupones: 0,
      conCuponesCopia: 0,
      cantCupones: '',
      cantCuponesCopia: '',
      selectedEmpresa: [],
      selectedEmpresaCopia: [],
      empresas: [],
      selectedCanal: [],
      selectedCanalCopia: [],
      canales: [],
      selectedSucursal: [],
      selectedSucursalCopia: [],
      selectedLocal: [],
      selectedLocalCopia: [],
      locales: [],
      selectedDia: [],
      selectedDiaCopia: [],
      dias: [],
      selectedCategoria: [],
      selectedCategoriaCopia: [],
      categorias: [],
      //artCodigo: '',
      //artNombre: '',
      headers: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers2: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Bono', align: 'center', value: 'bono' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      articulosCopy: [],
      articulosExcep: [],
      articulosExcepCopia: [],
      load: false,
      loadd: false,
      loadC: false,
      search: '',
      searchh: '',
      searchC: '',
      tienePermiso: 0,
      permisoBono: 0,
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      dialogSelectArtExcep: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos'
      },
      dialogSelectArtCombo: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos'
      },
      artRef: "artRef",
      artRefExcep: "artRefExcep",
      artRef2: "artRef2",
      reconRef: 'reconRef',
      selectedEstado: {},
      selectedEstadoCopia: {},
      estados: [],
      artCodigoExcep: '',
      artNombreExcep: '',
      bonoProm: '',
      objArchivo:{
        tipos: ['.txt', '.xls', '.xlsx'], 
        cantidad: 1,
        color: 'rowsel2',
      },
      objArchivoExceptuados:{
        tipos: ['.txt', '.xls', '.xlsx'], 
        cantidad: 1,
        color: 'rowsel2',
      },
      objArchivoCombos:{
        tipos: ['.txt', '.xls', '.xlsx'], 
        cantidad: 1,
        color: 'rowsel2',
      },
      pantallaChica: this.$vuetify.breakpoint.xs,
      panelA: 0,
      panelE: 0,
      panelC: 0,
      combos: [],
      combosCopia: [],
      tipoCombo: null,
      tipoComboCopia: null,
      filtroArticulos:{
        codigo: null,
        nombre: null,
      },
      filtroExceptuados:{
        codigo: null,
        nombre: null,
      },
      filtroCombo:{
        codigo: null,
        nombre: null,
      },
      allArticulos: [],
      cupos: null,
      cuposCopia: null,
      promoIdCupo: null,
      promoIdCupoCopia: null,
      cant_vendida_cupo: 0,
      cupoEstricto: null,
      cupoEstrictoCopia: null,
      panelFP: 0,
      formasPago: [],
      formasPagoSelect: [],
      formasPagoSelectCopia: [],
      tienePromoIdCupo: false,
      tieneCupo: false,
      dialogBuscarPromo: false,
      promos: [],
      monedas: [],
      monedasSelect: [],
      monedasSelectCopia: [],
    }
  },
  components: {
    FechaPicker,
    SearchDataTable,
    ModalSelectArticulo,
    BtnConfirmar,
    SubirArchivos,
    TextFieldMoney,
    ModalBuscarPromo
  },
  created () {
    if (this.$route.hasOwnProperty('params') && Object.keys(this.$route.params).length > 0){
      this.nuevaPromo = 0
      this.promoId = this.$route.params.id
    }else this.nuevaPromo = 1
    this.initForm()
  },
  computed: {
    ...mapState('genericosAcc', ['sucursales'])
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      await this.$store.dispatch('genericosAcc/get_sucursales')
      let initPeticion = await this.$store.dispatch('promociones/initFormUnaPromo', {nuevo: this.nuevaPromo, promocion_id: this.promoId})
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      // canales
      this.canales      = initPeticion.canales
      // locales
      this.locales      = initPeticion.locales
      // dias
      this.dias         = initPeticion.dias
      // categorias
      this.categorias   = initPeticion.categorias
      // permiso
      this.tienePermiso = initPeticion.permiso
      // estados
      this.estados      = initPeticion.estados
      // permiso para ver / modificar el bono
      this.permisoBono  = initPeticion.permiso_bono
      // formas de pago
      this.formasPago   = initPeticion.formasPago; order_list_by(this.formasPago, 'forma_pago_nombre');
      // monedas
      this.monedas      = initPeticion.monedas; order_list_by(this.monedas, 'moneda_nombre');
      // promociones para la lupita
      this.promos       = initPeticion.promociones_l;
      // esta por editar?
      if (this.nuevaPromo == 0)  this.completarForm(initPeticion.promocion)
    },
    async completarForm(promo){
      let msjError = ``
      // descripcion
      this.descripcion      = promo.promocion.descripcion
      this.descripcionCopia = JSON.parse(JSON.stringify(this.descripcion));
      // estado
      this.vigente = promo.promocion.estado == 1 ? 1 : 0
      let unEstado = this.estados.filter(element => element.estado_id == promo.promocion.estado)
      if (unEstado.length > 0){
        this.selectedEstado      = unEstado[0];
        this.selectedEstadoCopia = JSON.parse(JSON.stringify(this.selectedEstado));
      } 
      // descuento
      this.descuento      = roundNumber(promo.promocion.descuento, 4)
      this.descuentoCopia = JSON.parse(JSON.stringify(this.descuento));
      // fecha inicio y fin
      this.fechaInicio = moment(promo.promocion.fecha_inicio).format("DD/MM/YYYY")
      this.fechaInicioCopia = JSON.parse(JSON.stringify(this.fechaInicio));
      this.fechaFin = moment(promo.promocion.fecha_fin).format("DD/MM/YYYY")
      this.fechaFinCopia = JSON.parse(JSON.stringify(this.fechaFin));
      // con cupones?
      this.conCupones = promo.promocion.cupon == 1 ? 1 : 0
      this.conCuponesCopia = JSON.parse(JSON.stringify(this.conCupones));
      // cantidad de cupones
      this.cantCupones = promo.promocion.cant_cupones == null ? '' : promo.promocion.cant_cupones == 0 ? '' : promo.promocion.cant_cupones
      this.cantCuponesCopia = JSON.parse(JSON.stringify(this.cantCupones));
      // cantidad minima y maxima
      this.cantMin = promo.promocion.cantidad_minima == null ? '' : promo.promocion.cantidad_minima == 0 ? '' : promo.promocion.cantidad_minima
      this.cantMinCopia = JSON.parse(JSON.stringify(this.cantMin));
      this.cantMax = promo.promocion.cantidad_maxima == null ? '' : promo.promocion.cantidad_maxima == 0 ? '' : promo.promocion.cantidad_maxima
      this.cantMaxCopia = JSON.parse(JSON.stringify(this.cantMax));
      // importe minimo y maximo
      this.importeMin = promo.promocion.importe_min == null ? '' : promo.promocion.importe_min == 0 ? '' : roundNumber(promo.promocion.importe_min, 2)
      this.importeMinCopia = JSON.parse(JSON.stringify(this.importeMin));
      this.importeMax = promo.promocion.importe_max == null ? '' : promo.promocion.importe_max == 0 ? '' : roundNumber(promo.promocion.importe_max, 2)
      this.importeMaxCopia = JSON.parse(JSON.stringify(this.importeMax));
      // empresas
      if (promo.empresas.length > 0){
        let error = 0
        for (let id in promo.empresas){
          let unaEmp = this.empresas.filter(element => element.id == promo.empresas[id].empresa_codigo)
          if (unaEmp.length > 0) this.selectedEmpresa.push(unaEmp[0])
          else error = error + 1
        }
        this.selectedEmpresaCopia = JSON.parse(JSON.stringify(this.selectedEmpresa));
        if (parseInt(error) > 0) msjError = msjError + `Hubieron Empresas de la promoción que no se pudieron mostrar.`
      }
      // canales
      if (promo.canales.length > 0){
        let error = 0
        for (let id in promo.canales){
          let unCanal = this.canales.filter(element => element.canal_id == promo.canales[id].canal_id)
          if (unCanal.length > 0) this.selectedCanal.push(unCanal[0])
          else error = error + 1
        }
        this.selectedCanalCopia = JSON.parse(JSON.stringify(this.selectedCanal));
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos canales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos canales de la promoción.`
        }
      }
      // sucursales
      if (promo.sucursales.length > 0){
        let error = 0
        for (let id in promo.sucursales){
          let unaSuc = this.sucursales.filter(element => element.id == promo.sucursales[id].sucursal_codigo)
          if (unaSuc.length > 0) this.selectedSucursal.push(unaSuc[0])
          else error = error + 1
        }
        this.selectedSucursalCopia = JSON.parse(JSON.stringify(this.selectedSucursal));
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunas sucursales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunas sucursales de la promoción`
        }
      }
      // locales
      if (promo.locales.length > 0){
        let error = 0
        for (let id in promo.locales){
          let unLocal = this.locales.filter(element => element.local_codigo == promo.locales[id].local_codigo)
          if (unLocal.length > 0) this.selectedLocal.push(unLocal[0])
          else error = error + 1
        }
        this.selectedLocalCopia = JSON.parse(JSON.stringify(this.selectedLocal));
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos locales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos locales de la promoción.`
        }
      }
      // dias
      if (promo.dias.length > 0){
        let error = 0
        for (let id in promo.dias){
          let unDia = this.dias.filter(element => element.dia_id == promo.dias[id].dia_id)
          if (unDia.length > 0) this.selectedDia.push(unDia[0])
          else error = error + 1
        }
        this.selectedDiaCopia = JSON.parse(JSON.stringify(this.selectedDia));
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos días de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos días de la promoción.`
        }
      }
      // categorias
      if (promo.categorias.length > 0){
        let error = 0
        for (let id in promo.categorias){
          let unaCateg = this.categorias.filter(element => element.categoria_codigo == promo.categorias[id].categoria_codigo)
          if (unaCateg.length > 0) this.selectedCategoria.push(unaCateg[0])
          else error = error + 1
        }
        this.selectedCategoriaCopia = JSON.parse(JSON.stringify(this.selectedCategoria));
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunas categorías de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunas categorías de la promoción.`
        }
      }
      // artículos
      this.load  = true
      this.loadd = true
      this.loadC = true;
      let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
      this.load  = false
      this.loadd = false
      this.loadC = false;
      if (articulosPeticion.resultado == 1){
        this.dialogSelectArticulo.articulos = articulosPeticion.articulos
        this.dialogSelectArtExcep.articulos = articulosPeticion.articulos
        this.dialogSelectArtCombo.articulos = articulosPeticion.articulos
        this.allArticulos                   = articulosPeticion.articulos
      }else{
        return this.$store.dispatch('show_snackbar', { text: articulosPeticion.msj, color: 'error' })
      }
      if (promo.articulos.length > 0){
        let error = 0
        for (let id in promo.articulos){
          let unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_codigo == promo.articulos[id].articulo_codigo)
          if (unArt.length > 0){
            unArt[0].bono = roundNumber(promo.articulos[id].bono, 2)
            this.articulos.push(unArt[0])
          }
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos artículos de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos artículos de la promoción.`
        }
      }
      this.panelA = this.articulos.length > 0 ? 0 : 1;
      this.articulosCopy = JSON.parse(JSON.stringify(this.articulos))

      // artículos exceptuados
      if (promo.articulos_exceptuados.length > 0){
        let error = 0
        for (let id in promo.articulos_exceptuados){
          let unArt = this.dialogSelectArtExcep.articulos.filter(element => element.articulo_codigo == promo.articulos_exceptuados[id].articulo_codigo)
          if (unArt.length > 0) this.articulosExcep.push(unArt[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos artículos exceptuados de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos artículos exceptuados de la promoción.`
        }
      }
      this.panelE = this.articulosExcep.length > 0 ? 0 : 1;
      this.articulosExcepCopia = JSON.parse(JSON.stringify(this.articulosExcep));

      //combos de los articulos
      this.combos      = promo.combos;
      this.combosCopia = JSON.parse(JSON.stringify(this.combos));
      if(this.combos.length > 0 && this.combos[0].hasOwnProperty('articulo_codigo')){
        this.tipoCombo = 'articulos';
      }
      if(this.combos.length > 0 && !this.combos[0].hasOwnProperty('articulo_codigo')){
        this.tipoCombo = 'categorias';
      }
      this.tipoComboCopia = JSON.parse(JSON.stringify(this.tipoCombo));
      this.panelC = this.combos.length == 0 ? 1 : 0;

      //Formas de Pago
      this.formasPagoSelect      = promo.formasPago;
      this.formasPagoSelectCopia = JSON.parse(JSON.stringify(this.formasPagoSelect));
      this.panelFP               = this.formasPagoSelect.length == 0 ? 1 : 0;

      //Cupo
      this.cupos             = promo.promocion.cupo;
      this.cuposCopia        = JSON.parse(JSON.stringify(this.cupos));
      this.cant_vendida_cupo = promo.promocion.cant_vendida

      //cupoEstricto
      this.cupoEstricto      = promo.promocion.cupo_estricto
      this.cupoEstrictoCopia = JSON.parse(JSON.stringify(this.cupoEstricto));

      //Promo ID Cupo
      this.promoIdCupo      = promo.promocion.promo_id_cupo;
      this.promoIdCupoCopia = JSON.parse(JSON.stringify(this.promoIdCupo));

      // Monedas
      this.monedasSelect      = promo.monedas;
      this.monedasSelectCopia = JSON.parse(JSON.stringify(this.monedasSelect));


      if (!cadenaVacia(msjError)){
        this.$store.dispatch('show_snackbar', {
          text: msjError,
          color: 'error',
        })
      }
    },
    volverIndex(){
      router.push({path: '/promociones'})
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo.activo = value
    },
    setModalSelectArtExcep(value){
      this.dialogSelectArtExcep.activo = value
    },
    setModalSelectArtCombo(value){
      this.dialogSelectArtCombo.activo = value
    },
    setArticulo(art){
      //this.addArticulo(art, 1)
      this.addArticulo2(art,1,1);
    },
    setArticuloExcep(art){
      //this.addArticuloExcep(art, 1)
      this.addArticulo2(art,1,2);
    },
    setArticuloCombo(art){
      //this.addArticuloCombo(art, 1);
      this.addArticulo2(art,1,3);
    },
    async getAllArticulos(nombre, load){
      // obtengo los articulos
      if (this.dialogSelectArticulo.articulos.length == 0 && this.dialogSelectArtExcep.articulos.length == 0 && this.dialogSelectArtCombo.articulos.length == 0){
        this[load] = true;
        let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
        this[load] = false;
        
        if (articulosPeticion.resultado == 1){
          this[nombre].articulos = articulosPeticion.articulos
          this.allArticulos      = articulosPeticion.articulos;
        }else{
          return this.$store.dispatch('show_snackbar', { text: articulosPeticion.msj, color: 'error' })
        }
      }
      
      if(this.dialogSelectArticulo.articulos.length != 0){
        this.dialogSelectArtExcep.articulos = this.allArticulos;
        this.dialogSelectArtCombo.articulos = this.allArticulos;
      }
      if(this.dialogSelectArtExcep.articulos.length != 0){
        this.dialogSelectArticulo.articulos = this.allArticulos;
        this.dialogSelectArtCombo.articulos = this.allArticulos;
      }
      if(this.dialogSelectArtCombo.articulos.length != 0){
        this.dialogSelectArtExcep.articulos = this.allArticulos;
        this.dialogSelectArticulo.articulos = this.allArticulos;
      }
    },
    async getTodosArticulos(opcion, load){
      let nombre = 'dialogSelectArticulo';
      switch(opcion){
        case 2: nombre = 'dialogSelectArtExcep'; break;
        case 3: nombre = 'dialogSelectArtCombo'; break;
      }

      await this.getAllArticulos(nombre, load);

      // abro el modal
      this[nombre].activo = true
    },
    async addArticulo2(art, opcion, seccion){
      let objDialog = 'dialogSelectArticulo';
      let filtro    = 'filtroArticulos';
      let loadBool  = 'load';

      switch(seccion){
        case 2: {
          objDialog = 'dialogSelectArtExcep';
          filtro    = 'filtroExceptuados';
          loadBool  = 'loadd';
        } break;
        case 3: {
          objDialog = 'dialogSelectArtCombo';
          filtro    = 'filtroCombo';
          loadBool  = 'loadC';
        } break;
      }

      let articulo = {}
      if (opcion == 0){
        // viene del mismo formulario, ingresaron el código del artículo?
        if (!this[filtro].codigo || this[filtro].codigo == 0){
          this[filtro].codigo = null;
          return this.$store.dispatch('show_snackbar', { text: 'Ingrese un Código de Artículo válido.', color: 'info' });
        }
        // obtengo los articulos si no tengo cargado el array aun
        if (this[objDialog].articulos.length == 0){
          await this.getAllArticulos(objDialog, loadBool);
        }
        // hasta aqui ya tengo mis articulos, obtengo los datos solicitados
        let unArt = this[objDialog].articulos.filter(element => element.articulo_codigo == this[filtro].codigo)
        if (unArt.length > 0) articulo = unArt[0]
        else{
          this.getTodosArticulos(seccion, loadBool);
          this[filtro].codigo = null;
          return this.$store.dispatch('show_snackbar', { text: 'No se encontró el artículo solicitado.', color: 'info' })
        }
      }else articulo = art
      
      this[filtro].nombre = articulo.articulo_nombre
      if(seccion == 1){
        // 19-07-2024 por MMURILLO, agregamos el campo bono
        articulo.bono = (cadenaVacia(this.bonoProm) || this.bonoProm == 0) ? 0 : roundNumber(this.bonoProm, 2)
        if (roundNumber(articulo.bono, 2) < 0 || roundNumber(articulo.bono, 2) >= 100){
          return this.$store.dispatch('show_snackbar', { text: 'Porcentaje inválido. Intente otro valor.', color: 'info' })
        }
      }

      if(seccion != 3){
        // hasta este punto tengo que tener articulo con datos, verifico si ya se encuentra en el listado
        let unArt    = this.articulos.filter(element => element.articulo_codigo == articulo.articulo_codigo)
        // verifico si se encuentra en el listado de artículos exceptuados
        let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == articulo.articulo_codigo)
        if (unArt.length > 0 || artExcep.length > 0){
          this.bonoProm = '';
          this[filtro].codigo = null;
          this[filtro].nombre = null;
          return this.$store.dispatch('show_snackbar', {
            text: 'El artículo '+articulo.articulo_codigo+' ya se encuentra en el listado o bien se encuentra agregado en los artículos exceptuados.',
            color: 'warning',
          });
        }else{
          if(seccion == 1) this.articulos.push(articulo)
          if(seccion == 2) this.articulosExcep.push(articulo)
          this.bonoProm = ''
        }
      }else{
        let pos = this.combos.map(e => e.articulo_codigo).indexOf(articulo.articulo_codigo);
        if(pos != -1){
          this[filtro].codigo = null;
          this[filtro].nombre = null;
          return this.$store.dispatch('show_snackbar', { text: 'El artículo '+articulo.articulo_codigo+' ya se encuentra en el listado', color: 'warning' });
        }
        this.combos.push(articulo);
      }
      this.$store.dispatch('show_snackbar', { text: 'El artículo '+articulo.articulo_codigo+' se agregó correctamente al listado.', color: 'success' });
      this[filtro].codigo = null;
      this[filtro].nombre = null;
    },
    quitarArticulo(item, seccion){
      let array = [];
      switch(seccion){
        case 1: array = 'articulos'; break;
        case 2: array = 'articulosExcep'; break;
        case 3: array = 'combos'; break;
      }
      let pos = this[array].indexOf(item)
      this[array].splice(pos, 1)
    },
    async recibirCodigos(codigos){
      let art = {};
      let articulosEncontradosRepetidos = [];
      let articulosNoEncontrados        = [];

      // obtengo los articulos si no tengo cargado el array aun
      if (this.dialogSelectArticulo.articulos.length == 0){
        await this.getAllArticulos('dialogSelectArticulo', this.load);
      }
      for(let cod of codigos){
        const pos = this.dialogSelectArticulo.articulos.map(e => e.articulo_codigo).indexOf(cod);
        if(pos != -1){
          let unArt    = this.articulos.filter(element => element.articulo_codigo == cod)
          let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == cod)
          if(unArt.length > 0){
            articulosEncontradosRepetidos.push(unArt[0]);
          }else{
            if(artExcep.length > 0){
              articulosEncontradosRepetidos.push(artExcep[0]);
            }else{
              art = this.dialogSelectArticulo.articulos[pos];
              this.addArticulo2(art, 1, 1)
            }
          }
        }else articulosNoEncontrados.push(cod);
      }
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos no se encontraron: '+articulosNoEncontrados.join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length == 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: '+articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { 
          text: `Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: ${articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', ')}.
                 Los siguientes códigos no se encontraron: ${articulosNoEncontrados.join(', ')}`, 
          color: 'info' 
        })
      }
    },
    async recibirCodigosExceptuados(codigos){
      let art = {};
      let articulosEncontradosRepetidos = [];
      let articulosNoEncontrados        = [];

      // obtengo los articulos si no tengo cargado el array aun
      if (this.dialogSelectArtExcep.articulos.length == 0){
        await this.getAllArticulos('dialogSelectArtExcep', this.loadd);
      }
      for(let cod of codigos){
        const pos = this.dialogSelectArtExcep.articulos.map(e => e.articulo_codigo).indexOf(cod);
        if(pos != -1){
          let unArt    = this.articulos.filter(element => element.articulo_codigo == cod)
          let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == cod)
          if(unArt.length > 0){
            articulosEncontradosRepetidos.push(unArt[0]);
          }else{
            if(artExcep.length > 0){
              articulosEncontradosRepetidos.push(artExcep[0]);
            }else{
              art = this.dialogSelectArtExcep.articulos[pos];
              this.addArticulo2(art, 1, 2);
            }
          }
        }else articulosNoEncontrados.push(cod);
      }
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos no se encontraron: '+articulosNoEncontrados.join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length == 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: '+articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { 
          text: `Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: ${articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', ')}.
                 Los siguientes códigos no se encontraron: ${articulosNoEncontrados.join(', ')}`, 
          color: 'info' 
        })
      }
    },
    async recibirCodigosCombos(codigos){
      let art = {};
      let articulosEncontradosRepetidos = [];
      let articulosNoEncontrados        = [];

      // obtengo los articulos si no tengo cargado el array aun
      if (this.dialogSelectArtCombo.articulos.length == 0){
        await this.getAllArticulos('dialogSelectArtCombo', this.loadC);
      }
      this.$store.state.loading = true;
      for(let cod of codigos){
        const pos = this.dialogSelectArtCombo.articulos.map(e => e.articulo_codigo).indexOf(cod);
        if(pos != -1){
          //let unArt    = this.articulos.filter(element => element.articulo_codigo == cod)
          //let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == cod)
          let artCombo = this.combos.filter(e => e.articulo_codigo == cod)
          if(artCombo.length > 0){
            articulosEncontradosRepetidos.push(artCombo[0]);
          }else{
            if(artCombo.length > 0){
              articulosEncontradosRepetidos.push(artCombo[0]);
            }else{
              art = this.dialogSelectArtCombo.articulos[pos];
              await this.addArticulo2(art, 1, 3);
            }
          }
        }else articulosNoEncontrados.push(cod);
      }
      this.$store.state.loading = false;
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos no se encontraron: '+articulosNoEncontrados.join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length == 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { text: 'Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: '+articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', '), color: 'info' })
      }
      if(articulosNoEncontrados.length > 0 && articulosEncontradosRepetidos.length > 0){
        return this.$store.dispatch('show_snackbar', { 
          text: `Los siguientes códigos ya se encuentran en el listado de Artículos o en el listado de Artículos exceptuados: ${articulosEncontradosRepetidos.map(e=>e.articulo_codigo).join(', ')}.
                 Los siguientes códigos no se encontraron: ${articulosNoEncontrados.join(', ')}`, 
          color: 'info' 
        })
      }
    },
    controlarTipoCombo(){
      if(this.tipoCombo){
        this.combos = [];
      }
    },
    limpiarCombos(){
      this.tipoCombo = null; 
      this.combos    = [];
    },
    //en el array de categorias está "TODAS" opcion colocada por mi que permite seleccionar todas las categorias (excluye el obj TODAS)
    controlTodasCat(nombre){
      if (this[nombre] == undefined || this[nombre] == null) return
      // compruebo si eligieron TODAS
      let existe = this[nombre].filter(element => element.categoria_codigo == 0)
      if (existe.length > 0){
        this[nombre] = []
        this[nombre] = this.categorias.filter(element => element.categoria_codigo != 0)
      }
    },
    seleccionarPromo(item){
      this.promoIdCupo = item;
    },
    buscarPromoId(){
      if(this.promoIdCupo){
        let pos = this.promos.map(e => e.promocion_id).indexOf(parseInt(this.promoIdCupo));
        if(pos == -1){
          this.dialogBuscarPromo = true;
          this.promoIdCupo = null;
        }
      }
    },
    validarObligatorios(){
      // debe existir al menos una categoria o un artículo en el detalle
      if (this.selectedCategoria.length == 0 && this.articulos == 0) return {valido: false, msj: 'Seleccione una Categoria o un Artículo.'}
      
      // control de fechas
      let fechaDesde = moment(this.fechaInicio, "DD/MM/YYYY").toDate()
      let fechaHasta = moment(this.fechaFin, "DD/MM/YYYY").toDate()
      if (fechaDesde > fechaHasta) return {valido: false, msj: 'La fecha de fin debe ser mayor a la fecha de inicio.'}
      
      // estado
      if (Object.keys(this.selectedEstado).length == 0 && this.nuevaPromo == 0) return {valido: false, msj: 'Debe ingresar el estado de la promoción para continuar.'}
      
      // descuento
      if (!this.descuento || this.descuento <= 0 || this.descuento.toString().length == 0) return {valido: false, msj: 'Debe ingresar un descuento válido para continuar.'}
      
      // Cantidad Min
      if (this.cantMin && this.cantMin < 0){
        this.cantMin = 0;
        return {valido: false, msj: 'Debe ingresar un valor de cantidad mínima no negativo.'}
      }
      // Cantidad Max
      if (this.cantMax && this.cantMax < 0){
        this.cantMax = 0;
        return {valido: false, msj: 'Debe ingresar un valor de cantidad máxima no negativo.'}
      }
      // Cantidad Max > Cantidad Mín
      if ((this.cantMax && this.cantMin) && this.cantMin >= this.cantMax){
        this.cantMax = 0;
        return {valido: false, msj: 'Debe ingresar un valor de cantidad máxima superior a la mínima.'}
      }
      // Cupo
      if (this.cupos && this.cupos < 0){
        this.cupos = 0;
        return {valido: false, msj: 'Debe ingresar un valor de cupo no negativo.'}
      }
      //Cupo Estricto
      if(this.cupoEstricto && !this.cupos){
        this.cupo        = null;
        this.promoIdCupo = null;
        return {valido: false, msj: 'Debe ingresar un valor de cupo.'}
      }

      // PromoIdCupo
      if (this.promoIdCupo && this.promoIdCupo < 0){
        this.promoIdCupo = 0;
        return {valido: false, msj: 'Debe ingresar un ID de promo Válido para el cupo.'}
      }
      // Importe Mín
      if (this.importeMin && this.importeMin < 0){
        this.importeMin = 0;
        return {valido: false, msj: 'Debe ingresar un valor de importe mínimo no negativo.'}
      }
      // Importe Máx
      if (this.importeMax && this.importeMax < 0){
        this.importeMax = 0;
        return {valido: false, msj: 'Debe ingresar un valor de importe máximo no negativo.'}
      }
      // Cantidad Max > Cantidad Mín
      if ((this.importeMax && this.importeMin) && this.importeMax <= this.importeMin){
        return {valido: false, msj: 'Debe ingresar un valor de importe máximo mayor al mínimo.'}
      }
      return {valido: true, msj: 'OK'}
    },
    validarPorcentaje(item){
      if (cadenaVacia(item.bono)) item.bono = 0
      if (roundNumber(item.bono, 2) < 0 || roundNumber(item.bono, 2) >= 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje inválido. Intente otro valor.',
          color: 'warning',
        })
        let existe = this.articulos.filter(element => element.articulo_codigo == item.articulo_codigo)
        let pos = this.articulos.indexOf(existe[0])
        // decido que valor colocar
        let existeCopy = this.articulosCopy.filter(element => element.articulo_codigo == item.articulo_codigo)
        if (existeCopy.length > 0) this.articulos[pos].bono = roundNumber(existeCopy[0].bono, 2)
        else this.articulos[pos].bono = 0
      }else item.bono = roundNumber(item.bono, 2)
    },
    async sendPromocion(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        return this.$store.dispatch('show_snackbar', { text: valido.msj, color: 'warning' });
      }
      this.$store.state.loading = true
      // controlo los datos ingresados, la vigencia va a ser 0 es vigente (para ello tiene que tener permiso), 2 es pendiente de aprobar
      let vigente = 1
      if (this.vigente == 1 && this.tienePermiso == 1) vigente = 0
      if (this.tienePermiso != 1) vigente = 2
      // empresas
      let paraEmpresa = 0
      if (this.selectedEmpresa.length > 0) paraEmpresa = 1
      // canales
      let conCanales = 0
      if (this.selectedCanal.length > 0) conCanales = 1
      // sucursales
      let conSucursales = 0
      if (this.selectedSucursal.length > 0) conSucursales = 1
      // locales
      let conLocales = 0
      if (this.selectedLocal.length > 0) conLocales = 1
      // dias
      let conDias = 0
      if (this.selectedDia.length > 0) conDias = 1
      // categorias
      let conCategorias = 0
      if (this.selectedCategoria.length > 0) conCategorias = 1
      // articulos
      let conArticulos = 0
      if (this.articulos.length > 0) conArticulos = 1
      // cupones
      let conCupones = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) conCupones = 1
      // cantidad de cupones
      let cantidadCup = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) cantidadCup = this.cantCupones
      // redondeo el descuento
      let desc = roundNumber(this.descuento, 4)
      // cantidad minima
      let cantMin = 0
      if (this.cantMin != null && this.cantMin != undefined && this.cantMin != 0 && this.cantMin.toString().length > 0) cantMin = this.cantMin
      // cantidad maxima
      let cantMax = 0
      if (this.cantMax != null && this.cantMax != undefined && this.cantMax != 0 && this.cantMax.toString().length > 0) cantMax = this.cantMax
      // importe minimo
      let importeMin = 0
      if (this.importeMin != null && this.importeMin != undefined && this.importeMin != 0 && this.importeMin.toString().length > 0) importeMin = roundNumber(this.importeMin, 2)
      // importe maximo
      let importeMax = 0
      if (this.importeMax != null && this.importeMax != undefined && this.importeMax != 0 && this.importeMax.toString().length > 0) importeMax = roundNumber(this.importeMax, 2)
      // paso a grabar
      let grabarPeticion = await this.$store.dispatch('promociones/sendPromocion', {
        descripcion:           this.descripcion,
        vigente:               vigente,
        fecha_inicio:          this.fechaInicio,
        fecha_fin:             this.fechaFin,
        descuento:             desc,
        empresas:              this.selectedEmpresa,
        canales:               this.selectedCanal,
        sucursales:            this.selectedSucursal,
        locales:               this.selectedLocal,
        dias:                  this.selectedDia,
        categorias:            this.selectedCategoria,
        cantidad_min:          cantMin,
        cantidad_max:          cantMax,
        importe_minimo:        importeMin,
        importe_maximo:        importeMax,
        cantidad_cupones:      cantidadCup,
        articulos:             this.articulos,
        para_empresas:         paraEmpresa,
        para_canales:          conCanales,
        para_sucursales:       conSucursales,
        para_locales:          conLocales,
        para_dias:             conDias,
        para_categorias:       conCategorias,
        para_articulos:        conArticulos,
        para_cupones:          conCupones,
        articulos_exceptuados: this.articulosExcep,
        tipo_combo:            this.tipoCombo,
        combos:                this.combos,
        formasPago:            this.formasPagoSelect,
        cupo:                  this.cupos,
        cupo_estricto:         this.cupoEstricto,
        promo_id_cupo:         this.promoIdCupo,
        monedas:               this.monedasSelect,
      })
      this.$store.state.loading = false
      if (grabarPeticion.resultado == 1){
        this.$swal.fire({
          icon: 'success',
          title: `La Promoción ${grabarPeticion.promo_id} se creó exitosamente.`,
          timer: 2500
        })
        // controlo si la promoción fué con cupones
        if (conCupones == 1){
          // descargo el excel
          this.$store.state.loading = true
          let cuponesPeticion = await this.$store.dispatch('promociones/getCuponesPromocion', {promocion_id: grabarPeticion.promo_id})
          this.$store.state.loading = false
          if (cuponesPeticion.resultado == 1){
            download(cuponesPeticion.base64, `Cupones Promocion ${grabarPeticion.promo_id}.xlsx`, 'application/vnd.ms-excel')
          }else{
            this.$store.dispatch('show_snackbar', {
              text: cuponesPeticion.msj,
              color: 'error',
            })
          }
        }
        let id = grabarPeticion.promo_id;
        setTimeout(async () => {
          await this.$router.push({path: `/ver-promo/${id}`});
          location.reload();
        }, 3000);
      }else{
        this.$store.dispatch('show_snackbar', {
          text: grabarPeticion.msj,
          color: 'error',
        })
      }
    },
    async updatePromocion(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        return this.$store.dispatch('show_snackbar', { text: valido.msj, color: 'warning' })
      }
      this.$store.state.loading = true
      // controlo los datos ingresados, vigencia
      let vigente = this.selectedEstado.estado_id
      // empresas
      let paraEmpresa = 0
      if (this.selectedEmpresa.length > 0) paraEmpresa = 1
      // canales
      let conCanales = 0
      if (this.selectedCanal.length > 0) conCanales = 1
      // sucursales
      let conSucursales = 0
      if (this.selectedSucursal.length > 0) conSucursales = 1
      // locales
      let conLocales = 0
      if (this.selectedLocal.length > 0) conLocales = 1
      // dias
      let conDias = 0
      if (this.selectedDia.length > 0) conDias = 1
      // categorias
      let conCategorias = 0
      if (this.selectedCategoria.length > 0) conCategorias = 1
      // articulos
      let conArticulos = 0
      if (this.articulos.length > 0) conArticulos = 1
      // cupones
      let conCupones = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) conCupones = 1
      // cantidad de cupones
      let cantidadCup = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) cantidadCup = this.cantCupones
      // redondeo el descuento
      let desc = roundNumber(this.descuento, 4)
      // cantidad minima
      let cantMin = 0
      if (this.cantMin != null && this.cantMin != undefined && this.cantMin != 0 && this.cantMin.toString().length > 0) cantMin = this.cantMin
      // cantidad maxima
      let cantMax = 0
      if (this.cantMax != null && this.cantMax != undefined && this.cantMax != 0 && this.cantMax.toString().length > 0) cantMax = this.cantMax
      // importe minimo
      let importeMin = 0
      if (this.importeMin != null && this.importeMin != undefined && this.importeMin != 0 && this.importeMin.toString().length > 0) importeMin = roundNumber(this.importeMin, 2)
      // importe maximo
      let importeMax = 0
      if (this.importeMax != null && this.importeMax != undefined && this.importeMax != 0 && this.importeMax.toString().length > 0) importeMax = roundNumber(this.importeMax, 2)
      // cupos
      let cupo = 0;
      if(this.cupos) cupo = this.cupos;
      //promo ID cupo
      let pic = 0; 
      if(this.promoIdCupo){ pic = this.promoIdCupo; }

      // paso a grabar
      let updatePeticion = await this.$store.dispatch('promociones/updatePromocion', {
        descripcion:           this.descripcion,
        vigente:               vigente,
        fecha_inicio:          this.fechaInicio,
        fecha_fin:             this.fechaFin,
        descuento:             desc,
        empresas:              this.selectedEmpresa,
        canales:               this.selectedCanal,
        sucursales:            this.selectedSucursal,
        locales:               this.selectedLocal,
        dias:                  this.selectedDia,
        categorias:            this.selectedCategoria,
        cantidad_min:          cantMin,
        cantidad_max:          cantMax,
        importe_minimo:        importeMin,
        importe_maximo:        importeMax,
        cantidad_cupones:      cantidadCup,
        articulos:             this.articulos,
        para_empresas:         paraEmpresa,
        para_canales:          conCanales,
        para_sucursales:       conSucursales,
        para_locales:          conLocales,
        para_dias:             conDias,
        para_categorias:       conCategorias,
        para_articulos:        conArticulos,
        para_cupones:          conCupones,
        promocion_id:          this.promoId,
        articulos_exceptuados: this.articulosExcep,
        tipo_combo:            this.tipoCombo,
        combos:                this.combos,
        formasPago:            this.formasPagoSelect,
        cupo:                  cupo,
        cupo_estricto:         this.cupoEstricto,
        promo_id_cupo:         pic,
        monedas:               this.monedasSelect,
      })
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: `La Promoción ${this.promoId} se modificó exitosamente.`, color: 'success' })
        setTimeout(() => [location.reload()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    },
  },
  watch: {
    selectedCategoria: function(){
      if (this.selectedCategoria == undefined || this.selectedCategoria == null) this.selectedCategoria = []
    },
    editar: function(val){
      if(!val){
        this.descripcion       = JSON.parse(JSON.stringify(this.descripcionCopia));
        this.selectedEstado    = JSON.parse(JSON.stringify(this.selectedEstadoCopia));
        this.descuento         = JSON.parse(JSON.stringify(this.descuentoCopia));
        this.fechaInicio       = JSON.parse(JSON.stringify(this.fechaInicioCopia));
        this.fechaFin          = JSON.parse(JSON.stringify(this.fechaFinCopia));
        this.conCupones        = JSON.parse(JSON.stringify(this.conCuponesCopia));
        this.cantCupones       = JSON.parse(JSON.stringify(this.cantCuponesCopia));
        this.cantMin           = JSON.parse(JSON.stringify(this.cantMinCopia));
        this.cantMax           = JSON.parse(JSON.stringify(this.cantMaxCopia));
        this.importeMin        = JSON.parse(JSON.stringify(this.importeMinCopia));
        this.importeMax        = JSON.parse(JSON.stringify(this.importeMaxCopia));
        this.selectedEmpresa   = JSON.parse(JSON.stringify(this.selectedEmpresaCopia));
        this.selectedCanal     = JSON.parse(JSON.stringify(this.selectedCanalCopia));
        this.selectedSucursal  = JSON.parse(JSON.stringify(this.selectedSucursalCopia));
        this.selectedLocal     = JSON.parse(JSON.stringify(this.selectedLocalCopia));
        this.selectedDia       = JSON.parse(JSON.stringify(this.selectedDiaCopia));
        this.selectedCategoria = JSON.parse(JSON.stringify(this.selectedCategoriaCopia));
        
        this.articulos         = JSON.parse(JSON.stringify(this.articulosCopy))
        this.articulosExcep    = JSON.parse(JSON.stringify(this.articulosExcepCopia));
        this.combos            = JSON.parse(JSON.stringify(this.combosCopia));
        this.tipoCombo         = JSON.parse(JSON.stringify(this.tipoComboCopia));
        this.formasPagoSelect  = JSON.parse(JSON.stringify(this.formasPagoSelectCopia));
        this.cupos             = JSON.parse(JSON.stringify(this.cuposCopia));
        this.cupoEstricto          = JSON.parse(JSON.stringify(this.cupoEstrictoCopia));
        this.promoIdCupo       = JSON.parse(JSON.stringify(this.promoIdCupoCopia));
        this.monedasSelect     = JSON.parse(JSON.stringify(this.monedasSelectCopia));
        this.search            = '';
        this.searchh           = '';
        this.searchC           = '';
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
    promoIdCupo: function(val){
      if(val){
        this.tieneCupo        = false;
        this.tienePromoIdCupo = true;
        this.cupoEstricto     = null;
      }else{
        this.tienePromoIdCupo = false;
      }
    },
    cupos: function(val){
      if(val){
        this.tienePromoIdCupo = false;
        this.tieneCupo        = true;
      }else{
        this.tieneCupo        = false;
      }
    },
    cupoEstricto: function(val){
      if(val){
        this.promoIdCupo = null;
      }
    }
  },
}
</script>

<style>

</style>
