<template>
  <v-dialog v-model="dialog" max-width="600" >
    <template v-slot:default="{ props }">
      <div v-bind="props"
        class="draggable-dialog"
        :style="{ top: top + 'px', left: left + 'px', position: 'absolute' }">
        <v-card :style="`max-width: ${width}px;`">
          <v-card-title 
            @mousedown="startDragging"
            @mouseup="stopDragging"
            @mousemove="onDrag"
          >
            <span :class="`text-${pantallaChica ? 'subtitle-1' : 'h6'}`">Cambio Directo de Serie:<br v-if="pantallaChica"> {{ serie }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" sm="6" class="py-1">
                Fecha Venta
                <v-text-field
                  v-model.trim="fecha_venta_format"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                Fecha Cambio de Serie
                <v-text-field
                  v-model.trim="info.fecha"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                Serie Pre
                <v-text-field
                  v-model.trim="info.serie_pre"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                Serie Post
                <v-text-field
                  v-model.trim="info.serie_post"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6"  class="py-1">
                Usuario
                <v-text-field
                  v-model.trim="info.usuario"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6"  class="py-1">
                Motivo
                <v-text-field
                  v-model.trim="info.motivo"
                  hide-details
                  autofocus
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="dialog = false" color="secondary">Volver</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
    
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'VerCambioDirectoSerie',
  props: {
    value: Boolean,
    datos: {
      type: Object,
      default: {}
    }  
  },
  computed: {
    dialog: {
      get() {
        return this.datos.activo;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    serie: {
      get() {
        return this.datos.serie;
      }
    },
    fecha_venta:{
      get() {
        return this.datos.fecha_venta;
      }
    },
    width: {
      get() {
        return this.datos.width;
      }
    },
    height: {
      get() {
        return this.datos.height;
      }
    }
  },
  data() {
    return {
      moment: moment,
      info: {},
      pantallaChica: this.$vuetify.breakpoint.xs,
      fecha_venta_format: '',
      dragging: false,
      top: 0,//200,
      left: 0,//200,
      offsetX: 0,
      offsetY: 0,
    };
  },
  methods: {
    async init(){
      this.$store.state.loading = true;
      const res                 = await this.$store.dispatch('genericos/getInfoSerieCambioDirecto', { serie: this.serie });
      this.$store.state.loading = false;
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.info               = res.info[0];
      this.info.motivo        = this.info.motivo.toUpperCase();
      this.info.fecha         = moment(new Date(this.info.fecha)).format('DD/MM/YYYY HH:mm:ss')
      this.fecha_venta_format = moment(new Date(this.fecha_venta)).format('DD/MM/YYYY HH:mm:ss')
    },
    centerDialog() {
      //const dialogWidth = 600; // Ancho del diálogo
      //const dialogHeight = 400; // Establecer un alto deseado si es necesario

      //console.log("window.innerWidth: ",window.innerWidth);
      //console.log("window.innerHeight: ",window.innerHeight);

      //console.log("window.innerWidth/2: ",window.innerWidth/2);
      //console.log("window.innerHeight/2: ",window.innerHeight/2);

      this.left = (window.innerWidth - this.width) / 2 //- 300;
      this.top = (window.innerHeight - this.height) / 2;
    },
    startDragging(event) {
      event.preventDefault(); // Evita la selección de texto
      this.dragging = true;
      this.offsetX = event.clientX - this.left;
      this.offsetY = event.clientY - this.top;
    },
    stopDragging() {
      this.dragging = false;
    },
    onDrag(event) {
      if (this.dragging) {
        this.left = event.clientX - this.offsetX < 0 ? 0 : event.clientX - this.offsetX;
        this.top  = event.clientY - this.offsetY < 0 ? 0 : event.clientY - this.offsetY;
      }
    },
  },
  watch: {
    dialog: function(val){
      if(val){
        this.centerDialog();
        this.init();
      }else{
        this.dragging = false;
        //this.top = 200;
        //this.left = 200;
        this.offsetX = 0;
        this.offsetY = 0;
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
};
</script>

<style>
.draggable-dialog {
  /* Estilos adicionales si es necesario */
  max-width: 100%; /* O el ancho máximo que desees */
  overflow: hidden; /* Evita el desbordamiento */
}
</style>

