<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo del Formulario -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <div>Nuevo Remito Interno</div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="tipoIngreso"
            :label="tipoIngreso == false ? 'Ingreso por Cód. de Artículos' : 'Ingreso por Cód. de Barras'"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
          ></v-switch>
        </v-expand-x-transition>
      </v-col>
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Emision, Numero, Fecha, Referencia y Usuario de Grabacion -->
            <v-row>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Emisión
                <v-text-field
                  v-model="encabezado.emision"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Número
                <v-text-field
                  v-model="encabezado.numero"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Fecha
                <FechaPicker 
                  v-model="encabezado.fecha"
                />
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                Referencia
                <v-text-field
                  v-model="encabezado.referencia"
                  outlined
                  dense
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Usuario Grabación
                <v-text-field
                  v-model="encabezado.usuario"
                  outlined
                  dense
                  type="text"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Bodega Origen y Destino -->
            <v-row>
              <v-col cols="12" sm="6" md="6" class="py-0">
                Bodega Origen
                <v-autocomplete
                  v-model="selectedBodegaOrigen"
                  item-text="bodega_nombre"
                  :items="bodegasOrigen"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="controlarRemitosPendientes(1, selectedBodegaOrigen)"
                  :disabled="detalles.length > 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-0">
                Bodega Destino
                <v-autocomplete
                  v-model="selectedBodegaDestino"
                  item-text="bodega_nombre"
                  :items="bodegasDestino"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="controlarRemitosPendientes(2, selectedBodegaDestino)"
                  :disabled="detalles.length > 0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <br/>
            <!-- Artículos -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <!-- Titulo de Seccion, Botones Importar y Carga Asistida -->
                  <v-card-title>
                    Artículos
                    <v-btn icon title="Buscar Artículos" @click="buscarArticulosBodega()"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      title="Importar Archivo"
                      color="green darken-3"
                      class="ml-2"
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      :fab="$vuetify.breakpoint.smAndDown"
                      @click="iniciarImportarArchivo()"
                    >
                      <v-icon
                        :left="$vuetify.breakpoint.mdAndUp"
                        small
                      >
                        fas fa-file-upload
                      </v-icon>
                      <span v-text="$vuetify.breakpoint.mdAndUp ? 'Importar' : ''"></span>
                      <input
                        ref="input"
                        type="file"
                        class="d-none"
                      >
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="ml-3"
                      small
                      @click="iniciarCargaAsistida()"
                      :disabled="detalles.length > 0"
                    >
                      <v-icon small left>fas fa-barcode</v-icon>
                      Carga Asistida
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <!-- Agregar Artículos -->
                    <v-row>
                      <v-col cols="12" sm="2" md="2" class="py-0">
                        Cantidad
                        <v-text-field
                          v-model="cantidad"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-0" v-if="tipoIngreso == true">
                        Cód. Barras
                        <v-text-field
                          v-model="codigoBarras"
                          :id="barrasRef"
                          :ref="barrasRef"
                          outlined
                          dense
                          type="text"
                          @blur="getArticuloInfo(1, codigoBarras, 1)"
                          @keypress.native.enter="$refs[barrasRef].blur()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0" v-if="tipoIngreso == false">
                        Cód. Interno
                        <v-text-field
                          v-model="codBB"
                          :id="codRef"
                          :ref="codRef"
                          outlined
                          dense
                          type="number"
                          @blur="getArticuloInfo(1, codBB, 2)"
                          @keypress.native.enter="$refs[codRef].blur()"

                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" :sm="tipoIngreso == false ? 6 : 4" :md="tipoIngreso == false ? 6 : 4" class="py-0">
                        Nombre
                        <v-text-field
                          v-model="nombreArticulo"
                          outlined
                          dense
                          type="text"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0 pt-5">
                        <v-btn 
                          color="primary" 
                          title="Agregar Artículo"
                          @click="getArticuloInfo(1, '', 3)"
                        >
                          <v-icon small class="ma-1">fas fa-plus</v-icon>
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Listado de Artículos -->
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          class="cebra elevation-2 mt-2"
                          :headers="mostrarTotales == 0 ? headeers : headers"
                          :items="detalles"
                          :loading="load"
                          dense
                          :search="search"
                        >
                          <!-- Buscador en el datatable -->
                          <template v-slot:top>
                            <v-row class="d-flex justify-end pa-2" no-gutters>
                              <v-col cols="6" sm="3">
                                <SearchDataTable
                                  v-model="search"
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <!-- Items -->
                          <template v-slot:[`item.cantidad`]="{ item }">
                            <v-row class="d-flex justify-center" no-gutters>
                              <v-text-field
                                v-model="item.cantidad"
                                style="width: 50px"
                                type="number"
                                hide-details
                                dense
                                outlined
                                @blur="recalcularStock(item)"
                              ></v-text-field>
                            </v-row>
                          </template>
                          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                          <template
                            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }"
                          >
                            {{ header.formatter(value) }}
                          </template>
                          <!-- Acciones -->
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-btn icon small title="Quitar" @click="quitarArticulo(item)"><v-icon small color="error">fas fa-times</v-icon></v-btn>
                            <v-btn icon small title="Ver Series" @click="verSeries(item)" v-if="item.series.length > 0"><v-icon small color="success">fas fa-list</v-icon></v-btn>
                            <v-btn icon small title="Controle la cantidad a remitir de este artículo." @click="verMsjControlarCantRemitir(item)" v-if="item.reagrupado == 1"><v-icon small color="warning">fas fa-exclamation-triangle</v-icon></v-btn>
                            <v-btn icon small title="Validar cantidad a remitir." @click="validarCantRemitir(item)" v-if="item.validado == 0"><v-icon small color="success">fas fa-check</v-icon></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- Botones -->
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn
              color="warning"
              class="mr-2"
              @click="volverIndex()"
            >
              Volver
            </v-btn>
            <v-btn color="error" class="mr-2" @click="limpiarDetalles()" v-if="detalles.length > 0">Limpiar Detalles</v-btn>
            <BtnConfirmar 
              texto="¿Está seguro de generar el Remito?"
              @action="grabarRemito()"
              :disabled="permiteGrabar == false"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Buscar Artículos, todo el stock de la bodega origen -->
    <ArticulosXBodega 
      :dialogActivo="dialogArticulos"
      :bodega="parseInt(selectedBodegaOrigen.bodega_codigo)"
      @agregarArtModal="addArticulo"
      @setearModalArticulos="setModalArts"
    />
    <!-- Modal Seleccionar un Artículo, por estar repetido el codigo de barras -->
    <SeleccionarArticulo 
      :dialogActivo="dialogSelectArticulo"
      :detallesArts="selectArticulos"
      @setearModalSelectArt="setModalSelectArt"
      @seleccionarArt="setArtSeleccionado"
    />
    <!-- Modal Seleccionar Series -->
    <ControlSeries 
      :datosSeries="seriesParam"
      :showVolver="parseInt(agregarDetalle)"
      @setearModalCSeries="setModalCSeries"
      @confirmanSeries="setSeriesArticulo"
    />
    <!-- Modal Carga Asistida -->
    <CargaAsistidaRemito 
      :datosModal="dialogAsistParam"
      @setearModalCAsistida="setModalCAsist"
      @setearTipIngCAsistida="setTipIngCAsist"
      @setDetallesRemito="executeCargaAsistida"
    />
  </div>
</template>

<script>
  import FechaPicker from '../../components/util/FechaPicker.vue'
  import SearchDataTable from '../../components/util/SearchDataTable.vue'
  import ArticulosXBodega from '../../components/util/ArticulosXBodega.vue'
  import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
  import SeleccionarArticulo from '../../components/generales/SeleccionarArticulo.vue'
  import ControlSeries from '../../components/generales/ControlSeries.vue'
  import CargaAsistidaRemito from '../../components/generales/remitos/CargaAsistidaRemito.vue'
  import { format_money } from '../../util/utils'
  import moment from 'moment'
  import router from '../../router'
export default {
  components: {
    FechaPicker,
    SearchDataTable,
    ArticulosXBodega,
    BtnConfirmar,
    SeleccionarArticulo,
    ControlSeries,
    CargaAsistidaRemito
  },
  data() {
    return {
      encabezado: {
        emision: '',
        numero: '',
        fecha: moment(new Date()).format('DD/MM/YYYY'),
        referencia: '',
        usuario: ''
      },
      selectedBodegaOrigen: {},
      selectedBodegaDestino: {},
      bodegasOrigen: [],
      bodegasDestino: [],
      cantidad: '',
      codigoBarras: '',
      codBB: '',
      nombreArticulo: '',
      headers: [
        { text: 'Cód. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cód. Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Descripción', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio Unit.', align: 'center', value: 'precio', formatter: format_money },
        { text: 'Total', align: 'center', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headeers: [
      { text: 'Cód. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cód. Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Descripción', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      detalles: [],
      load: false,
      search: '',
      tipoIngreso: false,
      dialogArticulos: false,
      permiteGrabar: true,
      dialogSelectArticulo: false,
      selectArticulos: [],
      mostrarTotales: 0,
      seriesParam: {
        dialogSerie: false, // para abrir el modal de seleccion de series
        articulo_codigo: 0, // codigo del articulo del que voy a seleccionar las series
        articulo_cod_barras: '',
        articulo_nombre: '',  // idem al anterior
        series_disponibles: [], // TODAS LAS SERIES disponibles del artículo, se obtendría consultando las series en stock en la base de datos y quitando las series seleccionadas
        series_seleccionadas: [], // series seleccionadas por el usuario a remitir
        cantidad: 0, // cantidad de series que debe seleccionar el usuario para poder terminar de ingresar un artículo al detalle a remitir
        precio: 0,
        importe: 0,
        solicita_serie: 0,
        tipo: 0
      },
      agregarDetalle: 0,
      posDetalleSeries: -1,
      dialogAsistParam: {
        dialogActivo: false,
        bodegaOrigen: 0,
        bodegaDestino: 0,
        tipoIngreso: false,
        mostrarTotales: 0,
        archivo: 0
      },
      barrasRef: 'barrasRef',
      codRef: 'codRef'
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      this.encabezado.usuario = this.$store.state.username
      // ya tengo los datos en localStorage?
      if (localStorage.getItem('bodegasO') && JSON.parse(localStorage.getItem('bodegasO')).length > 0 &&
      localStorage.getItem('bodegasD') && JSON.parse(localStorage.getItem('bodegasD')).length > 0){
        this.$store.state.loading = false
        this.bodegasOrigen = JSON.parse(localStorage.getItem('bodegasO'))
        this.bodegasDestino = JSON.parse(localStorage.getItem('bodegasD'))
      }else{
        let initPeticion = await this.$store.dispatch('remitos/initForm')
        this.$store.state.loading = false
        if (initPeticion.resultado == 1){
          this.bodegasOrigen = initPeticion.bodegasO
          this.bodegasDestino = initPeticion.bodegasD
        }else{
          this.bodegasOrigen = []
          this.bodegasDestino = []
          this.$store.dispatch('show_snackbar', {
            text: initPeticion.msj,
            color: 'error',
          })
          return
        }
      }
    },
    limpiarDetalles(){
      this.detalles = []
    },
    buscarArticulosBodega(){
      // seleccionaron una bodega de origen?
      if (!this.controlBodegas(2)){
        this.limpiarSeccionArticulos()
        return
      }
      // abro el modal
      this.dialogArticulos = true
    },
    setModalArts(value){
      this.dialogArticulos = value
    },
    controlBodegas(opcion){
      if (opcion == 1){
        // controlo igualdad de bodegas
        if (Object.keys(this.selectedBodegaOrigen).length > 0 && Object.keys(this.selectedBodegaDestino).length > 0){
          if (this.selectedBodegaOrigen.bodega_codigo == this.selectedBodegaDestino.bodega_codigo){
            this.$store.dispatch('show_snackbar', {
              text: 'Las Bodegas Origen y Destino no pueden ser iguales.',
              color: 'warning',
            })
            return false
          }
        }
      }else{
        // controlo seleccion de bodegas vacias
        if (Object.keys(this.selectedBodegaOrigen).length === 0){
          // controlo que hayan seleccionado la bodega origen
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Bodega de Origen para poder agregar.',
            color: 'warning',
          })
          return false
        }
        if (Object.keys(this.selectedBodegaDestino).length === 0){
          // controlo que hayan seleccionado la bodega Destino
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Bodega de Destino para poder agregar.',
            color: 'warning',
          })
          return false
        }
      }
      return true
    },
    async controlarRemitosPendientes(opcion, selected){
      if (Object.keys(selected).length > 0){
        // control de bodegas
        if (!this.controlBodegas(1)){
          this.selectedBodegaOrigen = {}
          this.selectedBodegaDestino = {}
          this.limpiarSeccionArticulos()
          return
        }
        // limpio la seccion de articulos
        this.limpiarSeccionArticulos()
        // hago la llamada
        this.$store.state.loading = true
        let pendientesPeticion = await this.$store.dispatch('remitos/checkRemitosPendientes', {bodega: selected.bodega_codigo})
        this.$store.state.loading = false
        if (pendientesPeticion.resultado == 1){
          // controlo si llegaron remitos pendientes de aceptar, como bodega origen
          if (parseInt(pendientesPeticion.cant_remitosO) > 0){
            // no dejar grabar
            this.$store.dispatch('show_snackbar', {
              text: 'La Bodega seleccionada tiene remitos salientes que no se aceptaron: ' + pendientesPeticion.remitosO,
              color: 'warning',
            })
            this.permiteGrabar = false
            return
          }
          // controlo si llegaron remitos pendientes de aceptar, como bodega destino
          if (parseInt(pendientesPeticion.cant_remitosD) > 0){
            // no dejar grabar
            this.$store.dispatch('show_snackbar', {
              text: 'La Bodega seleccionada no terminó de aceptar todos los remitos pendientes: ' + pendientesPeticion.remitosD,
              color: 'warning',
            })
            this.permiteGrabar = false
            return
          }
          // indico si debo mostrar o no el precio
          if (opcion == 2){
            this.mostrarTotales = pendientesPeticion.mostrar_precio
          }
          this.permiteGrabar = true
        }else{
          this.$store.dispatch('show_snackbar', {
            text: pendientesPeticion.msj,
            color: 'error',
          })
          this.permiteGrabar = false
        }
      }
      // limpio los detalles que pudieron agregar
      this.detalles = []
    },
    volverIndex(){
      router.push({name: 'Remitos Internos'})
    },
    limpiarSeccionArticulos(){
      this.cantidad = ''
      this.codBB = ''
      this.codigoBarras = ''
      this.nombreArticulo = ''
    },
    limpiarSeriesParam(){
      this.seriesParam.articulo_codigo = 0
      this.seriesParam.articulo_nombre = ''
      this.seriesParam.articulo_cod_barras = ''
      this.seriesParam.series_disponibles = []
      this.seriesParam.series_seleccionadas = []
      this.seriesParam.cantidad = 0
      this.seriesParam.precio = 0
      this.seriesParam.importe = 0
      this.seriesParam.solicita_serie = 0
      this.seriesParam.tipo = 0
    },
    async getArticuloInfo(opcion, articulo, tipo){
      // lo mandan desde el boton?
      if (tipo == 3){
        if (this.codBB.toString().length > 0){
          articulo = this.codBB
          tipo = 2
        }
        if (this.codigoBarras.toString().length > 0){
          articulo = this.codigoBarras
          tipo = 1
        }
      }
      // articulo puede ser el codigo de BB del articulo o el código de barras
      if (articulo.toString().length > 0 && articulo != null && articulo != undefined){
        this.$store.state.loading = true
        if (opcion == 1){
          // lo estan mandando desde el formulario
          if (!this.controlBodegas(2)){
            this.limpiarSeccionArticulos()
            this.$store.state.loading = false
            return
          }
          // seteamos la cantidad
          if (this.cantidad.toString().length == 0 || this.cantidad == null || this.cantidad == undefined){
            this.cantidad = 1
          }
        }
        // hago la peticion
        let infoPeticion = await this.$store.dispatch('stock/getArticuloInfo', {articulo: articulo, tipo: tipo, bodega: this.selectedBodegaOrigen.bodega_codigo})
        if (infoPeticion.resultado == 1){
          // controlo la cantidad de artículos que llegaron
          if (infoPeticion.articulos.length == 0){
            this.$store.state.loading = false
            // no se encontraron resultados
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron resultados para su búsqueda. Pruebe otro método de ingreso.',
              color: 'info',
            })
            this.limpiarSeccionArticulos()
            return
          }else if (infoPeticion.articulos.length == 1){
            // puede ser código de barras o código de artículo, asigno los valores en caso de ser necesario
            if (opcion == 1){
              this.nombreArticulo = infoPeticion.articulos[0].articulo_nombre
            }
            // envío a agregar el artículo
            infoPeticion.articulos[0].codigo_barras = ''
            infoPeticion.articulos[0].opcion = opcion
            infoPeticion.articulos[0].tipo = tipo
            this.setArtSeleccionado(infoPeticion.articulos[0])
          }else{
            // seguro que es un código de barras con más de un artículo asociado, asigno los artículos al listado de artículos para seleccionar
            this.selectArticulos = infoPeticion.articulos
            // asigno el codigo de barras
            for (let id in this.selectArticulos){
              this.selectArticulos[id].codigo_barras = articulo
              this.selectArticulos[id].opcion = opcion
              this.selectArticulos[id].tipo = tipo
            }
            // abro el modal para que el usuario elija cúal es el que quiere
            this.dialogSelectArticulo = true
          }
        }else{
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: infoPeticion.msj,
            color: 'error',
          })
          this.limpiarSeccionArticulos()
        }
      }else{
        this.limpiarSeccionArticulos()
      }
    },
    setArtSeleccionado(item){
      if (item.opcion == 1){
        this.nombreArticulo = item.articulo_nombre
      }
      this.addArticulo(item)
      // es un artículo que solicita serie?
      /*if (item.solicita_serie == 1){
        // abrir el modal de series y obtener las series del artículo

      }else{
        // paso a agregar
        this.addArticulo(item)
      }*/
    },
    async addArticulo(articulo){
      // defino el objeto que va a contener los datos del artículo ---> para agregar a this.detalles
      let art = {}
      // variables para la busqueda
      let cantidadAgregar = 0
      if (articulo.opcion == 1){
        // la cantidad esta en el formulario
        cantidadAgregar = this.cantidad
      }else{
        // asigno la cantidad del articulo que vino del modal
        cantidadAgregar = parseInt(articulo.cantidad)
      }
      // en este modulo voy a controlar el stock y el precio del artículo en caso de tener
      let datosPeticion = await this.$store.dispatch('stock/checkStockBodega', {bodegaOrigen: this.selectedBodegaOrigen.bodega_codigo, bodegaDestino: this.selectedBodegaDestino.bodega_codigo, articulo_codigo: articulo.articulo_codigo, cantidad: cantidadAgregar, mostrar_precio: this.mostrarTotales})
      this.$store.state.loading = false
      // controlo el resultado
      if (datosPeticion.resultado == 1){
        // puedo agregar la cantidad que pedí?
        if (parseInt(datosPeticion.cant_disponible) == 0){
          // no tengo stock para remitir en la bodega origen
          this.$store.dispatch('show_snackbar', {
            text: 'No se tiene Stock disponible para remitir.',
            color: 'info',
          })
          return
        }else if (parseInt(cantidadAgregar) > parseInt(datosPeticion.cant_disponible)){
          // tengo stock, pero no el solicitado por el usuario
          this.$store.dispatch('show_snackbar', {
            text: 'No se cuenta con ' + cantidadAgregar + ' unidades. Se agrega por la cantidad disponible.',
            color: 'info',
          })
          cantidadAgregar = datosPeticion.cant_disponible
        }
        // agregado por mmacoritto el 7/6/23 para que si no tiene precio y es de bodega salon (2) no lo deje agregar
        else if (parseInt(datosPeticion.sin_precio) == 1) {
          this.limpiarSeccionArticulos()
          return this.$store.dispatch('show_snackbar', {
            text: 'El artículo ' + articulo.articulo_codigo + ' - ' + articulo.articulo_nombre + ' no cuenta con un precio definido en la lista de la bodega destino.',
            color: 'orange',
            timeout: 4000
          })
        }else{
          // tengo el stock que me pido el usuario
          cantidadAgregar = cantidadAgregar
        }
        if (articulo.opcion == 1){
          // debo armar art manualmente
          art = {
            articulo_codigo: parseInt(articulo.articulo_codigo),
            articulo_cod_barras: this.codigoBarras.toString(),
            articulo_nombre: articulo.articulo_nombre,
            cantidad: parseInt(cantidadAgregar),
            cantidadAnt: parseInt(cantidadAgregar),
            precio: this.convertDecimals(datosPeticion.precio),
            importe: this.convertDecimals(this.convertDecimals(datosPeticion.precio) * parseInt(cantidadAgregar)),
            solicita_serie: articulo.solicita_serie,
            series: articulo.series,
            tipo: articulo.tipo,
            reagrupado: 0,
            validado: 1
          }
        }else{
          // articulo ya viene armado, lo asigno a art
          let cod_barras = ''
          if (articulo.articulo_cod_barras != null && articulo.articulo_cod_barras != undefined && articulo.articulo_cod_barras.toString().length > 0){
            cod_barras = articulo.articulo_cod_barras
          }
          art = {
            articulo_codigo: parseInt(articulo.articulo_codigo),
            articulo_cod_barras: cod_barras.toString(),
            articulo_nombre: articulo.articulo_nombre,
            cantidad: parseInt(cantidadAgregar),
            cantidadAnt: parseInt(cantidadAgregar),
            precio: this.convertDecimals(datosPeticion.precio),
            importe: this.convertDecimals(this.convertDecimals(datosPeticion.precio) * parseInt(cantidadAgregar)),
            solicita_serie: articulo.solicita_serie,
            series: articulo.series,
            tipo: articulo.tipo,
            reagrupado: 0,
            validado: 1
          }
        }
        // verifico si el articulo ya se encuentra en el listado
        let unArt = this.detalles.filter(element => element.articulo_codigo == art.articulo_codigo)
        if (unArt.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El artículo ya se encuentra en el detalle para remitir.',
            color: 'warning',
          })
          return
        }
        // tengo armado la estructura de art, verifico si se trata de un artículo que solicite series
        if (art.solicita_serie == 1){
          this.agregarDetalle = 1
          this.posDetalleSeries = 0
          // el articulo viene con series, abro el modal para que el usuario elija
          this.abrirModalControlSeries(art)
          return
        }
        // si no lo encuentra, lo agrego
        this.detalles.push(art)
      }else{
        // error al obtener los datos del articulo
        this.$store.dispatch('show_snackbar', {
          text: datosPeticion.msj,
          color: 'error',
        })
      }
      this.limpiarSeccionArticulos()
    },
    validarReagrupados(){
      let reagrupados = this.detalles.filter(element => parseInt(element.reagrupado) == 1)
      let validados = this.detalles.filter(element => parseInt(element.validado) == 0)
      if (reagrupados.length > 0 || validados.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se terminó de confirmar las cantidades a remitir. No se puede grabar el remito.',
          color: 'warning',
        })
        return false
      }else{
        return true
      }
    },
    async grabarRemito(){
      // agregaron algo al detalle?
      if (this.detalles.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin artículos para remitir',
          color: 'warning',
        })
        return
      }
      // control cantidades - series
      if (!this.validarCantidadSeries()){
        return
      }
      // deben confirmar cantidades a remitir?
      if (!this.validarReagrupados()){
        return
      }
      // procedo a grabar
      this.$store.state.loading = true
      this.encabezado.bodega_origen = this.selectedBodegaOrigen.bodega_codigo
      this.encabezado.bodega_destino = this.selectedBodegaDestino.bodega_codigo
      this.encabezado.recepcion = 0
      let remitoPeticion = await this.$store.dispatch('remitos/nuevoRemitoInterno', {encabezado: this.encabezado, detalles: this.detalles})
      this.$store.state.loading = false
      if (remitoPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se creó con éxito el remito Número ' + remitoPeticion.remitoNumero,
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: remitoPeticion.msj,
          color: 'error',
        })
      }
    },
    abrirModalControlSeries(art){
      this.seriesParam.articulo_codigo = art.articulo_codigo
      this.seriesParam.articulo_nombre = art.articulo_nombre
      this.seriesParam.articulo_cod_barras = art.articulo_cod_barras
      this.seriesParam.series_disponibles = art.series
      this.seriesParam.series_seleccionadas = []
      this.seriesParam.cantidad = art.cantidad
      this.seriesParam.precio = art.precio
      this.seriesParam.importe = art.importe
      this.seriesParam.solicita_serie = art.solicita_serie
      this.seriesParam.tipo = art.tipo
      this.seriesParam.dialogSerie = true
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo = value
    },
    convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    },
    async recalcularStock(item){
      // agregado por mmacoritto el 7/6/23 pq permitia ingresar negativos y 0 y se crasheaba
      if (item.cantidad <= 0) {
        item.cantidad = item.cantidadAnt
        return this.$store.dispatch('show_snackbar', {
          text: 'La cantidad debe ser mayor que 0',
          color: 'orange'
        })
      }
      if (item.cantidad != item.cantidadAnt){
        // encuentro la posicion del item en el array
        let pos = this.detalles.indexOf(item)
        // cantidad a agregar
        let cantidadAgregar = item.cantidad
        // vuelvo a pedir stock y precio
        this.$store.state.loading = true
        let datosPeticion = await this.$store.dispatch('stock/checkStockBodega', {bodegaOrigen: this.selectedBodegaOrigen.bodega_codigo, bodegaDestino: this.selectedBodegaDestino.bodega_codigo, articulo_codigo: item.articulo_codigo, cantidad: cantidadAgregar, mostrar_precio: this.mostrarTotales})
        // puedo remitir lo que me estan pidiendo?
        if (parseInt(datosPeticion.cant_disponible) == 0){
          // sin stock disponible
          this.$store.dispatch('show_snackbar', {
            text: 'No se tiene Stock disponible para remitir.',
            color: 'info',
          })
          this.quitarArticulo(item)
          return
        }else if (parseInt(cantidadAgregar) > parseInt(datosPeticion.cant_disponible)){
          // no tengo el stock suficiente
          this.$store.dispatch('show_snackbar', {
            text: 'No se cuenta con ' + cantidadAgregar + ' unidades. Se actualiza por la cantidad disponible.',
            color: 'info',
          })
          // actualizo la cantidad, precio e importe
          this.detalles[pos].cantidad = parseInt(datosPeticion.cant_disponible) + 1
          this.detalles[pos].cantidadAnt = parseInt(datosPeticion.cant_disponible) + 1
          this.detalles[pos].precio = this.convertDecimals(datosPeticion.precio)
          this.detalles[pos].importe = this.convertDecimals(this.convertDecimals(datosPeticion.precio) * parseInt(datosPeticion.cant_disponible))
        }else{
          // tengo stock suficiente para remitir, actualizo la cantidad, precio e importe
          this.detalles[pos].cantidad = parseInt(cantidadAgregar)
          this.detalles[pos].cantidadAnt = parseInt(cantidadAgregar)
          this.detalles[pos].precio = this.convertDecimals(datosPeticion.precio)
          this.detalles[pos].importe = this.convertDecimals(this.convertDecimals(datosPeticion.precio) * parseInt(cantidadAgregar))
        }
        // hasta aqui solo resuelvo el tema de cantidad, precio e importe. Si se trata adicionalmente de un articulo con series tambien tengo que controlar eso
        if (item.solicita_serie == 1){
          // pido las series disponibles
          let infoPeticion = await this.$store.dispatch('stock/getArticuloInfo', {articulo: item.articulo_codigo, tipo: 2, bodega: this.selectedBodegaOrigen.bodega_codigo})
          if (infoPeticion.resultado == 1){
            this.posDetalleSeries = pos
            this.permiteGrabar = true
            // quito de las series series disponibles las que ya tengo agregadas
            let seriesDisponibles = this.getSeriesDisponibles(infoPeticion.articulos[0].series, item.series)
            // asigno valores
            this.seriesParam.articulo_codigo = item.articulo_codigo
            this.seriesParam.articulo_nombre = item.articulo_nombre
            this.seriesParam.articulo_cod_barras = item.articulo_cod_barras
            this.seriesParam.series_disponibles = seriesDisponibles
            this.seriesParam.series_seleccionadas = item.series
            this.seriesParam.cantidad = this.detalles[pos].cantidad
            this.seriesParam.precio = this.detalles[pos].precio
            this.seriesParam.importe = this.detalles[pos].importe
            this.seriesParam.solicita_serie = item.solicita_serie
            this.seriesParam.tipo = item.tipo
            this.seriesParam.dialogSerie = true
          }else{
            this.$store.dispatch('show_snackbar', {
              text: infoPeticion.msj,
              color: 'error',
            })
            // no permito grabar
            this.permiteGrabar = false
          }
        }
        this.$store.state.loading = false
      }
    },
    quitarArticulo(item){
      let pos = this.detalles.indexOf(item)
      this.detalles.splice(pos, 1)
    },
    setModalCSeries(value){
      this.seriesParam.dialogSerie = value
    },
    setSeriesArticulo(){
      if (this.agregarDetalle == 1){
        // articulo nuevo para agregar al detalle
        let art = {
          articulo_codigo: parseInt(this.seriesParam.articulo_codigo),
          articulo_cod_barras: this.seriesParam.articulo_cod_barras,
          articulo_nombre: this.seriesParam.articulo_nombre,
          cantidad: parseInt(this.seriesParam.cantidad),
          cantidadAnt: parseInt(this.seriesParam.cantidad),
          precio: this.convertDecimals(this.seriesParam.precio),
          importe: this.convertDecimals(this.seriesParam.importe),
          solicita_serie: this.seriesParam.solicita_serie,
          series: this.seriesParam.series_seleccionadas,
          tipo: this.seriesParam.tipo,
          reagrupado: 0,
          validado: 1
        }
        //lo agrego
        this.detalles.push(art)
      }else{
        // solo actualizo los campos que me interesan ya que el articulo se encuentra en el detalle
        this.detalles[this.posDetalleSeries].series = this.seriesParam.series_seleccionadas
      }
      this.agregarDetalle = 0
      this.posDetalleSeries = -1
      // limpio seriesParam
      this.limpiarSeriesParam()
      // limpio la seccion de articulos
      this.limpiarSeccionArticulos()
    },
    getSeriesDisponibles(seriesStock, seriesArt){
      let listado = []
      if (seriesStock.length > 0){
        for (let id in seriesStock){
          let cont = 0
          for (let jd in seriesArt){
            if (seriesStock[id].serie.toUpperCase() == seriesArt[jd].serie.toUpperCase()){
              cont = cont + 1
            }
          }
          if (parseInt(cont) == 0){
            listado.push(seriesStock[id])
          }
        }
      }
      return listado
    },
    async verSeries(item){
      this.agregarDetalle = 0
      // solicita serie?
      if (item.solicita_serie == 1){
        // obtengo la posicion donde se encuentra inicialmente el item
        this.posDetalleSeries = this.detalles.indexOf(item)
        this.$store.state.loading = true
        // obtengo nuevamente la info del articulo, en esta peticion obtengo las series (si es que la tuviera), por eso debo hacerla si o si, siempre mando tipo en 2 ya que tengo el código de artículo bien definido
        let infoPeticion = await this.$store.dispatch('stock/getArticuloInfo', {articulo: item.articulo_codigo, tipo: 2, bodega: this.selectedBodegaOrigen.bodega_codigo})
        this.$store.state.loading = false
        if (infoPeticion.resultado == 1){
          this.permiteGrabar = true
          // obtuve la informacion del articulo
          if (infoPeticion.articulos.length == 0){
            this.$store.state.loading = false
            // no se encontraron resultados
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron resultados para su búsqueda. Pruebe otro método de ingreso.',
              color: 'info',
            })
            this.limpiarSeccionArticulos()
            return
          }else{
            // obtuve la info, quito de las series disponibles las que ya tengo agregadas
            let seriesDisponibles = this.getSeriesDisponibles(infoPeticion.articulos[0].series, item.series)
            // asigno valores
            this.seriesParam.articulo_codigo = item.articulo_codigo
            this.seriesParam.articulo_nombre = item.articulo_nombre
            this.seriesParam.articulo_cod_barras = item.articulo_cod_barras
            this.seriesParam.series_disponibles = seriesDisponibles
            this.seriesParam.series_seleccionadas = item.series
            this.seriesParam.cantidad = item.cantidad
            this.seriesParam.precio = item.precio
            this.seriesParam.importe = item.importe
            this.seriesParam.solicita_serie = item.solicita_serie
            this.seriesParam.tipo = item.tipo
            this.seriesParam.dialogSerie = true
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: infoPeticion.msj,
            color: 'error',
          })
          // no permito grabar
          this.permiteGrabar = false
        }
      }
    },
    validarCantidadSeries(){
      for(let id in this.detalles){
        if (this.detalles[id].solicita_serie == 1){
          if (parseInt(this.detalles[id].cantidad) != parseInt(this.detalles[id].series.length)){
            this.$store.dispatch('show_snackbar', {
              text: 'Existen inconsistencias entre la cantidad a remitir y las series seleccionadas. No se puede grabar el remito.',
              color: 'error',
            })
            return false
          }
        }
      }
      return true
    },
    iniciarCargaAsistida(){
      // controlo que hayan ingresado la bodega
      if (!this.controlBodegas(2)){
        return
      }
      this.dialogAsistParam.tipoIngreso = this.tipoIngreso
      this.dialogAsistParam.bodegaOrigen = this.selectedBodegaOrigen.bodega_codigo
      this.dialogAsistParam.bodegaDestino = this.selectedBodegaDestino.bodega_codigo
      this.dialogAsistParam.mostrarTotales = this.mostrarTotales
      this.dialogAsistParam.archivo = 0
      this.dialogAsistParam.dialogActivo = true
    },
    iniciarImportarArchivo(){
      // controlo que hayan ingresado la bodega
      if (!this.controlBodegas(2)){
        return
      }
      this.dialogAsistParam.tipoIngreso = this.tipoIngreso
      this.dialogAsistParam.bodegaOrigen = this.selectedBodegaOrigen.bodega_codigo
      this.dialogAsistParam.bodegaDestino = this.selectedBodegaDestino.bodega_codigo
      this.dialogAsistParam.mostrarTotales = this.mostrarTotales
      this.dialogAsistParam.archivo = 1
      this.dialogAsistParam.dialogActivo = true
    },
    setModalCAsist(value){
      this.dialogAsistParam.dialogActivo = value
    },
    setTipIngCAsist(value){
      this.dialogAsistParam.tipoIngreso = value
      //this.tipoIngreso = value
    },
    executeCargaAsistida(res){
      if (res.acumular == 0){
        this.detalles = res.listadoSalida
      }else{
        // lo hicieron con archivo, acumulo totales
        if (this.detalles.length == 0){
          this.detalles = res.listadoSalida
        }else{
          // primero acumulo lo que no solicite serie
          let sinSeries = res.listadoSalida.filter(element => parseInt(element.solicita_serie) == 0)
          // luego lo que solicite serie
          let conSeries = res.listadoSalida.filter(element => parseInt(element.solicita_serie) == 1)
          if (sinSeries.length > 0){
            this.acumularSinSeries(sinSeries)
          }
          if (conSeries.length > 0){
            this.acumularConSeries(conSeries)
          }
        }
      }
    },
    verMsjControlarCantRemitir(item){
      this.$store.dispatch('show_snackbar', {
        text: 'La cantidad a remitir del artículo ' + item.articulo_codigo + ' fue recalculada. Verifique que sea la cantidad correcta a remitir.',
        color: 'info',
      })
    },
    validarCantRemitir(item){
      let pos = this.detalles.indexOf(item)
      this.detalles[pos].reagrupado = 0
      this.detalles[pos].validado = 1
    },
    acumularSinSeries(sinSeries){
      for (let id in sinSeries){
        let artBB = this.detalles.filter(element => element.articulo_codigo == sinSeries[id].articulo_codigo && element.articulo_codigo.toString() > 0)
        let artCodBarras = this.detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == sinSeries[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (artBB.length > 0 || artCodBarras.length > 0){
          if (artBB.length > 0){
            // acumulo y recalculo los totales
            let pos = this.detalles.indexOf(artBB[0])
            let cantidad = parseInt(this.detalles[pos].cantidad) + parseInt(sinSeries[id].cantidad)
            this.detalles[pos].cantidad = cantidad
            this.detalles[pos].cantidadAnt = cantidad
            this.detalles[pos].importe = this.convertDecimals(this.convertDecimals(this.detalles[pos].precio) * cantidad)
            this.detalles[pos].reagrupado = 1
            this.detalles[pos].validado = 0
          }else{
            // idem
            let pos = this.detalles.indexOf(artCodBarras[0])
            let cantidad = parseInt(this.detalles[pos].cantidad) + parseInt(sinSeries[id].cantidad)
            this.detalles[pos].cantidad = cantidad
            this.detalles[pos].cantidadAnt = cantidad
            this.detalles[pos].importe = this.convertDecimals(this.convertDecimals(this.detalles[pos].precio) * cantidad)
            this.detalles[pos].reagrupado = 1
            this.detalles[pos].validado = 0
          }
        }else{
          // no lo encontre, lo agrego
          this.detalles.push(sinSeries[id])
        }
      }
    },
    acumularConSeries(conSeries){
      // siempre me van a llegar los codigos de articulos
      for (let id in conSeries){
        let artBB = this.detalles.filter(element => element.articulo_codigo == conSeries[id].articulo_codigo && element.articulo_codigo.toString() > 0)
        if (artBB.length > 0){
          // existe, lo acumulo siempre y cuando las series que me respondieron del modal NO ESTEN YA AGREGADAS AL DETALLE
          let pos = this.detalles.indexOf(artBB[0])
          // controlo las series
          for (let jd in conSeries[id].series){
            let existe = this.detalles[pos].series.filter(element => element.serie.toString().toUpperCase() == conSeries[id].series[jd].serie)
            // solo me interesa si no encuentro la serie que vino del modal
            if (existe.length == 0){
              let unArtSerie = {
                articulo_codigo: conSeries[id].series[jd].articulo_codigo,
                serie: conSeries[id].series[jd].serie
              }
              this.detalles[pos].series.push(unArtSerie)
              let cantidadAgreg = parseInt(this.detalles[pos].cantidad) + 1
              this.detalles[pos].cantidad = cantidadAgreg
              this.detalles[pos].cantidadAnt = cantidadAgreg
              this.detalles[pos].importe = this.convertDecimals(this.detalles[pos].precio * cantidadAgreg)
              this.detalles[pos].reagrupado = 1
              this.detalles[pos].validado = 0
            }
          }
        }else{
          // no existe, lo agrego
          this.detalles.push(conSeries[id])
        }
      }
    }
  },
  watch: {
    tipoIngreso: function(){
      this.limpiarSeccionArticulos()
    },
    detalles: function(){
      if (this.detalles.length == 0){
        this.limpiarSeccionArticulos()
        this.limpiarSeriesParam()
      }
    }
  },
}
</script>

<style>

</style>