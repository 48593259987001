<template>
  <div>
    <v-dialog
      max-width="800"
      v-model="activo"
      persistent
    >
      <v-card
        flat
      >
        <v-card-title
          class="pt-0 pl-0 pr-0"
        >
          <v-toolbar
            color="indigo lighten-2"
            class="white--text"
          >
            <v-icon left class="mr-2 white--text">fas fa-disease</v-icon> Datos Clasificación
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="guardarDatosClasif()" ref="formDialog">
            <v-row no-gutters>
              <v-col cols="12" class="py-1">
                <v-autocomplete
                  v-model="select.antiguedad"
                  label="Antiguedad"
                  item-text="valor_nombre"
                  item-value="escala_medicion_valor_id"
                  :items="antiguedad"
                  clearable
                  outlined
                  dense
                  :rules="rules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-btn
                  color="success"
                  type="submit"
                  tabindex="1"
                  :loading="load"
                  block
                >
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearDatosEncClima', value)
      }
    },
    antiguedad: {
      get(){
        return this.datos.antiguedad
      }
    },
    encuesta_id: {
      get(){
        return this.datos.encuesta_id
      }
    }
  },
  data() {
    return {
      select: {
        antiguedad: null
      },
      rules: [
        v => !!v || 'Seleccione una Opcion.',
      ],
      load: false
    }
  },
  methods: {
    limpiarForm(){
      this.select = {
        antiguedad: null
      }
    },
    async guardarDatosClasif(){
      if (this.$refs.formDialog.validate()){
        this.load = true
        let savePeticion = await this.$store.dispatch('encuestas/guardar_datos_clasif', {
          antiguedad: this.select.antiguedad,
          encuesta_id: this.encuesta_id
        })
        this.load = false
        if (savePeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: savePeticion.message,
            color: 'error',
          })
          return
        }
        this.activo = false
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
    }
  },
}
</script>

<style>

</style>