<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="700"
      :persistent="load"
    >
      <v-card
        flat
        :loading="load"
        :disabled="load"
      >
        <v-card-title>
          <v-icon class="ma-2">fas fa-exchange-alt</v-icon>Cambiar Estado de Control
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Proveedor</th>
                      <th class="text-center">Facturas</th>
                      <th class="text-left">Estado Actual</th>
                      <th class="text-left">Nuevo Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in transiciones"
                      :key="item.index"
                    >
                      <td>{{ item.proveedor_nombre }}</td>
                      <td class="text-center">
                        <v-tooltip color="primary" left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="info"
                              class="ma-2"
                              style="cursor: pointer"
                              small
                            >
                              fas fa-info-circle
                            </v-icon>
                          </template>
                          <div v-for="fc in item.facturas" :key="fc.factura_id">
                            {{ fc.comprobante }}
                          </div>
                        </v-tooltip>
                      </td>
                      <td>{{ item.estado_control_nombre }}</td>
                      <td>
                        <v-autocomplete
                          v-model="item.new_est_control_id"
                          item-text="estado_control_nombre"
                          item-value="estado_control_id"
                          :items="estados_control"
                          hide-details
                          clearable
                          outlined
                          dense
                        ></v-autocomplete>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <BtnConfirmar
            texto="¿Está seguro de cambiar el estado de control de las facturas del listado?"
            @action="changeFCStates()"
            color="success"
            nombre="Actualizar Estados"
            icono="fas fa-magic"
            :loading="load"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { cadenaVacia } from '../../util/utils';
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      } 
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalCambEstCtlFC', value)
      }
    },
    facturas: {
      get(){
        return this.datos.facturas
      }
    },
    estados_control: {
      get(){
        return this.datos.estados_control
      }
    }
  },
  components: {
    BtnConfirmar,
  },
  data() {
    return {
      transiciones: [],
      load: false
    }
  },
  methods: {
    initForm(){
      // agrupo por estado y por proveedor
      let indice = -1
      for (let id in this.facturas){
        let existe = this.transiciones.filter(element => element.proveedor_codigo == this.facturas[id].proveedor_codigo && element.estado_control_id == this.facturas[id].estado_control_id)
        if (existe.length == 0){
          indice++
          this.transiciones.push({
            index: indice,
            proveedor_codigo: this.facturas[id].proveedor_codigo,
            proveedor_nombre: this.facturas[id].proveedor_nombre,
            estado_control_id: this.facturas[id].estado_control_id,
            estado_control_nombre: this.facturas[id].estado_control_nombre,
            facturas: this.facturas.filter(element => element.proveedor_codigo == this.facturas[id].proveedor_codigo && element.estado_control_id == this.facturas[id].estado_control_id),
            new_est_control_id: null,
            new_est_control_nom: null
          })
        }
      }
    },
    limpiarForm(){
      this.transiciones = []
      this.load = false
    },
    async changeFCStates(){
      let nuevosEst = this.transiciones.filter(element => cadenaVacia(element.new_est_control_id) || element.new_est_control_id == 0)
      if (nuevosEst.length == this.transiciones.length){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin cambios para aplicar',
          color: 'success',
        })
        this.$emit('succesChangeFC')
        this.activo = false
        return
      }
      this.load = true
      let changePeticion = await this.$store.dispatch('facturasCompra/cambiarFCEstadoControl', {transiciones: this.transiciones.filter(element => !cadenaVacia(element.new_est_control_id) && element.new_est_control_id != 0)})
      this.load = false
      if (changePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: changePeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Los estados de control se actualizaron correctamente.',
        color: 'success',
      })
      this.$emit('succesChangeFC')
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>