<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :persistent="load || editar"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ editar ? 'Editar' : '' }} Cheque Nº {{ cheque.num_comp }}
        <v-spacer></v-spacer>
        <v-switch
          v-if="permiso && !p_nuevo"
          v-model="editar"
          label="Editar"
          class="d-flex align-top mt-0"
          :disabled="load_data || !(cheque_copy.estado ? (estados_cheque.find(e => e.id == cheque_copy.estado).edita == 1 ? true : false) : false)"
          hide-details
          dense
        ></v-switch>
        <v-divider
          v-if="permiso"
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="load_data">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-text v-else class="pb-sm-4 pb-0">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="5" sm="4" md="2" class="py-0">
              <v-autocomplete
                v-model="cheque.formato"
                label="Formato"
                item-text="nombre"
                item-value="id"
                :items="formatos_cheque"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="7" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="cheque.tipo"
                label="Tipo"
                item-text="nombre"
                item-value="id"
                :items="tipos_cheque"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="cheque.origen"
                label="Origen"
                item-text="nombre"
                item-value="codigo"
                :items="origenes"
                outlined
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="cheque.estado"
                label="Estado"
                item-text="nombre"
                item-value="id"
                :items="estados_cheque"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="estados_cheque.find(e => e.id == cheque.estado).banco_pago != 1 ? (cheque.banco_pago = null, cheque.cuenta_pago = null, cheque.cbu_pago = null, cheque.fecha_pago = null) : ''; banco_default()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-text-field-money
                v-model="cheque.importe"
                label="Importe"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  outlined: true,
                  readonly: true,
                  filled: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
              />
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <FechaPicker
                v-model="cheque.emision"
                label="Emisión"
                disabled
              />
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <FechaPicker
                v-model="cheque.vencimiento"
                label="Vencimiento"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <FechaPicker
                v-if="estados_cheque.find(e => e.id == cheque.estado) ? (estados_cheque.find(e => e.id == cheque.estado).banco_pago == 1 ? true : false) : false"
                v-model="cheque.fecha_pago"
                :disabled="!editar"
                label="Fecha pago"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0" v-if="visible == 1">
              <v-autocomplete
                v-model="cheque.banco"
                label="Banco emisor"
                item-text="nombre"
                item-value="codigo"
                :items="all_bancos.filter(b => (b.inhabilitado != 1 && b.cuentas > 0) || b.codigo == cheque.banco)"
                outlined
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0" v-if="visible == 1">
              <v-autocomplete
                v-model="cheque.cuenta"
                label="Cuenta"
                item-text="numero"
                item-value="codigo"
                :items="all_cuentas.filter(c => (c.inhabilitada != 1 && c.banco_codigo == cheque.banco && c.empresa_codigo == cheque.empresa) || c.codigo == cheque.cuenta)"
                outlined
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0" v-if="visible == 1"> 
              <v-text-field
                v-model="cheque.cbu"
                label="CBU"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <!-- template para los estados q permiten ingresar el banco real del pago -->
            <template
              v-if="estados_cheque.find(e => e.id == cheque.estado) ? (estados_cheque.find(e => e.id == cheque.estado).banco_pago == 1 ? true : false) : false"
            >
              <v-col cols="12" sm="4" class="py-0">
                <v-autocomplete
                  v-model="cheque.banco_pago"
                  label="Banco pago"
                  item-text="nombre"
                  item-value="codigo"
                  :items="all_bancos.filter(b => (b.inhabilitado != 1 && b.cuentas > 0) || b.codigo == cheque.banco_pago)"
                  :rules="editar ? [rules.required] : []"
                  :readonly="!editar"
                  :filled="!editar"
                  validate-on-blur
                  outlined
                  dense
                  @change="cheque.cuenta_pago = null; cheque.cbu_pago = null"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="py-0">
                <v-autocomplete
                  v-model="cheque.cuenta_pago"
                  label="Cuenta pago"
                  item-text="numero"
                  item-value="codigo"
                  :items="all_cuentas.filter(c => (c.inhabilitada != 1 && c.banco_codigo == cheque.banco_pago && c.empresa_codigo == cheque.empresa) || c.codigo == cheque.cuenta_pago)"
                  :rules="editar ? [rules.required] : []"
                  :readonly="!editar"
                  :filled="!editar"
                  validate-on-blur
                  outlined
                  dense
                  @change="cheque.cbu_pago = all_cuentas.find(c => c.codigo == cheque.cuenta_pago) ? all_cuentas.find(c => c.codigo == cheque.cuenta_pago).cbu : null"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  v-model="cheque.cbu_pago"
                  label="CBU pago"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </template>
            <!-- gestor -->
            <template
              v-if="cheque.origen == 1"
            >
             <v-col cols="4" sm="4" md="3" class="py-0">
                <v-text-field
                  v-model="cheque.cliente_codigo"
                  label="Cliente"
                  ref="gestorCod"
                  type="number"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="6" class="py-0">
                <v-text-field
                  v-model="cheque.cliente_nombre"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col> 
            </template>
            <!-- Banco Emisor -->
            <v-col cols="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="cheque.banco_pais"
                label="Banco Emisor"
                item-text="banco_pais_nombre"
                item-value="banco_pais_id"
                :filled="!editar"
                :items="bancos_pais"
                outlined
                dense
                :disabled="!editar"
                clearable
              ></v-autocomplete>
            </v-col>
            <!-- beneficiario -->
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field
                v-model="cheque.beneficiario"
                label="Beneficiario"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field-integer
                v-model="cheque.beneficiario_doc"
                label="Beneficiario CUIT"
                v-bind:properties="{
                  type: 'tel',
                  'validate-on-blur': true,
                  outlined: true,
                  readonly: !editar,
                  filled: !editar,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '##-########-#',
                  outputMask: '###########',
                  empty: null
                }"
              />
            </v-col>
            <v-col
              v-if="cheque.origen == 4"
              cols="6" sm="4" md="3"
              class="py-0"
            >
              <v-text-field
                v-model="cheque.num_liquidacion"
                label="Nº liquidación"
                type="number"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-3 px-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!p_nuevo"
            class="mt-sm-0 mt-3"
            :color="editar ? 'error' : 'secondary'"
            :disabled="load || load_data"
            @click="dialog = false"
          >
            {{ editar ? 'Cancelar' : 'Volver' }}
          </v-btn>
          <BtnConfirmar
            v-if="editar"
            clase="ml-2 mt-sm-0 mt-3"
            :disabled="load_data"
            :loading="load"
            @action="guardar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'

export default {
  data () {
    return {
      permiso: false,
      load: false,
      load_data: false,
      dialog_gestor: false,
      editar: false,
      cheque: {},
      cheque_copy: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      visible: 0,
      bancos_pais: []
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('cheques/get_tipos')
    await this.$store.dispatch('cheques/get_estados')
    await this.$store.dispatch('cheques/get_formatos')
    await this.$store.dispatch('bancos/get_all_cuentas')
    this.permiso = await this.$store.dispatch('cheques/get_permiso_editar')
    let bancosPeticion = await this.$store.dispatch('bancos/get_bancos_pais')
    if (bancosPeticion.exito == 1) this.bancos_pais = bancosPeticion.bancos_pais
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_id: Number,
    p_nuevo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('cheques', ['tipos_cheque', 'estados_cheque', 'formatos_cheque']),
    ...mapState('bancos', ['all_bancos', 'all_cuentas']),
    ...mapState('movBancarios', ['origenes']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.editar = this.p_nuevo ? true : false
        this.cheque = {}
        if (this.p_id) {
          this.load_data = true
          await this.$store.dispatch('cheques/get_cheque', this.p_id)
            .then((res) => {
              this.cheque = res.data
              this.cheque_copy = JSON.parse(JSON.stringify(this.cheque))
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          await this.$store.dispatch('bancos/get_all_bancos', this.cheque.empresa)
          this.load_data = false
        }
      } else {
        this.$refs.form.resetValidation()
      }
    },
    editar (val) {
      this.$refs.form.resetValidation()
      if (!val) this.cheque = JSON.parse(JSON.stringify(this.cheque_copy))
    }
  },
  methods: {
    banco_default () {
      let estado_viejo = this.estados_cheque.find(e => e.id == this.cheque_copy.estado)
      let estado_nuevo = this.estados_cheque.find(e => e.id == this.cheque.estado)
      if (estado_viejo && estado_nuevo && estado_viejo.banco_pago != 1 && estado_nuevo.banco_pago == 1) {
        this.cheque.banco_pago = 1
        this.cheque.cuenta_pago = 13
        this.cheque.cbu_pago = this.all_cuentas.find(c => c.codigo == 13) ? this.all_cuentas.find(c => c.codigo == 13).cbu : null
      }
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        // valido que fecha de vecimiento no sea null
        if (!this.cheque.vencimiento) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha de vencimiento',
            color: 'orange'
          })
        }
        // si es un estado que solicita banco_pago la fecha de pago es obligatoria
        if (this.estados_cheque.find(e => e.id == this.cheque.estado).banco_pago == 1 && this.cheque.fecha_pago == null) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha de pago',
            color: 'orange'
          })
        }

        this.load = true
        // solo edita si realmente cambio algun valor
        if (JSON.stringify(this.cheque) != JSON.stringify(this.cheque_copy)) {
          await this.$store.dispatch('cheques/editar', this.cheque)
            .then((res) => {
              this.dialog = false
              // emite la accion definifa en el padre
              this.$emit('editar', {
                id: this.cheque.id,
                emision: this.cheque.emision,
                vencimiento: this.cheque.vencimiento,
                estado: get_nombre_obj_arr(this.estados_cheque, 'id', this.cheque.estado, 'nombre')
              })
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en el cheque: los campos nuevos son iguales a los anteriores',
            color: 'orange',
            timeout: 4000
          })
        }
        this.load = false        
      }
    }
  }
}
</script>