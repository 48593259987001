<template>
<div>
<v-dialog
    v-model="activo"
    width="850"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">Nuevo descuento</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-form @submit.prevent="guardarNuevoDesc()">
          <v-row class="pt-2">
            <v-col cols="12" sm="6" md="4" class="py-1">
              Documento
              <v-text-field
                v-if="esDni"
                v-model.trim="documentoNvo"
                type="number"
                hide-details
                outlined
                dense
                clearable
                @blur="determinarTipoDoc()"
              ></v-text-field>
              <v-text-field-integer
                v-if="!esDni"
                v-model.trim="documentoNvo"
                v-bind:properties="{
                  hideDetails: true,
                  clearable: true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '##-########-#',
                  outputMask: '###########',
                  empty: null
                }"
                @blur="determinarTipoDoc()"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-1">
              Cliente
              <v-text-field
                v-model="clienteNom"
                hide-details
                outlined
                dense
                readonly
                filled
              >
                <template v-slot:append-outer v-if="existeCliente">
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon class="px-2" color="info" small >
                          fas fa-info-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> Este documento no le pertenece a ningún cliente registrado. Igual puede continuar para grabar. </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-1">
              Porcentaje
              <v-text-field
                v-model="porcentaje"
                type="number"
                suffix="%"
                hide-details
                outlined
                dense
                clearable
                @change="controlarPorcentaje()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1 ">
              Rubro
              <v-autocomplete
                v-model="rubro_codigo"
                item-text="nombre"
                item-value="codigo"
                :items="rubros"
                hide-details
                outlined
                dense
                clearable
                @change="determinarCategoria()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              Categoria
              <v-autocomplete
                v-model="categoria_codigo"
                item-text="nombre"
                item-value="codigo"
                :items="rubro_codigo ? catPorRubro : categorias"
                hide-details
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              Fecha Desde
              <FechaPickerVue
                v-model="fechaDesde"
                hideDetails
                clearable
              />
            </v-col>            
            <v-col cols="12" sm="6" md="3" class="py-1">
              Fecha Hasta
              <FechaPickerVue
                v-model="fechaHasta"
                hideDetails
                :fecha_minima="fechaDesde"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              Articulo Código
              <v-text-field
                v-model="articulo_codigo"
                type="number"
                hide-details
                outlined
                dense
                clearable
                @change="obtenerArticulo()"
              >
                <template v-slot:append-outer>
                  <v-btn icon small>
                    <v-icon
                      color="info"
                      title="Buscar Articulo"
                      small
                      @click="abrirModal()"
                    >
                      fas fa-search
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="9" class="py-1">
              Nombre
              <v-text-field
                v-model="articulo_nombre"
                hide-details
                outlined
                dense
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1">
              Descripcion
              <v-text-field
                v-model="descripcion"
                hide-details
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          :texto="`Creará un nuevo descuento para el cliente ${ clienteNom ? clienteNom :''}. ¿Confirma esta accion?`"
          :nombre="'Grabar'"
          :loading="load"
          @action="guardarNuevoDesc()"
        />
      </v-card-actions>
    <ModalSeleccionarArticuloVue 
      v-model="objModal.dialogArt"
      :datos="objModal"
      @seleccionarArticulo="cargarNombreArticulo"
    />
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapState } from 'vuex';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import FechaPickerVue from '../util/FechaPicker.vue';
import ModalSeleccionarArticuloVue from './ModalSeleccionarArticulo.vue';

export default {
  name: 'ModalAgregarNvoDescEspecial',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    documento:{
      get(){
        return this.datos.cliente_documento;
      }
    },
    nombre_cliente:{
      get(){
        return this.datos.cliente_nombre;
      }
    },
    ...mapState('rubros',['rubros']),
    ...mapState('categorias',['categorias']),
  },
  data(){
    return{
      documentoNvo:null,
      clienteNom: null,
      porcentaje: null,
      //rubros: [],
      rubro_codigo: null,
      //categorias: [],
      categoria_codigo: null,
      catPorRubro: [],
      articulo_codigo: null,
      articulo_nombre: null,
      fechaDesde: null,
      fechaHasta: null,
      descripcion: null,
      load: false,
      esDni: true,
      objModal: {
        categoria_codigo: 0,
        dialogArt: false,
      },
      existeCliente: false,
    }
  },
  created(){
  },
  methods:{
    async init(){
      //Rubro
      this.$store.dispatch('rubros/get_rubros_habilitados');
      //Categorias 
      this.$store.dispatch('categorias/get_categorias_habilitadas');
    },
    determinarCategoria(){
      if(this.rubro_codigo){
        this.catPorRubro = this.categorias.filter(e => e.rubro_codigo == this.rubro_codigo);
      }else{
        this.rubro_codigo = null;
        this.categoria_codigo = null;
        this.catPorRubro = [];
      }
    },
    determinarTipoDoc(){
      if(this.documentoNvo){
        if(0 < this.documentoNvo.length && this.documentoNvo.length <= 8){
          this.esDni = true;
        }
        if(8 < this.documentoNvo.length){
          this.esDni = false; //esCuit
        }
        this.existeCliente = false;
        this.buscarNombreXdni();
      }
    },
    async buscarNombreXdni(){
      if(this.documentoNvo){
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('promociones/getCliente', { documento: this.documentoNvo.toUpperCase() })
        this.$store.state.loading = false;
        
        if(res.resultado == 0){
          this.existeCliente = true;
          this.clienteNom = null;
          return this.$store.dispatch('show_snackbar', { text: res.message+' Igual puede continuar y grabar el descuento.', color: 'info', })
        }
        this.existeCliente = false;
        this.clienteNom = res.cliente.cliente_nombre; // /find
      }
    },
    controlarPorcentaje(){
      if(this.porcentaje){
        if(this.porcentaje < 0){
          this.porcentaje = 0;
        }
        if(this.porcentaje > 100){
          this.porcentaje = 100;
        }
      }
    },
    async obtenerArticulo(){
      if(this.articulo_codigo && this.articulo_codigo != 0){
        this.$store.state.loading = true; //obtenerArticuloPorCodigo
        let res = await this.$store.dispatch('promociones/obtenerArticuloPorCodigo2', { articulo_codigo: this.articulo_codigo, categoria_codigo: 0 });
        this.$store.state.loading = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
        }
        //find
        if(res.articulo.length == 1){
          this.cargarNombreArticulo(res.articulo[0]);
        }
        if(res.articulo.length == 0){
          this.objModal.dialogArt = true;
          this.objModal.categoria_codigo = 0;
        }
      }else{
        this.articulo_nombre = null;
        this.objModal.dialogArt = true;
        this.objModal.categoria_codigo = 0;
      }
    },
    cargarNombreArticulo(item){
      this.objModal.dialogArt = false;
      this.objModal.categoria_codigo = 0;
      this.articulo_nombre = item.articulo_nombre;
      this.articulo_codigo = item.articulo_codigo;
      this.rubro_codigo = item.rubro_codigo;
      this.categoria_codigo = item.categoria_codigo;
      this.determinarCategoria();
    },
    abrirModal(){
      this.objModal.dialogArt = true;
      this.objModal.categoria_codigo = 0;
    },
    validadCampos(){
      let error = {};
      if(!this.documentoNvo){
        error.msg = 'Debe ingresar el campo documento.';
        error.color = 'warning';
        return error
      }
      if(this.porcentaje == null){
        error.msg = 'Debe ingresar el campo porcentaje.';
        error.color = 'warning';
        return error
      }
      if(!this.fechaDesde){
        error.msg = 'Debe ingresar el campo Fecha Desde.';
        error.color = 'warning';
        return error
      }
      if(!this.fechaHasta){
        error.msg = 'Debe ingresar el campo Fecha Hasta.';
        error.color = 'warning';
        return error
      }
      if(!this.rubro_codigo && !this.categoria_codigo && (!this.articulo_codigo || parseInt(this.articulo_codigo) == 0)){
        error.msg = 'Debe ingresar al menos un rubro, una categoria o un articulo.';
        error.color = 'warning';
        return error
      }
      return error;
    },
    async guardarNuevoDesc(){
      //Valido campos
      let error 
      error = this.validadCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', { text: error.msg, color: error.color });
      }

      /*let descrip;
      if(!this.articulo_codigo && (this.rubro_codigo || this.categoria_codigo) && existeCliente){
        descrip = 'CLIENTE '+ this.clienteNom.toUpperCase();
      }else{
        if(!this.articulo_codigo && (this.rubro_codigo || this.categoria_codigo) && !existeCliente){

        }
      }*/

      //Llamo a la api para nuevo
      this.$store.state.loading = true;
      let res = await this.$store.dispatch('promociones/nuevoDescuentoEspecial', {
        cliente_dni: this.documentoNvo,
        porcentaje: parseFloat(this.porcentaje),
        fecha_desde: this.fechaDesde,
        fecha_hasta: this.fechaHasta,
        rubro_codigo: this.rubro_codigo,
        categoria_codigo: this.categoria_codigo,
        articulo_codigo: this.articulo_codigo,
        descripcion: this.descripcion ? this.descripcion.toUpperCase() : `CLIENTES ${this.articulo_codigo ? (this.articulo_nombre+', ') : ''} ${ this.clienteNom ?  this.clienteNom.toUpperCase() : ''}`,
      });
      this.$store.state.loading = false;

      if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }

      this.$swal.fire({
        icon: 'success',
        title: 'Descuento Especial creado',
        text: 'Se creo el descuento especial con éxito para el cliente'+ ( this.clienteNom ? (': '+this.clienteNom) : '.' ),
        timer: 3000
      });

      this.activo = false;
      this.$emit('buscarNuevoDesc', this.documentoNvo);
    },
    limpiar(){
      this.documentoNvo =null,
      this.clienteNom = null,
      this.porcentaje = null,
      //this.rubros = [],
      this.rubro_codigo = null,
      //this.categorias = [],
      this.categoria_codigo = null,
      this.catPorRubro = [],
      this.articulo_codigo = null,
      this.articulo_nombre = null,
      this.fechaDesde = null;
      this.fechaHasta = null;
      this.descripcion = null;
      this.load = false,
      this.esDni = true,
      this.objModal = {
        dialogArt: false,
        categoria_codigo : 0
      }
    },
  },
  components:{
    FechaPickerVue, BtnConfirmarVue, ModalSeleccionarArticuloVue
  },
  watch:{
    activo: function(val){
      if(val){
        this.documentoNvo = this.documento; //los anteriores;
        this.clienteNom = this.nombre_cliente; //los anteriores;
        this.init();
      }else{
        this.limpiar();
      }
    },
    documentoNvo: function(val){
      if(!val){
        this.clienteNom = null;
        this.existeCliente = false;
      }
    },
    articulo_codigo: function(val){
      if(!this.articulo_codigo){
        this.articulo_nombre = null;
      }
    }
  }
}
</script>

<style>

</style>