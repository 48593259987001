<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col class="d-flex align-center text-h6 font-weight-regular pt-0 pb-4">
      <div class="align-center" :class="!$vuetify.breakpoint.xs ? 'd-flex' : ''">
        <div class="mr-2">
          {{ nueva ? 'Nueva venta mayorista' : `Venta mayorista N° ${id_venta}` }}
        </div>
        <v-scroll-x-transition>
          <span v-if="!nueva && log.usuario != ''" class="body-2">
            <span v-if="!$vuetify.breakpoint.xs" class="mr-1">–</span>
            Grabada por {{ log.usuario }} el {{ log.fecha }}
          </span>
        </v-scroll-x-transition>
      </div>
      <v-spacer></v-spacer>
      <v-slide-x-reverse-transition>
        <v-switch
          v-if="!nueva && permite_editar == 1 && edita_paquete == 1 && jarvis == 1"
          v-model="editar"
          label="Editar"
          class="mt-0"
          hide-details
          dense
        ></v-switch>
      </v-slide-x-reverse-transition>
    </v-col>
    <!-- ---------------- -->
    <!-- LOCAL Y VENDEDOR -->
    <!-- ---------------- -->
    <v-col cols="12">
      <v-row>
        <v-col cols="6" sm="3" md="2" class="py-0">
          <v-autocomplete
            v-model="sucursal"
            ref="atcSucursal"
            label="Sucursal"
            item-text="nombre"
            item-value="id"
            tabindex="1"
            :items="sucursales"
            :readonly="!nueva || bloquear_ptovta"
            :filled="!nueva || bloquear_ptovta"
            outlined
            dense
            @change="getPtovta()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-autocomplete
            v-model="ptovta"
            ref="atcLocal"
            label="Local"
            item-text="nombre"
            tabindex="1"
            :items="locales_user.filter(loc => loc.sucursal == sucursal && loc.canal == 2)"
            :readonly="!nueva || bloquear_ptovta"
            :filled="!nueva || bloquear_ptovta"
            return-object
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="9" md="6">
          <v-row>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor"
                ref="vtfVendedor"
                label="Vendedor"
                type="number"
                tabindex="1"
                :readonly="!nueva || bloquear_vend"
                :filled="!nueva || bloquear_vend"
                outlined
                dense
                @blur="buscarVendedor()"
                @keypress="enterKey"
              ></v-text-field>
            </v-col>
            <v-col cols="8" class="py-0">
              <v-text-field
                v-model.trim="vendedor_nombre"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- ----------------------- -->
    <!-- COMPONENTE DE ARTICULOS -->
    <!-- ----------------------- -->
    <template>
      <!-- CABECERA ART -->
      <v-col cols="12" class="pt-0" :class="!editar ? 'pb-0' : ''">
        <v-divider></v-divider>
        <div class="d-flex align-center py-3" style="font-size: 18px;">
          <v-icon left>fas fa-shopping-cart</v-icon>
          Artículos
          <v-btn
            v-if="editar"
            color="info"
            class="ml-2"
            title="Buscar artículos"
            small
            icon
            @click="modal_articulos = true"
          >
            <v-icon small>fas fa-search</v-icon>
          </v-btn>
          <v-switch
            v-if="nueva"
            v-model="simular"
            label="Simular"
            class="mt-0 ml-3"
            hide-details
            dense
          ></v-switch>
          <v-spacer></v-spacer>
          <v-col v-if="cupon != null && !nueva" cols="6" sm="4" md="3" xl="2" class="pa-0 pl-3">
            <v-text-field
              v-model.trim="cupon"
              label="Cupón de descuento"
              hide-details
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
        </div>
        <v-row v-if="editar">
          <v-col cols="12" sm="7" md="5" xl="4" class="d-flex align-center">
            <v-row no-gutters>
              <v-col cols="5" sm="4">
                <v-text-field
                  v-model.trim="cantidad"
                  label="Cantidad"
                  ref="vtfArticulo"
                  type="tel"
                  class="mr-3"
                  tabindex="1"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="7" sm="8" class="d-flex align-center">
                <v-text-field
                  v-model.trim="articulo"
                  label="Artículo"
                  type="tel"
                  class="mr-3"
                  tabindex="1"
                  hide-details
                  outlined
                  dense
                  @blur="buscarArticulo(1)"
                  @keypress="enterKey"
                ></v-text-field>
                <v-btn
                  v-if="editar && ($device.android || $device.ios)"
                  color="cyan"
                  class="mr-2"
                  title="Buscar artículos"
                  x-small
                  dark
                  fab
                  @click="modal_camara = true"
                >
                  <v-icon small>fas fa-camera</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn
              color="info"
              tabindex="1"
              @click="buscarArticulo(1)"
            >
              Agregar
            </v-btn>
          </v-col>
          <v-col v-if="false" cols="8" sm="3" md="2" class="d-flex align-center px-sm-0 ml-sm-3">
            <v-autocomplete
              v-model="lista"
              label="Lista"
              tabindex="1"
              item-value="codigo"
              item-text="nombre"
              :items="listas"
              hide-details
              outlined
              readonly
              filled
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="4" md="2">
            <v-text-field
              v-if="nueva"
              v-model.trim="cupon"
              label="Cupón de descuento"
              style="margin-top: 2px"
              tabindex="1"
              hide-details
              clearable
              outlined
              dense
              @change="validarCupon()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- LISTA DE ARTICULOS -->
      <v-col cols="12" :class="!editar ? 'pt-0' : ''">
        <v-data-table
          class="elevation-2 cebra"
          item-key="codigo"
          :items="articulos"
          :headers="headersArt"
          :items-per-page="-1"
          hide-default-footer
          mobile-breakpoint
          dense
        >
          <!-- campos editables -->
          <template v-slot:[`item.cantidad`]="{ item }">
            <v-text-field
              v-model.trim="item.cantidad"
              type="number"
              class="py-1"
              :readonly="!editar"
              :filled="!editar"
              hide-details
              outlined
              dense
              @change="validarCantidades(item)"
            ></v-text-field>
          </template>
          <!-- campos con formato -->
          <template v-slot:[`item.precio`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.importe`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.stock_restante`]="{ item }">
            <div class="d-flex">
              <span :class="(item.stock - item.cantidad < 0 || item.cantidad == 0) && !simular ? 'red--text font-weight-bold' : ''">
                {{ item.stock - item.cantidad }}
              </span>
              <v-icon
                v-if="item.stock - item.cantidad < 0 && !simular"
                color="orange"
                :title="`No puede grabar una venta con stock Negativo. Verificar`"
                :class="`ml-3 mt-1 pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                small
              >
                fas fa-exclamation-triangle
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.descuento`]="{ item }">
            {{ item.descuento.toFixed(parseFloat(item.descuento) % 1 == 0 || parseFloat(item.descuento) == 0 ? 0 : 2) }} %
            <v-tooltip
              v-if="item.descuento > 0 && promociones.find(pro => pro.articulo == item.codigo)"
              color="primary"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="info"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <div
                v-for="(promo, index) in promociones.find(pro => pro.articulo == item.codigo).detalle"
                :key="index"
                style="width: 400px;"
              >
                <v-row style="width: 100%;" no-gutters>
                  <v-col cols="10" class="font-weight-bold">
                    {{ promo.nombre.substring(0, 38) }}
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <strong>
                      {{ promo.porcentaje.toFixed(parseFloat(promo.porcentaje) % 1 == 0 || parseFloat(promo.porcentaje) == 0 ? 0 : 2) }} %
                    </strong>
                  </v-col>
                </v-row>
              </div> 
            </v-tooltip>
          </template>
          <!-- acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="editar"
              color="error"
              title="Quitar"
              style="margin-right: 6px;"
              small
              @click="quitarArticulo(item)"
            >
              fas fa-times-circle
            </v-icon>
            <v-icon
              v-if="item.solicita_serie == 1"
              color="cyan"
              title="Series"
              style="margin-right: 6px;"
              small
              :disabled="simular"
              @click="verSeries(item)"
            >
              fas fa-th-list
            </v-icon>
          </template>
          <!-- no datos -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="600"
              type="info"
              border="left"
              dense
              text
            >
              Agregue artículos por IMEI (serie), código de Barra o código Interno
            </v-alert>
          </template>
          <!-- fila del total de articulos -->
          <template slot="body.append">
            <tr class="font-weight-bold">
              <th style="font-size: 14px" >Subtotal</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="font-size: 16px" class="text-right">
                {{ formatMoney(this.monto_articulos) }}
              </th>
              <th></th>
              <th v-if="editar"></th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </template>
    <!-- ----------------------- -->
    <!-- COMPONENTE DE CLIENTE   -->
    <!-- ----------------------- -->
    <v-col cols="12">
      <cliente-mayorista
        :datos="cliente_param"
        :p_refresh="editar"
        p_venta
        @setSelectCliente="setCliente"
        @setearClearCliente="setClearCliente"
      />
    </v-col>
    <!-- ----------------------- -->
    <!-- COMPONENTE DE ENVIOS    -->
    <!-- ----------------------- -->
    <v-col cols="12">
      <v-card>
        <v-card-title class="py-3">
          <v-icon left>fas fa-truck</v-icon>
          Envío
        </v-card-title>
        <v-card-text :class="`body-1 ${$vuetify.theme.dark ? 'white' : 'secondary'}--text`" class="mb-0">
          Elija el método de Envío del Producto
          <v-radio-group
            v-model="envio"
            class="my-1"
            :column="$vuetify.breakpoint.smAndDown"
            :row="!$vuetify.breakpoint.smAndDown"
            :disabled="!nueva"
            dense
            @change="setEnvio()"
          >
            <v-radio
              label="Retiro en Sucursal (entrega presencial)"
              :value="1"
            ></v-radio>
            <v-radio
              label="Envio a Domicilio (armado de pedido)"
              :value="2"
            ></v-radio>
          </v-radio-group>
          <v-expand-transition>
            <div v-if="envio == 2">
              <v-form ref="formEnvio" class="pt-8">
                <v-row>
                  <v-col cols="6" sm="3" class="py-0">
                    <v-autocomplete
                      v-model="datos_envio.provincia"
                      label="Provincia"
                      item-text="nombre"
                      item-value="codigo"
                      :items="provincias"
                      :readonly="!editar"
                      :filled="!editar"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      @change="datos_envio.localidad = null; datos_envio.cp = ''; datos_envio.direccion = ''; datos_envio.observaciones = ''; getLocalidades()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" class="py-0">
                    <v-autocomplete
                      v-model="datos_envio.localidad"
                      label="Localidad"
                      item-text="nombre"
                      item-value="codigo"
                      :items="localidades"
                      :readonly="!editar"
                      :filled="!editar"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      @change="datos_envio.cp = ''; datos_envio.direccion = ''; datos_envio.observaciones = ''; setCP()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos_envio.cp"
                      label="Código Postal"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="4" class="py-0">
                    <v-text-field
                      v-model="datos_envio.direccion"
                      label="Dirección"
                      :rules="[rules.required]"
                      :readonly="!editar"
                      :filled="!editar"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      v-model="datos_envio.observaciones"
                      label="Observaciones"
                      placeholder="Ingrese la informacion complementaria del domicilio de entrega. Ej: Casa amarilla con rejas negras"
                      :rows="$vuetify.breakpoint.xs ? 4 : 3"
                      :readonly="!editar"
                      :filled="!editar"
                      persistent-placeholder
                      auto-grow
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- ----------------------- -->
    <!-- SALDOS Y TOTALES        -->
    <!-- ----------------------- -->
    <!-- SUBTOTALES -->
    <v-col v-if="editar" cols="12" sm="8" md="6" xl="5">
      <v-card class="pa-3" style="font-size: 18px; cursor: default;">
        <v-row class="pb-2">
          <v-col>Subtotal</v-col>
          <v-col class="d-flex justify-end font-weight-bold success--text">
            {{ formatMoney(monto_articulos) }}
          </v-col>
        </v-row>
        <v-row v-if="saldo < 0" class="mt-n6 pb-2">
          <v-col>Saldo</v-col>
          <v-col class="d-flex justify-end font-weight-bold orange--text">
            {{ formatMoney(saldo) }}
          </v-col>
        </v-row>
        <v-divider class="pt-2"></v-divider>
        <v-row class="font-weight-bold">
          <v-col>Total a Pagar</v-col>
          <v-col class="d-flex justify-end primary--text" style="font-size: 20px;">
            {{ formatMoney(monto_articulos + (saldo < 0 ? (-1 * saldo) : 0)) }}
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- MEDIOS DE PAGO -->
    <v-col cols="12" sm="8" md="6" xl="5">
      <v-card class="pa-3" style="font-size: 16px; cursor: default;">
        <template v-if="editar || jarvis == 1">
          <v-row>
            <v-col cols="7" sm="8" class="d-flex align-center">
              <v-icon color="purple" style="padding-right: 10px;" small>
                fas fa-wallet
              </v-icon>
              Saldo {{ !$vuetify.breakpoint.xs ? 'Cta. Cte.' : '' }}
              <span v-if="saldo > 0" class="ml-2" style="font-size: 14px;">
                (<strong class="success--text">{{ formatMoney(saldo) }}</strong>)
              </span>
            </v-col>
            <v-col cols="5" sm="4" class="d-flex justify-end font-weight-medium" style="font-size: 18px;">
              {{ formatMoney(monto_saldo) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" sm="8" class="d-flex align-center pt-0">
              <v-icon color="success" small left>
                fas fa-money-bill-alt
              </v-icon>
              Efectivo
              <v-checkbox
                v-if="editar"
                v-model="efectivo"
                class="mt-n1 ml-2 mr-n1"
                hide-details
                dense
                @change="calcularTotales()"
              >
                <template v-slot:label>
                  <span class="body-2">(Solo efectivo)</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="5" sm="4" class="d-flex justify-end font-weight-medium pt-0" style="font-size: 18px;">
              <text-field-money
                v-model="monto_efectivo"
                v-bind:properties="{
                  prefix: '$',
                  readonly: !editar,
                  filled: !editar,
                  hideDetails: true
                }"
                @change="calcularTotales(1)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" sm="8" class="d-flex align-center pt-0">
              <v-icon color="pink" small left>
                fas fa-money-check-alt
              </v-icon>
              Crédito
              <span v-if="editar" class="ml-2" style="font-size: 14px;">
                (límite: <strong class="success--text">{{ formatMoneyRound(cliente.limite_credito ? cliente.limite_credito : 0) }}</strong>)
              </span>
            </v-col>
            <v-col cols="5" sm="4" class="d-flex justify-end font-weight-medium pt-0" style="font-size: 18px;">
              <text-field-money
                v-model="monto_credito"
                v-bind:properties="{
                  prefix: '$',
                  readonly: efectivo || bloquear_credito || credito <= 0 || !editar,
                  filled: efectivo || bloquear_credito || credito <= 0 || !editar,
                  hideDetails: true
                }"
                @change="calcularTotales(2)"
              />
            </v-col>
          </v-row>
        </template>
        <v-divider class="mt-3 mb-5"></v-divider>
        <v-row class="d-flex justify-center font-weight-bold" style="font-size: 20px;">
          <v-col cols="7" sm="8" class="d-flex align-center pt-0">
            Total
          </v-col>
          <v-col cols="5" sm="4" class="d-flex justify-end primary--text pt-0">
            <strong>
              {{ formatMoney(monto_total) }}
            </strong>
          </v-col>
        </v-row>
        <v-row v-show="roundNumberTF(saldo_final) != 0 && cliente.vendedor_codigo">
          <v-col cols="12" class="d-flex justify-end pt-0">
            <span>
              (<span>
                Saldo Final Cta. Cte.
                <strong :class="`${saldo_final > 0 ? 'success' : 'orange'}--text`">
                  {{ formatMoney(saldo_final) }}
                </strong>
              </span>)
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- ALERTAS -->
    <template v-if="!!cliente.vendedor_codigo && editar">
      <v-expand-transition>
        <v-col
          v-if="roundNumberTF(saldo_final) * -1 > roundNumberTF(credito)"
          cols="12" sm="10" md="8" xl="6"
        >
          <v-alert
            type="error"
            border="left"
          >
            El Saldo Final supera el límite de Crédito. Verificar
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col
          v-if="roundNumberTF(monto_total) < roundNumberTF(monto_articulos + (saldo < 0 ? (-1 * saldo) : 0))"
          cols="12" sm="10" md="8" xl="6"
        >
          <v-alert
            type="error"
            border="left"
          >
            Existen diferencias entre el Total a Pagar y el Total. Verificar
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col v-if="envio == 2" cols="12">
          <v-alert
            class="mx-auto my-0 body-1"
            max-width="650"
            type="info"
            border="left"
            text
          >
            <strong>Se generará un paquete</strong>. Para entregarlo debe tener correcto los saldos
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col
          v-if="editar && ptovta.factura_auto == 0 && promociones.filter(pr => pr.categoria == 10).length > 0 &&
                cliente.condicion_iva_codigo != null && cond_iva.filter(ci => ci.condicion_iva_codigo == cliente.condicion_iva_codigo).length > 0"
          cols="12"
        >
          <v-alert
            class="mx-auto my-0 text-h6"
            max-width="450"
            type="warning"
            color="orange"
            border="left"
            text
          >
            La Factura a emitir será de
            <strong>
              TIPO "{{ cond_iva.find(ci => ci.condicion_iva_codigo == cliente.condicion_iva_codigo).tipo_fac }}"
            </strong>
          </v-alert>
        </v-col>
      </v-expand-transition>
    </template>
    <!-- ------- -->
    <!-- BOTONES -->
    <!-- ------- -->
    <v-col cols="12" class="d-flex justify-end pt-4">
      <v-row class="d-flex justify-end pa-3">
        <template v-if="editar">
          <v-btn
            color="teal"
            class="mb-2"
            :disabled="!cliente.vendedor_codigo"
            :dark="!!cliente.vendedor_codigo"
            @click="nuevaAcreditacion()"
          >
            <v-icon left>fas fa-receipt</v-icon>
            {{ !$vuetify.breakpoint.smAndDown ? 'Nueva' : '' }} Acreditación
          </v-btn>
          <v-btn
            color="teal"
            class="mb-2 ml-2"
            :disabled="!cliente.vendedor_codigo"
            :dark="!!cliente.vendedor_codigo"
            @click="buscarCaja()"
          >
            <v-icon left>fas fa-university</v-icon>
            {{ !$vuetify.breakpoint.smAndDown ? 'Grabar' : '' }} Mov. Bancario
          </v-btn>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown"></v-col>
          <v-spacer v-else></v-spacer>
        </template>
        <v-btn
          class="mb-2"
          :color="nueva ? 'error' : 'secondary'"
          @click="letMeOut()"
        >
          {{ nueva ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <v-btn
          v-if="!nueva && permite_anular == 1 && jarvis == 1"
          color="error"
          class="mb-2 ml-2"
          @click="anularVenta()"
        >
          <v-icon left>fas fa-ban</v-icon>
          Anular
        </v-btn>
        <v-btn
          v-if="editar"
          color="indigo"
          class="mb-2 ml-2"
          :disabled="simular || pendientes || envio == null || envio == 1 || !cliente.vendedor_codigo || articulos.length == 0"
          :dark="!(simular || pendientes || envio == null || envio == 1 || !cliente.vendedor_codigo || articulos.length == 0)"
          @click="nueva ? grabarVenta(1) : editarPedido()"
        >
          <v-icon left>fas fa-truck</v-icon>
          {{ nueva ? 'Grabar' : 'Modificar' }} pedido
        </v-btn>
        <v-btn
          v-if="nueva"
          color="success"
          class="mb-2 ml-2"
          :disabled="simular || pendientes || envio == null || envio == 2 || !cliente.vendedor_codigo || articulos.length == 0"
          @click="grabarVenta()"
        >
          <v-icon left>fas fa-save</v-icon>
          Grabar
        </v-btn>
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <buscar-articulo
      v-model="modal_articulos"
      :p_lista="precios"
      @selected="seleccionarArticulo"
    />
    <buscar-vendedor
      v-model="modal_vendedores"
      :p_lista="vendedores"
      @selected="seleccionarVendedor"
    />
    <seleccionar-serie
      v-model="modal_series"
      :p_articulo="articulo_select"
      :nueva="editar"
      @confirmar="setSeries"
      @cancelar="cancelarSeries"
    />
    <ModalDeposito
      v-model="modal_deposito"
      :cj_numero="caja.cj_num"
      :ca_numero="caja.numero"
      :ca_nivel="caja.nivel"
      :origen="2"
      :concepto="126"
      :referencia="null"
      :num_comp="null"
      :importe="null"
      :empresa="ptovta.empresa"
      :comp_id="null"
      :cliente_id="cliente.vendedor_codigo"
      @exito_deposito="getSaldo()"
    />
    <ModalVerAcreditacion
      :datos="dialog_acreditacion"
      :p_cliente_codigo="cliente.vendedor_codigo"
      @setearModalVerAcred="setModalVerAcred"
      @recalcular_saldos="getSaldo()"
    />
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
    <LectorCodigos
      v-model="modal_camara"
      :key="keyCamera"
      @result="setResultCamera"
    />
    <!-- modal para seleccionar 1 art si encuentra varios -->
    <v-dialog
      v-model="modal_select_art"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Seleccionar artículo
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="modal_select_art = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <v-data-table
            class="cebra"
            sort-by="nombre"
            :items="select_arts"
            :headers="[
              { text: 'Código', value: 'codigo', align: 'end', sortable: false, filterable: false, },
              { text: 'Nombre', value: 'nombre', sortable: false, filterable: false, },
              { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
            ]"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="success"
                title="Seleccionar"
                small
                @click="seleccionarArticulo(item)"
              >
                fas fa-check
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- super modal de espera -->
    <v-dialog
      v-model="modal_espera"
      width="500"
      persistent
      scrollable
    >
      <v-card class="text-center">
        <v-card-title :class="$vuetify.breakpoint.xs ? 'pa-0' : ''">
          <v-alert
            class="text-h5"
            style="word-break: normal; width: 100%"
            :type="estado_titulo"
            prominent
            outlined
            text
          >
            {{ titulo_espera }}
          </v-alert>
        </v-card-title>
        <v-card-text class=" body-1">
          <!-- listado de mensajes -->
          <div
            v-for="(msj, index) of mensajes"
            :key="index"
            class="text-left mb-1"
          >
            <v-icon :color="msj.status" class="mb-1" left small>
              fas fa-{{ msj.status == 'success' ? 'check'  : 'times' }}-circle
            </v-icon>
            {{ msj.mensaje }}
            <v-tooltip
              v-if="msj.status == 'error'"
              right
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="orange" class="mb-1" small>
                  fas fa-exclamation-triangle
                </v-icon>
              </template>
              {{ msj.error }}
            </v-tooltip>
          </div>
          <!-- loading -->
          <div
            v-if="mensaje_espera != ''"
            class="d-flex justify-center align-center mt-4"
            style="font-size: 20px !important;"
          >
            <vue-loaders-ball-clip-rotate-multiple class="mr-4" :color="$vuetify.theme.dark ? 'white' : '#1E88E5'" />
            {{ mensaje_espera }} ...
          </div>
        </v-card-text>
        <v-card-actions v-if="show_exit_button" class="d-flex pb-4">
          <v-row class="d-flex justify-center pa-3">
            <v-btn
              v-if="estado_titulo == 'success'"
              v-show="archivos.length > 0"
              color="info"
              :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mr-3'"
              :disabled="mensaje_espera != ''"
              @click="modal_impresion = true"
            >
              <v-icon small left>fas fa-print</v-icon>
              Reimprimir comprobantes
            </v-btn>
            <v-btn
              v-else
              color="info"
              :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mr-3'"
              :disabled="permite_anular != 1"
              @click="anularVenta()"
            >
              <v-icon small left>fas fa-sync</v-icon>
              Reintentar Anulación
            </v-btn>
            <v-btn
              color="warning"
              @click="letMeOut()"
            >
              <v-icon small left>fas fa-sign-in-alt</v-icon>
              Salir
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal de seleccion de impresiones -->
    <v-dialog
      v-model="modal_impresion"
      width="400"
      persistent
      scrollable
    >
      <v-card class="text-center">
        <v-card-title style="word-break: normal;">
          ¿Desea descargar los siguientes comprobantes?
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
            style="cursor: pointer;"
            :items="archivos"
            :headers="[
              { text: '', value: 'actions', sortable: false, filterable: false, width: '1' },
              { text: '', value: 'name', sortable: false, filterable: false },
              { text: 'Acciones', value: 'acciones', sortable: false, filterable: false }
            ]"
            :items-per-page="-1"
            hide-default-footer
            hide-default-header
            dense
            @click:row="rowClick"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-checkbox
                v-model="item.imprimir"
                class="py-0 my-0 mr-n8 ml-4"
                :true-value="1"
                :false-value="0"
                :disabled="item.opcional == 0"
                hide-details
                readonly
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon
                color="info"
                title="Ver"
                small
                @click.stop="viewPDF(item)"
              >
                fas fa-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex py-4">
          <v-row class="d-flex justify-center pa-3">
            <v-btn
              color="success"
              class="mr-2"
              @click="descargar()"
            >
              Sí
            </v-btn>
            <v-btn
              color="error"
              class="ml-2"
              @click="modal_impresion = false; show_exit_button = true"
            >
              No
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import download from 'downloadjs'
import { mapState, mapGetters } from 'vuex'
import { format_money, format_money_round, order_list_by, roundNumberTF } from '../../util/utils'
import { facturaA4, remitoReciboA4 } from '../../util/plantillas/pdfs'
import TextFieldMoney from '../../components/util/TextFieldMoney.vue'
import BuscarArticulo from '../../components/ventas/venta/BuscarArticulo.vue'
import BuscarVendedor from '../../components/ventas/venta/BuscarVendedor.vue'
import SeleccionarSerie from '../../components/ventas/venta/SeleccionarSerie.vue'
import ClienteMayorista from '../../components/generales/clientes/ClienteMayorista.vue'
import LectorCodigos from '../../components/util/LectorCodigos.vue'
import ModalDeposito from '../../components/caja/ModalDeposito.vue'
import ModalVerAcreditacion from '../../components/clientes/ModalVerAcreditacion.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'

export default {
  data () {
    return {
      formatMoney: format_money,
      formatMoneyRound: format_money_round,
      roundNumberTF: roundNumberTF,
      id_venta: this.$route.params.id,
      jarvis: null,
      nueva: false,
      editar: false,
      simular: false,
      pendientes: false,
      modal_espera: false,
      modal_camara: false,
      modal_deposito: false,
      modal_articulos: false,
      modal_impresion: false,
      modal_select_art: false,
      modal_vendedores: false,
      modal_series: false,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
      bloquear_vend: false,
      bloquear_ptovta: false,
      bloquear_credito:false,
      show_exit_button: false,
      titulo_espera: '',
      estado_titulo: '',
      mensaje_espera: '',
      permite_editar: 0,
      permite_anular: 0,
      keyCamera: 0,
      credito: 0,
      saldo: 0,
      efectivo: false,
      cantidad: null,
      sucursal: null,
      ptovta: {},
      articulo: null,
      vendedor: null,
      vendedor_acc: null,
      vendedor_nombre: null,
      lista: null,
      canal: null,
      cupon: null,
      envio: null,
      edita_paquete: 0,
      monto_articulos: 0,
      monto_efectivo: 0,
      monto_credito: 0,
      monto_saldo: 0,
      monto_total: 0,
      saldo_final: 0,
      cliente: {},
      articulo_select: {},
      listas: [],
      precios: [],
      cond_iva: [],
      promociones: [],
      localidades: [],
      provincias: [],
      vendedores: [],
      articulos: [],
      select_arts: [],
      locales_user: [],
      archivos: [],
      mensajes: [],
      headersArt: [
        { text: 'Cant.', value: 'cantidad', sortable: false, filterable: false, width: '65', align: 'right' },
        { text: 'Cod. Interno', value: 'codigo', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Artículo', value: 'nombre', sortable: false, filterable: false },
        { text: 'Desc.', value: 'descuento', sortable: false, filterable: false, width: '85', align: 'right' },
        { text: 'Precio', value: 'precio', sortable: false, filterable: false, width: '135', align: 'right' },
        { text: 'Importe', value: 'importe', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, width: '1' }
      ],
      venta_copy: {},
      log: {
        usuario: '',
        fecha: ''
      },
      datos_envio: {
        provincia: null,
        localidad: null,
        direccion: '',
        observaciones: '',
        localidad_nombre: '',
        cp: ''
      },
      caja: {
        cj_num: null,
        numero: null,
        nivel: null
      },
      cliente_param: {
        tienePermiso: 0,
        ejecutivo_codigo: 0,
        pto_vta: 0,
        lockCliente: false, // no se usa aca
        modifica: 0,        // no se usa aca
        cliente: 0,         // no se usa aca
        limpiar_seccion: false,
        datosCli: {},
        nuevo: true,
        miRuta: 'mayoristaCobranzas/obtenerClientesMayoristas'
      },
      dialog_acreditacion: {
        activo: false,
        acreditacion: {},
        nuevo: 1,
        editar: 0,
        tiene_permiso: 0,
        ejecutivo_codigo: 0,
        estados: [],
        origenes: [],
        tarjetas: []
      },
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  async created () {
    this.nueva = !this.id_venta
    await new Promise(resolve => setTimeout(resolve, 1))
    this.$store.state.loading = true
    let result = await this.$store.dispatch('ventasMay/init_form')
    this.$store.state.loading = false

    if (result.exito == 1) {
      const datos = result.data
      this.cond_iva = datos.cond_iva.data
      this.provincias = datos.provincias.data
      this.locales_user = datos.locales.data
      this.cliente_param.tienePermiso = this.setFuncParmDefault(datos.funciones.crear_clientes)
    } else {
      this.$store.dispatch('show_snackbar', {
        text: result.message,
        color: 'error'
      })
    }

    if (this.nueva) {
      this.editar = true
      if (this.una_sucursal) {
        this.sucursal = this.sucursales[0].id
        this.getPtovta()
      } else {
        this.$refs.atcSucursal.focus()
      }
    } else {
      this.cliente_param.tienePermiso = 0
      this.cliente_param.nuevo = false

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/get_vta_may', this.id_venta)
      this.$store.state.loading = false

      if (result.exito != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }

      this.venta_copy = JSON.parse(JSON.stringify(result.data))
      this.loadVta(result.data)
    }
  },
  computed: {
    ...mapGetters(['una_sucursal']),
    ...mapState(['sucursales']),
    ...mapState('caja', ['cajas_user'])
  },
  components: {
    TextFieldMoney,
    BuscarArticulo,
    BuscarVendedor,
    SeleccionarSerie,
    ClienteMayorista,
    LectorCodigos,
    ModalDeposito,
    ModalVerAcreditacion,
    PDFViewer
  },
  watch: {
    ptovta: {
      handler: async function () {
        if (this.nueva) this.limpiar()
        if (this.ptovta == null) {
          this.ptovta = {}
          return
        }
        if (Object.keys(this.ptovta).length != 0) {
          this.canal = this.ptovta.canal
          this.cliente_param.pto_vta = this.ptovta.id

          this.$store.state.loading = true
          let result = await this.$store.dispatch('ventasMay/ptovta_data', {
            empresa: this.ptovta.empresa,
            sucursal: this.ptovta.sucursal,
            local: this.ptovta.id,
            local_acc: this.ptovta.local_accesorios
          })
          this.$store.state.loading = false

          if (result.exito == 1) {
            const datos = result.data
            this.lista = datos.favorito
            this.listas = datos.listas
            this.precios = datos.precios.data
            this.vendedores = datos.vendedores.data
            // si tiene 1 solo vendedor se lo autoselecciono y bloqueo
            if (this.vendedores.length == 1) {
              this.cliente_param.ejecutivo_codigo = this.vendedores[0].ejecutivo_codigo
              this.vendedor = this.vendedores[0].codigo
              this.vendedor_acc = this.vendedores[0].codigo_acc
              this.vendedor_nombre = this.vendedores[0].nombre
              this.bloquear_vend = true
            }
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }

          // verifico si tiene paquetes pendientes
          if (!this.nueva) return
          this.$store.state.loading = true
          let resultado = await this.$store.dispatch('ventasMay/pedidos_antiguos', this.ptovta.id)
          this.$store.state.loading = false

          if (resultado.exito == 1) {
            this.pendientes = resultado.data > 0
            if (this.pendientes) {
              this.$swal.fire({
                icon: 'warning',
                title: 'Paquetes antiguos',
                text: 'Ud. posee paquetes antiguos pendientes de entrega. Proceda al tratamiento de los mismos para poder grabar'
              })
            }
          } else {
            this.$store.dispatch('show_snackbar', {
              text: resultado.message,
              color: 'error'
            })
          }
        }
      },
      deep: true
    },
    articulos: {
      handler: function () {
        this.calcularTotales()
      },
      deep: true
    },
    simular (val) {
      val || this.validarDesimulacion()
    },
    async editar (val) {
      if (val) {
        this.headersArt.push({ text: 'Stock restante', value: 'stock_restante', sortable: false, filterable: false, width: '1', align: 'right' })
        // tengo q traer el stock de todos lo articulos y su stock real seria stock traido + stock de la vta
        if (this.id_venta) {
          await this.getStock()
          this.cliente_param.cliente = this.venta_copy.cabecera.cliente_codigo
        }
      } else {
        this.headersArt.splice(this.headersArt.length - 1, 1)
        this.loadVta(JSON.parse(JSON.stringify(this.venta_copy)))
      }
    },
    modal_series (val) {
      val || this.validarDesimulacionSeries()
    },
    modal_camara (val) {
      val || this.keyCamera ++
    }
  },
  methods: {
    /****************
      FACTURACION Y DESCARGAS
     ****************/
    async descargar () {
      this.mensaje_espera = 'Descargando comprobantes'
      for (const archivo of this.archivos) {
        if (archivo.imprimir == 1 || archivo.opcional == 0) {
          await download(archivo.file, `${archivo.name}.pdf`, 'application/pdf')
          // si mando a imprimir pongo todo opcional, esto es para q la primera vez q imprima sea obligatorio y si reimprime ya no
          archivo.opcional = 1
        }
      }
      this.mensaje_espera = ''
      this.modal_impresion = false
      this.show_exit_button = true
    },
    async getNotaCredito () {
      // solo hago la rnc si esta facturada
      if (this.venta_copy.cabecera.permite_modificar == 1) return

      this.mensaje_espera = 'Obteniendo el Comprobante Asociado'
      let result_comp = await this.$store.dispatch('ventas/get_comp_asociado', this.venta_copy.comprobante_caja.id)

      if (result_comp.exito != 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_comp.message
        })
        return
      }

      this.mensaje_espera = 'Solicitando Nota de Crédito'
      let result_nc = await this.$store.dispatch('afip/getNotaCredito', { comprobante_caja_id: result_comp.data })

      if (result_nc.resultado == 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })

        this.mensaje_espera = 'Obteniendo datos de la Nota de Crédito'
        await this.generarFactura(result_comp.data)
        
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_nc.msj
        })
      }

    },
    async facturar (comprobante) {
      this.mensaje_espera = 'Facturando la Venta'
      let result_fa = await this.$store.dispatch('afip/getFactura', { comprobante_caja_id: comprobante })

      if (result_fa.resultado == 1) {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })

        this.mensaje_espera = 'Obteniendo datos de la Factura'
        await this.generarFactura(comprobante)
        
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_fa.msj
        })
      }
    },
    async generarFactura (comprobante_id) {
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comprobante_id })

      if (respuesta.exito == 1) {
        const datos = respuesta.datos
        let pdf_factura = await facturaA4(datos)
        this.archivos.push({
          file: pdf_factura,
          name: `${datos.encabezado.referencia}-${datos.encabezado.emynum}`,
          imprimir: 0,
          opcional: 1
        })
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: respuesta.message
        })
      }
    },
    async getComprobante (tipo) {
      this.mensaje_espera = 'Obteniendo el ' + tipo
      let result_re = await this.$store.dispatch('ventasMay/get_datos_pdf', {
        venta_id: this.id_venta,
        comprobante: tipo
      })
      if (result_re.exito == 1) {
        let pdf = await remitoReciboA4(result_re.data, tipo)
        this.archivos.push({
          file: pdf,
          name: `${tipo} N° ${result_re.data.numero}`,
          imprimir: 1,
          opcional: 1
        })
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'success'
        })
      } else {
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result_re.message
        })
      }
    },
    /****************
      VENTA
     ****************/
    async anularVenta () {
      let json_modal = {
        icon: 'question',
        title: `¿Está seguro de anular la Venta N° ${this.id_venta}?`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowEnterKey: false
      }

      // si tiene una cobranza agrego el check
      const tiene_cobranza = this.venta_copy.cabecera.cobranza_id != null
      if (tiene_cobranza) {
        json_modal.input = 'radio'
        json_modal.text = 'ANULAR CON COBRANZAS:',
        json_modal.inputOptions = {
          0: 'NO',
          1: 'SI'
        }
        json_modal.inputValidator = (value) => {
          if (!value) {
            return 'Debe seleccionar una opción'
          }
        }
      }

      let modal = await this.$swal.fire(json_modal)
      if (!modal.isConfirmed) return

      this.initModalEspera('Anulando la Venta N° ' + this.id_venta, 'info')

      this.mensaje_espera = 'Anulando la Venta'
      let result = await this.$store.dispatch('ventasMay/anular_vta_may', {
        id: this.id_venta,
        cobranza: tiene_cobranza ? modal.value : 0
      })

      if (result.exito != 1) {
        this.titulo_espera = 'No se pudo anular la Venta N°' + this.id_venta
        this.estado_titulo = 'error'
        this.show_exit_button = true
        this.mensajes.push({
          mensaje: this.mensaje_espera,
          status: 'error',
          error: result.message
        })
        this.mensaje_espera = ''
        return
      }

      // venta anulada correctamente []v
      this.titulo_espera = 'Venta N°' + this.id_venta + ' anulada correctamente'
      this.estado_titulo = 'success'
      this.mensajes.push({
        mensaje: this.mensaje_espera,
        status: 'success'
      })

      // paso a ver si genero la NC
      await this.getNotaCredito()
      
      // imprimo los comprobantes y finalizo
      this.mensaje_espera = ''
      if (this.archivos.length > 0) {
        this.modal_impresion = true
      } else {
        this.show_exit_button = true
      }

    },
    async grabarVenta (con_pedido) {
      const titulo = `Error al grabar ${con_pedido == 1 ? 'el Pedido' : 'la Venta'}`

      let result_validar = await this.validarVenta(titulo, con_pedido)
      if (result_validar.exito != 1) {
        return
      }

      // pasó todas las validaciones >:D
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          nombre: art.nombre,
          precio: art.precio,
          precio_lista: art.precio_lista,
          importe: art.importe,
          solicita_serie: art.solicita_serie,
          cantidad: art.cantidad,
          descuento: art.descuento,
          series: art.series
        }
      })

      if (this.envio == 2) {
        let localidad = this.localidades.find(loc => loc.codigo == this.datos_envio.localidad)
        if (localidad) this.datos_envio.localidad_nombre = localidad.nombre
      }

      let json = {
        accion: 'NUEVA',
        sucursal: this.ptovta.sucursal,
        local: this.ptovta.id,
        local_acc: this.ptovta.local_accesorios,
        vendedor: this.vendedor,
        vendedor_acc: this.vendedor_acc,
        total_efectivo: roundNumberTF(this.monto_efectivo),
        total_credito: roundNumberTF(this.monto_credito),
        total_saldo: roundNumberTF(this.monto_saldo),
        subtotal: roundNumberTF(this.monto_articulos),
        cupon: this.cupon,
        canal: this.canal,
        lista: this.lista,
        articulos: articulos,
        cliente: {
          cliente_codigo: this.cliente.cliente_codigo,
          vendedor_codigo: this.cliente.vendedor_codigo,
          ejecutivo_codigo: this.cliente.ejecutivo_codigo,
          nombre: this.cliente.vendedor_nombre,
          num_doc: this.cliente.numero_documento,
          tipo_doc: this.cliente.tipo_doc_codigo,
          cond_iva: this.cliente.condicion_iva_codigo,
          telefono: this.cliente.telefono,
          email: this.cliente.mail,
          direccion: this.cliente.domicilio,
          repartidor_id: this.cliente.repartidor_id
        },
        opcion_envio: this.envio,
        datos_envio: this.datos_envio
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/grabar_vta_may', json)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: titulo,
          text: result.message
        })
        return
      }

      this.initModalEspera(result.message, 'success')

      this.id_venta = result.datos.pedido_num

      // genero el remito y los recibos
      await this.getComprobante('Remito')

      if (result.datos.cobranza_id != null) {
        await this.getComprobante('Recibo')
      }

      if (result.datos.permite_fe == 1) {
        await this.facturar(result.datos.comprobante_id)
      }

      this.mensaje_espera = ''
      if (this.archivos.length > 0) {
        this.modal_impresion = true
      } else {
        this.show_exit_button = true
      }

    },
    async editarPedido () {
      const titulo = 'Error al modificar el Pedido'

      let result_validar = await this.validarVenta(titulo, 1)
      if (result_validar.exito != 1) {
        return
      }

      // pasó todas las validaciones >:D
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          nombre: art.nombre,
          precio: art.precio,
          precio_lista: art.precio_lista,
          importe: art.importe,
          solicita_serie: art.solicita_serie,
          cantidad: art.cantidad,
          descuento: art.descuento,
          series: art.series
        }
      })

      let localidad = this.localidades.find(loc => loc.codigo == this.datos_envio.localidad)
      if (localidad) this.datos_envio.localidad_nombre = localidad.nombre

      const venta = this.venta_copy.cabecera

      let json = {
        accion: 'EDITAR',
        venta_id: this.id_venta,
        sucursal: venta.sucursal_codigo,
        local: venta.local_codigo,
        local_acc: venta.local_acc,
        vendedor: venta.vend_codigo,
        vendedor_acc: venta.vend_acc,
        total_efectivo: roundNumberTF(this.monto_efectivo),
        total_credito: roundNumberTF(this.monto_credito),
        total_saldo: roundNumberTF(this.monto_saldo),
        subtotal: roundNumberTF(this.monto_articulos),
        cupon: venta.cupon,
        canal: venta.canal,
        lista: venta.lista_codigo,
        articulos: articulos,
        cliente: {
          cliente_codigo: venta.cliente_id,
          vendedor_codigo: venta.cliente_codigo,
          ejecutivo_codigo: venta.ejecutivo_codigo,
          nombre: venta.cliente_nombre,
          num_doc: venta.documento,
          tipo_doc: venta.tipo_doc,
          cond_iva: venta.condiva_codigo,
          telefono: venta.telefono,
          email: venta.email,
          direccion: venta.domicilio,
          repartidor_id: venta.repartidor_id
        },
        opcion_envio: 2,
        datos_envio: this.datos_envio
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/grabar_vta_may', json)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: titulo,
          text: result.message
        })
        return
      }

      this.initModalEspera(result.message, 'success')

      // paso a ver si genero la NC
      await this.getNotaCredito()

      this.id_venta = result.datos.pedido_num
      
      // genero el remito y los recibos
      await this.getComprobante('Remito')

      if (result.datos.cobranza_id != null) {
        await this.getComprobante('Recibo')
      }

      if (result.datos.permite_fe == 1) {
        await this.facturar(result.datos.comprobante_id)
      }

      this.mensaje_espera = ''
      if (this.archivos.length > 0) {
        this.modal_impresion = true
      } else {
        this.show_exit_button = true
      }
    },
    async validarVenta (titulo, con_pedido) {
      return await new Promise(async (resolve, reject) => {
        const data_error = {
          exito: 0
        }

        // muestro el modal de confirmación
        let modal = await this.$swal.fire({
          title: `Confirmar ${con_pedido == 1 ? 'Pedido' : 'Venta'}`,
          html: `
          <span style="font-size: 18px">Desea confirmar ${con_pedido == 1 ? 'el pedido' : 'la venta'} por un total de:</span>
          <table style="width: 100%; margin-top: 15px; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
            <tr style="height: 30px">
              <th style="font-size: 18px; text-align: left">Total Saldo Cta. Cte.</th>
              <th style="font-size: 20px; text-align: right">${format_money(this.monto_saldo)}</th>
            </tr>
            <tr style="height: 30px">
              <th style="font-size: 18px; text-align: left">Total Efectivo</th>
              <th style="font-size: 20px; text-align: right">${format_money(this.monto_efectivo)}</th>
            </tr>
            <tr style="height: 30px">
              <th style="font-size: 18px; text-align: left">Total Crédito</th>
              <th style="font-size: 20px; text-align: right">${format_money(this.monto_credito)}</th>
            </tr>
          </table>
          <div style="margin${this.$vuetify.breakpoint.xs ? '-top: 15px' : ': 15px 15px 0px 15px'}; border: 1px solid"></div>
          <table style="width: 100%; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
            <tr style="height: 50px">
              <th style="font-size: 20px; text-align: left">Total</th>
              <th style="font-size: 22px; text-align: right">${format_money(this.monto_efectivo + this.monto_credito + this.monto_saldo)}</th>
            </tr>
          </table>
          `,
          width: '450px',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          allowEnterKey: false
        })

        if (!modal.isConfirmed) return resolve(data_error)
        
        // empiezo con las validaciones ¬¬
        // valido que el vendedor no sea null
        if (!this.vendedor) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Debe seleccionar un vendedor para poder grabar la venta'
          })
          return resolve(data_error)
        }

        // valido los totales
        if (roundNumberTF(this.monto_total) < roundNumberTF(this.monto_articulos + (this.saldo < 0 ? (-1 * this.saldo) : 0))) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Existen diferencias entre el Total a Pagar y el Total. Verificar'
          })
          return resolve(data_error)
        }

        // si tiene monto de efectivo el mismo debe ser multiplo de 10
        if (roundNumberTF(this.monto_efectivo) > 0 && roundNumberTF(this.monto_efectivo % 10) != 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'El efectivo debe ser multiplo de 10. Verificar'
          })
          return resolve(data_error)
        }

        // si es envio a domicilio valido los campos del mismo
        if (this.envio == 2 && !this.$refs.formEnvio.validate()) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Complete los campos para el envío'
          })
          return resolve(data_error)
        }

        // vuelvo a validar el stock de los articulos
        let articulos = []
        if (this.nueva) {
          articulos = this.articulos.map(art => {
            return {
              codigo: art.codigo,
              cantidad: art.cantidad
            }
          })
        } else {
          // si esta editando tengo q restar el stock original
          for (const articulo of this.articulos) {
            let original = this.venta_copy.articulos.find(art => art.codigo == articulo.codigo)
            let stock = original ? parseInt(original.cantidad) : 0
            articulos.push({
              codigo: articulo.codigo,
              cantidad: parseInt(articulo.cantidad) - stock
            })
          }
        }

        this.$store.state.loading = true
        let result_stk = await this.$store.dispatch('ventasMay/validar_stock', {
          local: this.ptovta.local_accesorios,
          articulos: articulos
        })
        this.$store.state.loading = false

        if (result_stk.exito != 1) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: result_stk.message
          })
          return resolve(data_error)
        }

        // valido nuevamente el saldo
        let saldo_real = 0
        this.$store.state.loading = true
        let result_saldo = await this.$store.dispatch('ventasMay/saldo_gestor', this.cliente.vendedor_codigo)
        this.$store.state.loading = false

        if (result_saldo.exito != 1) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: result_saldo.message
          })
          return resolve(data_error)
        }

        if (this.nueva) {
          saldo_real = result_saldo.saldo
        } else {
          // el saldo cuando modifica es el saldo actual + subtotal de la vta
          saldo_real = roundNumberTF(result_saldo.saldo) + roundNumberTF(this.venta_copy.cabecera.subtotal)
        }

        // si el saldo q traje es menor al q dice q tiene gg
        if (roundNumberTF(saldo_real) < roundNumberTF(this.saldo)) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Se detectaron variaciones en el saldo actual. Verifique refrescando los datos del cliente'
          })
          return resolve(data_error)
        }

        // si llego hasta salio tode bien Bv
        return resolve({
          exito: 1
        })
      })
    },
    async loadVta (venta) {
      if (Object.keys(venta).length == 0) return

      this.limpiar()

      const data = venta.cabecera
      this.jarvis = venta.cabecera.jarvis
      this.cliente_param.cliente = 0

      this.log = {
        usuario: data.usuario_grabacion,
        fecha: moment(data.fecha).format(`DD/MM/YYYY${moment(data.fecha).format('HH:mm:ss') != '00:00:00' ? ' [a las] HH:mm:ss' : ''}`)
      }

      this.sucursal = data.sucursal_codigo
      this.ptovta = {}
      this.getPtovta()

      let local = this.locales_user.find(loc => loc.id == data.local_codigo)
      if (local) {
        this.ptovta = local
      } else {
        return this.$swal.fire({
          icon: 'error',
          title: 'No se encontró el local de la venta'
        })
      }

      this.cupon = data.cupon
      this.envio = data.paquete_id == null ? 1 : 2
      this.edita_paquete = data.permite_editar_paquete
      this.vendedor = data.vend_codigo
      this.vendedor_nombre = data.vend_nombre
      this.permite_anular = data.permite_anular
      this.articulos = venta.articulos
      this.promociones = venta.promociones
      this.permite_editar = data.permite_modificar
      this.monto_articulos = data.subtotal
      this.monto_total = data.subtotal
      this.monto_saldo = data.monto_saldo
      this.monto_credito = data.monto_credito
      this.monto_efectivo = venta.comprobante_caja.efectivo
      this.cliente_param.ejecutivo_codigo = data.ejecutivo_codigo
      this.cliente_param.datosCli = {
        vendedor_codigo: data.cliente_codigo,
        vendedor_nombre: data.cliente_nombre,
        numero_documento: data.documento,
        tipo_documento: data.nombre_doc,
        tipo_doc_codigo: data.tipo_doc,
        domicilio: data.domicilio,
        mail: data.email,
        telefono: data.telefono,
        inhabilitado: data.inhabilitado
      }

      if (data.paquete_id == null) return
      const paquete = venta.paquete
      this.datos_envio.provincia = paquete.provincia
      await this.getLocalidades()
      this.datos_envio = paquete

    },
    /***********
      ARTICULOS
     ***********/
    async buscarArticulo (buscar) {
      if (this.articulo == null || this.articulo == '' || this.$store.state.loading) return

      let art_cod = this.articulo
      let serie = null
      
      if (buscar == 1) {
        if (Object.keys(this.ptovta).length == 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Seleccione un local para agregar artículos',
            color: 'info'
          })
          return
        }
        this.$store.state.loading = true
        let resultado = await this.$store.dispatch('articulos/get_articulo', {
          codigo: this.articulo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (resultado.exito == 1) {
          if (resultado.data.length == 1) {
            // si lo encontro tengo q ver si el codigo que ingrego coincide con un codigo de articulo
            // si coincide y el codigo es distinto al codigo de articulo muestro modal para q elija
            let existe = this.precios.find(art => art.codigo == art_cod)
            if (existe && existe.codigo != resultado.data[0].codigo) {
              this.select_arts = [ resultado.data[0], existe ]
              this.modal_select_art = true
              this.articulo = null
              this.cantidad = null
              return
            } else {
              art_cod = resultado.data[0].codigo
              serie = resultado.data[0].serie
            }
          } else if (resultado.data.length > 1) {
            this.select_arts = resultado.data
            this.modal_select_art = true
            this.articulo = null
            this.cantidad = null
            return
          }
        } else {
          this.$store.dispatch('show_snackbar', {
            text: resultado.message,
            color: 'error'
          })
          return
        }
        this.$refs.vtfArticulo.focus()
      }

      let cantidad = this.cantidad ? parseInt(this.cantidad) : 1
      cantidad = cantidad <= 0 ? 1 : cantidad

      let articulo = this.precios.find(art => art.codigo == art_cod)
      if (articulo) {
        let existe = this.articulos.find(art => art.codigo == articulo.codigo)
        if (existe) {
          // si solicita serie y buscó por serie, verifico que la serie a ingresar no este ya incluida
          if (existe.solicita_serie == 1 && serie != null) {
            // si no está, la agrego
            if (existe.series.filter(se => se.serie == serie).length == 0) {
              existe.series.push({ serie: serie })
            } else {
              this.articulo = null
              this.cantidad = null
              this.$store.dispatch('show_snackbar', {
                text: 'La serie ingresada ya se ecuentra en el listado',
                color: 'info'
              })
              return
            }
          }
          // si no buscó por serie => muestro el modal para q seleccione la serie
          else if (existe.solicita_serie == 1) {
            this.solicitarSerie(existe)
            this.articulo = null
            this.cantidad = null
            return
          }
          // verifico q no supere el stock
          if (existe.cantidad + cantidad > existe.stock) {
            this.articulo = null
            this.cantidad = null
            this.$store.dispatch('show_snackbar', {
              text: 'No hay suficiente stock para agregar otra unidad del artículo ' + articulo.nombre,
              color: 'orange'
            })
            return
          }
          existe.cantidad += cantidad
          this.recalcularImporteArticulo(existe)
          this.articulo = null
          this.cantidad = null
          return
        }

        // obtengo el stock disponible del articulo
        this.$store.state.loading = true
        let result = await this.$store.dispatch('ventasMay/stock_art', {
          articulo: art_cod,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (result.exito != 1) {
          this.articulo = null
          this.cantidad = null
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
          return
        }

        if (result.stock < cantidad && !this.simular) {
          this.articulo = null
          this.cantidad = null
          this.$store.dispatch('show_snackbar', {
            text: `El artículo ${articulo.codigo} no tiene suficiente stock. Stock disponible: ` + result.stock,
            color: 'info',
            timer: 4000
          })
          return
        }

        let unArticulo = {
          codigo: articulo.codigo,
          nombre: articulo.nombre,
          precio: articulo.precio,
          precio_lista: articulo.precio,
          precio_lista_original: articulo.precio,
          precio_original: articulo.precio,
          importe: articulo.precio,
          solicita_serie: articulo.series,
          solicita_datos: articulo.cli_datos_add,
          cantidad: cantidad,
          descuento: 0,
          series_disponibles: [],
          series: [],
          stock: result.stock,
          nuevo: 1
        }

        // si es un art que solicita serie, abro el modal
        if (unArticulo.solicita_serie == 1) {
          this.solicitarSerie(unArticulo, serie)
          this.articulo = null
          this.cantidad = null
          return
        }
        this.articulos.unshift(unArticulo)
        this.getDescuentos()
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontro el artículo ' + this.articulo,
          color: 'error'
        })
      }
      this.articulo = null
      this.cantidad = null
    },
    async solicitarSerie (articulo, serie) {
      // si esta simulando agrego el articulo sin asco
      if (this.simular) {
        this.articulos.unshift(articulo)
        this.getDescuentos()
        return
      }

      if (articulo.nuevo == 1) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('articulos/get_series', {
          articulo: articulo.codigo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (result.exito == 1) {
          articulo.series_disponibles = result.data
          // si buscó por serie y esa serie existe y la cantidad es 1 => directamente la agrego
          if (serie && articulo.series_disponibles.filter(se => se.serie == serie).length != 0) {
            articulo.series.push({ serie: serie })
            if (articulo.cantidad == 1) {
              this.articulos.unshift(articulo)
              this.getDescuentos()
              return
            }
          }
          this.validarSerieExistente(articulo)
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      } else {
        this.articulo_select = articulo
        this.modal_series = true
      }
    },
    async verSeries (art) {
      if (this.nueva || art.nuevo == 1) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('articulos/get_series', {
          articulo: art.codigo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (result.exito == 1) {
          art.series_disponibles = result.data
          this.validarSerieExistente(art)
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      } else {
        this.articulo_select = art
        this.modal_series = true
      }
    },
    async validarCantidades (art) {
      if (this.simular) {
        this.recalcularImporteArticulo(art)
        return
      }

      if (art.cantidad > art.stock) {
        if (art.solicita_serie == 1) {
          art.cantidad = art.series.length
        } else {
          art.cantidad = 1
          this.recalcularImporteArticulo(art)
        }
        this.$store.dispatch('show_snackbar', {
          text: 'La cantidad ingresada supera el stock disponible del artículo',
          color: 'orange'
        })
        return
      }

      if (art.solicita_serie == 1) {
        if (art.nuevo == 1) {
          this.$store.state.loading = true
          let result = await this.$store.dispatch('articulos/get_series', {
            articulo: art.codigo,
            local: this.ptovta.local_accesorios
          })
          this.$store.state.loading = false

          if (result.exito == 1) {
            art.series_disponibles = result.data
            this.validarSerieExistente(art)
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }
        } else {
          this.articulo_select = art
          this.modal_series = true
        }
      } else {
        this.recalcularImporteArticulo(art)
      }
    },
    async validarDesimulacion () {
      // todo lo que tenga stock restrante negativo lo pongo en 1
      let negativos = this.articulos.filter(art => art.solicita_serie != 1 && art.stock - art.cantidad < 0)
      for (let articulo of negativos) {
        articulo.cantidad = 1
        this.recalcularImporteArticulo(articulo, 1)
      }
      if (negativos.length > 0) {
        await this.getDescuentos()
      }
      this.validarDesimulacionSeries()
    },
    validarDesimulacionSeries () {
      // si deseleccionan simular tengo q verificar q todo lo q solicite serie cumpla con su cantidad
      // esta funcion tambien es recursiva cuando se cierra el modal de series >:D
      let incompletos = this.articulos.filter(art => art.solicita_serie == 1 && art.cantidad != art.series.length)
      if (incompletos.length > 0 && !this.simular) {
        this.solicitarSerie(incompletos[0])
      }
    },
    validarSerieExistente (articulo) {
      // reviso si la cantidad de series disponibles es distinta al stock
      if (articulo.series_disponibles.length != articulo.stock) {
        articulo.stock = articulo.series_disponibles.length
      }
      // si las series disponibles cambiaron y la serie q habia seleccionado ya no existe => tengo q eliminar
      let existentes = []
      for (let serie of articulo.series) {
        let existe = articulo.series_disponibles.find(ser => ser.serie == serie.serie)
        if (existe) {
          existentes.push(existe)
        }
      }
      // si la longitud es distinta significa q hay series q no existen
      if (articulo.series.length != existentes.length) {
        articulo.series = existentes
      }
      // abro el modal de series
      this.articulo_select = articulo
      this.modal_series = true
    },
    quitarArticulo (item) {
      const index = this.articulos.indexOf(item)
      this.articulos.splice(index, 1)
      this.getDescuentos()
    },
    cancelarSeries () {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.cantidad = this.articulo_select.series.length
      }
      this.articulo_select = {}
      this.modal_series = false
    },
    setSeries (series, cantidad) {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.series = series
        existe.cantidad = cantidad
        this.recalcularImporteArticulo(existe)
      } else {
        this.articulo_select.series = series
        this.articulo_select.cantidad = cantidad
        this.articulos.unshift(this.articulo_select)
        this.recalcularImporteArticulo(this.articulo_select)
      }
      this.articulo_select = {}
      this.modal_series = false
    },
    seleccionarArticulo (art) {
      this.modal_select_art = false
      this.articulo = art.codigo
      this.buscarArticulo()
    },
    recalcularImporteArticulo (articulo, sin_descuento) {
      // recalculo el importe
      if (articulo.cantidad == '' || parseInt(articulo.cantidad) <= 0) articulo.cantidad = 1
      articulo.importe = parseInt(articulo.cantidad) * parseFloat(articulo.precio)
      if (sin_descuento != 1) {
        this.getDescuentos()
      }
    },
    // opciones: 1 -> modifico efectivo, 2 -> modifico credito, undefined -> automatico
    calcularTotales (opcion) {
      if (!this.editar) return

      this.monto_articulos = parseFloat(this.articulos.reduce((sum, art) => sum + roundNumberTF(art.importe), 0))
      this.bloquear_credito = false
      this.saldo_final = 0

      if (!this.monto_efectivo || this.monto_efectivo < 0) this.monto_efectivo = 0
      if (!this.monto_credito || this.monto_credito < 0) this.monto_credito = 0
      if (!this.saldo) this.saldo = 0

      const total_pagar = this.monto_articulos + (this.saldo < 0 ? (-1 * this.saldo) : 0)

      // si es solo efectivo
      if (this.efectivo && opcion != 1) {
        this.monto_efectivo = parseFloat(total_pagar)
        this.monto_credito = 0
        this.monto_saldo = 0
        this.monto_total = parseFloat(total_pagar)
        return
      }
      
      // verifico el saldo
      if (this.saldo > 0) {
        // si es mayor q los articulos uso todito, sino uso lo q alcance u.u
        if (roundNumberTF(this.saldo) > roundNumberTF(this.monto_articulos)) {
          this.monto_saldo = parseFloat(this.monto_articulos)
          this.monto_credito = 0
          this.bloquear_credito = true
        } else {
          this.monto_saldo = parseFloat(this.saldo)
        }
      } else {
        this.monto_saldo = 0
      }

      // verifico si entro por efectivo, credito o automatico
      switch (opcion) {
        case 1:
          // en el caso de efectivo ponemos en 0 el credito y lo calculamos en base al efectivo
          this.monto_credito = 0
          this.monto_efectivo = parseFloat(this.monto_efectivo)

          // si es solo efectivo tampoco lo dejo tener saldo
          if (this.efectivo) {
            this.monto_saldo = 0
            let saldo = this.saldo > 0 ? this.saldo : 0
            this.saldo_final = (parseFloat(this.monto_efectivo) - total_pagar) + saldo
            this.saldo_final = this.saldo_final < 0 ? 0 : this.saldo_final
          } else {
            // si todavia me falta 5 pal peso lo saco del credito (siempre q alcance)
            if (roundNumberTF(this.monto_saldo + this.monto_efectivo) < roundNumberTF(total_pagar)) {
              this.monto_credito = total_pagar - (this.monto_saldo + this.monto_efectivo)
              if (roundNumberTF(this.monto_credito) > roundNumberTF(this.credito)) {
                this.monto_credito = parseFloat(this.credito)
              }
              if (this.monto_credito < 0) {
                this.monto_credito = 0
                this.saldo_final = 0
              } else {
                this.saldo_final = -1 * parseFloat(this.monto_credito)
              }
            } else {
              this.saldo_final = (this.monto_saldo + this.monto_efectivo) - total_pagar
            }
          }
          break
        case 2:
          // en el caso de credito ponemos el efectivo en 0 y lo calculamos en base al credito
          this.monto_efectivo = 0
          
          const monto = total_pagar - this.monto_saldo

          if (roundNumberTF(this.monto_credito) > roundNumberTF(this.credito)) {
            this.monto_credito = 0
            this.$store.dispatch('show_snackbar', {
              text: 'El límite de crédito es de ' + format_money_round(this.credito),
              color: 'deep-orange'
            })
          } else if (roundNumberTF(this.monto_credito) > roundNumberTF(monto)) {
            this.monto_credito = parseFloat(monto)
            this.$store.dispatch('show_snackbar', {
              text: 'El monto de crédito no debe superar el total de la venta',
              color: 'orange'
            })
          } else {
            this.monto_credito = parseFloat(this.monto_credito)
          }

          this.monto_efectivo = total_pagar - (this.monto_saldo + this.monto_credito)
          this.saldo_final = -1 * parseFloat(this.monto_credito)
          break
        default:
          // modo automatico, credito es 0 y valido efctivo
          this.monto_credito = 0
          this.monto_efectivo = total_pagar - this.monto_saldo
          break
      }

      this.monto_total = this.monto_efectivo + this.monto_credito + this.monto_saldo
    },
    /*********
      GETTERS
     *********/
    async getStock () {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/get_stock', this.id_venta)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.editar = false
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
        return
      }

      // por cada articulo q me devolvio busco el articulo y le sumo el stock y le agrego las series
      for (const art of result.data) {
        let articulo = this.articulos.find(ar => ar.codigo == art.codigo)
        if (articulo) {
          articulo.stock += parseInt(art.stock)
          for (const serie of art.series) {
            articulo.series_disponibles.push(serie)
          }
        }
      }
    },
    async getDescuentos () {
      if (this.ptovta == null || Object.keys(this.ptovta).length == 0 || !this.editar) return

      const cliente = {
        codigo: this.cliente.cliente_codigo,
        num_doc: this.cliente.numero_documento,
        tipo_doc: this.cliente.tipo_doc_codigo
      }
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          cantidad: art.cantidad,
          precio_lista: art.precio_lista
        }
      })

      this.promociones = []

      this.$store.state.loading_dct = true
      let result = await this.$store.dispatch('ventas/calcular_descuento', {
        cliente: cliente,
        articulos: articulos,
        local: this.ptovta.local_accesorios,
        canal: this.canal,
        cupon: this.cupon,
        efectivo: this.efectivo ? 1 : 0,
        subtotal: parseFloat(this.articulos.reduce((sum, art) => sum + (art.precio_lista * art.cantidad), 0)),
        fecha: this.nueva ? null : moment(this.venta_copy.cabecera.fecha).format('DD/MM/YYYY'),
        venta: this.nueva ? null : this.id_venta
      })
      this.$store.state.loading_dct = false

      if (result.exito == 1) {
        this.promociones = result.data.descuentos
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }

      // antes de aplicar los descuentos limpio el array de articulos
      for (let articulo of this.articulos) {
        articulo.precio = articulo.precio_original
        articulo.importe = articulo.precio_original * articulo.cantidad
        articulo.descuento = 0
      }

      // aplico los descunetos
      for (const promo of this.promociones) {
        // busco el articulo en el array
        let articulo = this.articulos.find(art => art.codigo == promo.articulo)
        if (articulo) {
          // aplico los descuentos
          let porcentaje = 0
          for (const desc of promo.detalle) {
            porcentaje += parseFloat(desc.porcentaje)
          }
          let descuento = porcentaje / 100
          descuento = parseFloat(descuento)

          articulo.descuento = porcentaje
          articulo.precio -= articulo.precio * descuento
          articulo.importe -= articulo.importe * descuento
        }
      }
    },
    async getPendientesCli () {
      if (!this.cliente.cliente_codigo || !this.nueva) return

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/pedidos_antiguos_cliente', {
        cliente: this.cliente.cliente_codigo,
        local: this.ptovta.id
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.pendientes = result.data > 0
        if (this.pendientes) {
          this.$swal.fire({
            icon: 'warning',
            title: 'Paquetes antiguos',
            text: 'Ud. posee paquetes antiguos pendientes de entrega para este cliente. Proceda al tratamiento de los mismos para poder grabar'
          })
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async getSaldo () {
      if (!this.cliente.vendedor_codigo) {
        this.saldo = 0
        this.calcularTotales()
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasMay/saldo_gestor', this.cliente.vendedor_codigo)
      this.$store.state.loading = false

      if (result.exito == 1) {
        if (this.nueva) {
          this.saldo = result.saldo
        } else {
          // el saldo cuando modifica es el saldo actual + subtotal de la vta
          this.saldo = roundNumberTF(result.saldo) + roundNumberTF(this.venta_copy.cabecera.subtotal)
        }
      } else {
        this.saldo = 0
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
      this.calcularTotales()
    },
    async getLocalidades () {
      this.localidades = []
      if (this.datos_envio.provincia) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_localidades_tmp', this.datos_envio.provincia)
          .then((result) => {
            this.localidades = result.data
          }).catch((err) => {
            this.$store.dispatch('show_snackbar', {
              text: err.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    getPtovta () {
      let locales = this.locales_user.filter(loc => loc.sucursal == this.sucursal && loc.canal == 2)
      if (locales.length == 1) {
        this.ptovta = locales[0]
        this.bloquear_ptovta = this.una_sucursal
      } else {
        this.ptovta = {}
      }
    },
    /********
      VARIOS
     ********/
    setResultCamera (result) {
      this.articulo = result
      if (this.cantidad != null) {
        this.buscarArticulo(1)
      }
    },
    viewPDF (archivo) {
      this.pdf = archivo.file
      this.pdf_nombre = archivo.name
      this.pdf_dialog = true
    },
    initModalEspera (mensaje, estado) {
      this.modal_espera = true
      this.titulo_espera = mensaje
      this.estado_titulo = estado
      this.mensaje_espera = ''
      this.archivos = []
      this.mensajes = []
    },
    async validarCupon () {
      if (!this.cupon) {
        this.getDescuentos()
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/validar_cupon', this.cupon)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.cupon = null
        this.getDescuentos()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        return
      }

      if (result.valido != 1) {
        this.cupon = null
        this.$swal.fire({
          icon: 'info',
          title: 'Cupón invalido',
          text: 'El cupón ingresado ya fue utilizado, no es un cupón valido, o no esta vigente'
        })
      }
      this.getDescuentos()

    },
    async buscarCaja () {
      this.limpiarCaja()

      if (Object.keys(this.ptovta).length == 0 || !this.cliente.vendedor_codigo) return

      // obtengo las cajas del usuario
      this.$store.state.loading = true
      await this.$store.dispatch('caja/get_cajas_user')
      this.$store.state.loading = true

      // reviso si el local q selecciono figura en las cajas
      let caja = this.cajas_user.filter(cu => cu.local == this.ptovta.id)
      if (caja.length != 1) {
        this.$store.dispatch('show_snackbar', {
          text: 'La caja del local no está disponible',
          color: 'error'
        })
        return
      }

      // obtengo la ultima caja abierta
      this.$store.state.loading = true
      let result = await this.$store.dispatch('caja/get_caja', { cj_numero: caja[0].id })
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        return
      }

      this.caja = {
        cj_num: result.caja.cj_numero,
        numero: result.caja.numero,
        nivel: caja[0].nivel
      }
      this.modal_deposito = true

    },
    setEnvio () {
      if (this.envio == 1) {
        this.limpiarPedido()
        this.credito = this.cliente.limite_credito ? this.cliente.limite_credito : 0
      } else {
        this.setDatosEnvio()
      }
      this.calcularTotales()
    },
    async setDatosEnvio () {
      if (Object.keys(this.cliente).length > 0 && this.envio == 2) {
        this.credito = Infinity
        this.bloquear_credito = false
        this.datos_envio.provincia = this.cliente.provincia_codigo
        await this.getLocalidades()
        this.$refs.formEnvio.resetValidation()
        // busco la localidad con el mismo cp
        let localidad = this.localidades.filter(lo => lo.codigo_postal == this.cliente.codigo_postal)
        if (localidad.length > 0) {
          order_list_by(localidad, 'codigo', 'desc')
          this.datos_envio.localidad = localidad[0].codigo
          this.datos_envio.cp = localidad[0].codigo_postal
        }
        this.datos_envio.direccion = this.cliente.domicilio
      }
    },
    setCP () {
      if (this.datos_envio.localidad) {
        let localidad = this.localidades.find(lo => lo.codigo == this.datos_envio.localidad)
        if (localidad) {
          this.datos_envio.cp = localidad.codigo_postal
        }
      }
    },
    setCliente (item) {
      this.cliente = {}
      this.cliente = item
      if (this.nueva) {
        this.credito = this.cliente.limite_credito ? this.cliente.limite_credito : 0
        this.limpiarPedido(1)
        this.setDatosEnvio()
      } else {
        this.credito = Infinity
        this.bloquear_credito = false
      }
      this.getSaldo()
      this.getPendientesCli()
    },
    setClearCliente (value) {
      this.cliente_param.limpiar_seccion = value
      if (this.cliente_param.limpiar_seccion) this.cliente_param.limpiar_seccion = false
    },
    async nuevaAcreditacion () {
      if (Object.keys(this.ptovta).length == 0 || !this.cliente.vendedor_codigo) return

      // si es la primera vez hago el init
      if (this.dialog_acreditacion.estados.length == 0) {
        this.$store.state.loading = true
        let initPeticion = await this.$store.dispatch('acreditaciones/initFormABMAcreditaciones')
        this.$store.state.loading = false

        if (initPeticion.resultado == 1) {
          this.dialog_acreditacion.tiene_permiso = initPeticion.permiso
          this.dialog_acreditacion.estados = initPeticion.estados
          this.dialog_acreditacion.origenes = initPeticion.origenes
          this.dialog_acreditacion.tarjetas = initPeticion.tarjetas
          if (initPeticion.ejecutivo.length > 0) dialog_acreditacion.ejecutivo_codigo = initPeticion.ejecutivo[0].ejecutivo_codigo
        } else {
          this.$store.dispatch('show_snackbar', {
            text: initPeticion.msj,
            color: 'error'
          })
          return
        }
      }
      this.setModalVerAcred(true)
    },
    setModalVerAcred (value) {
      this.dialog_acreditacion.activo = value
    },
    buscarVendedor () {
      if (!this.nueva || this.bloquear_vend) return
      if (this.vendedor == null || this.vendedor == '') {
        this.cliente_param.ejecutivo_codigo = 0
        this.vendedor_acc = null
        this.vendedor_nombre = null
        return
      }
      let vendedor = this.vendedores.find(ven => ven.codigo == this.vendedor)
      if (vendedor) {
        this.cliente_param.ejecutivo_codigo = vendedor.ejecutivo_codigo
        this.vendedor_acc = vendedor.codigo_acc
        this.vendedor_nombre = vendedor.nombre
        this.$refs.vtfArticulo.focus()
      } else {
        this.cliente_param.ejecutivo_codigo = 0
        this.vendedor = null
        this.vendedor_acc = null
        this.vendedor_nombre = null
        this.modal_vendedores = true
      }
    },
    seleccionarVendedor (ven) {
      this.cliente_param.ejecutivo_codigo = ven.ejecutivo_codigo
      this.vendedor = ven.codigo
      this.vendedor_acc = ven.codigo_acc
      this.vendedor_nombre = ven.nombre
    },
    setFuncParmDefault (value) {
      return value ? value : 0
    },
    limpiar () {
      this.cliente_param.limpiar_seccion = true
      this.cliente_param.ejecutivo_codigo = 0
      this.cliente_param.pto_vta = 0
      this.bloquear_credito = false
      this.bloquear_vend = false
      this.pendientes = false
      this.efectivo = false
      this.cupon = null
      this.vendedor = null
      this.vendedor_acc = null
      this.vendedor_nombre = null
      this.canal = null
      this.lista = null
      this.cliente = {}
      this.credito = 0
      this.saldo = 0
      this.listas = []
      this.precios = []
      this.promociones = []
      this.vendedores = []
      this.articulos = []
      this.limpiarPedido(1)
      this.limpiarCaja()
    },
    limpiarPedido (con_envio) {
      this.datos_envio = {
        provincia: null,
        localidad: null,
        direccion: '',
        observaciones: '',
        localidad_nombre: '',
        cp: ''
      }
      if (con_envio == 1 && this.nueva) this.envio = null
    },
    limpiarCaja () {
      this.caja = {
        cj_num: null,
        numero: null,
        nivel: null
      }
    },
    enterKey (event) {
      if (event.key == 'Enter') document.activeElement.blur()
    },
    rowClick (item, row) {
      if (item.opcional == 1) {
        item.imprimir = item.imprimir == 1 ? 0 : 1
      }
    },
    letMeOut () {
      window.close()
    }
  }
}
</script>

<style>
.pulse-light {
  animation: pulse-light-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-light-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.pulse-dark {
  animation: pulse-dark-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-dark-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}
</style>