import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    categorias: []
  },
  mutations: {
    set_categorias (state, payload) {
      state.categorias = payload
      order_list_by(state.categorias, 'nombre')
      localStorage.setItem('categorias', JSON.stringify(payload))
    },
    add_categoria(state, payload){
      state.categorias.push(payload);
    }
  },
  actions: {
    // payload = codigo de la categoria
    async inhabilitar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/inhabilitarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo de la categoria
    async habilitar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/habilitarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo (de la categoria), nombre, rubro_codigo, tipo_fe_codigo, solicita_fe_aut, no_paga_desc_empl, solicita_serie, actualiza_precio_may
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/editarCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = nombre, rubro_codigo, tipo_fe_codigo, solicita_fe_aut, no_paga_desc_empl, solicita_serie, actualiza_precio_may
    async nueva ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/nuevaCategoria`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = filtro
    async get_categorias ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/getCategorias`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene las categorias habilitadas y las guarda en el localStorage para utilizarlas en otros formularios
    async get_categorias_habilitadas ({ dispatch, commit, state }) {
      if (state.categorias.length == 0) {
        await dispatch('get_categorias', { inhabilitado: 0 })
          .then((res) => {
            commit('set_categorias', res.data)
          })
          .catch((error) => {
            commit('set_categorias', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    //agrega al array del localStorage esa nueva cat creada
    add_categoria({commit, state}, payload){
      commit('add_categoria', payload);
      commit('set_categorias', state.categorias);
    },
    //Inicio del formulario
    async iniciarForm({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/initForm?categoria_codigo=${payload.categoria_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al iniciar el formulario: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    //init del formulario de Objetivos por categoria para nuevo o editar
    async iniciarFormObjetivos({}){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/iniciarFormObjetivos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al iniciar el formulario: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    //consulta que trae los articulos que tenga asociados la comision de la categoria
    async getTieneArticulos({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/getTieneArticulos?categoria_codigo=${payload.categoria_codigo}&comision_categoria_id=${payload.comision_categoria_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar obtener los artículos de la categoria: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    //consulta para editar o crear un nuevo Objetivo
    async nuevoEditarObjetivo({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/nuevoEditarObjetivo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();
          resolve(data);

        } catch (error) {
          let cad = payload.datos.id == 0 ? 'crear' : 'editar';
          resolve({
            message: 'Ocurrió un problema al intentar '+cad+' un objetivo por categoria: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    //para inhabilitar / habilitar
    async inhabilitarHabilitar({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/inhabilitarHabilitarObjetivo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();
          resolve(data);

        } catch (error) {
          let cad = payload.accion == 0 ? 'habilitar' : 'inhabilitar';
          resolve({
            message: 'Ocurrió un problema al intentar '+cad+' el objetivo por categoria: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async cargaMasivaArticulosObjetivos({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/cargaMasivaArtsObj`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar obtener los artículos del Archivo: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async initMarkups({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/initMarkups`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar obtener las listas con markup: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async buscarMarkups({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/buscarMarkups?categoria_codigo=${payload.categoria_codigo}&lista_codigo=${payload.lista_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar obtener los markups: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async listasMarkup({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/listasMarkup?categoria_codigo=${payload.categoria_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar obtener las listas para el nuevo markup: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async nuevoEditarMarkup({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/nuevoEditarMarkup?`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload),
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          let cad = payload.accion ? 'grabar' : 'editar';
          resolve({
            message: 'Ocurrió un problema al intentar '+cad+' el markup: '+error.message,
            resultado: 0,
          })
        }
      })
    },
    async eliminarMarkup({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/artsCategorias/eliminarMarkup?`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload),
          })

          const data = await res.json()
          resolve(data);

        } catch (error) {
          resolve({
            message: 'Ocurrió un problema al intentar eliminar el markup: '+error.message,
            resultado: 0,
          })
        }
      })
    },
  }
}