<template>
  <v-dialog
    v-model="dialog"
    width="750"
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        Costos financieros proyectados
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :class="$vuetify.breakpoint.xs ? 'mr-3' : ''"
          small
          fab
          @click="costo = null; dialog_costo = true"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-divider
          v-if="$vuetify.breakpoint.smAndUp"
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          sort-by="tipo_nombre"
          :loading="load"
          :headers="headers"
          :items="costos"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.alicuota`]="{ item }">
            {{ parseFloat(item.alicuota.toFixed(2)) }}%
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="info"
              title="Ver"
              small
              @click="costo = item; dialog_costo = true"
            >
              fas fa-eye
            </v-icon>
            <BtnConfirmar
              icono="fas fa-trash"
              color="error"
              clase="ml-2"
              title="Eliminar"
              :texto="`¿Desea <strong>ELIMINAR</strong> el costo financiero <strong>${item.tipo_nombre}</strong> del <strong>${item.alicuota}</strong>%?
                </br></br><strong>(Esta acción no se puede deshacer)</strong>`"
              icon_button
              small
              @action="eliminar(item)"
            />
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron costos financieros
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <CostoFinanciero
      v-model="dialog_costo"
      :p_costos="costos"
      :p_cuota="p_cuota"
      :p_costo="costo"
      @actualizar="buscar()"
    />
  </v-dialog>
</template>

<script>
import CostoFinanciero from './CostoFinanciero'
import BtnConfirmar from '../util/BtnConfirmar.vue'

export default {
  data () {
    return {
      load: false,
      dialog_costo: false,
      costo: null,
      costos: [],
      headers: [
        { text: 'Tipo descuento', value: 'tipo_nombre' },
        { text: 'Alicuota', value: 'alicuota' },
        { text: 'Vig. desde', value: 'desde', sortable: false, filterable: false },
        { text: 'Vig. hasta', value: 'hasta', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    value: Boolean,
    p_cuota: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    CostoFinanciero,
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_cuota.codigo) {
          await this.buscar()
        }
      }
    }
  },
  methods: {
    async eliminar (item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('cuotas/delete_cf', item.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.buscar()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      this.costos = []
      this.load = true
      await this.$store.dispatch('cuotas/get_costos_financieros', this.p_cuota.codigo)
        .then((res) => {
          this.costos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    }
  }
}
</script>