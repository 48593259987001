<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.area = null; filtro.puesto = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Área
                  <v-autocomplete
                    v-model="filtro.area"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="areas.filter(a => a.empresa == filtro.empresa && a.inhabilitada != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.puesto = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-1">
                  Puesto
                  <v-autocomplete
                    v-model="filtro.puesto"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="puestos.filter(p => p.area == filtro.area && p.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="py-1">
                  <v-row>
                    <v-col>
                      Nombre
                      <v-text-field
                        v-model.trim="filtro.nombre"
                        tabindex="1"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      Apellido
                      <v-text-field
                        v-model.trim="filtro.apellido"
                        tabindex="1"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.dni"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2" class="py-3">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="empleados"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" xl="10">
              <download-excel
                  v-if="empleados.length > 0"
                  name="Empleados.xls"
                  :data="empleados"
                  :fields="{
                    'Apellido': 'apellido',
                    'Nombre': 'nombre',
                    'Nro. Doc': 'num_doc',
                    'Celular': 'celular',
                    'Teléfono': 'telefono',
                    'Email': 'mail',
                    'Área': 'area',
                    'Puesto': 'puesto',
                    'Empresa': 'empresa'
                  }"
                >
                  <v-btn
                    color="success"
                    class="ml-2"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    :small="$vuetify.breakpoint.mdAndUp"
                    :fab="$vuetify.breakpoint.smAndDown"
                    dark
                  >
                    <v-icon :left="$vuetify.breakpoint.mdAndUp" small>fas fa-file-download</v-icon>
                    <span v-text="$vuetify.breakpoint.mdAndUp ? 'Excel' : ''"></span>
                  </v-btn>
                </download-excel>
            </v-col>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            clase="mr-2"
            :icono="item.inhabilitado != 1 ? 'fas fa-ban' : 'fas fa-check'"
            :color="item.inhabilitado != 1 ? 'error' : 'success'"
            :title="item.inhabilitado != 1 ? 'Inhabilitar' : 'Habilitar'"
            :texto="item.inhabilitado != 1
              ? `¿Desea inhabilitar al empleado <strong>${item.nombre} ${item.apellido}</strong>?`
              : `¿Desea habilitar al empleado <strong>${item.nombre} ${item.apellido}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado != 1 ? inhabilitar(item) : habilitar(item)"
          />
          <v-tooltip
            v-if="item.telefono || item.celular || item.mail"
            color="indigo"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="indigo"
                class="mr-2"
                style="cursor: pointer"
                title="Click para copiar"
                small
                @click="copy_clipboard(`${item.telefono ? item.telefono + '\n' : ''}${item.celular ? item.celular + '\n' : ''}${item.mail ? item.mail : ''}`)"
              >
                fas fa-address-card
              </v-icon>
            </template>
            <div v-if="item.telefono">
              Teléf.: <strong class="ml-1">{{ item.telefono }}</strong>
            </div>
            <div v-if="item.celular">
              Cel.: <strong style="margin-left: 15px">{{ item.celular }}</strong>
            </div>
            <div v-if="item.mail">
              Email: <strong class="ml-1">{{ item.mail }}</strong>
            </div>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      search: '',
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Apellido', value: 'apellido' },
        { text: 'Nº documento', value: 'num_doc' },
        { text: 'Puesto', value: 'puesto' },
        { text: 'Área', value: 'area' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      empleados: [],
      filtro: {
        empresa: null,
        area: null,
        puesto: null,
        nombre: null,
        apellido: null,
        dni: null,
        inhabilitado: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericosServ/get_empresas')
    await this.$store.dispatch('genericosServ/get_areas')
    await this.$store.dispatch('genericosServ/get_puestos')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('genericosServ', ['empresas', 'areas', 'puestos'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable
  },
  methods: {
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('empleados/inhabilitar', item.id)
        .then((res) => {
          const index = this.empleados.indexOf(item)
          this.empleados.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('empleados/habilitar', item.id)
        .then((res) => {
          const index = this.empleados.indexOf(item)
          this.empleados.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.empleados = []
      await this.$store.dispatch('empleados/get_empleados', this.filtro)
        .then((res) => {
          this.empleados = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    open_window (id) {
      let path = '/empleado'
      if (id) {
        path = `${path}/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        area: null,
        puesto: null,
        nombre: null,
        apellido: null,
        dni: null,
        inhabilitado: 0
      }
    }
  }
}
</script>