<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Contenido -->
      <v-col cols="12" class="pa-0">
        <!-- Encabezado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                Liquidación {{ $route.params.id }}
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  dark
                  color="indigo"
                  title="Liquidaciones Asociadas"
                  small
                  @click="liqAsociadas()"
                >
                  <v-icon dark small>
                    far fa-list-alt
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <!-- Liquidacion Id, Nro de Resumen, Nro de Comercio, Periodo -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.id"
                      type="number"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Liq. Id"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.nro_resumen"
                      type="number"
                      outlined
                      dense
                      hide-details
                      label="Nro. Resumen"
                      :readonly="encab.auditado == 1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.nro_comercio"
                      type="number"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Nro. Comercio"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.periodo"
                      type="text"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Periodo"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Forma de Cobro, Tarjeta, Fecha de Grabación, Usuario de Grabación -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.forma_cobro_nombre"
                      type="text"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Forma Cobro"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.tarjeta_nombre"
                      type="text"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Tarjeta"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.fecha_grabacion"
                      type="text"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="F. Grab."
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-text-field
                      v-model="encab.usuario"
                      type="text"
                      outlined
                      dense
                      hide-details
                      readonly
                      label="Usua. Grab."
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Total Bruto, Neto y Descuento -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-2">
                    <TextFieldMoney 
                      v-model="encab.total_bruto"
                      outlined
                      dense
                      :label="`Total Bruto`"
                      v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': true, 'filled': boolString == 'true' }"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-2">
                    <TextFieldMoney 
                      v-model="encab.total_neto"
                      outlined
                      dense
                      :label="`Total Neto`"
                      v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': true, 'filled': boolString == 'true' }"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-2">
                    <TextFieldMoney 
                      v-model="encab.descuentos"
                      outlined
                      dense
                      :label="`Total Descuento`"
                      v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': true, 'filled': boolString == 'true' }"
                    />
                  </v-col>
                </v-row>
                <!-- Neto Grabado, Iva, Fecha Contable -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="5" md="5" class="py-2">
                    <TextFieldMoney 
                      v-model="encab.neto_grabado"
                      outlined
                      dense
                      :label="`Neto Grabado`"
                      v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': true, 'filled': boolString == 'true' }"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-2">
                    <TextFieldMoney 
                      v-model="encab.iva"
                      outlined
                      dense
                      :label="`Iva`"
                      v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': true, 'filled': boolString == 'true' }"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2" align="right">
                    <FechaPicker
                      v-model="encab.fecha_contable"
                      label="Fecha Contable"
                      :hideDetails="true"
                      :clearable="encab.auditado == 0"
                      :disabled="encab.auditado == 1"
                    />
                  </v-col>
                </v-row>
                <!-- Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="encab.auditado == 0">
                  <v-col cols="12" class="py-1" align="right">
                    <BtnConfirmar
                      :texto="'¿Confirma reprocesar la liquidación mensual?'"
                      @action="reprocesarLiqMensual()"
                      color="info"
                      nombre="Reprocesar"
                      icono="fas fa-sync-alt"
                      :clase="'ma-2'"
                    />
                    <BtnConfirmar
                      :texto="'¿Desea guardar los cambios realizados?'"
                      @action="guardarCambios()"
                      color="success"
                      nombre="Guardar"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Descuentos -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                Descuentos
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Neto 21%</th>
                            <th class="text-center">Iva 21%</th>
                            <th class="text-center">Neto 10.5%</th>
                            <th class="text-center">Iva 10.5%</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody v-if="descuentos.length > 0">
                          <tr>
                            <td class="text-center">
                              <div v-if="editarDesc == false">{{ format_money(descuentos[0].neto_grabado_21) }}</div>
                              <div v-else>
                                <TextFieldMoney 
                                  v-model="descuentos[0].neto_grabado_21"
                                  outlined
                                  dense
                                  v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': false, 'filled': boolString == 'false' }"
                                />
                              </div>
                            </td>
                            <td class="text-center">{{ format_money(descuentos[0].iva_21) }}</td>
                            <td class="text-center">
                              <div v-if="editarDesc == false">{{ format_money(descuentos[0].neto_grabado_10_5) }}</div>
                              <div v-else>
                                <TextFieldMoney 
                                  v-model="descuentos[0].neto_grabado_10_5"
                                  outlined
                                  dense
                                  v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': true, 'readonly': false, 'filled': boolString == 'false' }"
                                /></div>
                            </td>
                            <td class="text-center">{{ format_money(descuentos[0].iva_10_5) }}</td>
                            <td class="text-center">
                              <v-tooltip bottom v-if="editarDesc == false && encab.auditado == 0">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="info" @click="editarDescuentos()" v-on="on">
                                    <v-icon small>fas fa-edit</v-icon>
                                  </v-btn>
                                </template>
                                <span>Editar</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="editarDesc == true && encab.auditado == 0">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="warning" @click="cancelarEditarDescuentos()" v-on="on">
                                    <v-icon small>fas fa-ban</v-icon>
                                  </v-btn>
                                </template>
                                <span>Deshacer</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="editarDesc == true && encab.auditado == 0">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="success" @click="confirmarDescuentos()" v-on="on">
                                    <v-icon small>far fa-check-circle</v-icon>
                                  </v-btn>
                                </template>
                                <span>Confirmar</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr><td>
                            <v-alert
                              class="mx-auto mt-4"
                              max-width="400"
                              type="warning"
                              border="left"
                              dense
                              text
                            >
                              Sin Descuentos para mostrar.
                            </v-alert>
                          </td></tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-end" v-if="encab.auditado == 0">
                <BtnConfirmar
                  :texto="'¿Desea confirmar los cambios para las Bases Imponibles?'"
                  @action="confirmarBasesImp()"
                  color="success"
                  nombre="Confirmar Base Imp."
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- Retenciones de Ingresos Brutos -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                Retenciones de Ingresos Brutos
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12">
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="headers"
                      :items="retenciones"
                      :loading="load"
                      dense
                      :search="search"
                    >
                      <!-- Buscador en el datatable -->
                      <template v-slot:top>
                        <v-row class="d-flex justify-end pa-2" no-gutters>
                          <v-col cols="6" sm="3">
                            <SearchDataTable
                              v-model="search"
                            />
                          </v-col>
                        </v-row>
                      </template>
                      <!-- Msj que se mostrara si no existen resultados -->
                      <template v-slot:no-data>
                        <v-alert
                          class="mx-auto mt-4"
                          max-width="400"
                          type="warning"
                          border="left"
                          dense
                          text
                        >
                          Sin Retenciones para mostrar.
                        </v-alert>
                      </template>
                      <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                      <template
                        v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                        v-slot:[`item.${header.value}`]="{ value }"
                      >
                        {{ header.formatter(value) }}
                      </template>
                      <!-- ultima row para el total -->
                      <template slot="body.append">
                        <tr class="font-weight-bold">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th class="text-right">Total Ret. Ing. Brutos: {{ format_money(retenciones.filter(element => element.descuento_id != 200).reduce((sum, item) => sum + roundNumber(item.importe, 2), 0)) }}</th>
                        </tr>
                        <tr class="font-weight-bold">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th class="text-right">Total Ret. Tucumán: {{ format_money(retenciones.filter(element => element.descuento_id == 200).reduce((sum, item) => sum + roundNumber(item.importe, 2), 0)) }}</th>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn
                  class="ma-2"
                  color="warning"
                  @click="$router.push('/liquidaciones-mensuales')"
                >
                  <v-icon small class="ma-1">fas fa-arrow-left</v-icon> Volver
                </v-btn>
                <BtnConfirmar
                  :texto="'¿Está seguro de Auditar esta Liquidación? No se podrán realizar modificaciones posteriormente a esta acción.'"
                  @action="auditar(1)"
                  color="info"
                  nombre="Auditar"
                  icono="fas fa-user-shield"
                  v-if="encab.auditado == 0 && permiso == 1"
                />
                <BtnConfirmar
                  :texto="'¿Está seguro de Desauditar esta Liquidación?'"
                  @action="auditar(0)"
                  color="primary"
                  nombre="Desauditar"
                  icono="fas fa-shield-virus"
                  v-if="encab.auditado == 1 && permiso == 1"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Liquidaciones Asociadas -->
    <ModalLiqAsociadas 
      :datos="dialogLiq"
      @setearModalLiqAsoc="setModalLiqAsoc"
    />
  </div>
</template>

<script>
import TextFieldMoney from '../../components/util/TextFieldMoney.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import FechaPicker from '../../components/util/FechaPicker.vue'
import ModalLiqAsociadas from '../../components/administracion/ModalLiqAsociadas.vue'
import router from '../../router'
import { roundNumber, format_money, cadenaVacia } from '../../util/utils'
import moment from 'moment'
export default {
  data() {
    return {
      format_money: format_money,
      roundNumber: roundNumber,
      encab: {
        id: null,
        nro_resumen: null,
        nro_comercio: null,
        periodo: null,
        forma_cobro_nombre: null,
        tarjeta_nombre: null,
        fecha_grabacion: null,
        usuario: null,
        total_bruto: null,
        total_neto: null,
        descuentos: null,
        neto_grabado: null,
        iva: null,
        fecha_contable: moment(new Date()).format('DD/MM/YYYY'),
        auditado: 0
      },
      boolString: "true",
      descuentos: [],
      editarDesc: false,
      descuentosCopy: {},
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'Tipo Desc.', align: 'left', value: 'tipo_desc_nombre' },
        { text: 'Descuento', align: 'left', value: 'descuento_nombre' },
        { text: 'Importe', align: 'right', value: 'importe', formatter: format_money }
      ],
      retenciones: [],
      load: false,
      search: '',
      dialogLiq: {
        activo: false,
        liquidacion_id: 0
      },
      permiso: 0
    }
  },
  components: {
    TextFieldMoney,
    SearchDataTable,
    BtnConfirmar,
    FechaPicker,
    ModalLiqAsociadas
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('liqMensuales/initLiqMensual', {liquidacion_id: this.$route.params.id})
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      if (initPeticion.liquidacion.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontró datos para la Liquidación Mensual solicitada.',
          color: 'info',
        })
        setTimeout(() => [router.push({path: "/liquidaciones-mensuales"})], 3000)
        return
      }
      this.encab = initPeticion.liquidacion[0]
      // acomodo los valores
      this.encab.descuentos = roundNumber(this.encab.descuentos, 2)
      this.encab.neto_grabado = roundNumber(this.encab.neto_grabado, 2)
      this.encab.iva = roundNumber(this.encab.iva, 2)
      this.encab.total_neto = roundNumber(this.encab.total_neto, 2)
      this.encab.total_bruto = roundNumber(this.encab.total_bruto, 2)
      this.encab.fecha_grabacion = moment(this.encab.fecha_grabacion).format("DD/MM/YYYY")
      this.encab.periodo = moment(this.encab.periodo).format("DD/MM/YYYY")
      this.encab.fecha_contable = cadenaVacia(this.encab.fecha_contable) ? moment(new Date()).format('DD/MM/YYYY') : moment(this.encab.fecha_contable).format('DD/MM/YYYY')
      // descuentos
      this.descuentos = initPeticion.descuentos
      // retenciones de ingresos brutos
      this.retenciones = initPeticion.retenciones_ing_brutos
      // permiso para auditar / desauditar
      this.permiso = initPeticion.permiso
    },
    async guardarCambios(){
      if (cadenaVacia(this.encab.fecha_contable) || cadenaVacia(this.encab.nro_resumen)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el Nro de Resumen y Fecha Contable.',
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let savePeticion = await this.$store.dispatch('liqMensuales/saveCambiosEnc', {
        liquidacion_id: this.$route.params.id,
        nro_resumen: this.encab.nro_resumen,
        fecha_contable: this.encab.fecha_contable
      })
      this.$store.state.loading = false
      if (savePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Liquidación modificada con éxito.',
        color: 'success',
      })
    },
    async reprocesarLiqMensual(){
      this.$store.state.loading = true
      let syncPeticion = await this.$store.dispatch('liqMensuales/syncLiqMensual', {liquidacion_id: this.$route.params.id})
      this.$store.state.loading = false
      if (syncPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: syncPeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Se reprocesó la Liquidación exitosamente.',
        color: 'success',
      })
      setTimeout(() => [location.reload()], 3000)
    },
    editarDescuentos(){
      this.descuentosCopy = JSON.parse(JSON.stringify(this.descuentos[0]))
      this.editarDesc = true
    },
    cancelarEditarDescuentos(){
      this.descuentos[0].iva_10_5 = roundNumber(this.descuentosCopy.iva_10_5, 2)
      this.descuentos[0].iva_21 = roundNumber(this.descuentosCopy.iva_21, 2)
      this.descuentos[0].neto_grabado_10_5 = roundNumber(this.descuentosCopy.neto_grabado_10_5, 2)
      this.descuentos[0].neto_grabado_21 = roundNumber(this.descuentosCopy.neto_grabado_21, 2)
      this.descuentosCopy = {}
      this.editarDesc = false
    },
    confirmarDescuentos(){
      this.descuentosCopy = {}
      this.editarDesc = false
    },
    async confirmarBasesImp(){
      this.$store.state.loading = true
      let savePeticion = await this.$store.dispatch('liqMensuales/saveBaseImponible',{
        liquidacion_id: this.$route.params.id,
        descuentos: this.descuentos
      })
      this.$store.state.loading = false
      if (savePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Base Imponible actualizada con éxito.',
        color: 'success',
      })
    },
    async liqAsociadas(){
      this.dialogLiq.liquidacion_id = this.$route.params.id
      this.dialogLiq.activo = true
    },
    setModalLiqAsoc(value){
      this.dialogLiq.activo = value
      if (this.dialogLiq.activo == false) this.dialogLiq.liquidacion_id = 0
    },
    async auditar(opcion){
      this.$store.state.loading = true
      let auditaPeticion = await this.$store.dispatch('liqMensuales/auditar', {
        liquidacion_id: this.$route.params.id,
        auditar: opcion
      })
      this.$store.state.loading = false
      if (auditaPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: auditaPeticion.msj,
          color: 'error',
        })
        return
      }
      let msjExito = ''
      if (opcion == 1){
        msjExito = 'La liquidación fue auditada con éxito.'
        this.encab.auditado = 1
      }else{
        msjExito = 'La liquidación fue desauditada con éxito.'
        this.encab.auditado = 0
      }
      this.$store.dispatch('show_snackbar', {
        text: msjExito,
        color: 'success',
      })
    }
  },
  watch: {
    descuentos: function(){
      if (this.descuentos.length > 0){
        for (let id in this.descuentos){
          this.descuentos[id].iva_10_5 = roundNumber(this.descuentos[id].iva_10_5, 2)
          this.descuentos[id].iva_21 = roundNumber(this.descuentos[id].iva_21, 2)
          this.descuentos[id].neto_grabado_10_5 = roundNumber(this.descuentos[id].neto_grabado_10_5, 2)
          this.descuentos[id].neto_grabado_21 = roundNumber(this.descuentos[id].neto_grabado_21, 2)
        }
      }
    },
    retenciones: function(){
      if (this.retenciones.length > 0){
        for (let id in this.retenciones){
          this.retenciones[id].importe = roundNumber(this.retenciones[id].importe, 2)
        }
      }
    }
  },
}
</script>

<style>

</style>