<template>
<div>
  <v-dialog
    v-model="activo"
    width="800"
    :persistent="nuevo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">{{ nuevo ? 'Nuevo Banner' : (permiso == 1 ? 'Editar Banner' : 'Ver Banner') }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3 mb-0">
        <v-row class="pt-2">
          <v-col cols="12" sm="6" md="8" class="py-1">
            Empresas
            <v-autocomplete
              v-model="empresas_select"
              item-text="empresa_abrev"
              :items="empresas"
              hide-details
              outlined
              dense
              :clearable="permiso == 1"
              multiple
              chips
              small-chips
              :deletable-chips="permiso == 1"
              return-object
              :readonly="permiso == 0"
              :filled="permiso == 0"
              :error="errorMsjEmp"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1" v-if="!nuevo">
            Descripción
            <v-text-field 
              v-model="banner.descripcion"
              outlined
              dense
              :error="errorMsjNom"
              hide-details
              :clearable="permiso == 1"
              :readonly="permiso == 0"
              :filled="permiso == 0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1 d-flex justify-end align-end" v-if="nuevo">
            <v-btn @click="dialog = true" dark color="primary" :block="pantallaChica">
              <v-icon class="mr-2" small>fas fa-paperclip</v-icon> Adjuntar Archivos
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <img
              v-if="!nuevo"
              :src="`data:image/png;base64,${banner.img}`"
              :alt="banner.nombre_archivo"
              style="object-fit: contain; width: 100%; height: 100%;"
            /> 
            <v-simple-table dense v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nombre
                    </th>
                    <th class="text-left">
                      Descripción
                    </th>
                    <th class="text-right">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in files"
                    :key="index"
                  >
                    <td>
                      <v-icon
                        color="primary"
                        small
                        left
                      >
                        far fa-image
                      </v-icon>
                      {{ item.name }}
                    </td>
                    <td>
                      <v-text-field 
                        v-model="item.descripcion"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </td>
                    <td class="text-right">
                      <v-btn
                        color="info"
                        title="Previsualizar"
                        small
                        icon
                        @click="previsualizar(item/*item.name, item.file*/)"
                      >
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        title="Quitar"
                        small icon
                        @click="quitar(item)"
                      >
                        <v-icon small>fas fa-trash</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmar
          :texto="nuevo? `¿Estás seguro de queres grabar el banner?` : '¿Estás seguro de actualizar los datos del banner?'" 
          :nombre="nuevo ? 'GRABAR' : 'GUARDAR CAMBIOS'"
          :loading="load"
          @action="nuevo ? subirArchivos() : guardarCambios()"
        />
      </v-card-actions>
    </v-card>
    <AdjuntarArchivos
      v-model="dialog"
      :tipos="['.png', '.jpg', '.webp']"
      :archivos.sync="files"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre_ver"/>
  </v-dialog>
</div>
</template>

<script>
import AdjuntarArchivos from '../incidentes/AdjuntarArchivos.vue';
import BtnConfirmar from '../util/BtnConfirmar.vue';
import ImgViewer from '../util/ImgViewer.vue';
import { getBase64 } from '../../util/utils';

export default {
  name: 'ModalNvoEditarBanner',
  props:{
    value: Boolean,
    nuevo:{
      type: Boolean,
      default: true,
    },
    empresas: Array,
    datos:{
      type: Object,
      default: () => {}
    },
    permiso: Number
  },
  computed:{
    activo:{
      get(){
        return this.value;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  data(){
    return{
      dialog:         false,
      files:          [],
      archivos:       [],
      files_length:   0,
      empresas_select:[],
      load:           false,
      banner:         {},
      img:            '',
      dialog_img:     false,
      nombre_ver:     '',
      errorMsjEmp:    false,
      errorMsjNom:    false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  created(){

  },
  methods:{
    async init(){
      await new Promise(resolve => setTimeout(resolve, 1))
      this.$store.state.loading = true
      const res                 = await this.$store.dispatch('genericos/initBanner',{ id: this.banner.id });
      this.$store.state.loading = false
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.empresas_select = res.banner
    },
    async previsualizar (/*name,*/file) {
      this.nombre_ver   = file.name
      await getBase64(file).then(data => {
        this.img        = data
        this.dialog_img = true
      })
    },
    quitar (file) {
      const index = this.files.indexOf(file);
      this.files.splice(index, 1);

      this.files.forEach(e => {
        this.archivos.push({
          file:        e,
          name:        e.name,
          descripcion: e.descripcion,
        })
      });
    },
    validarCampos(){
        let error = {}
        if( this.empresas_select.length == 0 ){
          error.text    = 'Se debe seleccionar al menos una empresa.'
          error.color   = 'warning'
          this.errorMsjEmp = true;
          return error
        }
        if(this.nuevo){
          if( this.files.length == 0 ){
            error.text  = 'Se debe adjuntar al menos un archivo.'
            error.color = 'warning'
            return error
          }
          else{
            this.archivos = [];
            this.files.forEach(e => {
              this.archivos.push({
                file:        e,
                name:        e.name,
                descripcion: e.descripcion ? e.descripcion : '',
              })
            });
            let n = this.archivos.length;
            let i = 0;
            while(i<n && this.archivos[i].descripcion.length != 0){
              i++;
            }
            if(i<n){
              error.text  = 'No se admiten descripciones vacías.'
              error.color = 'warning'
              return error
            }
          }
        }else{
          if( !this.banner.descripcion ){
            error.text       = 'La descripción no puede ser vacía.';
            error.color      = 'warning';
            this.errorMsjNom = true;
            return error
          }
        }
        return error;
      },
    async subirArchivos(){
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }

      for (const item of this.archivos) {
        await getBase64(item.file).then(data => {
          item.file_base64 = data.split(',')[1]
        })
      }

      this.$store.state.loading = true
      const res                 = await this.$store.dispatch('genericos/nuevoBanners',{empresas: this.empresas_select, archivos: this.archivos});
      this.$store.state.loading = false

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }

      this.$store.dispatch('show_snackbar', { text: 'Banner grabado con éxito.', color: 'success' })
      this.activo = false
      this.$emit('actualizar');
    },
    async guardarCambios(){
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }
      this.$store.state.loading = true
      const res                 = await this.$store.dispatch('genericos/editarBanners',{empresas: this.empresas_select, descripcion: this.banner.descripcion, id: this.banner.id});
      this.$store.state.loading = false

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }

      this.$store.dispatch('show_snackbar', { text: 'Banner editado con éxito.', color: 'success' })
      this.activo = false
      this.$emit('actualizar');
    },
  },
  components:{
    AdjuntarArchivos, BtnConfirmar, ImgViewer
  },
  watch:{
    dialog: async function(val){
      if(!val){
      }
    },  
    files: function(val){
      if(val.length != this.files_length){
        this.files_length = val.length;
        if(this.files_length > 0){
          this.$store.dispatch('show_snackbar', { text: "Archivos seleccionados correctamente.", color: 'success' })
        }else{
          this.$store.dispatch('show_snackbar', { text: "No hay archivos seleccionados.", color: 'info' })
        }
      }
    },
    activo: function(val){
      if(!val){
        this.empresas_select = [];
        this.files           = [];
        this.archivos        = [];
        this.files_length    = 0;
      }else{
        this.banner = JSON.parse(JSON.stringify(this.datos))
        if(!this.nuevo){
          this.init();
        }
      }
    },
    empresas_select: function(val){
      if(val.length > 0){
        this.errorMsjEmp = false;
      }
    },
    'banner.descripcion': {
      handler: function(val){
        if(val){
          this.errorMsjNom = false;
        }
      },
      deep: true
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }

}
</script>