<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="dialog_cargar = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="$store.state.empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="$store.state.sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipo Destinatario
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_ent"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Destinatario
                  <v-text-field
                    v-model.trim="filtro.entidad_cod"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    @blur="find_entidad"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar destinatario"
                        small
                        @click="dialog_buscar = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    class="pt-6"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Periodo
                  <FechaPickerMes
                    :fecha.sync="filtro.periodo"
                  />
                </v-col>
                <v-col :cols="big ? 12 : 6" :sm="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="filtro.fecha"
                    :limpiar.sync="limpiar_fecha"
                    @changeSize="changeSize"
                  />
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <SelectedCounter
        :items.sync="gift_cards"
        :selected.sync="selected"
      />

      <v-data-table
        v-model="selected"
        v-on:item-expanded="get_caja"
        class="cebra elevation-2 mt-2"
        item-key="codigo"
        :headers="headers"
        :items="gift_cards"
        :loading="load"
        :expanded.sync="expanded"
        expand-icon="fas fa-angle-down"
        single-expand
        show-expand
        show-select
        dense
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>

        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" >
            <v-scroll-x-transition>
              <div
                v-if="info"
                v-html="info"
              ></div>
            </v-scroll-x-transition>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            class="mr-2"
            title="Imprimir"
            small
            @click="imprimir(item)"
          >
            fas fa-print
          </v-icon>
          <v-icon
            color="purple"
            class="mr-2"
            title="Caja"
            small
            @click="expand(item)"
          >
            fas fa-cash-register
          </v-icon>
          <BtnConfirmar
            v-if="item.estado_id !== 4"
            icono="fas fa-ban"
            color="error"
            title="Dar de baja"
            :texto="`¿Desea dar de baja la Gift Card <strong>${item.codigo}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="dar_baja(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>

      <v-row cols="12" class="d-flex justify-end mx-0 my-1">
        <v-btn
          color="info"
          class="mt-2 ml-2"
          :disabled="selected.length < 2"
          @click="imprimir_masiva"
        >
          <v-icon left>fas fa-print</v-icon>
          Impresión masiva
        </v-btn>
        <v-btn
          color="success"
          class="mt-2 ml-2"
          @click="dialog_masiva = true"
        >
          <v-icon left>fas fa-plus</v-icon>
          Carga masiva
        </v-btn>
      </v-row>

    </v-col>

    <NuevaGiftCard
      v-model="dialog_cargar"
      :key="cargarKey"
      :tipos_ent="tipos_ent"
      :tipos_gf="tipos_gf"
      :estados="estados"
    />

    <CargaMasiva
      v-model="dialog_masiva"
      :tipos_ent="tipos_ent"
      :tipos_gf="tipos_gf"
      :estados="estados"
    />

    <ModalEntidades
      v-model="dialog_buscar"
      :sucursal="filtro.sucursal"
      :tipo="filtro.tipo"
      :entidad_id.sync="filtro.entidad_id"
      :entidad_cod.sync="filtro.entidad_cod"
      :entidad_nom.sync="filtro.nombre"
    />

    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_titulo"
    />

  </v-row>
</template>

<script>
import { format_money } from '../../util/utils'
import { giftcardA4, giftcardA4_masiva } from '../../util/plantillas/pdfs'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPickerMes from '../../components/util/FechaPickerMes'
import FechaPickerRango from '../../components/util/FechaPickerRango'
import ModalEntidades from '../../components/util/ModalEntidades'
import NuevaGiftCard from '../../components/giftcards/NuevaGiftCard'
import CargaMasiva from '../../components/giftcards/CargaMasiva'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import PDFViewer from '../../util/plantillas/PDFViewer'
import SelectedCounter from '../../components/util/SelectedCounter'

export default {
  data () {
    return {
      cargarKey: 0, // esta variable se utiliza para refrescar el dialog de nueva giftcard
      panel: 0,
      big: false,
      load: false,
      dialog_pdf: false,
      dialog_buscar: false,
      dialog_cargar: false,
      dialog_masiva: false,
      limpiar_fecha: false,
      pdf: null,
      pdf_nombre: 'incentivo',
      pdf_titulo: 'Gift Card',
      info: '',
      item_sel: {},
      estados: [],
      tipos_gf: [],
      tipos_ent: [],
      gift_cards: [],
      expanded: [],
      selected: [],
      headers: [
        { text: '', value: 'data-table-expand' },
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Periodo', value: 'periodo' },
        { text: 'Destinatario', value: 'entidad_nombre' },
        { text: 'Importe', value: 'importe', align: 'end', formatter: format_money },
        { text: 'Estado', value: 'estado' },
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Tipo', value: 'tipo_entidad' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      filtro: {
        empresa: this.$store.state.empresas[0].id,
        codigo: null,
        sucursal: null,
        estado: null,
        tipo: null,
        entidad_cod: null,
        entidad_id: null,
        periodo: null,
        fecha: [ null, null ],
        nombre: '',
      }
    }
  },
  components: {
    BtnFiltro,
    FechaPickerMes,
    FechaPickerRango,
    ModalEntidades,
    NuevaGiftCard,
    CargaMasiva,
    BtnConfirmar,
    PDFViewer,
    SelectedCounter,
  },
  created () {
    if (this.$store.state.sucursales.length === 1) {
      this.filtro.sucursal = this.$store.state.sucursales[0].id
    }
    this.get_data()
  },
  watch: {
    dialog_cargar (val) {
      if (val) {
        (this.cargarKey += 1)
      }
    },
  },
  methods: {
    async imprimir_masiva () {
      this.$store.state.loading = true
      let data = []
      for (const index in this.selected) {
        const item = this.selected[index]

        await this.$store.dispatch('giftcards/get_data_print', { id: item.id })
          .then((res) => {
            // guarda los datos para imprimir
            data.push(res.data)
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }

      this.pdf = await giftcardA4_masiva(data)
      this.pdf_nombre = 'incentivo-masivo'
      this.pdf_titulo = 'Gift Card masiva'
      this.dialog_pdf = true

      this.$store.state.loading = false
    },
    async imprimir (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('giftcards/get_data_print', { id: item.id })
        .then(async (res) => {
          // genera el pdf para imprimir
          this.pdf = await giftcardA4(res.data)
          this.pdf_nombre = `incentivo-${item.codigo}`
          this.pdf_titulo = `Gift Card - ${item.entidad_nombre}`
          this.dialog_pdf = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async dar_baja (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('giftcards/baja_virtual', { gf_id: item.id })
        .then(() => {
          item.estado_id = 4
          item.estado = this.estados.find(e => e.id === 4).nombre
          this.$store.dispatch('show_snackbar', {
            text: 'Gift Card dada de baja correctamente',
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async get_caja (payload) {
      if (payload.value) {
        const item = payload.item
        this.$store.state.loading = true
        this.info = ''
        await this.$store.dispatch('giftcards/get_caja', { comp_caja: item.caja })
          .then(data => {
            const caja = data.caja
            this.info = `<strong>Caja</strong>: ${caja.numero} (${caja.nombre}); <strong>Usario</strong>: ${item.usuario}; <strong>Fecha</strong>: ${item.fecha}`
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }
    },
    async buscar () {
      if (!this.filtro.fecha[0] && !this.filtro.periodo) {
        this.$store.dispatch('show_snackbar', {
            text: 'Seleccione un rango de fechas o un periodo',
            color: 'warning',
          })
        return
      }
      this.load = true
      this.expanded = [] // limpia en caso de que haya quedado una row abierta
      this.selected = []
      this.gift_cards = []
      await this.$store.dispatch('giftcards/get_gift_cards', this.filtro)
        .then(data => {
          this.gift_cards = data.gift_cards
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    async get_data () {
      this.$store.state.loading = true
      await this.$store.dispatch('giftcards/get_estados')
        .then(data => {
          this.estados = data.estados
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('giftcards/get_tipos_entidad')
        .then(data => {
          this.tipos_ent = data.tipos
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('giftcards/get_tipos')
        .then(data => {
          this.tipos_gf = data.tipos
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async find_entidad () {
      if (this.filtro.entidad_cod) {
        this.$store.state.loading = true
        await this.$store.dispatch('giftcards/get_destinatario', { entidad: this.filtro.entidad_cod })
          .then((data) => {
            this.filtro.entidad_id = null
            this.filtro.entidad_cod = null
            this.filtro.nombre = ''

            switch (data.size) {
              case 1:
                const ente = data.entidades[0]
                this.filtro.entidad_id = ente.id
                this.filtro.entidad_cod = ente.codigo
                this.filtro.nombre = ente.nombre
                break

              case 0:
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró el destinatario',
                  color: 'error',
                })
                break

              default:
                this.$store.dispatch('show_snackbar', {
                  text: 'Existe más de una coincidencia',
                  color: 'error',
                })
                break
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false 
      }
    },
    expand (item) {
      if (this.expanded.length === 0) {
        // si no hay ninguna row abierta, abre la que seleccionó
        this.expanded.push(item)
        this.get_caja({
          item: item,
          value: true
        })
      }
      /** si hay una row abierta, verifica si donde hizo click
       *  es la misma row que esta abierta, si es así la cierra.
       *  Caso contrario, elimina la row vieja y abre la nueva
       */
      else if (item === this.expanded[0]) {
        this.expanded = []
      } else {
        this.expanded = []
        this.expanded.push(item)
        this.get_caja({
          item: item,
          value: true
        })
      }
    },
    limpiar () {
      this.filtro = {
        empresa: this.$store.state.empresas[0].id,
        codigo: null,
        sucursal: null,
        estado: null,
        tipo: null,
        entidad_cod: null,
        entidad_id: null,
        periodo: null,
        fecha: [ null, null ],
        nombre: '',
      }
      this.limpiar_fecha = true
      this.selected = []
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>