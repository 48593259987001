<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nuevo ? 'Nuevo' : (editar ? 'Editar' : 'Detalle' ) + ' Nº ' + credeb.numero }}
        <v-spacer></v-spacer>
        <template v-if="!p_nuevo">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="credeb.tipo"
                label="Tipo"
                :items="p_tipos"
                :rules="p_nuevo ? [rules.required] : []"
                :readonly="!p_nuevo"
                :filled="!p_nuevo"
                :autofocus="p_nuevo"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="credeb.cliente_codigo"
                label="Cliente"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @blur="buscar_cliente()"
              >
                <template v-slot:append-outer>
                  <v-icon
                    class="mt-1"
                    color="info"
                    title="Buscar cliente"
                    :disabled="!editar"
                    small
                    @click="dialog_buscar = true"
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="credeb.cliente_nombre"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <FechaPicker
                v-model="credeb.fecha"
                label="Fecha"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="credeb.importe"
                label="Importe"
                prefix="$"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="credeb.referencia"
                label="Referencia"
                rows="3"
                :readonly="!editar"
                :filled="!editar"
                hide-details
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <ModalMayoristas
      v-model="dialog_buscar"
      titulo_nombre="clientes"
      @obtener="set_cliente"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear un credito/debito
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar) al padre caundo se crea o edita un credito/debito (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import moment from 'moment'
import ModalMayoristas from '../util/ModalMayoristas'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'

export default {
  data () {
    return {
      load: false,
      editar: false,
      dialog_buscar: false,
      credeb: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nuevo: Boolean, // para editar una cuota enviar en false, para una cuota nueva en true
    p_crede: Object,  // en caso de ser nuevo enviar el objeto vacio {}
    p_tipos: Array    // array de tipos, por lo general tiene dos valores: CREDITO y DEBITO
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    ModalMayoristas,
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nuevo) {
          this.editar = true
          // si es nuevo setea los campos en null
          this.credeb = {
            tipo: null,
            cliente_codigo: null,
            cliente_nombre: null,
            fecha: moment(new Date()).format('DD/MM/YYYY'),
            importe: null,
            referencia: null,
            imputacion: 'MANUAL'
          }
        } else {
          this.editar = false
          // crea una copia del prop p_crede para editarlo
          this.credeb = JSON.parse(JSON.stringify(this.p_crede))
        }
      } else {
        this.$refs.form.resetValidation()
      }
    },
    editar (val) {
      if (!val) {
        // cuando cancela la edicion vuelve a dejar los campos como estaban originalmente
        this.credeb = JSON.parse(JSON.stringify(this.p_crede))
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nuevo) {
          await this.$store.dispatch('creditosDebitos/nuevo', this.credeb)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
              // emite la accion 'nuevo' definifa en el padre
              this.$emit('nuevo', res.item)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente se modifico algun valor
          if (JSON.stringify(this.credeb) != JSON.stringify(this.p_crede)) {
            await this.$store.dispatch('creditosDebitos/editar', this.credeb)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.credeb)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: `No se realizaron cambios en el ${this.credeb.tipo} Nº ${this.credeb.numero}: los nuevos valores son iguales a los anteriores`,
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    async buscar_cliente () {
      const cliente = this.credeb.cliente_codigo
      if (cliente && this.editar) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_mayoristas', { codigo: cliente })
          .then((res) => {
            this.credeb.cliente_nombre = res.item.nombre
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            // limpia los datos
            this.credeb.cliente_codigo = null
            this.credeb.cliente_nombre = null
          })
        this.$store.state.loading = false
      }
    },
    set_cliente (obj) {
      this.credeb.cliente_codigo = obj.codigo
      this.credeb.cliente_nombre = obj.nombre
    }
  }
}
</script>