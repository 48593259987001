import config from '../../config'
import store from '../store'

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async anular_factura_compra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anulacionPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/cancelFacturaCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anulacion = await anulacionPeticion.json()
          return resolve({
            resultado: anulacion.resultado,
            msj: anulacion.message
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anular_factura_compra: ' + error.message
          })
        }
      })
    },
    async get_an_fc_data({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let facturaPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getAnFcData?factura_id=${datos.idFactura}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let factura = await facturaPeticion.json()
          return resolve({
            resultado: factura.resultado,
            msj: factura.message,
            datos: factura.datos,
            tiposFacturas: factura.facTipos,
            detalles: factura.datosDetalles,
            retenciones: factura.retencionesEsp,
            periodosPagos: factura.pagos,
            permiso: factura.permiso
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_an_fc_data: ' + error.message,
            datos: null,
            tiposFacturas: null,
            detalles: null
          })
        }
      })
    },
    async get_art_concepts_data({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let itemsPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getArtConcepXProv?proveedor_codigo=${datos.id_proveedor}&empresa_id=${datos.empresa_id}&opcion=${datos.opcion}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let items = await itemsPeticion.json()
          return resolve({
            resultado: items.resultado,
            msj: items.message,
            itemsModal: items.items
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_art_concepts_data: ' + error.message,
            itemsModal: null
          })
        }
      })
    },
    async get_retenciones_especiales({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let retPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getRetencionesEspeciales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let retenciones = await retPeticion.json()
          return resolve({
            resultado: retenciones.resultado,
            msj: retenciones.message,
            retenciones: retenciones.retenciones
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_retenciones_especiales: ' + error.message,
            retenciones: null
          })
        }
      })
    },
    async get_fp_compras({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let fpPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getFPCompras`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let fps = await fpPeticion.json()
          return resolve({
            resultado: fps.resultado,
            msj: fps.message,
            formas_pago: fps.formasPago
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_fp_compras: ' + error.message,
            formas_pago: null
          })
        }
      })
    },
    async update_fc_compra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/updateFCCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          return resolve({
            resultado: update.resultado,
            msj: update.message
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo update_fc_compra: ' + error.message
          })
        }
      })
    },
    async get_fc_files({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let filesPeticion = await fetch(`${config.BASE_URL}/getAllFiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let files = await filesPeticion.json()
          return resolve({
            resultado: files.exito,
            msj: 'OK',
            archivos: files.files,
            size: files.size
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_fc_files: ' + error.message,
            archivos: null,
            size: null
          })
        }
      })
    },
    async set_fc_verify({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let verifyPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/setFcVerify`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let verify = await verifyPeticion.json()
          return resolve({
            resultado: verify.resultado,
            msj: verify.message
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo set_fc_verify: ' + error.message
          })
        }
      })
    },
    async get_proveedores({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let provPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getEmpresaProveedor?id_empresa=${datos.id_empresa}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let prov = await provPeticion.json()
          return resolve({
            resultado: prov.resultado,
            msj: prov.message,
            proveedores: prov.provOracle
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_proveedores: ' + error.message,
            proveedores: null
          })
        }
      })
    },
    async get_fc_tipos_comprobantes({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let compPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getFacComprasTiposComp`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let tipComprobantes = await compPeticion.json()
          return resolve({
            resultado: tipComprobantes.resultado,
            msj: tipComprobantes.message,
            tiposComp: tipComprobantes.tipos
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_fc_tipos_comprobantes: ' + error.message,
            proveedores: null
          })
        }
      })
    },
    async create_fc_compra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let createPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/createFCCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let create = await createPeticion.json()
          return resolve({
            resultado: create.resultado,
            msj: create.message,
            newFcId: create.newId
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo create_fc_compra: ' + error.message,
            newFcId: null
          })
        }
      })
    },
    async syncProveedoresBejerman({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPetition = await fetch(`${config.BASE_URL}/adminFacturasCompra/syncProveedoresBejerman?tipo_importacion=${datos.tipo_importacion}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sync = await syncPetition.json()
          let horas = {}
          if (sync.hasOwnProperty('horas')){
            horas = sync.horas
          }
          return resolve({
            resultado: sync.resultado,
            msj: sync.message,
            horas: horas
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncProveedoresBejerman: ' + error.message,
            horas: null
          })
        }
      })
    },
    async syncFCCompras({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPetition = await fetch(`${config.BASE_URL}/adminFacturasCompra/syncFCCompras?tipo_importacion=${datos.tipo_importacion}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sync = await syncPetition.json()
          let horas = {}
          if (sync.hasOwnProperty('horas')){
            horas = sync.horas
          }
          return resolve({
            resultado: sync.resultado,
            msj: sync.message,
            horas: horas
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncFCCompras: ' + error.message,
            horas: null
          })
        }
      })
    },
    async syncConceptosBejerman({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPetition = await fetch(`${config.BASE_URL}/adminFacturasCompra/syncConceptosBejerman?tipo_importacion=${datos.tipo_importacion}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sync = await syncPetition.json()
          let horas = {}
          if (sync.hasOwnProperty('horas')){
            horas = sync.horas
          }
          return resolve({
            resultado: sync.resultado,
            msj: sync.message,
            horas: horas
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncConceptosBejerman: ' + error.message,
            horas: null
          })
        }
      })
    },
    async get_multi_proveedor({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let multiPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getMulticuentaProv?proveedor_codigo=${datos.proveedor_codigo}&empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let multi = await multiPeticion.json()
          multi.msj = multi.message
          return resolve(multi)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_multi_proveedor: ' + error.message
          })
        }
      })
    },
    async get_condiciones_pago({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let condPagoPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/get_condiciones_pago`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let condPago = await condPagoPeticion.json()
          condPago.msj = condPago.message
          return resolve(condPago)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_condiciones_pago: ' + error.message
          })
        }
      })
    },
    async validarFecha({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let diaPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/validarFecha?fecha=${datos.fecha}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let dia = await diaPeticion.json()
          dia.msj = dia.message
          return resolve(dia)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo validarFecha: ' + error.message
          })
        }
      })
    },
    async get_fp_compras_pago({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let fpPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getFPComprasPago`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let fps = await fpPeticion.json()
          return resolve({
            resultado: fps.resultado,
            msj: fps.message,
            formas_pago: fps.formasPago
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_fp_compras_pago: ' + error.message,
            formas_pago: null
          })
        }
      })
    },
    async getEstadoControlActual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let estadosPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getEstadoControlActual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let estados = await estadosPeticion.json()
          estados.msj = estados.message
          return resolve(estados)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEstadoControlActual: ' + error.message
          })
        }
      })
    },
    async guardarPagosFinales({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let pagosPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/guardarPagosFinales`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let pagos = await pagosPeticion.json()
          pagos.msj = pagos.message
          return resolve(pagos)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo guardarPagosFinales: ' + error.message
          })
        }
      })
    },
    async uploadFilesOP({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let upPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/uploadFilesOP`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let up = await upPeticion.json()
          up.msj = up.message
          return resolve(up)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo uploadFilesOP: ' + error.message
          })
        }
      })
    },
    async getOrdenPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ordenPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/getOrdenPago?factura_id=${datos.factura_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let orden = await ordenPeticion.json()
          orden.msj = orden.message
          return resolve(orden)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrdenPago: ' + error.message
          })
        }
      })
    },
    async marcarFCPagadas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let upPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/marcarFCPagadas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let up = await upPeticion.json()
          up.msj = up.message
          return resolve(up)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarFCPagadas: ' + error.message
          })
        }
      })
    },
    /*
      RODRIGO - FACTURAS COMPRAS AUDITORIA
    */
    async iniciarFormulario({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/initFacturaCompra?factura_id=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al iniciar el formulario de Facturas de Compra: " + error.message
          })
        }
      })
    },
    async obternerRecepcioneXproveedor({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getRecepcionesXProveedor?proveedor_codigo=${payload.proveedor_codigo}&opcion=${payload.opcion}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener las Recepciones: " + error.message
          })
        }
      })
    },
    async guardarRecepcionesXfacturaCompra({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/saveRecepXFacturaCompra`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data);
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    //No se usa
    async obternerOrdenesCompraXproveedor({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getOrdenesXProv?proveedor_codigo=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async guardarOrdenesDeCompraXfacturaCompra({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/saveOrdCompraXFactCompra`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async obtenerArticulos({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getArticulosFacturas?empresa_codigo=${payload.empresa_codigo}&proveedor_codigo=${payload.proveedor_codigo}&todos=${payload.todos}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los artículos: " + error.message
          })
        }
      })
    },
    async obtenerOrdenesDeCompraDisponibles({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getOrdenesCompraNewFC?proveedor_codigo=${payload.proveedor_codigo}&moneda_id=${payload.moneda_id}&opcion=${payload.opcion}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async obtenerDetallesRecepciones({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getRecepcionesDetalle`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async obtenerDetallesOrdenesCompra({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getOrdenesDetalle`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async crearFacturaCompra({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/saveFactCompra`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          /*
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }*/
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al crear la Nueva Factura: " + error.message
          })
        }
      })
    },
    async obtenerFacturasCompraProveedor({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getFactCompXProveedor?proveedor_codigo=${payload.proveedor_codigo}&empresa_codigo=${payload.empresa_codigo}&tipo=${payload.tipo}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async obtenerDetalleFacturasCompraAsociada({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/getDetallesFC?factura_id=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
            resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los detalles: " + error.message
          })
        }
      })
    },
    async actualizarFacturaCompra({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFacturasCompra/updateFactCompra`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al actualizar la Factura de Compra: " + error.message
          })
        }
      })
    },
    async cambiarFCEstadoControl({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cambioPeticion = await fetch(`${config.BASE_URL}/adminFacturasCompra/changeControlStatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cambio = await cambioPeticion.json()
          cambio.msj = cambio.message
          return resolve(cambio)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo cambiarFCEstadoControl: ' + error.message
          })
        }
      })
    }
  }
}