<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="800"
    >
      <v-card
        flat
        :color="color"
      >
        <v-card-title>
          {{ titulo_modal }}
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" class="px-0 py-1">
              <v-data-table
                class="cebra elevation-2"
                :headers="bloquear_cant == true ? headerss : bloquear_precio == true ? headersss : con_stock == true ? headers_stock : headers"
                :items="con_stock == true && en_stock ? articulos.filter(art => art.stock > 0) : articulos"
                :loading="load"
                dense
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col v-if="con_stock == true" cols="12" sm="3">
                      <v-checkbox
                        v-model="en_stock"
                        label="Centro de distribución"
                        class="mb-1"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-spacer v-if="con_stock == true"></v-spacer>
                    <v-col cols="12" sm="6" md="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin artículos para mostrar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-btn icon small title="Agregar" @click="agregarArticulo(item)"><v-icon small color="success">fas fa-check</v-icon></v-btn>
                </template>
                <!-- Cantidad -->
                <template v-slot:[`item.cantidad`]="{ item }" v-if="bloquear_cant == false">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field v-model="item.cantidad" type="number" outlined dense style="width: 50px" hide-details></v-text-field>
                  </v-row>
                </template>
                <!-- Precio s/Iva -->
                <template v-slot:[`item.precio_s_iva`]="{ item }" v-if="bloquear_precio == false">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field v-model="item.precio_s_iva" type="number" prefix="$" outlined dense hide-details></v-text-field>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from './SearchDataTable.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalSelectArt', value)
      }
    },
    articulos: {
      get(){
        return this.datos.articulos
      }
    },
    bloquear_cant: {
      get(){
        return this.datos.bloquear_cant
      }
    },
    bloquear_precio: {
      get(){
        return this.datos.bloquear_precio
      }
    },
    cerrar_seleccion: {
      get(){
        return this.datos.cerrar_seleccion
      }
    },
    titulo_modal: {
      get(){
        return this.datos.titulo_modal
      }
    },
    color: {
      get(){
        return this.datos.color
      }
    },
    con_stock: {
      get(){
        return this.datos.con_stock
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barra', align: 'left', value: 'articulo_cod_barras' },
        { text: 'Cod. EAN', align: 'left', value: 'codigo_ean' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio', align: 'center', value: 'precio_s_iva' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'left', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headersss: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barra', align: 'left', value: 'articulo_cod_barras' },
        { text: 'Cod. EAN', align: 'left', value: 'codigo_ean' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers_stock: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barra', align: 'left', value: 'articulo_cod_barras' },
        { text: 'Cod. EAN', align: 'left', value: 'codigo_ean' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Stock', align: 'center', value: 'stock' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      en_stock: true,
      load: false,
      search: '',
      sortBy: 'articulo_codigo',
      sortDesc: true
    }
  },
  methods: {
    agregarArticulo(item){
      this.$emit('setearArticulo', item)
      if (this.cerrar_seleccion == true) this.activo = false
    }
  },
}
</script>

<style>

</style>