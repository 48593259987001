<template>
<div>
<v-dialog
    v-model="activo"
    width="950"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">Descuentos Especiales</div>
        <!-- Boton Agregar nueva Promoción -->
        <v-btn
          class="ml-4"
          color="success"
          x-small
          fab
          title="Nuevo Descuento"
          @click="nuevoDescuento()"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent="buscar()">
              <v-row class="pt-2">
                <v-col cols="12" sm="4" md="4" class="py-1 px-2">
                  Documento
                  <v-text-field
                    v-if="esDni"
                    v-model.trim="documento"
                    hide-details
                    outlined
                    dense
                    clearable
                    @change="determinarTipoDoc()"
                    v-bind:options="{
                      inputMask: '###########',
                      outputMask: '###########',
                      empty: null
                    }"
                  ></v-text-field>
                  <v-text-field-integer
                    v-if="!esDni"
                    v-model.trim="documento"
                    v-bind:properties="{
                      hideDetails: true,
                      clearable: true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '##-########-#',
                      outputMask: '###########',
                      empty: null
                    }"
                    @change="determinarTipoDoc()"
                  />
                </v-col>
                <v-col cols="12" sm="5" md="4" class="py-1">
                  Cliente
                  <v-text-field
                    v-model="cliente_nombre"
                    hide-details
                    filled
                    readonly
                    outlined
                    dense
                  >
                    <template v-slot:append-outer v-if="existeCliente">
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on">
                            <v-icon class="px-2" color="info" small >
                              fas fa-info-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <span> Este documento no le pertenece a ningún cliente registrado. Igual puede continuar para grabar. </span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="4" class="py-1 px-0">
                  <BtnFiltroVue
                    :loading="load"
                    @action="buscar()"
                    clase="mt-0"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <!-- DATA TABLE -->
        <v-row>
          <v-col cols="12" class="px-0 py-1">
            <v-data-table 
              class="cebra mt-2 elevation-1"
              :headers="header"
              :items="descuentos"
              :loading="load"
              :search="search"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              dense
            >
              <!-- Busqueda -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pt-0 pl-3 pr-3 pb-3" no-gutters>
                  <v-col cols="6" sm="3" md="2">
                    <SearchDataTableVue
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para mostrar
                </v-alert>
              </template>
              <template v-slot:[`item.acciones`]="{item}">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-btn @click="inhabilitarDescuento(item)" icon small v-if="item.inhabilitado == 0">
                      <v-icon
                        class="px-2"
                        v-on="on"
                        color="error"
                        small
                      >
                        fas fa-ban
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> Anular </span>
                </v-tooltip>
              </template>
              <!-- Fecha Desde -->
              <template v-slot:[`item.fecha_desde`]="{item}">
                {{ moment(item.fecha_desde).format('DD/MM/YY') }}
              </template>
              <!-- Fecha Hasta -->
              <template v-slot:[`item.fecha_hasta`]="{item}">
                {{ moment(item.fecha_hasta).format('DD/MM/YY') }}
              </template>
              <!-- Formato moneda -->
              <!-- <template
                v-for="header in header.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template> -->
              <template v-slot:[`item.inhabilitado`]={item}>
                <v-icon v-if="item.inhabilitado == null || item.inhabilitado == 0" color="success" small>
                  fas fa-check-circle
                </v-icon>
              </template>
              <template v-slot:[`item.porcentaje`]={item}>
                {{item.porcentaje}} %
              </template>
            </v-data-table> 
          </v-col>
        </v-row>
      </v-card-text>
      <ModalAgregarNvoDescEspecialVue 
        v-model="objModal.activo"
        :datos="objModal"
        @buscarNuevoDesc="buscarDescNuevo"
      />
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import moment from 'moment';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import FechaPickerVue from '../util/FechaPicker.vue';
import ModalAgregarNvoDescEspecialVue from './ModalAgregarNvoDescEspecial.vue';
import BtnFiltroVue from '../util/BtnFiltro.vue';
import SearchDataTableVue from '../util/SearchDataTable.vue';

export default {
  name: 'ModalNvoEditarFeriados',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  data(){
    return{
      moment: moment,
      documento: null,
      esDni: true,
      cliente_nombre: null,
      load: false,
      search: '',
      objModal: {
        activo: false,
        cliente_documento: null,
        cliente_nombre: null,
      },
      header: [
        { text: 'Porcentaje', value: 'porcentaje', align: 'end' },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Categoría', value: 'categoria_nombre' },
        { text: 'Articulo', value: 'articulo_nombre' },
        { text: 'Fecha Desde', value: 'fecha_desde' },
        { text: 'Fecha Hasta', value: 'fecha_hasta' },
        { text: 'Vigente', value: 'inhabilitado',align: 'center', },
        { text: 'Acción', value: 'acciones', align: 'center', sorteable: false, filtreable: false,},
      ],
      descuentos:[],
      existeCliente: false,
    }
  },
  created(){
  },
  methods:{
    async init(){

    },
    determinarTipoDoc(){
      if(this.documento){
        if(0 < this.documento.length && this.documento.length <= 8){
          this.esDni = true;
        }
        if(8 < this.documento.length){
          this.esDni = false;
        }
        this.existeCliente = false;
        this.buscarNombreXdni();
      }
    },
    async buscarNombreXdni(){
      if(this.documento){
        this.descuentos = [];
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('promociones/getCliente', { documento: this.documento.toUpperCase() })
        this.$store.state.loading = false;
        
        if(res.resultado == 0){
          this.existeCliente = true;
          this.cliente_nombre = null;
          return this.$store.dispatch('show_snackbar', { text: res.message+' Igual puede continuar con la búsqueda.', color: 'info', })
          //return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
        }
        this.cliente_nombre = res.cliente.cliente_nombre; // /find
        this.existeCliente = false;
        this.buscar();
      }
    },
    async buscar(){
      if(!this.documento){
        return this.$store.dispatch('show_snackbar', { text: 'Es necesario ingresar un documento', color: 'warning' })
      }

      this.descuentos = [];
      this.search = '';
      this.load = true;
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('promociones/getDescuentosCliente', { documento: this.documento.toUpperCase() })
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.descuentos = res.descuentos;
    },
    limpiar(){
      this.documento = null;
      this.cliente_nombre = null;
      this.descuentos = [];
      this.search = '';
      this.load = false;
      this.existeCliente = false;
    },
    nuevoDescuento(){
      //let docValidado = this.documento && this.documento != 0; //true pasa, false no
      //if((docValidado && this.cliente_nombre) || (!docValidado && !this.cliente_nombre)){
        this.objModal.cliente_documento = this.documento;
        this.objModal.cliente_nombre = this.cliente_nombre;
        this.objModal.activo = true;
      //}
    },
    //Una vez creado el nuevo descuento lo muestra junto con los otros del cliente
    async buscarDescNuevo(dni){
      this.documento = dni;
      this.search = '';

      this.$store.state.loading = true;
      const resp = await this.$store.dispatch('promociones/getCliente', { documento: this.documento.toUpperCase() })
      const res = await this.$store.dispatch('promociones/getDescuentosCliente', { documento: this.documento.toUpperCase() })
      this.$store.state.loading = false;

      if(resp.resultado == 0){
        if(resp.message == 'El cliente no existe en el sistema.'){
          this.cliente_nombre = null;
          this.existeCliente = true;
        }else {
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
        }
      }else{
        this.existeCliente = false;
        this.cliente_nombre = resp.cliente.cliente_nombre;
      }

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.descuentos = res.descuentos;
    },
    async inhabilitarDescuento(item){
      this.$swal.fire({
        icon: 'warning',
        title: `Está por anular el Descuento Especial. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true;
          const res = await this.$store.dispatch('promociones/inhabilitarDescuentoEspecial', { descuento_id: item.descuento_id })
          this.$store.state.loading = false;

          if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }

          this.$swal.fire({
            icon: 'success',
            title: 'Descuento Especial Anulado',
            text: 'Se anuló el descuento especial con éxito.',
            timer: 3000
          });
          let pos = this.descuentos.map(e => e.descuento_id).indexOf(item.descuento_id);
          if(pos != -1){
            this.descuentos[pos].inhabilitado = 1;
          }
        }
      })
    }
  },
  components:{
    FechaPickerVue, BtnConfirmarVue, ModalAgregarNvoDescEspecialVue,
    BtnFiltroVue, SearchDataTableVue
  },
  watch:{
    activo: function(val){
      if(!val){
        this.limpiar();
      }
    },
    documento: function(val){
      if(!this.documento){
        this.cliente_nombre = null;
        this.existeCliente = false;
      }
    },
  }
}
</script>

<style>

</style>