<template>
  <v-dialog
    v-model="activo"
    width="850">
    <v-card>
      <v-card-title>
        <span >Carga masiva de promociones</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" sm="5" md="4" class="py-1">
            Canal
            <v-autocomplete
              v-model="canal_id"
              item-text="canal_nombre"
              item-value="canal_id"
              :items="canales"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="8" sm="5" md="4" class="py-1 d-flex align-end" >
            <div class="d-flex">
              <v-btn
                title="Adjuntar archivo"
                color="primary"
                @click="$refs.uploader.click()"
                >
                <v-icon small class="mr-2">fas fa-upload</v-icon>
                Subir Archivo
              </v-btn>
            </div>
            <!-- Input del archivo, nunca visible -->
            <input 
              ref="uploader" 
              class="d-none" 
              type="file"
              :accept="tipos.join(',')"
              @change="onFileChanged($event)"
            >
          </v-col>
          <v-col cols="2" sm="2" md="4" class="py-1 d-flex align-end" >
            <download-excel
              name="Modelo de Subir Promos.xls"
              :data="[
                {a:'Promo Prueba a04',b: '30968', c: '130000', d: '95000', e: '10/04/2023', f: '12/04/2023', g: '500', h: ''},
                {a:'Promo Prueba a03',b: '30393', c: '46999',  d: '42000', e: '13/04/2023', f: '15/04/2023', g: '',    h: '4969'},
                {a:'Promo Prueba a55',b: '470',   c: '90000',  d: '75000', e: '13/04/2024', f: '15/04/2025', g: '',    h: ''},
              ]"
              :fields="{
                'Promoción': 'a',
                'Artículo': 'b',
                'Precio Venta': 'c',
                'Precio Desc.': 'd',
                'Fecha Inicio' : 'e',
                'Fecha Fin' : 'f',
                'Cupo' : 'g',
                'Promo Id Cupo': 'h'
              }"
            >
              <v-btn
                title="Descargar modelo"
                color="green darken-3"
                class="ml-2"
                dark
              >
                <v-icon
                  :left="$vuetify.breakpoint.mdAndUp"
                  small
                >
                  fas fa-file-download
                </v-icon>
                <span v-text="$vuetify.breakpoint.mdAndUp ? 'Modelo' : ''"></span>
              </v-btn>
            </download-excel>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="header"
              :items="promos"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Descuento -->
               <template v-slot:[`item.descuento`]="{item}">
                {{ roundNumber((1- (item.precio_descuento/item.precio_venta))*100,2) }}%
               </template>
              <!-- aplica formatos -->
              <template
                v-for="h in header.filter((h) => h.hasOwnProperty('formatter'))"
                v-slot:[`item.${h.value}`]="{ value }">
                {{ h.formatter(value) }}
              </template>
              <!-- Si no hay datos -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos de promociones
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="activo = false" color="secondary" class="mr-2">Cancelar</v-btn>
        <BtnConfirmar
          nombre="Grabar"
          texto="¿Desea grabar éstas promociones?"
          @action="grabarPromosMasivas"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import BtnConfirmar from '../util/BtnConfirmar.vue';
  import { read, utils } from 'xlsx'
  import moment from 'moment'
  import SearchDataTable from '../util/SearchDataTable.vue';
  import { format_money, roundNumber } from '../../util/utils';

  export default{
    name: 'ModalSubirArchivoPromo',
    props:{
      value: Boolean,
      canales: Array,
    },
    computed:{
      activo:{
        get(){
          return this.value;
        },  
        set(value){
          this.$emit('input', value);
        }
      }
    },
    data(){
      return {
        format_money: format_money,
        roundNumber: roundNumber,
        moment: moment,
        pantallaChica: false,
        canal_id: 0,
        promos: [],
        header: [
          { text: 'Promo', value: 'promo_nombre' },
          { text: 'Artículo', value: 'articulo_codigo' },
          { text: 'Precio Venta', value: 'precio_venta', align: 'end', formatter: format_money },
          { text: 'Precio Desc.', value: 'precio_descuento', align: 'end', formatter: format_money },
          { text: 'Descuento', value: 'descuento', align: 'end' },
          { text: 'Fec. Inicio', value: 'fecha_inicio' },
          { text: 'Fec. Fin', value: 'fecha_fin' },
          { text: 'Cupo', value: 'cupo' },
          { text: 'Promo Id Cupo', value: 'promo_id_cupo', align: 'end'}
        ],
        nameFile: '',
        load: false,
        search: '',
        tipos: ['.xls', '.xlsx', '.xlsm', '.xlsb'],
      }
    },
    methods: {
      async init(){
       
      },
      async to_json (file) {
        return await new Promise(async (resolve, reject) => {
          try{
            let contenidoArchivo = []
            const reader = new FileReader()
            reader.onload = (e) => {
              const bytes = e.target.result
              const excel = read(bytes, { type: 'binary', cellDates: true })
              // obtiene el nombre de la primer hoja
              const hoja_nombre = excel.SheetNames[0]
              // obtiene la primer hoja
              const hoja = excel.Sheets[hoja_nombre]
              // obtiene la data de la primer hoja
              const data = utils.sheet_to_json(hoja, { header: 1 })
              // cicla por cada row de la primer hoja (excluyendo los headers)
              this.json = []
              for (let i = 1; i < data.length; i++){
                const row = data[i]
                if (row.length > 0){
                  let unaRow = {}
                  if (row[0] && row[1] && row[2] && row[3] && row[4] && row[5]){
                    unaRow = {
                      promo_nombre:     row[0],
                      articulo_codigo:  row[1],
                      precio_venta:     row[2],
                      precio_descuento: row[3],
                      fecha_inicio:     JSON.stringify(row[4]).indexOf('/') == -1 ? moment(row[4]).format("DD/MM/YYYY") : row[4],
                      fecha_fin:        JSON.stringify(row[5]).indexOf('/') == -1 ? moment(row[5]).format("DD/MM/YYYY") : row[5],
                      cupo:             row[6],
                      promo_id_cupo:    row[7],
                    }
                    contenidoArchivo.push(unaRow)
                  }
                }
              }
              return resolve({
                resultado: 1,
                msj: 'OK',
                contenido: contenidoArchivo
              })
            }
            reader.readAsBinaryString(file)
          }catch(error){
            return resolve({
              resultado: 0,
              msj: 'Ocurrio un problema al ejecutar el metodo to_json: ' + error.message,
              contenido: null
            })
          }
        })
      },
      async onFileChanged() {
        this.$store.state.loading = true
        const selectedFile = event.target.files[0]
        this.nameFile = selectedFile.name;
        let contenidoPeticion = await this.to_json(selectedFile)
        if (contenidoPeticion.resultado == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo leer correctamente el contenido del archivo. Vuelva a intentarlo por favor.',
            color: 'error'
          })
          return
        }
        this.$refs.uploader.value = '';
        this.$store.state.loading = false
        this.promos = [];
        this.promos = contenidoPeticion.contenido;        
      },
      async grabarPromosMasivas(){
        if(!this.canal_id || this.canal_id == 0){
          return this.$store.dispatch('show_snackbar', { text: 'Se requiere seleccionar un canal.', color: 'info' })
        }

        this.$store.state.loading = true
        let generarPeticion       = await this.$store.dispatch('promociones/generarPromosFile', {file: this.promos, canal_id: this.canal_id})
        this.$store.state.loading = false
        if (generarPeticion.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: generarPeticion.msj, color: 'error' })
        }
        this.$store.dispatch('show_snackbar', { text: 'El archivo fué importado correctamente.', color: 'success' });

        // limpio el archivo seleccionado
        this.selectedFile = null
        this.activo = false;
      },
    },
    components:{
      BtnConfirmar, SearchDataTable 
    },
    watch: {
      activo: function(val){
        if(val){
          this.init();
        }else{
          this.promos               = [];
          this.nameFile             = '';
          this.$refs.uploader.value = '';
        }
      },
      '$vuetify.breakpoint.xs'(val){
        this.pantallaChica = val;
      },
    }
  }
</script>
