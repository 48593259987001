<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nuevo ? 'Alta de empleado' : `Empleado: ${empleado_copy.id} - ${empleado_copy.nombre} ${empleado_copy.apellido}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe && empleado_copy.inhabilitado != 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <!-- FORMULARIO -->
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model.trim="empleado.apellido"
                label="Apellido"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model.trim="empleado.nombre"
                label="Nombre"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field-simplemask
                v-model="empleado.cuil"
                label="CUIL"
                v-bind:properties="{
                  rules: editar ? [rules.required] : [],
                  readonly: !editar,
                  filled: !editar,
                  'validate-on-blur': true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '##-########-#',
                  outputMask: '###########',
                  applyAfter: false,
                  alphanumeric: false,
                  empty: null
                }"
                @blur="autocompletar_dni()"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="empleado.tipo_doc"
                label="Tipo Documento"
                item-text="nombre"
                item-value="id"
                :items="tipos_doc"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="empleado.num_doc"
                label="Nº Documento"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <FechaPicker
                v-model="empleado.nacimiento"
                label="Fec. Nacimiento"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-autocomplete
                v-model="empleado.sexo"
                label="Sexo"
                item-text="nombre"
                item-value="id"
                :items="sexo"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" xl="3" class="py-0">
              <v-text-field
                v-model.trim="empleado.calle"
                label="Calle"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model="empleado.numero"
                label="Nº"
                type="number"
                :rules="editar && empleado.calle ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model="empleado.piso"
                label="Piso"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model.trim="empleado.dep"
                label="Dep."
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="empleado.telefono"
                label="Teléfono"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="empleado.celular"
                label="Celular"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="py-0">
              <v-text-field
                v-model.trim="empleado.mail"
                label="Email"
                :rules="editar && empleado.mail ? [rules.email] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="py-0">
              <v-autocomplete
                v-model="empleado.empresa"
                label="Empresa"
                item-text="nombre"
                item-value="id"
                :items="empresas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="empleado.area = null; empleado.puesto = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-autocomplete
                v-model="empleado.area"
                label="Área"
                item-text="nombre"
                item-value="id"
                :items="areas.filter(a => a.empresa == empleado.empresa && a.inhabilitada != 1 || a.id == empleado.area)"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="empleado.puesto = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="7" md="6" class="py-0">
              <v-autocomplete
                v-model="empleado.puesto"
                label="Puesto"
                item-text="nombre"
                item-value="id"
                :items="puestos.filter(p => p.area == empleado.area && p.inhabilitado != 1 || p.id == empleado.puesto)"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5" md="2" class="py-0">
              <v-autocomplete
                v-model="empleado.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <FechaPicker
                v-model="empleado.ingreso"
                label="Fec. Ingreso"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <FechaPicker
                v-if="!nuevo"
                v-model="empleado.egreso"
                label="Fec. Egreso"
                :disabled="!editar"
                :clearable="true"
              />
            </v-col>
            <v-col v-if="!nuevo" cols="6" sm="3" md="2" class="py-0">
              <v-checkbox
                v-model="empleado.inhabilitado"
                label="Inhabilitado"
                class="my-0"
                :disabled="!editar"
                :true-value="1"
                :false-value="0"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <!-- BOTONES -->
    <v-col cols="12" class="d-flex justify-end px-0">
      <v-btn
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3"
        @action="guardar()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'

export default {
  data () {
    return {
      id: this.$route.params.id,
      existe: false,
      editar: false,
      nuevo: false,
      sexo: ['F', 'M', 'X'],
      empleado_copy: {},
      empleado: {
        cuil: null,
        ingreso: null,
        egreso: null,
        inhabilitado: 0,
        puesto: null,
        area: null,
        empresa: null,
        sucursal: null,
        nombre: null,
        apellido: null,
        num_doc: null,
        tipo_doc: 1,
        nacimiento: null,
        sexo: null,
        calle: null,
        numero: null,
        piso: null,
        dep: null,
        telefono: null,
        celular: null,
        mail: null
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email no válido'
        },
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericosServ/get_empresas')
    await this.$store.dispatch('genericosServ/get_areas')
    await this.$store.dispatch('genericosServ/get_puestos')
    await this.$store.dispatch('genericosServ/get_tipos_doc')
    await this.$store.dispatch('genericosAcc/get_sucursales')
    this.$store.state.loading = false
    
    // si existe el id, obtengo los datos del empleado
    if (this.id) {
      this.$store.state.loading = true
      await this.$store.dispatch('empleados/get_empleado', this.id)
        .then((res) => {
          this.empleado = res.data
          this.empleado_copy = JSON.parse(JSON.stringify(this.empleado))
          this.existe = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
    else {
      this.editar = true
      this.nuevo = true
    }
  },
  computed: {
    ...mapState('genericosServ', ['empresas', 'areas', 'puestos', 'tipos_doc']),
    ...mapState('genericosAcc', ['sucursales'])
  },
  components: {
    FechaPicker,
    BtnConfirmar
  },
  watch: {
    editar (val) {
      this.$refs.form.resetValidation()
      if (!val) {
        this.empleado = JSON.parse(JSON.stringify(this.empleado_copy))
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (!this.empleado.ingreso || !this.empleado.nacimiento) {
          return this.$store.dispatch('show_snackbar', {
            text: 'La fecha de ingreso y/o nacimiento son obligatorias',
            color: 'orange'
          })
        }
        // nuevo empleado
        if (this.nuevo) {
          this.$store.state.loading = true
          await this.$store.dispatch('empleados/nuevo', this.empleado)
            .then((res) => {
              this.actualizar_copia()
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
        // editar empleado
        else {
          // solo edita si realmente cambio algun valor
          if (JSON.stringify(this.empleado) != JSON.stringify(this.empleado_copy)) {
            this.$store.state.loading = true
            await this.$store.dispatch('empleados/editar', this.empleado)
              .then((res) => {
                this.actualizar_copia()
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false
          }
          else {
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el empleado: los valores nuevos son iguales a los anteriores',
              color: 'orange',
              timeout: 4000
            })
          }
        }
      }
    },
    actualizar_copia () {
      this.empleado_copy = JSON.parse(JSON.stringify(this.empleado))
      this.editar = false
    },
    autocompletar_dni () {
      if (!this.empleado_copy.num_doc) {
        this.empleado.num_doc = this.empleado.cuil.substring(2, 10)
      }
    },
    cerrar () {
      window.close()
    }
  }
}
</script>