<template>
  <v-dialog
    v-model="activo"
    max-width="55%"
    persistent
  >
    <v-card
      flat
    >
      <v-card-title>
        Seleccione un Artículo para proseguir:
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="detalles"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon small title="Agregar" @click="seleccionarArticulo(item)"><v-icon small color="success">fas fa-check</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import SearchDataTable from '../util/SearchDataTable.vue'
export default {
  components: {
    SearchDataTable,
  },
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    detallesArts: {
      type: Array,
      default: []
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalSelectArt', value)
      }
    },
    detalles: {
      get(){
        return this.detallesArts
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Cód. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cód. Barras', align: 'center', value: 'codigo_barras' },
        { text: 'Cód. EAN', align: 'center', value: 'codigo_ean' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: ''
    }
  },
  methods: {
    seleccionarArticulo(item){
      this.$emit('seleccionarArt', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.search = ''
      }
    }
  },
}
</script>

<style>

</style>