import config from '../../config'
import store from '../store'
import { formatQuery } from '../util/utils'

export default{
  namespaced: true,
  state: {
    tipos_camp: [],
    estados_camp: [],
    formas_cobro: []
  },
  mutations: {
    set_tipos (state, payload) {
      state.tipos_camp = payload
    },
    set_estados (state, payload) {
      state.estados_camp = payload
    },
    set_formas_cobro (state, payload) {
      state.formas_cobro = payload
    }
  },
  actions: {
    async get_tipos ({ state, commit }) {
      try {
        if (state.tipos_camp.length == 0) {
          const res = await fetch(`${config.BASE_URL}/encuestas/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos', data.data)
          } else {
            commit('set_tipos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_camp.length == 0) {
          const res = await fetch(`${config.BASE_URL}/encuestas/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_campanias ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/campVentas?nombre=${formatQuery(filtro.nombre)}&tipo=${formatQuery(filtro.tipo)}&estado=${formatQuery(filtro.estado)}&fecha=${formatQuery(filtro.fecha)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_clientes ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/getClientes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nueva_camp ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/nuevaCamp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_detalles ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/detallesCamp?camp_id=${filtro.camp_id}&enviado=${filtro.enviado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_campania ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/campEncuesta?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async inhabilitar_camp ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/inhabilitar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async finalizar_camp ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/finalizar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_camp ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/editarCamp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async marcar_enviados ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/marcarEnviados`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async eliminar_detalle ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/eliminarDetalle`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async agregar_comentario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/agregarComentario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async repetidos_camp ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/repeditosOtrasCamp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    /*async get_permisos () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/permiso`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
  
          resolve(data.permiso == 1)
  
        } catch (error) {
          resolve(false)
        }
      })
    },*/
    async getPermiso ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getPermisoFuncion?funcion_codigo=${parseInt(payload.funcion_codigo)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json();
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            msj: 'Ocurrió un error al obtener permiso: '+error
          })
        }
      })
    },
    async get_formas_cobro ({ state, commit }) {
      try {
        if (state.formas_cobro.length == 0) {
          const res = await fetch(`${config.BASE_URL}/encuestas/formasCobro`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_formas_cobro', data.data)
          } else {
            commit('set_formas_cobro', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_formas_cobro', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async reasignar_vendedor ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/reasignar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async set_click_wpp({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/setClickWpp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_excel_campanias({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/getExcelCampanias?id=${payload.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async acceder_encuesta({}, datos){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/accederEncClima?codigo=${datos.codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método validar_clave: ' + error.message
          })
        }
      })
    },
    async guardar_datos_clasif({}, datos){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/guardarClasificacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método guardar_datos_clasif: ' + error.message
          })
        }
      })
    },
    async save_grupo_preguntas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/saveGrupoPreguntas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método save_grupo_preguntas: ' + error.message
          })
        }
      })
    },
    async finalizar_encuesta({}, datos){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/encuestas/finalizarEncuesta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
  
          const data = await res.json()
  
          resolve(data)
  
        } catch (error) {
          resolve({
            resultado: 0,
            message: 'Ocurrió un problema al ejecutar el método finalizar_encuesta: ' + error.message
          })
        }
      })
    }
  }
}