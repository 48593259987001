<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="400"
      :persistent="load"
    >
      <v-card flat>
        <v-card-title>
          Generar Liquidación Mensual
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="7" md="7" class="py-1">
              <FechaPickerMes
                :fecha.sync="periodo"
                :clearable="true"
                :label="true"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-1" align="right">
              <BtnConfirmar
                texto="¿Está seguro de generar las liquidaciones Mensuales para el Periodo seleccionado?"
                @action="comprobarExistencia()"
                color="success"
                nombre="Generar"
                icono="fas fa-magic"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      texto="Ya existen liquidaciones mensuales para el Periodo seleccionado. El proceso creará las nuevas liquidaciones y reprocesará las que ya existen ¿Confirma continuar?"
      @action="generarLiqMensual()"
      @setearModalConfAccion="setConfirmar"
    />
  </div>
</template>

<script>
import FechaPickerMes from '../util/FechaPickerMes.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import ModalConfirmar from '../util/ModalConfirmar.vue'
import { cadenaVacia } from '../../util/utils';
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      } 
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalGenLiqMens', value)
      }
    }
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
    ModalConfirmar
  },
  data() {
    return {
      periodo: null,
      load: false,
      dialogConf: false
    }
  },
  methods: {
    async generarLiqMensual() {
      this.$store.state.loading = true
      let generatePeticion = await this.$store.dispatch('liqMensuales/generaLiqMensual', {periodo: this.periodo})
      this.$store.state.loading = false
      if (generatePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: generatePeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Se generaron las liquidaciones mensuales para el Periodo seleccionado.',
        color: 'success',
      })
      this.activo = false
    },
    async comprobarExistencia() {
      if (cadenaVacia(this.periodo)){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese el Periodo.',
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let existePeticion = await this.$store.dispatch('liqMensuales/existeLiqMensual', {periodo: this.periodo})
      this.$store.state.loading = false
      if (existePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: existePeticion.msj,
          color: 'error',
        })
        return
      }
      if (existePeticion.existe > 0){
        this.dialogConf = true
        return
      }
      this.generarLiqMensual()
    },
    setConfirmar(value){
      this.dialogConf = value
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.dialogConf = false
        this.periodo = null
      }
    }
  },
}
</script>

<style>

</style>