<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Nueva cobranza"
            small
            fab
            @click="open_cobranza(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscarCobranzas()">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="pt-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal_codigo"
                    :items="sucursales"
                    item-text="sucursal_nombre" 
                    item-value="sucursal_codigo"
                    tabindex="1"
                    hide-details
                    outlined
                    dense clearable
                    @change="getPtosVentas()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="pt-1">
                  Punto de Venta
                  <v-autocomplete
                    v-model="filtro.pto_vta_codigo"
                    item-text="pto_vta_nombre"
                    item-value="pto_vta_codigo"
                    tabindex="1"
                    :items="selectPtosVenta"
                    hide-details
                    outlined
                    dense
                    clearable
                    @change="getVendedores()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="12" sm="6" md="6" class="pt-1">
                  Vendedor
                  <v-autocomplete
                    v-model="filtro.vendedor_codigo"
                    item-text="vendedor_nombre"
                    item-value="vendedor_codigo"
                    tabindex="1"
                    :items="selectVendedores"
                    hide-details
                    outlined
                    dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="pt-1">
                  Cliente código
                  <v-text-field
                    v-model="filtro.cliente_codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="pt-1">
                  Cliente
                  <v-text-field
                    v-model="filtro.cliente_nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="12" md="4">
                          Fecha Desde
                          <FechaPickerVue
                            v-model="filtro.fecha_desde"
                            clearable
                            hide-details
                            :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          Fecha Hasta
                          <FechaPickerVue
                            v-model="filtro.fecha_hasta"
                            clearable
                            hide-details
                            :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                          />
                        </v-col>
                        <v-col cols="12" xs="2" md="4">
                          ID Cobranza
                          <v-text-field
                            v-model="filtro.cobranza_id"
                            tabindex="1"
                            hide-details
                            outlined
                            dense
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-content-end" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''">
                      <BtnFiltroVue
                        :loading="load"
                        clase="mt-0"
                        @clear="limpiar()"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="deposito_nombre"
        :headers="headers"
        :items="cobranzas"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
              <DownloadDataTableVue
                name="Cobranzas"
                :data="cobranzas"
                :headers="headers_excel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            small
            @click="open_cobranza(item)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            class="ml-2"
            color="orange"
            title="Descargar recibo"
            small
            @click="descargaArchivo(item)"
          >
            fas fa-file-download
          </v-icon>
          
        </template>
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.cobranza_id`]="{item}">
          <strong>{{item.cobranza_id}}</strong>
        </template>
        <template v-slot:[`item.cliente_nombre`]="{item}">
          <strong>{{item.cliente_nombre}}</strong>
        </template>
      </v-data-table> 
    </v-col>
    <PDFViewerVue
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-row>
</div>
</template>

<script>
import moment from 'moment';
import BtnFiltroVue from '../../components/util/BtnFiltro.vue';
import DownloadDataTableVue from '../../components/util/DownloadDataTable.vue';
import FechaPickerVue from '../../components/util/FechaPicker.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import { format_money } from '../../util/utils';
import { order_list_by } from '../../util/utils';
import PDFViewerVue from '../../util/plantillas/PDFViewer.vue';
import { reciboLitechA4 } from '../../util/plantillas/pdfs';

export default {
  name: 'IndexMayoristaCobranza',
  data(){
    return{
      moment: moment,
      load: false,
      search: '',
      panel: 0,
      //pdf
      pdf: null,
      dialogPdf: false,
      pdf_nombre: '',
      //panel expandible de filtro
      filtro:{
        sucursal_codigo: null,
        pto_vta_codigo: null,
        vendedor_codigo: null,
        cliente_codigo: null,
        cliente_nombre: null,
        fecha_desde: null,
        fecha_hasta: null,
        cobranza_id: null,
      },
      selectPtosVenta:[],
      selectVendedores: [],
      //data-table
      headers: [
        { text: 'ID', value: 'cobranza_id' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Cliente Código', value: 'cliente_codigo' },
        { text: 'Cliente Nombre', value: 'cliente_nombre' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Total', value: 'total', align:'end' ,formatter: format_money },
        { text: 'Acción', value: 'acciones', align: 'center', sortable: false, filterable: false },
      ],
      cobranzas:[],
      headers_excel:{
        'ID': 'cobranza_id',
        'Fecha': 'fecha',
        'Cliente Código': 'cliente_codigo',
        'Cliente Nombre': 'cliente_nombre',
        'Estado': 'estado_nombre',
        'Total': 'total',
      },
      //init nuevo = 0 & cobranza_id = 1
      sucursales: [],
      ptos_venta: [],
      vendedores: [],
    }
  },
  created(){
    this.init();
  },
  methods:{
    //Trae al inicio los array para los autocomplete
    async init(){
      this.$store.state.loading = true;
      await this.$store.dispatch('mayoristaCobranzas/initMayoristaCobranza',{ nuevo: 1, cobranza_id: 0 })
      .then(res => {
        this.sucursales = res.sucursales; order_list_by(this.sucursales, 'sucursal_nombre'); 
        this.ptos_venta = res.ptos_vta; order_list_by(this.ptos_venta, 'pto_vta_nombre');
        this.vendedores = res.vendedores; order_list_by(this.vendedores, 'vendedor_nombre');
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
      this.$store.state.loading = false;
    },
    //busca las cobranzas según los valores del filtro
    async buscarCobranzas(){
      if(!this.filtro.fecha_desde){
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione una Fecha desde',
          color: 'orange'
        })
      }
      this.search = '';
      this.cobranzas = [];
      this.load = true;

      await this.$store.dispatch('mayoristaCobranzas/buscarCobranzas',this.filtro)
        .then((res) => {
          this.cobranzas = res.cobranzas;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })

      this.load = false;
    },
    //limpia los valores del filtro, el search, el array de cobranzas
    limpiar(){
      this.filtro = {
        sucursal_codigo: null,
        pto_vta_codigo: null,
        vendedor_codigo: null,
        cliente_codigo: null,
        cliente: null,
        fecha_desde: null,
        fecha_hasta: null,
        cobranza_id: null,
      }
      this.search = '';
      this.cobranzas = [];
      this.selectPtosVenta=[];
    },
    //devuelve los ptos de ventas con respecto a la sucursal seleccionada
    getPtosVentas(){
      if(this.filtro.sucursal_codigo){
        this.selectPtosVenta = this.ptos_venta.filter(elem => elem.sucursal_codigo === this.filtro.sucursal_codigo);
        this.filtro.vendedor_codigo = null;
        this.selectVendedores = [];
      }else{
        this.filtro.pto_vta_codigo = null;
        this.selectPtosVenta = [];
      }
    },
    //Devuelte los vendedores dependiendo del punto de venda seleccionado
    getVendedores(){
      if(this.filtro.pto_vta_codigo){
        this.selectVendedores = this.vendedores.filter(elem => elem.pto_vta_codigo === this.filtro.pto_vta_codigo);
      }else{
        this.filtro.vendedor_codigo = null;
        this.selectVendedores = [];
      }
    },
    //Abre la ventana de la cobranza
    open_cobranza(item){
      let path = '/nueva-cobranza'
      if (item) {
        path = `/cobranza/${item.cobranza_id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    //ícono naranja del pdf
    async descargaArchivo(item){      
      this.$store.state.loading = true
      const res = await this.$store.dispatch('mayoristaCobranzas/generarPdfCobranza', item.cobranza_id)
      this.$store.state.loading = false
      if(res.resultado == 1){
        this.pdf = await reciboLitechA4({
          cliente: res.cliente[0],
          encabezado: res.encabezado[0],
          saldos_usados: res.saldos_usados,
        })
        this.pdf_nombre = `Recibo - ${item.cobranza_id}`
        this.dialogPdf = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
      }
    }
  },
  computed:{

  },
  components:{
    FechaPickerVue, BtnFiltroVue, SearchDataTableVue, DownloadDataTableVue, PDFViewerVue
  }, 
  watch:{
    cobranzas: function(){
      if(this.cobranzas.length > 0){
        for(let i in this.cobranzas){
          this.cobranzas[i].total = parseFloat(this.cobranzas[i].total).toFixed(2);
        }
      }
    }
  }
}
</script>

<style>

</style>