<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar destinatario
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent="buscar_entidad">
          <v-row class="mx-4 mt-0 mb-3">
            <v-col cols="12" md="8" class="py-1">
              Nombre
              <v-text-field
                v-model.trim="nombre"
                hide-details
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
                :loading="load"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          v-model="selected"
          checkbox-color="primary"
          :headers="headers"
          :items="entidades"
          :loading="load"
          single-select
          show-select
          dense
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="selected.length"
          color="success"
          @click="select_entidad"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      nombre: '',
      selected: [],
      entidades: [],
      headers: [
        { text: 'Código', value: 'codigo' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo', value: 'tipo' },
      ],
    }
  },
  props: ['entidad_cod'],
  props: {
    value: Boolean,
    sucursal: Number,
    tipo:  Number,
    entidad_id: Number,
    entidad_nom: String,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    entidad: {
      get () {
        return this.entidad_id
      },
      set (value) {
        this.$emit('update:entidad_id', value)
      }
    },
    ent_codigo: {
      get () {
        return this.entidad_cod
      },
      set (value) {
        this.$emit('update:entidad_cod', value)
      }
    },
    ent_nombre: {
      get () {
        return this.entidad_nom
      },
      set (value) {
        this.$emit('update:entidad_nom', value)
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async buscar_entidad () {
      if (!this.nombre) {
        this.$store.dispatch('show_snackbar', {
          text: 'Nombre es obligatorio',
          color: 'warning',
        })
        return
      }
      this.load = true
      this.selected = []
      this.entidades = []
      await this.$store.dispatch('giftcards/get_destinatario', {
        sucursal: this.sucursal,
        tipo: this.tipo,
        nombre: this.nombre,
      })
        .then((data) => {
          this.entidades = data.entidades
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    select_entidad () {
      const ent = this.selected[0]
      this.entidad = ent.id
      this.ent_nombre = ent.nombre
      this.ent_codigo = ent.codigo
      this.dialog = false
    },
    clear () {
      this.nombre = ''
      this.selected = []
      this.entidades = []
    }
  }
}
</script>