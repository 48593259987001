<template>
  <div>
    <v-dialog
      v-model="activo"
      width="850"
      scrollable>
      <v-card>
        <v-card-title class="">
          <div style="font-size: 20px">Buscar Promoción</div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <!-- Listado de las promociones -->
          <v-data-table
            class="cebra elevation-2 mt-2"
            :headers="headers"
            :items="promos"
            :loading="load"
            dense
            :search="search"
            sort-by="promocion_id"
          >
            <!-- Buscador en el datatable -->
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Fecha Inicio -->
            <template v-slot:[`item.fecha_inicio`]="{item}">
              {{ item.fecha_inicio ? moment(item.fecha_inicio).format('DD/MM/YYYY') : ''}}
            </template>
            <!-- Fecha Inicio -->
            <template v-slot:[`item.fecha_fin`]="{item}">
              {{ item.fecha_fin ? moment(item.fecha_fin).format('DD/MM/YYYY') : '' }}
            </template>
            <!-- Descuento -->
            <template v-slot:[`item.descuento`]="{item}">
              {{ roundNumber(item.descuento,2) }}%
            </template>
            <!-- Accion seleccion -->
            <template v-slot:[`item.accion`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="success" @click="seleccionPromo(item)" v-on="on">
                    <v-icon class="" small>fas fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Seleccionar Promoción</span>
              </v-tooltip>
            </template>
            <!-- No hay datos -->
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No se encontraron promociones
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import moment from 'moment';
import FechaPickerVue from '../util/FechaPicker.vue';
import SearchDataTable from '../util/SearchDataTable.vue';
import { order_list_by, roundNumber } from '../../util/utils'; 

export default {
  name: 'ModalBuscarPromos',
  props:{
    value: Boolean,
    promociones: Array,
  },
  computed:{
    activo:{
      get(){
        return this.value;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    promos:{
      get(){
        return this.promociones;
      }
    }
  },
  data(){
    return{
      roundNumber: roundNumber,
      moment: moment,
      load: false,
      search: '',
      headers: [
        {text: 'ID', value: 'promocion_id'},
        {text: 'Descripcion', value: 'descripcion'},
        {text: 'Fec. Inicio', value: 'fecha_inicio'},
        {text: 'Fec. Fin', value: 'fecha_fin'},
        {text: 'Desc.', value: 'descuento', align: 'end'},
        {text: 'Acción', value: 'accion'},
      ],
      //promos: [],
    }
  },
  created(){
  },
  methods:{
    async init(){
      let body = {
        codigo: 0,
        descripcion:  '',
        estado:       -1,
        fecha_desde:  '',
        fecha_hasta:  '',
        categorias:   [],
        articulos:    [],
        fp:           0,
        cupon:        0,
        cupo:         0,
        combo:        0,
        fidelizacion: 0,
        cumpleanios:  0,
        monedas:      []
      }
      this.load = true;
      const res = await this.$store.dispatch('promociones/getPromociones', body);
      this.load = false;
      if(res.resultado == 0){
        this.$store.dispatch('show_snackbar', { text: res.msj, color: 'error' })
      }
      this.promos = res.promociones;
      order_list_by(this.promos, 'promocion_id');
    },
    seleccionPromo(item){
      this.$emit('seleccionarPromo', item.promocion_id);
      this.activo = false;
    }
  },
  components:{
    FechaPickerVue, SearchDataTable
  },
  watch:{
    activo: function(val){
      if(val){
        //this.init();
      }
    },
  }
}
</script>

<style>

</style>