<template>
  <v-dialog
    v-model="dialog"
    max-width="425px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva categoria' : 'Editar categoria' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="categoria.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-autocomplete
                v-model="categoria.rubro_codigo"
                label="Rubro"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="rubros"
                validate-on-blur
                outlined
                dense
              ><!-- @change="getCantidadRubro()" -->
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="pa-0">
              <v-btn
                color="success"
                title="Nuevo rubro"
                small
                icon
                @click="dialog_rubro = true; get_familias()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-autocomplete
                v-model="categoria.tipo_fe_codigo"
                label="FE tipo concepto"
                item-text="nombre"
                item-value="id"
                :rules="[rules.required]"
                :items="tipos_fe"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                v-model="categoria.solicita_fe_aut"
                label="Solicita FE autom."
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                v-model="categoria.no_paga_desc_empl"
                label="No comis. desc. empl."
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                v-model="categoria.solicita_serie"
                label="Solicita serie"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-checkbox
                v-model="categoria.actualiza_precio_may"
                label="Actualiza precio mayorista"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <!-- <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                v-model="categoria.cant_limit_anual_categoria"
                label="Cant. límite anual Desc. Empleados"
                type="number"
                outlined
                dense
                :hint="categoria.cant_arts_desc_emp_rubros ? `Tener en cuenta la cant. máx. del rubro: ${categoria.cant_arts_desc_emp_rubros}` : ''"
                :disabled="permiso_editar_cant == 0"
                @change="controlarCant()"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" class="py-1">
              <v-alert
                class="mx-auto mt-2 mb-0 pt-2" max-width="500"
                type="info" border="left"
                dense text
              >
                Luego se recomienda ver otras config. <br/>
                Por ej: ver promos con descuentos por categorías, desc. en efectivo, 
                desc. empleados, config. para que sume en el objetivo, etc…
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <Rubro
      v-model="dialog_rubro"
      :p_nuevo="true"
      :p_rubro="{}"
      :p_familias="familias"
      @nuevo="nuevo_rubro"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una categoria
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una categoria (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../../util/BtnConfirmar.vue';
import Rubro from '../Rubro.vue';
import { order_list_by } from '../../../util/utils';

export default {
  data () {
    return {
      load: false,
      dialog_rubro: false,
      familias: [],
      tipos_fe: [],
      categoria: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      },
      permiso_editar_cant: 0,
    }
  },
  async created () {
    this.$store.state.loading = true
    this.tipos_fe = []
    await this.$store.dispatch('get_tipos_concepto_fe')
      .then((res) => {
        this.tipos_fe = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_nueva: Boolean,    // para editar una categoria enviar en false, para una categoria nueva en true
    p_categoria: Object, 
    p_rubros: Array      // array de todos los rubros habilitados (enviar sincronizado por si crea un nuevo rubro desde este componente)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rubros: {
      get () {
        return this.p_rubros
      },
      set (value) {
        this.$emit('update:p_rubros', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    Rubro
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          // si es nueva setea los campos en null
          this.categoria = {
            codigo:                     null,
            nombre:                     null,
            rubro_codigo:               null,
            tipo_fe_codigo:             null,
            solicita_fe_aut:            null,
            no_paga_desc_empl:          null,
            solicita_serie:             null,
            actualiza_precio_may:       null,
            //cant_limit_anual_categoria: null,
            //cant_arts_desc_emp_rubros:  null
          }
        } else {
          // crea una copia del prop p_categoria para editarlo
          this.categoria = JSON.parse(JSON.stringify(this.p_categoria))
        }
        this.init();
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async init(){
      this.$store.state.loading = true;
      let res = await this.$store.dispatch('genericos/getPermiso', {funcion_codigo: 167});
      this.$store.state.loading = false;
      if(res.exito == 0){
        return this.$store.dispatch('show_snackbar', {  text: res.msj,   color: 'error'   })
      }
      this.permiso_editar_cant = res.permiso;
    },
    /*getCantidadRubro(){
      if(this.categoria.rubro_codigo){
        let aux = this.rubros.filter(e => e.codigo == this.categoria.rubro_codigo);
        this.categoria.cant_arts_desc_emp_rubros = aux[0].cant_art_desc_empleado;
      }
    },
    controlarCant(){
      if(this.categoria.cant_limit_anual_categoria && this.categoria.cant_limit_anual_categoria < 0){
        this.categoria.cant_limit_anual_categoria = 0;
      }
    }, */
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('categorias/nueva', this.categoria)
            .then((res) => {
              this.dialog = false
              // emite la accion 'nueva' definida en el padre
              this.$emit('nueva', res.categoria)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo edita si realmente se modifico la categoria
          if (JSON.stringify(this.categoria) != JSON.stringify(this.p_categoria)) {
            /*if((JSON.stringify(this.categoria.cant_limit_anual_categoria) != JSON.stringify(this.p_categoria.cant_limit_anual_categoria)) &&
               (this.categoria.cant_limit_anual_categoria == null || this.categoria.cant_limit_anual_categoria == undefined || !this.categoria.cant_limit_anual_categoria )
            ){
              this.load = false;
              return this.$store.dispatch('show_snackbar', { text: 'La cantidad no puede modificarse por un campo vacío.', color: 'info' })
            }*/

            await this.$store.dispatch('categorias/editar', this.categoria)
              .then((res) => {
                this.dialog = false
                // hace un UPPER del nombre de la categoria y obtiene el nombre del rubro
                this.categoria.nombre = this.categoria.nombre.toUpperCase()
                const rubro = this.rubros.find(rubro => rubro.codigo == this.categoria.rubro_codigo)
                if (rubro) {
                  this.categoria.rubro_nombre = rubro.nombre
                }
                // obtiene el nombre del fe tipo concepto
                const tipo_fe = this.tipos_fe.find(tfe => tfe.id == this.categoria.tipo_fe_codigo)
                if (tipo_fe) {
                  this.categoria.tipo_fe_nombre = tipo_fe.nombre
                }
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.categoria)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la categoria: el nombre, el rubro y los demás valores son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    async get_familias () {
      if (this.familias.length == 0) {
        this.$store.state.loading = true
        await this.$store.dispatch('rubros/get_familias')
          .then((res) => {
            this.familias = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        // ordena las familias por nombre desc
        order_list_by(this.familias, 'nombre')
        this.$store.state.loading = false 
      }
    },
    nuevo_rubro (rubro) {
      // añade el nuevo rubro al array de rubros y lo selecciona
      this.rubros.push(rubro)
      this.categoria.rubro_codigo = rubro.codigo
    }
  }
}
</script>